import React, { useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Mailverify } from "../Redux/Action";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";

const VerifyPassword = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordV, setPasswordV] = useState(false);
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(""); // State variable for email error
  const [passwordError, setPasswordError] = useState(""); // State variable for password error
  const dispatch = useDispatch();
  const [pass, setPass] = useState("");
  const [data, setData] = useState({
    email: id,
    code: "",
    password: "",
  });
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const togglePassword = () => {
    setPasswordV(!passwordV);
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleChangePassword = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    // Check if the password meets the minimum length requirement (3 characters)
    if (newPassword.length < 3) {
      setPasswordError("Minimum Password length should be 3 characters");
    } else {
      setPasswordError(""); // Reset the error message
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Reset error messages
    setEmailError("");
    setPasswordError("");

    if (data.password !== pass) {
      Swal.fire("Passwords do not match");
      return;
    }

    dispatch(Mailverify(data)).then((res) => {
      if (res.data.data === "Code expired") {
        Swal.fire(res.data.data);
        navigate("/login");
      } else {
        Swal.fire(res.data.data);
        navigate("/login");
      }
    });
  };

  return (
    <div>
      {/* <!--===// Start: login Form
        =================================--> */}
      <section
        id="post-section"
        class="post-section av-py-default"
        style={{
          backgroundColor: "#fff",
          backgroundImage: "url('/assets/images/register.jpeg')",
        }}
      >
        <div class="av-container">
          <div class="av-columns-area">
            <div class="av-column-10 mx-auto">
              <div id="comments" class="comments-area">
                <div id="respond" class="comment-respond">
                  <form onSubmit={handleSubmit}>
                    {/* <!-- Email input --> */}
                    <h3 id="reply-title" class="comment-reply-title">
                      Verify the code and set the new password.{" "}
                      <small>
                        <a
                          rel="nofollow"
                          id="cancel-comment-reply-link"
                          href="#"
                          style={{ display: "none" }}
                        >
                          Cancel reply
                        </a>
                      </small>
                    </h3>
                    {/* <div class="form-outline mb-4">
                      <label class="form-label" for="form2Example1">Enter Verify Code</label><br/>
                      <input
                        type="email"
                        id="form2Example1"
                        onChange={(e) => setData(e.target.value)}
                        class="form-control"
                        placeholder="Enter your Email"
                      />
                    </div> */}

                    <div class="form-outline mb-4">
                      <label class="form-label" for="form2Example1">
                        Enter Verify Code
                      </label>
                      <br />
                      <input
                        type="text"
                        id="form2Example1"
                        name="code"
                        onChange={handleChange}
                        class="form-control"
                        placeholder="Enter your verify code"
                        autoComplete="new-password"
                      />
                    </div>

                    {/* <!-- Password input --> */}
                    <div class="form-outline mb-4">
                      <label class="form-label" for="form2Example2">
                        Enter New Password
                      </label>
                      <br />
                      <div className="input-group" style={{ display: "flex" }}>
                        <input
                          type={passwordVisible ? "text" : "password"}
                          id="form2Example2"
                          required
                          name="password"
                          value={password}
                          onChange={handleChangePassword}
                          className="form-control"
                          placeholder="Enter your new password"
                          style={{ background: "white" }}
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary"
                          style={{
                            borderRadius: "none",
                            background: "none",
                            marginLeft: "-50px",
                            border: "none",
                            fontSize: "20px",
                          }}
                          onClick={togglePasswordVisibility}
                        >
                          {passwordVisible ? (
                            <BsEyeSlash style={{ color: "red" }} />
                          ) : (
                            <BsEye style={{ color: "red" }} />
                          )}
                        </button>
                      </div>
                    
                      {passwordError && (
                        <p className="text-danger" style={{ color: "red" }}>
                          {passwordError}
                        </p>
                      )}
                    </div>

                    <div class="form-outline mb-4">
                      <label class="form-label" for="form2Example2">
                        Enter Confirm Password
                      </label>
                      <br />
                      <div className="input-group" style={{ display: "flex" }}>
                        <input
                          type={passwordV ? "text" : "password"}
                          id="form2Example2"
                          required
                          name="pass"
                          value={pass}
                          onChange={(e) => setPass(e.target.value)}
                          className="form-control"
                          placeholder="Confirm your password"
                          style={{ background: "white" }}
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary"
                          style={{
                            borderRadius: "none",
                            background: "none",
                            marginLeft: "-50px",
                            border: "none",
                            fontSize: "20px",
                          }}
                          onClick={togglePassword}
                        >
                          {passwordV ? (
                            <BsEyeSlash style={{ color: "red" }} />
                          ) : (
                            <BsEye style={{ color: "red" }} />
                          )}
                        </button>
                      </div>
                      {/* Display password error */}
                    
                    </div>

                    {/* <!-- Submit button --> */}
                    <button
                      type="submit"
                      class="btn btn-primary btn-block mb-4"
                    >  
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: login Form
        =================================--> */}
    </div>
  );
};

export default VerifyPassword;
