import React, { useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { Forgotpassword } from "../Redux/Action";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    e.currentTarget.reset();
    dispatch(Forgotpassword({ email: email })).then((res) => {
      if (res.data.data === "User not found") {
        Swal.fire(res.data.data);
        navigate("/login");
      } else {
        Swal.fire(res.data.data);
        navigate("/verifypassword/" + email);
      }
    });
  };

  return (
    <div>
      {/* <!--===// Start: forgotpassword Form
        =================================--> */}
      <section
        id="post-section"
        class="post-section av-py-default"
        style={{
          backgroundColor: "#fff",
          backgroundImage: "url('/assets/images/register.jpeg')",
        }}
      >
        <div class="av-container">
          <div class="av-columns-area">
            <div class="av-column-10 mx-auto">
              <div id="comments" class="comments-area">
                <div id="respond" class="comment-respond">
                  {/* <h3 id="reply-title" class="comment-reply-title">Login Your Account <small><a rel="nofollow" id="cancel-comment-reply-link" href="#" style={{display:"none"}}>Cancel reply</a></small></h3>
                                <form action="#" method="post" id="commentform" class="comment-form" novalidate>
                                    <p class="comment-notes">
                                        <span id="email-notes">Your email address will not be published.</span> Required fields are marked <span class="required">*</span>
                                    </p>
                                    <p class="comment-form-author">
                                        <input id="author" name="author" type="text" size="30" maxlength="245" required='required' placeholder="Your Name" />
                                    </p>
                                    <p class="comment-form-email">
                                        <input id="email" name="email" type="email" size="30" maxlength="100" aria-describedby="email-notes" required='required' placeholder="Your Email" /></p>
                                    <p class="comment-form-subject">
                                        <input id="subject" name="subject" type="text" size="30" maxlength="200" placeholder="Your Subject" />
                                    </p>
                                    <p class="comment-form-comment">
                                        <textarea name="comment" id="comment" cols="45" rows="8" class="comment-textarea" placeholder="Write a comment&hellip;" required="required"></textarea>
                                    </p>
                                  
                                    <p class="form-submit">
                                        <input name="submit" type="submit" id="comment-submit" class="xl-btn" value="SUBMIT" /> 
                                        <input type='hidden' name='comment_post_ID' id='comment_post_ID' />
                                        <input type='hidden' name='comment_parent' id='comment_parent' />
                                    </p>
                                </form> */}
                  <form onSubmit={handleSubmit}>
                    {/* <!-- Email input --> */}
                    <h3 id="reply-title" class="comment-reply-title">
                      Kindly mention your email.
                      <small>
                        <a
                          rel="nofollow"
                          id="cancel-comment-reply-link"
                          href="#"
                          style={{ display: "none" }}
                        >
                          Cancel reply
                        </a>
                      </small>
                    </h3>
                    <div class="form-outline mb-4">
                      <input
                        type="email"
                        id="form2Example1"
                        onChange={(e) => setEmail(e.target.value)}
                        name="email"
                        class="form-control"
                        placeholder="Enter your email address"
                      />
                    </div>

                    {/* <!-- 2 column grid layout for inline styling --> */}
                    <div
                      class="row mb-4"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div class="col d-flex justify-content-center">
                        {/* <!-- Checkbox --> */}
                        <div class="form-check" style={{ display: "none" }}>
                          <label class="form-check-label" for="form2Example31">
                            {" "}
                            Remember me{" "}
                          </label>
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="form2Example31"
                            checked
                          />
                        </div>
                      </div>
                    </div>

                    {/* <!-- Submit button --> */}
                    <button
                      type="submit"
                      class="btn btn-primary btn-block mb-4"
                    >
                      NEXT
                    </button>

                    {/* <!-- Register buttons --> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: forgotpassword Form   
        =================================--> */}
    </div>
  );
};

export default ForgotPassword;
