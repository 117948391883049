import React from "react";
import { MdVerifiedUser, MdLocalOffer, MdMessage } from "react-icons/md";
import { RiCustomerService2Fill } from "react-icons/ri";

const Index = () => {
  return (
    <div>
      {/* <!--===// Start: groom
        =================================--> */}
      <section
        id="team-section"
        className="team-section av-py-default team-home shapes-section"
        style={{ backgroundColor: "#fff", paddingBottom: "0px" }}
      >
        <div className="av-container">
          <div className="av-columns-area">
            <div className="av-column-12">
              <div className="heading-default text-center wow fadeInUp">
                <h3>
                  Featured <span className="primary-color">Bride</span>
                </h3>
                <span className="separator">
                  <span>
                    <span></span>
                  </span>
                </span>
                <p>
                  Bride: A women with a fine prospect of happiness behind her.
                </p>
              </div>
            </div>
          </div>
          <div className="av-columns-area">
            <div className="av-column-3 av-sm-column-6">
              <div className="team-member">
                <div className="team-thumb" tabIndex="0">
                  <img
                    src="assets/images/team/team03.jpg"
                    alt="team_item"
                    title=""
                  />
                  {/* <div className="team-view"><a href="#"><i className="fa fa-eye"></i></a></div> */}
                  <div className="team-thumber">
                    <aside className="widget widget_social_widget">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fa fa-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </aside>
                  </div>
                </div>
                <div className="team-info">
                  <h5>
                    <a href="/bride">Steven Lucy</a>
                  </h5>
                  <p>View Profile</p>
                </div>
              </div>
            </div>
            <div className="av-column-3 av-sm-column-6">
              <div className="team-member">
                <div className="team-thumb" tabIndex="0">
                  <img
                    src="assets/images/team/team03.jpg"
                    alt="team_item"
                    title=""
                  />
                  {/* <div className="team-view"><a href="#"><i className="fa fa-eye"></i></a></div> */}
                  <div className="team-thumber">
                    <aside className="widget widget_social_widget">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fa fa-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </aside>
                  </div>
                </div>
                <div className="team-info">
                  <h5>
                    <a href="/bride">Glenn Maxwell</a>
                  </h5>
                  <p>View Profile</p>
                </div>
              </div>
            </div>
            <div className="av-column-3 av-sm-column-6">
              <div className="team-member">
                <div className="team-thumb" tabIndex="0">
                  <img
                    src="assets/images/team/team03.jpg"
                    alt="team_item"
                    title=""
                  />
                  {/* <div className="team-view"><a href="#"><i className="fa fa-eye"></i></a></div> */}
                  <div className="team-thumber">
                    <aside className="widget widget_social_widget">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fa fa-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </aside>
                  </div>
                </div>
                <div className="team-info">
                  <h5>
                    <a href="/bride">Aoron Finch</a>
                  </h5>
                  <p>View Profile</p>
                </div>
              </div>
            </div>
            <div className="av-column-3 av-sm-column-6">
              <div className="team-member">
                <div className="team-thumb" tabIndex="0">
                  <img
                    src="assets/images/team/team03.jpg"
                    alt="team_item"
                    title=""
                  />
                  {/* <div className="team-view"><a href="#"><i className="fa fa-eye"></i></a></div> */}
                  <div className="team-thumber">
                    <aside className="widget widget_social_widget">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fa fa-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </aside>
                  </div>
                </div>
                <div className="team-info">
                  <h5>
                    <a href="/bride">Christiana Ena</a>
                  </h5>
                  <p>View Profile</p>
                </div>
              </div>
            </div>
            <div className="av-column-3 av-sm-column-6">
              <div className="team-member">
                <div className="team-thumb" tabIndex="0">
                  <img
                    src="assets/images/team/team03.jpg"
                    alt="team_item"
                    title=""
                  />
                  {/* <div className="team-view"><a href="#"><i className="fa fa-eye"></i></a></div> */}
                  <div className="team-thumber">
                    <aside className="widget widget_social_widget">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fa fa-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </aside>
                  </div>
                </div>
                <div className="team-info">
                  <h5>
                    <a href="/bride">Glenn Maxwell</a>
                  </h5>
                  <p>View Profile</p>
                </div>
              </div>
            </div>
            <div className="av-column-3 av-sm-column-6">
              <div className="team-member">
                <div className="team-thumb" tabIndex="0">
                  <img
                    src="assets/images/team/team03.jpg"
                    alt="team_item"
                    title=""
                  />
                  {/* <div className="team-view"><a href="#"><i className="fa fa-eye"></i></a></div> */}
                  <div className="team-thumber">
                    <aside className="widget widget_social_widget">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fa fa-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </aside>
                  </div>
                </div>
                <div className="team-info">
                  <h5>
                    <a href="/bride">Aoron Finch</a>
                  </h5>
                  <p>View Profile</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shape13">
          <img src="assets/images/team/clipArt/shape13.png" alt="image" />
        </div>
        <div className="shape14">
          <img src="assets/images/team/clipArt/shape14.png" alt="image" />
        </div>
        <div className="shape15">
          <img src="assets/images/team/clipArt/shape15.png" alt="image" />
        </div>
        <div className="shape16">
          <img src="assets/images/team/clipArt/shape16.png" alt="image" />
        </div>
      </section>
      {/* <!-- End: groom
        =================================--> */}

      {/* <!--===// Start: bride
        =================================--> */}
      <section
        id="team-section"
        className="team-section av-py-default team-home shapes-section"
        style={{ backgroundColor: "#fff", paddingBottom: "0px" }}
      >
        <div className="av-container">
          <div className="av-columns-area">
            <div className="av-column-12">
              <div className="heading-default text-center wow fadeInUp">
                <h3>
                  Featured <span className="primary-color">Groom</span>
                </h3>
                <span className="separator">
                  <span>
                    <span></span>
                  </span>
                </span>
                <p>Groom: Being happy never goes out of style.</p>
              </div>
            </div>
          </div>
          <div className="av-columns-area">
            <div className="av-column-3 av-sm-column-6">
              <div className="team-member">
                <div className="team-thumb" tabIndex="0">
                  <img
                    src="assets/images/team/team02.jpg"
                    alt="team_item"
                    title=""
                  />
                  {/* <div className="team-view"><a href="#"><i className="fa fa-eye"></i></a></div> */}
                  <div className="team-thumber">
                    <aside className="widget widget_social_widget">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fa fa-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </aside>
                  </div>
                </div>
                <div className="team-info">
                  <h5>
                    <a href="/groom">Steven Lucy</a>
                  </h5>
                  <p>View Profile</p>
                </div>
              </div>
            </div>
            <div className="av-column-3 av-sm-column-6">
              <div className="team-member">
                <div className="team-thumb" tabIndex="0">
                  <img
                    src="assets/images/team/team02.jpg"
                    alt="team_item"
                    title=""
                  />
                  {/* <div className="team-view"><a href="#"><i className="fa fa-eye"></i></a></div> */}
                  <div className="team-thumber">
                    <aside className="widget widget_social_widget">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fa fa-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </aside>
                  </div>
                </div>
                <div className="team-info">
                  <h5>
                    <a href="/groom">Glenn Maxwell</a>
                  </h5>
                  <p>View Profile</p>
                </div>
              </div>
            </div>
            <div className="av-column-3 av-sm-column-6">
              <div className="team-member">
                <div className="team-thumb" tabIndex="0">
                  <img
                    src="assets/images/team/team02.jpg"
                    alt="team_item"
                    title=""
                  />
                  {/* <div className="team-view"><a href="#"><i className="fa fa-eye"></i></a></div> */}
                  <div className="team-thumber">
                    <aside className="widget widget_social_widget">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fa fa-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </aside>
                  </div>
                </div>
                <div className="team-info">
                  <h5>
                    <a href="/groom">Aoron Finch</a>
                  </h5>
                  <p>View Profile</p>
                </div>
              </div>
            </div>
            <div className="av-column-3 av-sm-column-6">
              <div className="team-member">
                <div className="team-thumb" tabIndex="0">
                  <img
                    src="assets/images/team/team02.jpg"
                    alt="team_item"
                    title=""
                  />
                  {/* <div className="team-view"><a href="#"><i className="fa fa-eye"></i></a></div> */}
                  <div className="team-thumber">
                    <aside className="widget widget_social_widget">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fa fa-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </aside>
                  </div>
                </div>
                <div className="team-info">
                  <h5>
                    <a href="/groom">Christiana Ena</a>
                  </h5>
                  <p>View Profile</p>
                </div>
              </div>
            </div>
            <div className="av-column-3 av-sm-column-6">
              <div className="team-member">
                <div className="team-thumb" tabIndex="0">
                  <img
                    src="assets/images/team/team02.jpg"
                    alt="team_item"
                    title=""
                  />
                  {/* <div className="team-view"><a href="#"><i className="fa fa-eye"></i></a></div> */}
                  <div className="team-thumber">
                    <aside className="widget widget_social_widget">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fa fa-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </aside>
                  </div>
                </div>
                <div className="team-info">
                  <h5>
                    <a href="/groom">Steven Lucy</a>
                  </h5>
                  <p>View Profile</p>
                </div>
              </div>
            </div>
            <div className="av-column-3 av-sm-column-6">
              <div className="team-member">
                <div className="team-thumb" tabIndex="0">
                  <img
                    src="assets/images/team/team02.jpg"
                    alt="team_item"
                    title=""
                  />
                  {/* <div className="team-view"><a href="#"><i className="fa fa-eye"></i></a></div> */}
                  <div className="team-thumber">
                    <aside className="widget widget_social_widget">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fa fa-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </aside>
                  </div>
                </div>
                <div className="team-info">
                  <h5>
                    <a href="/groom">Glenn Maxwell</a>
                  </h5>
                  <p>View Profile</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shape13">
          <img src="assets/images/team/clipArt/shape13.png" alt="image" />
        </div>
        <div className="shape14">
          <img src="assets/images/team/clipArt/shape14.png" alt="image" />
        </div>
        <div className="shape15">
          <img src="assets/images/team/clipArt/shape15.png" alt="image" />
        </div>
        <div className="shape16">
          <img src="assets/images/team/clipArt/shape16.png" alt="image" />
        </div>
      </section>
      {/* <!-- End: bride
        =================================--> */}

      {/* <!--===// Start: Contact Info
        =================================--> */}
      <section
        id="contactinfo-section"
        className="contactinfo-section av-py-default"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="av-container">
          <div className="av-columns-area">
            <div className="av-column-12">
              <div className="heading-default text-center wow fadeInUp">
                <h3>
                  Why Choose{" "}
                  <span className="primary-color">VVK Matrimony</span>
                </h3>
                <span className="separator">
                  <span>
                    <span></span>
                  </span>
                </span>
                <p>
                  Most trusted online matrimonial exclusive for Arya Vysya
                  Community since 2008.
                </p>
              </div>
            </div>
          </div>
          <div className="av-columns-area contactinfo-row">
            <div className="av-column-3 av-sm-column-6 px-2">
              <div className="contactinfo-item">
                <i className="fa fa-user"></i>
                <h5>Wide Profile Coverage</h5>
                <p style={{ textAlign: "justify" }}>
                  Easy registration steps - less than 10 minutes. Largest
                  collection of profiles in Arya Vysya Community. Quick and
                  advanced search option to shortlist your matching profile.
                </p>
                <a href="/about">Read More</a>
              </div>
            </div>
            <div className="av-column-3 av-sm-column-6 px-2">
              <div className="contactinfo-item">
                <i className="fa fa-camera"></i>
                <h5>
                  Enhanced <br /> Profile
                </h5>
                <p style={{ textAlign: "justify" }}>
                  Photo protection option. Personal notes option to keep track
                  of prospects. Personalized express interest. Show profile
                  option. Preferred partner settings. Pre populated matching
                  profiles.
                </p>
                <a href="/about">Read More</a>
              </div>
            </div>
            <div className="av-column-3 av-sm-column-6 px-2">
              <div className="contactinfo-item">
                <i className="fa fa-institution"></i>
                <h5>Matrimony Dashboard</h5>
                <p style={{ textAlign: "justify" }}>
                  Real time dashboard of number of visitors, viewed profiles,
                  shortlisted profiles, matching profile count, express interest
                  sent/received pre populated matching profiles.
                </p>
                <a href="/about">Read More</a>
              </div>
            </div>
            <div className="av-column-3 av-sm-column-6 px-2">
              <div className="contactinfo-item">
                <i className="fa fa-headphones"></i>
                <h5>
                  Happy <br /> To Help{" "}
                </h5>
                <p style={{ textAlign: "justify" }}>
                  Matrimonial assistance thru customer support helpline 98942 34066 | email: vvkmatrimony12@gmail.com | WhatsApp at
                  98942 34066. We are here to help you to find your soulmate.
                </p>
                <a href="/about">Read More</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: Contact Info
        =================================--> */}

      {/* <!--===// Start: CTA
        =================================--> */}
      <section
        id="cta-section"
        className="cta-section cta-home"
        style={{
          backgroundImage: "url('assets/images/cta_bg.jpg')",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="cta-overlay">
          <div className="av-container">
            <div className="av-columns-area">
              <div className="av-column-5 my-auto">
                <div className="call-wrapper">
                  <div className="call-icon-box">
                    <i className="fa fa-user"></i>
                  </div>
                  <div className="cta-info">
                    <div className="call-title">Call Us:</div>
                    <div className="call-phone">
                      <a href="#">+(91) 99999 99999</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="av-column-7 my-auto">
                <div className="cta-content-wrap">
                  <div className="cta-content">
                    <span className="cta-icon-wrap">
                      <i className="fa fa-phone"></i>
                    </span>
                    <h4>Professional and Dedicated Consulting Services</h4>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore.
                    </p>
                  </div>
                  <div className="cta-btn">
                    <a href="#" className="av-btn av-btn-primary av-btn-bubble">
                      Contact Us <i className="fa fa-arrow-right"></i>{" "}
                      <span className="bubble_effect">
                        <span className="circle top-left"></span>{" "}
                        <span className="circle top-left"></span>{" "}
                        <span className="circle top-left"></span>{" "}
                        <span className="button effect-button"></span>{" "}
                        <span className="circle bottom-right"></span>{" "}
                        <span className="circle bottom-right"></span>{" "}
                        <span className="circle bottom-right"></span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: CTA
        =================================--> */}

      {/* <!--===// Start: Pricing
        =================================--> */}
      <section
        id="pricing-section"
        className="pricing-section bg-primary-light av-py-default pricing-home"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="av-container">
          <div className="av-columns-area">
            <div className="av-column-6 av-sm-column-12">
              <div className="heading-default text-left wow fadeInUp">
                <h3>Not Any Hidded Charge Choose Your Plan</h3>
                <span className="separator">
                  <span>
                    <span></span>
                  </span>
                </span>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p>
              </div>
              <div className="pricing-tab wow fadeInUp">
                <a
                  href=";"
                  data-tab="monthly"
                  className="av-btn av-btn-secondary av-btn-bubble active"
                >
                  Monthly <i className="fa fa-arrow-right"></i>{" "}
                  <span className="bubble_effect">
                    <span className="circle top-left"></span>{" "}
                    <span className="circle top-left"></span>{" "}
                    <span className="circle top-left"></span>{" "}
                    <span className="button effect-button"></span>{" "}
                    <span className="circle bottom-right"></span>{" "}
                    <span className="circle bottom-right"></span>{" "}
                    <span className="circle bottom-right"></span>
                  </span>
                </a>
                <a
                  href=";"
                  data-tab="yearly"
                  className="av-btn av-btn-secondary av-btn-bubble"
                >
                  Yearly <i className="fa fa-arrow-right"></i>{" "}
                  <span className="bubble_effect">
                    <span className="circle top-left"></span>{" "}
                    <span className="circle top-left"></span>{" "}
                    <span className="circle top-left"></span>{" "}
                    <span className="button effect-button"></span>{" "}
                    <span className="circle bottom-right"></span>{" "}
                    <span className="circle bottom-right"></span>{" "}
                    <span className="circle bottom-right"></span>
                  </span>
                </a>
              </div>
            </div>
            <div className="av-column-6 av-sm-column-12">
              <div className="pricing-table-wrapper">
                <div id="tabsContent" className="tab-content">
                  <div id="monthly" className="tab-pane fade active show">
                    <div className="pricing-plan-item-list clearfix">
                      <div className="pricing-item">
                        <div className="pricing-heading">
                          <h5>Monthly Plan</h5>
                        </div>
                        <div className="pricing-rate">
                          <p className="pricing-name">Monthly Package</p>
                          <span className="pricing">$69.75</span>
                        </div>
                        <div className="pricing-list">
                          <ul>
                            <li>Photoshop</li>
                            <li>HTML5 & CSS 3</li>
                            <li>Java</li>
                            <li>Javascript</li>
                          </ul>
                        </div>
                        <div className="pricing-btn">
                          <a
                            href="#"
                            className="av-btn av-btn-primary av-btn-bubble"
                          >
                            Choose Plan <i className="fa fa-arrow-right"></i>{" "}
                            <span className="bubble_effect">
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="button effect-button"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="pricing-item">
                        <div className="pricing-heading">
                          <h5>Yearly Plan</h5>
                        </div>
                        <div className="pricing-rate">
                          <p className="pricing-name">Yearly Package</p>
                          <span className="pricing">$89.10</span>
                        </div>
                        <div className="pricing-list">
                          <ul>
                            <li>Photoshop</li>
                            <li>HTML5 & CSS 3</li>
                            <li>Javascript</li>
                          </ul>
                        </div>
                        <div className="pricing-btn">
                          <a
                            href="#"
                            className="av-btn av-btn-primary av-btn-bubble"
                          >
                            Choose Plan <i className="fa fa-arrow-right"></i>{" "}
                            <span className="bubble_effect">
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="button effect-button"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="yearly" className="tab-pane fade">
                    <div className="pricing-plan-item-list clearfix">
                      <div className="pricing-item">
                        <div className="pricing-heading">
                          <h5>Monthly Plan</h5>
                        </div>
                        <div className="pricing-rate">
                          <p className="pricing-name">Monthly Package</p>
                          <span className="pricing">$69.75</span>
                        </div>
                        <div className="pricing-list">
                          <ul>
                            <li>Photoshop</li>
                            <li>HTML5 & CSS 3</li>
                            <li>WordPress</li>
                            <li>Javascript</li>
                          </ul>
                        </div>
                        <div className="pricing-btn">
                          <a
                            href="#"
                            className="av-btn av-btn-primary av-btn-bubble"
                          >
                            Choose Plan <i className="fa fa-arrow-right"></i>{" "}
                            <span className="bubble_effect">
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="button effect-button"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="pricing-item">
                        <div className="pricing-heading">
                          <h5>Yearly Plan</h5>
                        </div>
                        <div className="pricing-rate">
                          <p className="pricing-name">Yearly Package</p>
                          <span className="pricing">$89.10</span>
                        </div>
                        <div className="pricing-list">
                          <ul>
                            <li>Photoshop</li>
                            <li>HTML5 & CSS 3</li>
                            <li>WordPress</li>
                          </ul>
                        </div>
                        <div className="pricing-btn">
                          <a
                            href="#"
                            className="av-btn av-btn-primary av-btn-bubble"
                          >
                            Choose Plan <i className="fa fa-arrow-right"></i>{" "}
                            <span className="bubble_effect">
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="button effect-button"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: Pricing
        =================================--> */}

      {/* <!--===// Start: Step Section
        =================================--> */}
      <section id="step-section" className="step-section av-py-default">
        <div className="av-container">
          <div className="av-columns-area">
            <div className="av-column-12">
              <div className="heading-default text-center wow fadeInUp">
                <h3>
                  Benefits of{" "}
                  <span className="primary-color">Premium Membership</span>
                </h3>
                <span className="separator">
                  <span>
                    <span></span>
                  </span>
                </span>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p>
              </div>
            </div>
          </div>
          <div className="av-columns-area">
            <div className="av-column-3 av-sm-column-6">
              <div className="stepbox">
                <div className="stepbox-outer">
                  <div className="step-effect"></div>
                  <div className="stepbox-icon">
                    {/* <span className="stepbox-number">1</span> */}
                    {/* <i className="fa fa-paint-brush"></i> */}
                    <MdVerifiedUser />
                  </div>
                </div>
                <div className="stepbox-content">
                  <h5 className="stepbox-title">
                    <a href=";">View Verified Contact Details</a>
                  </h5>
                  <div className="stepbox-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt.
                  </div>
                </div>
              </div>
            </div>
            <div className="av-column-3 av-sm-column-6">
              <div className="stepbox">
                <div className="stepbox-outer">
                  <div className="step-effect"></div>
                  <div className="stepbox-icon">
                    {/* <span className="stepbox-number">2</span> */}
                    {/* <i className="fa fa-paint-brush"></i> */}
                    <MdLocalOffer />
                  </div>
                </div>
                <div className="stepbox-content">
                  <h5 className="stepbox-title">
                    <a href=";">Special Offers From Partner</a>
                  </h5>
                  <div className="stepbox-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt.
                  </div>
                </div>
              </div>
            </div>
            <div className="av-column-3 av-sm-column-6">
              <div className="stepbox">
                <div className="stepbox-outer">
                  <div className="step-effect"></div>
                  <div className="stepbox-icon">
                    {/* <span className="stepbox-number">3</span> */}
                    {/* <i className="fa fa-paint-brush"></i> */}
                    <MdMessage />
                  </div>
                </div>
                <div className="stepbox-content">
                  <h5 className="stepbox-title">
                    <a href=";">Write Direct Message to Member</a>
                  </h5>
                  <div className="stepbox-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt.
                  </div>
                </div>
              </div>
            </div>
            <div className="av-column-3 av-sm-column-6">
              <div className="stepbox">
                <div className="stepbox-outer">
                  <div className="step-effect"></div>
                  <div className="stepbox-icon">
                    {/* <span className="stepbox-number">4</span> */}
                    {/* <i className="fa fa-paint-brush"></i> */}
                    <RiCustomerService2Fill />
                  </div>
                </div>
                <div className="stepbox-content">
                  <h5 className="stepbox-title">
                    <a href=";">Quick Response Service </a>
                  </h5>
                  <div className="stepbox-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: Step Section
        =================================--> */}

      {/* <!--===// Start: Testimonial
        =================================--> */}
      <section
        id="testimonial-section"
        className="testimonial-section av-py-default"
      >
        <div className="av-container">
          <div className="av-columns-area">
            <div className="av-column-12">
              <div className="heading-default heading-white text-center wow fadeInUp">
                <h3>
                  Happy<span className="primary-color"> Stories</span>
                </h3>
                <span className="separator">
                  <span>
                    <span></span>
                  </span>
                </span>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p>
              </div>
            </div>
          </div>
          <div className="av-columns-area">
            <div className="av-md-column-10 av-xs-column-12 mx-auto">
              <div className="testimonial-carousel owl-carousel owl-theme">
                <div className="testimonials-item">
                  <div className="testimonials-client-thumb">
                    <div className="img-fluid">
                      <img
                        src="assets/images/VM22221-550138.jpeg"
                        alt="testimonial"
                        data-img-url="assets/images/VM22221-550138.jpeg"
                      />
                    </div>
                  </div>
                  <div className="testimonials-content">
                    <div className="testimonials-icon">
                      <i className="fa fa-quote-right"></i>
                    </div>
                    {/* <div className="rating"><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></div> */}
                    <h4>Lorem ipsum dolor sit amet</h4>
                    <p>
                      I found my life partner in this platform and am very happy
                      that within a short span of time I met my perfect match
                      and we are marrying very soon.Thanks a lot VVK Matrimony.
                    </p>
                    <div className="testimonials-title">
                      <h5>Romeo & Juliet</h5>
                      {/* <p>IT Manager</p> */}
                    </div>
                  </div>
                </div>
                <div className="testimonials-item">
                  <div className="testimonials-client-thumb">
                    <div className="img-fluid">
                      <img
                        src="assets/images/ss1.jpg"
                        alt="testimonial"
                        data-img-url="assets/images/ss1.jpg"
                      />
                    </div>
                  </div>
                  <div className="testimonials-content">
                    <div className="testimonials-icon">
                      <i className="fa fa-quote-right"></i>
                    </div>
                    {/* <div className="rating"><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></div> */}
                    <h4>Lorem ipsum dolor sit amet</h4>
                    <p>
                      I found my life partner in this platform and am very happy
                      that within a short span of time I met my perfect match
                      and we are marrying very soon.Thanks a lot VVK Matrimony
                    </p>
                    <div className="testimonials-title">
                      <h5>Romeo & Juliet</h5>
                      {/* <p>PPO</p> */}
                    </div>
                  </div>
                </div>
                <div className="testimonials-item">
                  <div className="testimonials-client-thumb">
                    <div className="img-fluid">
                      <img
                        src="assets/images/ss2.jpg"
                        alt="testimonial"
                        data-img-url="assets/images/ss2.jpg"
                      />
                    </div>
                  </div>
                  <div className="testimonials-content">
                    <div className="testimonials-icon">
                      <i className="fa fa-quote-right"></i>
                    </div>
                    {/* <div className="rating"><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></div> */}
                    <h4>Lorem ipsum dolor sit amet</h4>
                    <p>
                      I found my life partner in this platform and am very happy
                      that within a short span of time I met my perfect match
                      and we are marrying very soon.Thanks a lot VVK Matrimony
                    </p>
                    <div className="testimonials-title">
                      <h5>Romeo & Juliet</h5>
                      {/* <p>PPO</p> */}
                    </div>
                  </div>
                </div>
                <div className="testimonials-item">
                  <div className="testimonials-client-thumb">
                    <div className="img-fluid">
                      <img
                        src="assets/images/ss3.jpg"
                        alt="testimonial"
                        data-img-url="assets/imagesss3.jpg"
                      />
                    </div>
                  </div>
                  <div className="testimonials-content">
                    <div className="testimonials-icon">
                      <i className="fa fa-quote-right"></i>
                    </div>
                    {/* <div className="rating"><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></div> */}
                    <h4>Lorem ipsum dolor sit amet</h4>
                    <p>
                      I found my life partner in this platform and am very happy
                      that within a short span of time I met my perfect match
                      and we are marrying very soon.Thanks a lot VVK Matrimony.
                    </p>
                    <div className="testimonials-title">
                      <h5>Romeo & Juliet</h5>
                      {/* <p>IT Manager</p> */}
                    </div>
                  </div>
                </div>
                <div className="testimonials-item">
                  <div className="testimonials-client-thumb">
                    <div className="img-fluid">
                      <img
                        src="assets/images/ss4.jpg"
                        alt="testimonial"
                        data-img-url="assets/images/ss4.jpg"
                      />
                    </div>
                  </div>
                  <div className="testimonials-content">
                    <div className="testimonials-icon">
                      <i className="fa fa-quote-right"></i>
                    </div>
                    {/* <div className="rating"><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></div> */}
                    <h4>Lorem ipsum dolor sit amet</h4>
                    <p>
                      I found my life partner in this platform and am very happy
                      that within a short span of time I met my perfect match
                      and we are marrying very soon.Thanks a lot VVK Matrimony.
                    </p>
                    <div className="testimonials-title">
                      <h5>Romeo & Juliet</h5>
                      {/* <p>PPO</p> */}
                    </div>
                  </div>
                </div>
                <div className="testimonials-item">
                  <div className="testimonials-client-thumb">
                    <div className="img-fluid">
                      <img
                        src="assets/images/ss5.jpg"
                        alt="testimonial"
                        data-img-url="assets/images/ss5.jpg"
                      />
                    </div>
                  </div>
                  <div className="testimonials-content">
                    <div className="testimonials-icon">
                      <i className="fa fa-quote-right"></i>
                    </div>
                    {/* <div className="rating"><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></div> */}
                    <h4>Lorem ipsum dolor sit amet</h4>
                    <p>
                      I found my life partner in this platform and am very happy
                      that within a short span of time I met my perfect match
                      and we are marrying very soon.Thanks a lot VVK Matrimony.
                    </p>
                    <div className="testimonials-title">
                      <h5>Romeo & Juliet</h5>
                      {/* <p>PPO</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: Testimonial
        =================================--> */}

      {/* <!--===// kula deiva start
        =================================--> */}
      <section
        id="team-section"
        className="team-section av-py-default team-home shapes-section"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="av-container">
          <div className="av-columns-area">
            <div className="av-column-12">
              <div className="heading-default text-center wow fadeInUp">
                <h3>
                  Kula<span className="primary-color"> Deivam</span>
                </h3>
                <span className="separator">
                  <span>
                    <span></span>
                  </span>
                </span>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p>
              </div>
            </div>
          </div>
          <div className="av-columns-area">
            <div className="av-column-12">
              <div className="team-carousel owl-carousel owl-theme">
                <div className="team-member">
                  <div className="team-thumb" tabIndex="0">
                    <img
                      src="assets/images/contact/1.jpg"
                      alt="team_item"
                      title=""
                    />
                    {/* <div className="team-view"><a href="#"><i className="fa fa-eye"></i></a></div>
                                    <div className="team-thumber">
                                        <aside className="widget widget_social_widget">
                                            <ul>
                                                <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                                <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                                <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                                <li><a href="#"><i className="fa fa-instagram"></i></a></li>
                                            </ul>
                                        </aside>
                                    </div> */}
                  </div>
                  <div className="team-info">
                    <h5>
                      <a href=";">Namasthe Vasavidevi</a>
                    </h5>
                    {/* <p>Executive</p> */}
                  </div>
                </div>
                <div className="team-member">
                  <div className="team-thumb" tabIndex="0">
                    <img
                      src="assets/images/contact/2.jpg"
                      alt="team_item"
                      title=""
                    />
                    {/* <div className="team-view"><a href="#"><i className="fa fa-eye"></i></a></div>
                                    <div className="team-thumber">
                                        <aside className="widget widget_social_widget">
                                            <ul>
                                                <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                                <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                                <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                                <li><a href="#"><i className="fa fa-instagram"></i></a></li>
                                            </ul>
                                        </aside>
                                    </div> */}
                  </div>
                  <div className="team-info">
                    <h5>
                      <a href=";">Namasthe Vishvapavani</a>
                    </h5>
                    {/* <p>Project Manager</p> */}
                  </div>
                </div>
                <div className="team-member">
                  <div className="team-thumb" tabIndex="0">
                    <img
                      src="assets/images/contact/3.jpg"
                      alt="team_item"
                      title=""
                    />
                    {/* <div className="team-view"><a href="#"><i className="fa fa-eye"></i></a></div>
                                    <div className="team-thumber">
                                        <aside className="widget widget_social_widget">
                                            <ul>
                                                <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                                <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                                <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                                <li><a href="#"><i className="fa fa-instagram"></i></a></li>
                                            </ul>
                                        </aside>
                                    </div> */}
                  </div>
                  <div className="team-info">
                    <h5>
                      <a href=";">Vratha Sambadhha</a>
                    </h5>
                    {/* <p>Manager and director</p> */}
                  </div>
                </div>
                <div className="team-member">
                  <div className="team-thumb" tabIndex="0">
                    <img
                      src="assets/images/contact/4.jpg"
                      alt="team_item"
                      title=""
                    />
                    {/* <div className="team-view"><a href="#"><i className="fa fa-eye"></i></a></div>
                                    <div className="team-thumber">
                                        <aside className="widget widget_social_widget">
                                            <ul>
                                                <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                                <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                                <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                                <li><a href="#"><i className="fa fa-instagram"></i></a></li>
                                            </ul>
                                        </aside>
                                    </div> */}
                  </div>
                  <div className="team-info">
                    <h5>
                      <a href=";">Koumathrethe</a>
                    </h5>
                    {/* <p>Executive Officer</p> */}
                  </div>
                </div>
                <div className="team-member">
                  <div className="team-thumb" tabIndex="0">
                    <img
                      src="assets/images/contact/5.jpg"
                      alt="team_item"
                      title=""
                    />
                    {/* <div className="team-view"><a href="#"><i className="fa fa-eye"></i></a></div>
                                    <div className="team-thumber">
                                        <aside className="widget widget_social_widget">
                                            <ul>
                                                <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                                <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                                <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                                <li><a href="#"><i className="fa fa-instagram"></i></a></li>
                                            </ul>
                                        </aside>
                                    </div> */}
                  </div>
                  <div className="team-info">
                    <h5>
                      <a href=";">Namonnamaha</a>
                    </h5>
                    {/* <p>Executive</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shape13">
          <img src="assets/images/team/clipArt/shape13.png" alt="image" />
        </div>
        <div className="shape14">
          <img src="assets/images/team/clipArt/shape14.png" alt="image" />
        </div>
        <div className="shape15">
          <img src="assets/images/team/clipArt/shape15.png" alt="image" />
        </div>
        <div className="shape16">
          <img src="assets/images/team/clipArt/shape16.png" alt="image" />
        </div>
      </section>
      {/* <!-- kula deiva end
        =================================--> */}
    </div>
  );
};

export default Index;
