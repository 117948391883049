import React, { useEffect } from "react";

const Disclaimer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* <!--===// Start: Breadcrumb
        =================================--> */}
      <section id="breadcrumb-section" class="breadcrumb-area breadcrumb-left">
        <div class="av-container">
          <div class="av-columns-area">
            <div class="av-column-12">
              <div class="breadcrumb-content">
                <div class="breadcrumb-heading wow fadeInLeft">
                  <h2>Privacy Policy</h2>
                </div>
                <ol class="breadcrumb-list wow fadeInRight">
                  <li>
                    <a href="/">
                      <i class="fa fa-home"></i> Home Page
                    </a>{" "}
                    &nbsp;-&nbsp;
                  </li>
                  <li class="active">Privacy Policy</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: Breadcrumb
        =================================--> */}

      {/* <!--===// Start: terms and conditions
        =================================--> */}
      <section
        id="team-section"
        className="team-section av-py-default team-home shapes-section"
        style={{ backgroundColor: "#fff", paddingBottom: "0" }}
      >
        {" "}
        <section
          id="timeline-section"
          class="timeline-section av-py-default shapes-section"
          style={{ backgroundColor: "#fff" }}
        >
          <div class="av-container">
            <div class="av-column-12">
              <div class="heading-default text-center wow fadeInUp">
                <h3>Disclaimer</h3>
                <span class="separator">
                  <span>
                    <span></span>
                  </span>
                </span>
              </div>
            </div>
            <div class="av-columns-area">
              <div class="av-column-12 timeline">
                <div class="av-columns-area timeline-item">
                  <div class="av-xs-column-12 av-md-column-12 order-3 order-md-1 timeline-content wow fadeInRight ">
                    <p style={{ marginBottom: "60px" }}>
                      {" "}
                      The information, content, and services provided on
                      vvkmatrimony.com site are for general informational
                      purposes only. We do not make any warranty or
                      representation regarding the accuracy, completeness,
                      timeliness, or reliability of any information or services
                      provided on the site, including but not limited to member
                      profiles, photos, and chat messages. We do not guarantee
                      that you will find a suitable match through the site, and
                      we will not be liable for any direct, indirect,
                      incidental, special, or consequential damages arising from
                      your use of the site or from any information, content, or
                      services on the site.
                      <br />
                      <br />
                      vvkmatrimony.com advise you to exercise caution and
                      perform your own due diligence before communicating with
                      or meeting any member through the site. We do not conduct
                      background checks or verify the accuracy of member
                      profiles, and we cannot be responsible for any
                      misrepresentations or fraudulent activities by members. We
                      encourage you to report any suspicious or inappropriate
                      behaviour by members to our support team, who will
                      investigate the matter and take appropriate action.
                      <br />
                      <br />
                      Please note that vvkmatrimony.com reserve the right to
                      modify, suspend, or terminate any services or features of
                      the site at any time, without notice and without liability
                      to you. We also reserve the right to modify these
                      disclaimers and any other terms and conditions governing
                      your use of the site at any time, without notice, and your
                      continued use of the site after any such modifications
                      will constitute your acceptance of the new terms.
                      <br />
                      <br />
                      The matchmaking reports provided by vvkmatrimony.com are
                      based on the birth details provided by customers and the
                      principles of astrology. Please note that while we strive
                      to provide accurate and reliable reports, we do not
                      guarantee their accuracy, and we do not assume any
                      responsibility for the decisions or consequences that may
                      arise from the use of these reports. Customers are solely
                      responsible for any decisions they make based on the
                      information provided in the reports, and they should use
                      their own judgment and discretion when interpreting the
                      reports. We recommend that customers take the matchmaking
                      reports as guidance only, and not as a substitute for
                      professional advice or judgment. Furthermore, we cannot be
                      held liable for any damages or losses that may result from
                      the use of the reports or the services provided on our
                      website. Please contact us if you have any questions or
                      concerns about our matchmaking reports or any other aspect
                      of our services.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div class='shape1'>
        <img src='assets/images/service/clipArt/shape1.png' alt='image' />
      </div>
      <div class='shape2'>
        <img src='assets/images/service/clipArt/shape2.png' alt='image' />
      </div>
      <div class='shape3'>
        <img src='assets/images/service/clipArt/shape3.png' alt='image' />
      </div>
      <div class='shape4'>
        <img src='assets/images/service/clipArt/shape4.png' alt='image' />
      </div>
      <div class='shapetimeline1'>
        <img src='assets/images/timeline/timeline1.png' alt='image' />
      </div>
      <div class='shapetimeline2'>
        <img src='assets/images/timeline/timeline2.png' alt='image' />
      </div>
      <div class='shapetimeline3'>
        <img src='assets/images/timeline/timeline3.png' alt='image' />
      </div> */}
        </section>
      </section>
      {/* <!-- End: terms and conditions
        =================================--> */}
    </div>
  );
};

export default Disclaimer;
