import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdVerifiedUser, MdLocalOffer, MdMessage } from "react-icons/md";
import { MembershipPlans, Buymembership } from "../Redux/Action";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { RiCustomerService2Fill } from "react-icons/ri";

const Premium = () => {
  const [plans, setPlans] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Token = localStorage.getItem("Authtok");
  const plan = useSelector(({ MembershipPlans }) => MembershipPlans.payload);
  const [gold, setGold] = useState("");
  const [diamont, setDiamond] = useState("");
  const [platinum, setPlatinum] = useState("");

  const goldMenbership = (e) => {
    dispatch(Buymembership({ id: plan[0].id })).then((res) => {
      if (res === "Membership already exists") {
        Swal.fire(res);
      } else {
        const url = res?.url;
        if (url) {
          window.location.href = url;
        }
      }
    });
    // navigate("/ordersummary");
  };

  const platinumMenbership = (id) => {
    dispatch(Buymembership({ id: plan[1].id })).then((res) => {
      if (res === "Membership already exists") {
        Swal.fire(res);
      } else {
        const url = res?.url;
        if (url) {
          window.location.href = url;
        }
      }
    });
    // navigate("/ordersummary");
  };

  const diamondMenbership = (e) => {
    dispatch(Buymembership({ id: plan[2].id })).then((res) => {
      if (res === "Membership already exists") {
        Swal.fire(res);
      } else {
        const url = res?.url;
        if (url) {
          window.location.href = url;
        }
      }
    });
    // navigate("/ordersummary");
  };

  useEffect(() => {
    if (Token) {
      window.scrollTo(0, 0);

      Promise.allSettled([dispatch(MembershipPlans())]).then((resultArray) => {
        const data = resultArray[0].value.data.data;

        setPlans(data);
      });
    }
  }, []);
  return (
    <div>
      {/* <!--===// Start: Pricing
        =================================--> */}
      <section
        id="pricing-section"
        className="pricing-section pricing-two av-py-default shapes-section"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="av-container">
          <div className="av-columns-area">
            <div className="av-column-12">
              <div className="heading-default text-center wow fadeInUp">
                <h3>
                  Our <span className="primary-color">Pricing</span>
                </h3>
                <span className="separator">
                  <span>
                    <span></span>
                  </span>
                </span>
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
              </div>
            </div>
          </div>
          <div className="av-filter-wrapper-2">
            <div className="av-columns-area wow fadeInUp">
              <div className="av-column-12">
                {/* <div className="av-tab-filter filters-theme">
                                <a data-filter="*" className="av-btn av-btn-primary av-btn-bubble active">All <span className="bubble_effect"><span className="circle top-left"></span> <span className="circle top-left"></span> <span className="circle top-left"></span> <span className="button effect-button"></span> <span className="circle bottom-right"></span> <span className="circle bottom-right"></span> <span className="circle bottom-right"></span></span></a>
                                <a data-filter=".monthly" className="av-btn av-btn-primary av-btn-bubble">Monthly <span className="bubble_effect"><span className="circle top-left"></span> <span className="circle top-left"></span> <span className="circle top-left"></span> <span className="button effect-button"></span> <span className="circle bottom-right"></span> <span className="circle bottom-right"></span> <span className="circle bottom-right"></span></span></a>
                                <a data-filter=".yearly" className="av-btn av-btn-primary av-btn-bubble">Yearly <span className="bubble_effect"><span className="circle top-left"></span> <span className="circle top-left"></span> <span className="circle top-left"></span> <span className="button effect-button"></span> <span className="circle bottom-right"></span> <span className="circle bottom-right"></span> <span className="circle bottom-right"></span></span></a>
                                <a data-filter=".lifetime" className="av-btn av-btn-primary av-btn-bubble">Lifetime <span className="bubble_effect"><span className="circle top-left"></span> <span className="circle top-left"></span> <span className="circle top-left"></span> <span className="button effect-button"></span> <span className="circle bottom-right"></span> <span className="circle bottom-right"></span> <span className="circle bottom-right"></span></span></a>
                            </div> */}
                <div
                  id="av-filter-init-2"
                  className="av-columns-area av-filter-init pricing-row"
                >
                  <div className="av-column-4 av-sm-column-6 av-filter-item all monthly">
                    <div className="pricing-item">
                      <div className="pricing-heading">
                        <h5>Golden User</h5>
                      </div>
                      <div className="pricing-rate">
                        <p className="pricing-name">Yearly Package</p>
                        <span className="pricing">
                          {plans?.[0]?.amount
                            ? `${parseFloat(plans[0].amount).toLocaleString(
                                "en-IN",
                                {
                                  maximumFractionDigits: 0,
                                }
                              )} INR`
                            : "0 INR"}{" "}
                        </span>
                      </div>
                      {/* <div className="pricing-payment">
                                            <p>Available only 12 months plan (in future we introduce 3 and 6 months plans)</p>
                                            <ul>
                                                <li><a href=";"><i className="fa fa-cc-visa"></i></a></li>
                                                <li><a href=";"><i className="fa fa-cc-stripe"></i></a></li>
                                                <li><a href=";"><i className="fa fa-cc-paypal"></i></a></li>
                                                <li><a href=";"><i className="fa fa-cc-mastercard"></i></a></li>
                                                <li><a href=";"><i className="fa fa-cc-discover"></i></a></li>
                                            </ul>
                                        </div> */}
                      <div className="pricing-list">
                        <ul>
                          <li>
                            Access 500 profiles per year <br />
                            (changable)
                          </li>
                          <li>Send interest requests</li>
                          <li>Marriage compatability feature (porutham)</li>
                          <li>Can see and download horoscope details</li>
                        </ul>
                      </div>
                      {Token ? (
                        <div className="pricing-btn">
                          <a
                            onClick={goldMenbership}
                            target="_blank"
                            className="av-btn av-btn-secondary av-btn-bubble"
                          >
                            Purchase Plan <i className="fa fa-arrow-right"></i>{" "}
                            <span className="bubble_effect">
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="button effect-button"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>
                            </span>
                          </a>
                        </div>
                      ) : (
                        <div className="pricing-btn">
                          <a
                            href="/login"
                            className="av-btn av-btn-secondary av-btn-bubble"
                          >
                            Purchase Plan<i className="fa fa-arrow-right"></i>{" "}
                            <span className="bubble_effect">
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="button effect-button"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>
                            </span>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="av-column-4 av-sm-column-6 av-filter-item all lifetime">
                    <div className="pricing-item">
                      <div className="pricing-heading">
                        <h5>Plantinum User</h5>
                      </div>
                      <div className="pricing-rate">
                        <p className="pricing-name">Yearly Package</p>
                        <span className="pricing">
                          {plans?.[1]?.amount
                            ? `${parseFloat(plans[1].amount).toLocaleString(
                                "en-IN",
                                {
                                  maximumFractionDigits: 0,
                                }
                              )} INR`
                            : "0 INR"}{" "}
                          {/* You can include "0 INR" as the default value */}
                        </span>
                      </div>
                      {/* <div className="pricing-payment">
                                            <p>Available only 12 months plan (in future we introduce 3 and 6 months plans)</p>
                                            <ul>
                                                <li><a href=";"><i className="fa fa-cc-visa"></i></a></li>
                                                <li><a href=";"><i className="fa fa-cc-stripe"></i></a></li>
                                                <li><a href=";"><i className="fa fa-cc-paypal"></i></a></li>
                                                <li><a href=";"><i className="fa fa-cc-mastercard"></i></a></li>
                                                <li><a href=";"><i className="fa fa-cc-discover"></i></a></li>
                                            </ul>
                                        </div> */}
                      <div className="pricing-list">
                        <ul>
                          <li>Access unlimited profiles for a year</li>
                          <li>Show your profile in Home Page for 3 months</li>
                          <li>Display in suggestion profiles</li>
                          <li>
                            Profile optimization for increase the chances of
                            finding profile by 3 times
                          </li>
                        </ul>
                      </div>
                      {Token ? (
                        <div className="pricing-btn">
                          <a
                            onClick={platinumMenbership}
                            target="_blank"
                            className="av-btn av-btn-secondary av-btn-bubble"
                          >
                            Purchase Plan<i className="fa fa-arrow-right"></i>{" "}
                            <span className="bubble_effect">
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="button effect-button"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>
                            </span>
                          </a>
                        </div>
                      ) : (
                        <div className="pricing-btn">
                          <a
                            href="/login"
                            className="av-btn av-btn-secondary av-btn-bubble"
                          >
                            Purchase Plan<i className="fa fa-arrow-right"></i>{" "}
                            <span className="bubble_effect">
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="button effect-button"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>
                            </span>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="av-column-4 av-sm-column-6 av-filter-item all yearly">
                    <div className="pricing-item recommended">
                      <div className="pricing-heading">
                        <h5>Diamond User</h5>
                      </div>
                      <div className="pricing-rate">
                        <p className="pricing-name">Yearly Package</p>
                        <span className="pricing">
                          {plans?.[2]?.amount
                            ? `${parseFloat(plans[2].amount).toLocaleString(
                                "en-IN",
                                {
                                  maximumFractionDigits: 0,
                                }
                              )} INR`
                            : "0 INR"}{" "}
                          {/* You can include "0 INR" as the default value */}
                        </span>
                      </div>
                      {/* <div className="pricing-payment">
                                            <p>Available only 12 months plan (in future we introduce 3 and 6 months plans)</p>
                                            <ul>
                                                <li><a href=";"><i className="fa fa-cc-visa"></i></a></li>
                                                <li><a href=";"><i className="fa fa-cc-stripe"></i></a></li>
                                                <li><a href=";"><i className="fa fa-cc-paypal"></i></a></li>
                                                <li><a href=";"><i className="fa fa-cc-mastercard"></i></a></li>
                                                <li><a href=";"><i className="fa fa-cc-discover"></i></a></li>
                                            </ul>
                                        </div> */}
                      <div className="pricing-list">
                        <ul>
                          <li>
                            Access 750 profiles per year <br />
                            (changable)
                          </li>
                          <li>Can see and download horoscope details</li>
                          <li>
                            Send interest requests <br />
                            (porutham)
                          </li>
                          <li>Receiving hand picked profiles via Email</li>
                        </ul>
                      </div>
                      {Token ? (
                        <div className="pricing-btn">
                          <a
                            onClick={diamondMenbership}
                            className="av-btn av-btn-secondary av-btn-bubble"
                            target="_blank"
                          >
                            Purchase Plan<i className="fa fa-arrow-right"></i>{" "}
                            <span className="bubble_effect">
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="button effect-button"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>
                            </span>
                          </a>
                        </div>
                      ) : (
                        <div className="pricing-btn">
                          <a
                            href="/login"
                            className="av-btn av-btn-secondary av-btn-bubble"
                          >
                            Purchase Plan<i className="fa fa-arrow-right"></i>{" "}
                            <span className="bubble_effect">
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="button effect-button"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>
                            </span>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: Pricing
        =================================--> */}

      {/* <!--===// Start: CTA
        =================================--> */}
      <section
        id="cta-section"
        class="cta-section cta-home"
        style={{
          backgroundImage: "url('assets/images/cta_bg.jpg')",
          backgroundAttachment: "fixed",
        }}
      >
        <div class="cta-overlay">
          <div class="av-container">
            <div class="av-columns-area">
              <div class="av-column-5 my-auto">
                <div class="call-wrapper">
                  <div class="call-icon-box">
                    <i class="fa fa-user"></i>
                  </div>
                  <div class="cta-info">
                    <div class="call-title">Call Us:</div>
                    <div class="call-phone">
                      <a href="tel:+(91)9894234066" target="_blank">
                        +(91) 98942 34066
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="av-column-7 my-auto">
                <div class="cta-content-wrap">
                  <div class="cta-content">
                    <span class="cta-icon-wrap">
                      <i class="fa fa-phone"></i>
                    </span>
                    <h4>Professional and Dedicated Consulting Services</h4>
               
                  </div>
                  <div class="cta-btn">
                    <a href="#" class="av-btn av-btn-primary av-btn-bubble">
                      Contact Us<i class="fa fa-arrow-right"></i>{" "}
                      <span class="bubble_effect">
                        <span class="circle top-left"></span>{" "}
                        <span class="circle top-left"></span>{" "}
                        <span class="circle top-left"></span>{" "}
                        <span class="button effect-button"></span>{" "}
                        <span class="circle bottom-right"></span>{" "}
                        <span class="circle bottom-right"></span>{" "}
                        <span class="circle bottom-right"></span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: CTA
        =================================--> */}
      {/* <!--===// Start: Step Section
        =================================--> */}
      <section id="step-section" class="step-section av-py-default">
        <div class="av-container">
          <div class="av-columns-area">
            <div class="av-column-12">
              <div class="heading-default text-center wow fadeInUp">
                <h3>
                  Benefits of{" "}
                  <span class="primary-color">Premium Membership</span>
                </h3>
                <span class="separator">
                  <span>
                    <span></span>
                  </span>
                </span>
                {/* <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p> */}
              </div>
            </div>
          </div>
          <div class="av-columns-area">
            <div class="av-column-3 av-sm-column-6">
              <div class="stepbox">
                <div class="stepbox-outer">
                  <div class="step-effect"></div>
                  <div class="stepbox-icon">
                    {/* <span class="stepbox-number">1</span> */}
                    {/* <i class="fa fa-paint-brush"></i> */}
                    <MdVerifiedUser />
                  </div>
                </div>
                <div class="stepbox-content">
                  <h5 class="stepbox-title">
                    <a href=";">View Verified Contact Details</a>
                  </h5>
               
                </div>
              </div>
            </div>
            <div class="av-column-3 av-sm-column-6">
              <div class="stepbox">
                <div class="stepbox-outer">
                  <div class="step-effect"></div>
                  <div class="stepbox-icon">
                    {/* <span class="stepbox-number">2</span> */}
                    {/* <i class="fa fa-paint-brush"></i> */}
                    <MdLocalOffer />
                  </div>
                </div>
                <div class="stepbox-content">
                  <h5 class="stepbox-title">
                    <a href=";">Special Offers From Partner</a>
                  </h5>
                  
                </div>
              </div>
            </div>
            <div class="av-column-3 av-sm-column-6">
              <div class="stepbox">
                <div class="stepbox-outer">
                  <div class="step-effect"></div>
                  <div class="stepbox-icon">
                    {/* <span class="stepbox-number">3</span> */}
                    {/* <i class="fa fa-paint-brush"></i> */}
                    <MdMessage />
                  </div>
                </div>
                <div class="stepbox-content">
                  <h5 class="stepbox-title">
                    <a href=";">Write Direct Message to Member</a>
                  </h5>
                  
                </div>
              </div>
            </div>
            <div class="av-column-3 av-sm-column-6">
              <div class="stepbox">
                <div class="stepbox-outer">
                  <div class="step-effect"></div>
                  <div class="stepbox-icon">
                    {/* <span class="stepbox-number">4</span> */}
                    {/* <i class="fa fa-paint-brush"></i> */}
                    <RiCustomerService2Fill />
                  </div>
                </div>
                <div class="stepbox-content">
                  <h5 class="stepbox-title">
                    <a href=";">Quick Response Service </a>
                  </h5>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: Step Section
        =================================--> */}

      {/* <!--===// Start: Contact Form
        =================================--> */}
      {/* <section
        id="contactform-section"
        class="contactform-section av-pb-default"
        style={{ backgroundColor: "#fff" }}
      >
        <div class="contactform-cards" style={{ marginTop: "0" }}>
          <div class="av-container">
            <div class="av-columns-area">
              <div class="av-column-6">
                <div
                  class="contactform-cards-item"
                  style={{
                    backgroundImage: "url('assets/images/contact/card01.jpg')",
                  }}
                >
                  <div class="cards-area">
                    <div class="cards-icon">
                      <i class="fa fa-wechat"></i>
                    </div>
                    <div class="cards-title" style={{ fontSize: "25px" }}>
                      WhattsApp Chat
                    </div>
                    <div class="cards-btn">
                      <a href="tel:+(91)9894234066" class="av-btn av-btn-primary av-btn-bubble">
                        Start Chat <i class="fa fa-arrow-right"></i>{" "}
                        <span class="bubble_effect">
                          <span class="circle top-left"></span>{" "}
                          <span class="circle top-left"></span>{" "}
                          <span class="circle top-left"></span>{" "}
                          <span class="button effect-button"></span>{" "}
                          <span class="circle bottom-right"></span>{" "}
                          <span class="circle bottom-right"></span>{" "}
                          <span class="circle bottom-right"></span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="av-column-6">
                <div
                  class="contactform-cards-item"
                  style={{
                    backgroundImage: "url('assets/images/contact/card02.jpg')",
                  }}
                >
                  <div class="cards-area">
                    <div class="cards-icon">
                      <i class="fa fa-wechat"></i>
                    </div>
                    <div class="cards-title" style={{ fontSize: "25px" }}>
                      WhattsApp Chat
                    </div>
                    {/* <div class="cards-btn"> 
                                        <aside class="share-toolkit widget widget_social_widget">
                                            <a href="#" class="toolkit-hover"><i class="fa fa-share-alt"></i></a>
                                            <ul>
                                                <li><a href="javascript:void(0);"><i class="fa fa-facebook"></i></a></li>
                                                <li><a href="javascript:void(0);"><i class="fa fa-twitter"></i></a></li>
                                                <li><a href="javascript:void(0);"><i class="fa fa-linkedin"></i></a></li>
                                                <li><a href="javascript:void(0);"><i class="fa fa-instagram"></i></a></li>
                                            </ul>
                                        </aside>
                                    </div> 
                    <div class="cards-btn">
                      <a href="tel:+(91)9894234066" class="av-btn av-btn-primary av-btn-bubble">
                        Start Chat <i class="fa fa-arrow-right"></i>{" "}
                        <span class="bubble_effect">
                          <span class="circle top-left"></span>{" "}
                          <span class="circle top-left"></span>{" "}
                          <span class="circle top-left"></span>{" "}
                          <span class="button effect-button"></span>{" "}
                          <span class="circle bottom-right"></span>{" "}
                          <span class="circle bottom-right"></span>{" "}
                          <span class="circle bottom-right"></span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- End: Contact Form
        =================================--> */}
    </div>
  );
};

export default Premium;
