//login
export const profile = {
  login: {
    success: "profileSuccess",
    error: "profileError",
  },
};

// personaldetails
export const personal = {
  login: {
    success: "personalSuccess",
    error: "personalError",
  },
};
// conatct
export const contact = {
  list: {
    success: "contactSuccess",
    error: "contactError",
  },
};
// payment
export const payNow = {
  pay: {
    success: "paymentSuccess",
    error: "paymentError",
  },
};
// pdfview
export const pdfview = {
  view: {
    success: "viewSuccess",
    error: "viewError",
  },
};
// loginuser
export const userlog = {
  login: {
    success: "userlogSuccess",
    error: "userlogError",
  },
};

// profession details
export const profdet = {
  login: {
    success: "profdetSuccess",
    error: "profdetError",
  },
};

// horoscope details auto
export const horoscopeauto = {
  login: {
    success: "horoscopeautoSuccess",
    error: "horoscopeautoinError",
  },
};

// idproof details
export const proof = {
  login: {
    success: "proofSuccess",
    error: "proofError",
  },
};

// family details
export const family = {
  login: {
    success: "familySuccess",
    error: "familyError",
  },
};

// partner preference
export const partner = {
  login: {
    success: "partnerSuccess",
    error: "partnerError",
  },
};

// View profile list
export const viewprofile = {
  login: {
    success: "viewprofileSuccess",
    error: "viewprofileError",
  },
  horo: {
    success: "horoSuccess",
    error: "horoError",
  },
};

// View full profile list
export const viewfullprofile = {
  get: {
    success: "viewfullprofileSuccess",
    error: "viewfullprofileError",
  },
};

// Completion list
export const completion = {
  get: {
    success: "completionSuccess",
    error: "completionError",
  },
};
// recommend profile
export const recommend = {
  recommendprofile: {
    success: "recommendProfileSuccess",
    error: "recommendProfileError",
  },
};

// banner profile
export const banprof = {
  bannerprofile: {
    success: "BannerProfileSuccess",
    error: "BannerProfileError",
  },
};
// visit profile
export const visitprofile = {
  addprofile: {
    success: "AddVisitProfileSuccess",
    error: "AddvisitProfileError",
  },
  viewprofile: {
    success: "ViewVisitProfileSuccess",
    error: "ViewVisitProfileError",
  },
};
// visit profile
export const list = {
  like: {
    success: "ViewlikeProfileSuccess",
    error: "ViewlikeProfileError",
  },
  interest: {
    success: "ViewintrestProfileSuccess",
    error: "ViewintrestProfileError",
  },
  visit: {
    success: "ViewVisitedProfileSuccess",
    error: "ViewVisitedtProfileError",
  },
  interestrecive: {
    success: "ViewIntrestReciveProfileSuccess",
    error: "ViewIntrestReciveProfileError",
  },
  fav: {
    success: "FavProfileSuccess",
    error: "FavProfileError",
  },
};

// premium profile
export const prepof = {
  premiumprofile: {
    success: "premiumProfileSuccess",
    error: "premiumProfileError",
  },
};
// natchathira profile
export const natchathiraPro = {
  natchathiraprofile: {
    success: "natchathiraProfileSuccess",
    error: "natchathiraProfileError",
  },
};
// normal profile
export const normalprof = {
  normalprofile: {
    success: "normalProfileSuccess",
    error: "normalProfileError",
  },
};

// hide profile
export const Hidematch = {
  hide: {
    success: "HidematchSuccess",
    error: "HidematchError",
  },
};

// List hide profile
export const ListHidematch = {
  Listhide: {
    success: "ListHidematchSuccess",
    error: "ListHidematchError",
  },
};

// recent profile
export const recentprof = {
  recentprofile: {
    success: "recentProfileSuccess",
    error: "recentProfileError",
  },
};

// home recent profile
export const homerecentprof = {
  homerecent: {
    success: "recentProfileSuccess",
    error: "recentProfileError",
  },
};

// searchby horoscope
export const searchprofile = {
  searchoro: {
    success: "searchProfileSuccess",
    error: "searchProfileError",
  },
};

// searchby location
export const searchlocation = {
  searchs: {
    success: "searchlocationSuccess",
    error: "searchlocationError",
  },
};

// searchby martial status
export const searchmartailstatus = {
  searchm: {
    success: "searchmartailstatusSuccess",
    error: "searchmartailstatusError",
  },
};

// searchby foregin intrest
export const searchforeginintrest = {
  searchf: {
    success: "searchforeginintrestSuccess",
    error: "searchforeginintrestError",
  },
};

// success story
export const successtestimony = {
  story: {
    success: "successtestimonySuccess",
    error: "successtestimonyError",
  },
};

// logout
export const logout = {
  logoutUser: {
    success: "logoutSuccess",
    error: "logouterror",
  },
};

// membership plans
export const membership = {
  plans: {
    success: "membershipplanSuccess",
    error: "membershipplanError",
  },
  subcription: {
    success: "SubcriptionSuccess",
    error: "SubcriptionError",
  },
};

// report
export const reportAcc = {
  report: {
    success: "reportaccountSuccess",
    error: "reportaccountError",
  },
};

// feedback
export const feedbackacc = {
  feedback: {
    success: "feedbackSuccess",
    error: "feedbackError",
  },
};

// Add Intrest
export const addIntrestUser = {
  AddIn: {
    success: "addIntrestUserSuccess",
    error: "addIntrestUserError",
  },
};

// Add favourite
export const AddFavourite = {
  Addfav: {
    success: "AddFavouriteSuccess",
    error: "AddFavouriteError",
  },
};

// ListShortlist
export const ListFavourite = {
  Listfav: {
    success: "ListFavouriteSuccess",
    error: "ListFavouriteError",
  },
};

// Add contact profile
export const AddContactProfile = {
  Addcontact: {
    success: "AddContactProfileSuccess",
    error: "AddContactProfileError",
  },
};

// Add success story
export const AddSuccess = {
  Story: {
    success: "AddSuccessSuccess",
    error: "AddSuccessError",
  },
};

// Add success story
export const SingleUserprofile = {
  Singleuser: {
    success: "SingleUserprofileSuccess",
    error: "SingleUserprofileError",
  },
};

// update profile
export const profileUpdate = {
  Profile: {
    success: "profileUpdateSuccess",
    error: "profileUpdateError",
  },
};

// update profile
export const personalityUpdate = {
  Personality: {
    success: "personalityUpdateSuccess",
    error: "personalityUpdateError",
  },
};

// update Professsion
export const professionUpdate = {
  Profession: {
    success: "professionUpdateSuccess",
    error: "professionUpdateError",
  },
};

// update family details
export const familydetUpdate = {
  familydet: {
    success: "familydetUpdateSuccess",
    error: "familydetUpdateError",
  },
};
// update horoscope details
export const horoscopeUpdate = {
  horoscopedet: {
    success: "horoscopedetUpdateSuccess",
    error: "horoscopedetUpdateError",
  },
};

// update partner preference
export const PartnerPreferenceUpdate = {
  PartnerPre: {
    success: "PartnerPreferenceUpdateSuccess",
    error: "PartnerPreferenceUpdateError",
  },
};
// delete account
export const accountDelete = {
  Delete: {
    success: "accountDeleteSuccess",
    error: "accountDeleteError",
  },
};

// buy membership
export const Membership = {
  planMembership: {
    success: "MembershipSuccess",
    error: "MembershipError",
  },
};

//  membership summary
export const membershipsum = {
  summary: {
    success: "membershipsumSuccess",
    error: "membershipsumError",
  },
};

// forgot password
export const forgotuser = {
  forgot: {
    success: "forgotuserSuccess",
    error: "forgotuserError",
  },
};

// otp verify
export const verifyotp = {
  otp: {
    success: "verifyotpSuccess",
    error: "verifyotpError",
  },
};

// update image
export const imageupdate = {
  image: {
    success: "imageupdateSuccess",
    error: "imageupdateError",
  },
};

// user meta
export const metauser = {
  user: {
    success: "metauserSuccess",
    error: "metauserError",
  },
  details: {
    success: "metauserdetailsSuccess",
    error: "metauserdetailsError",
  },
};

// get partner preference
export const getprefpartner = {
  getpartner: {
    success: "getprefpartnerSuccess",
    error: "getprefpartnerError",
  },
};

// get family details
export const getfamdet = {
  famdet: {
    success: "famdetSuccess",
    error: "famdetError",
  },
};

// get personal detailss
export const getpersonal = {
  pesonaldetail: {
    success: "personalSuccess",
    error: "personalError",
  },
};

// getProfession details
export const getprofdetails = {
  detailprof: {
    success: "profdetailsSuccess",
    error: "profdetailsError",
  },
};

// mail verify
export const verifymail = {
  mail: {
    success: "verifymailSuccess",
    error: "verifymailError",
  },
};

//mobile number otp verify
export const otpverify = {
  otpnumber: {
    success: "otpverifySuccess",
    error: "otpverifyError",
  },
};

//advertisement
export const getad = {
  ad: {
    success: "adSuccess",
    error: "adError",
  },
};

// regular search
export const regularSearch = {
  regular: {
    success: "SearchSuccess",
    error: "SearchError",
  },
};

// filter profile
export const filter = {
  fprofile: {
    success: "filterSuccess",
    error: "filterError",
  },
};

// astrology rasi horo
export const amsam = {
  horoamsam: {
    success: "amsamSuccess",
    error: "amsamError",
  },
};

// astrology amsam horo
export const rasi = {
  hororasi: {
    success: "rasiSuccess",
    error: "rasiError",
  },
};
// horoscope maches
export const horomaches = {
  horomatches: {
    success: "matchSuccess",
    error: "matchError",
  },
};
// birth maches
export const birthmatch = {
  birthmatches: {
    success: "matchSuccess",
    error: "matchError",
  },
};
// birth maches
export const birthchart = {
  birthcharts: {
    success: "matchSuccess",
    error: "matchError",
  },
};
// banner
export const banner = {
  banner: {
    success: "bannerSuccess",
    error: "bannerError",
  },
};
export const userdoc = {
  docs: {
    success: "docSuccess",
    error: "docError",
  },
};
