import React from "react";
import { Link } from "react-router-dom";

const Blog = () => {
  return (
    <div>
      <div id="content" class="gradiant-content">
        {/* <!--===// Start: Breadcrumb
=================================--> */}
        <section
          id="breadcrumb-section"
          class="breadcrumb-area breadcrumb-left"
        >
          <div class="av-container">
            <div class="av-columns-area">
              <div class="av-column-12">
                <div class="breadcrumb-content">
                  <div class="breadcrumb-heading wow fadeInLeft">
                    <h2>Blog</h2>
                  </div>
                  <ol class="breadcrumb-list wow fadeInRight">
                    <li>
                      <Link to="/">
                        <i class="fa fa-home"></i> Home Page
                      </Link>{" "}
                      &nbsp;-&nbsp;
                    </li>
                    <li class="active">Blog</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End: Breadcrumb
=================================--> */}

        {/* <!--===// Start: Blog
=================================--> */}
        <section
          id="post-section"
          class="post-section av-py-default shapes-section"
        >
          <div class="av-container">
            <div class="av-columns-area">
              <div class="av-column-12">
                <div class="heading-default text-center wow fadeInUp">
                  <h3>
                    Our <span class="primary-color">Blogs</span>
                  </h3>
                  <span class="separator">
                    <span>
                      <span></span>
                    </span>
                  </span>
                  <p>
                    Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </p>
                </div>
              </div>
            </div>
            <div class="av-columns-area">
              <div class="av-column-4 av-md-column-6">
                <article class="post-items">
                  <figure class="post-image post-image-absolute">
                    <div class="featured-image">
                      <Link to="/blogdetails" class="post-hover">
                        <img
                          src="assets/images/blog/blogFullSize/img01.jpg"
                          alt="post_item"
                        />
                      </Link>
                    </div>
                  </figure>
                  <div class="post-content">
                    <span class="post-date">
                      {" "}
                      <a href=";">
                        <span>08</span>July 2021
                      </a>{" "}
                    </span>
                    <h5 class="post-title">
                      <a href=";" rel="bookmark">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
                      </a>
                    </h5>
                    <div class="post-footer">
                      <p>
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Quidem omnis rerum quam accusamus! At earum
                        explicabo aperiam temporibus provident ab? Natus
                        repellat labore voluptate.
                      </p>
                      <Link to="/blogdetails" class="more-link">
                        Read More
                      </Link>
                      <span class="post-count">01</span>
                    </div>
                  </div>
                </article>
              </div>
              <div class="av-column-4 av-md-column-6">
                <article class="post-items">
                  <figure class="post-image post-image-absolute">
                    <div class="featured-image">
                      <Link to="/blogdetails" class="post-hover">
                        <img
                          src="assets/images/blog/blogFullSize/img02.jpg"
                          alt="post_item"
                        />
                      </Link>
                    </div>
                  </figure>
                  <div class="post-content">
                    <span class="post-date">
                      {" "}
                      <a href=";">
                        <span>08</span>July 2021
                      </a>{" "}
                    </span>
                    <h5 class="post-title">
                      <a href=";" rel="bookmark">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </a>
                    </h5>
                    <div class="post-footer">
                      <p>
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Quidem omnis rerum quam accusamus! At earum
                        explicabo aperiam temporibus provident ab? Natus
                        repellat labore voluptate.
                      </p>
                      <Link to="/blogdetails" class="more-link">
                        Read More
                      </Link>
                      <span class="post-count">02</span>
                    </div>
                  </div>
                </article>
              </div>
              <div class="av-column-4 av-md-column-6">
                <article class="post-items">
                  <figure class="post-image post-image-absolute">
                    <div class="featured-image">
                      <Link to="/blogdetails" class="post-hover">
                        <img
                          src="assets/images/blog/blogFullSize/img03.jpg"
                          alt="post_item"
                        />
                      </Link>
                    </div>
                  </figure>
                  <div class="post-content">
                    <span class="post-date">
                      {" "}
                      <a href=";">
                        <span>10</span>July 2021
                      </a>{" "}
                    </span>
                    <h5 class="post-title">
                      <a href=";" rel="bookmark">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </a>
                    </h5>
                    <div class="post-footer">
                      <p>
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Quidem omnis rerum quam accusamus! At earum
                        explicabo aperiam temporibus provident ab? Natus
                        repellat labore voluptate.
                      </p>
                      <Link to="/blogdetails" class="more-link">
                        Read More
                      </Link>
                      <span class="post-count">03</span>
                    </div>
                  </div>
                </article>
              </div>
              <div class="av-column-4 av-md-column-6">
                <article class="post-items">
                  <figure class="post-image post-image-absolute">
                    <div class="featured-image">
                      <Link to="/blogdetails" class="post-hover">
                        <img
                          src="assets/images/blog/blogFullSize/img04.jpg"
                          alt="post_item"
                        />
                      </Link>
                    </div>
                  </figure>
                  <div class="post-content">
                    <span class="post-date">
                      {" "}
                      <a href=";">
                        <span>11</span>July 2021
                      </a>{" "}
                    </span>
                    <h5 class="post-title">
                      <a href=";" rel="bookmark">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </a>
                    </h5>
                    <div class="post-footer">
                      <p>
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Quidem omnis rerum quam accusamus! At earum
                        explicabo aperiam temporibus provident ab? Natus
                        repellat labore voluptate.
                      </p>
                      <Link to="/blogdetails" class="more-link">
                        Read More
                      </Link>
                      <span class="post-count">04</span>
                    </div>
                  </div>
                </article>
              </div>
              <div class="av-column-4 av-md-column-6">
                <article class="post-items">
                  <figure class="post-image post-image-absolute">
                    <div class="featured-image">
                      <Link to="/blogdetails" class="post-hover">
                        <img
                          src="assets/images/blog/blogFullSize/img05.jpg"
                          alt="post_item"
                        />
                      </Link>
                    </div>
                  </figure>
                  <div class="post-content">
                    <span class="post-date">
                      {" "}
                      <a href=";">
                        <span>12</span>July 2021
                      </a>{" "}
                    </span>
                    <h5 class="post-title">
                      <a href=";" rel="bookmark">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </a>
                    </h5>
                    <div class="post-footer">
                      <p>
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Quidem omnis rerum quam accusamus! At earum
                        explicabo aperiam temporibus provident ab? Natus
                        repellat labore voluptate.
                      </p>
                      <Link to="/blogdetails" class="more-link">
                        Read More
                      </Link>
                      <span class="post-count">05</span>
                    </div>
                  </div>
                </article>
              </div>
              <div class="av-column-4 av-md-column-6">
                <article class="post-items">
                  <figure class="post-image post-image-absolute">
                    <div class="featured-image">
                      <Link to="/blogdetails" class="post-hover">
                        <img
                          src="assets/images/blog/blogFullSize/img06.jpg"
                          alt="post_item"
                        />
                      </Link>
                    </div>
                  </figure>
                  <div class="post-content">
                    <span class="post-date">
                      {" "}
                      <a href=";">
                        <span>13</span>July 2021
                      </a>{" "}
                    </span>
                    <h5 class="post-title">
                      <a href=";" rel="bookmark">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </a>
                    </h5>
                    <div class="post-footer">
                      <p>
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Quidem omnis rerum quam accusamus! At earum
                        explicabo aperiam temporibus provident ab? Natus
                        repellat labore voluptate.
                      </p>
                      <Link to="/blogdetails" class="more-link">
                        Read More
                      </Link>
                      <span class="post-count">06</span>
                    </div>
                  </div>
                </article>
              </div>
              <div class="av-column-4 av-md-column-6">
                <article class="post-items">
                  <figure class="post-image post-image-absolute">
                    <div class="featured-image">
                      <Link to="/blogdetails" class="post-hover">
                        <img
                          src="assets/images/blog/blogFullSize/img07.jpg"
                          alt="post_item"
                        />
                      </Link>
                    </div>
                  </figure>
                  <div class="post-content">
                    <span class="post-date">
                      {" "}
                      <a href=";">
                        <span>14</span>July 2021
                      </a>{" "}
                    </span>
                    <h5 class="post-title">
                      <a href=";" rel="bookmark">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </a>
                    </h5>
                    <div class="post-footer">
                      <p>
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Quidem omnis rerum quam accusamus! At earum
                        explicabo aperiam temporibus provident ab? Natus
                        repellat labore voluptate.
                      </p>
                      <Link to="/blogdetails" class="more-link">
                        Read More
                      </Link>
                      <span class="post-count">07</span>
                    </div>
                  </div>
                </article>
              </div>
              <div class="av-column-4 av-md-column-6">
                <article class="post-items">
                  <figure class="post-image post-image-absolute">
                    <div class="featured-image">
                      <Link to="/blogdetails" class="post-hover">
                        <img
                          src="assets/images/blog/blogFullSize/img08.jpg"
                          alt="post_item"
                        />
                      </Link>
                    </div>
                  </figure>
                  <div class="post-content">
                    <span class="post-date">
                      {" "}
                      <a href=";">
                        <span>15</span>July 2021
                      </a>{" "}
                    </span>
                    <h5 class="post-title">
                      <a href=";" rel="bookmark">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </a>
                    </h5>
                    <div class="post-footer">
                      <p>
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Quidem omnis rerum quam accusamus! At earum
                        explicabo aperiam temporibus provident ab? Natus
                        repellat labore voluptate.
                      </p>
                      <Link to="/blogdetails" class="more-link">
                        Read More
                      </Link>
                      <span class="post-count">08</span>
                    </div>
                  </div>
                </article>
              </div>
              <div class="av-column-4 av-md-column-6">
                <article class="post-items">
                  <figure class="post-image post-image-absolute">
                    <div class="featured-image">
                      <Link to="/blogdetails" class="post-hover">
                        <img
                          src="assets/images/blog/blogFullSize/img09.jpg"
                          alt="post_item"
                        />
                      </Link>
                    </div>
                  </figure>
                  <div class="post-content">
                    <span class="post-date">
                      {" "}
                      <a href=";">
                        <span>16</span>July 2021
                      </a>{" "}
                    </span>
                    <h5 class="post-title">
                      <a href=";" rel="bookmark">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </a>
                    </h5>
                    <div class="post-footer">
                      <p>
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Quidem omnis rerum quam accusamus! At earum
                        explicabo aperiam temporibus provident ab? Natus
                        repellat labore voluptate.
                      </p>
                      <Link to="/blogdetails" class="more-link">
                        Read More
                      </Link>
                      <span class="post-count">09</span>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
          <div class="shape20">
            <img
              src="assets/images/blog/clipArt/shape20.png"
              alt="Decorative blue geometric pattern"
            />
          </div>
          <div class="shape21">
            <img
              src="assets/images/blog/clipArt/shape21.png"
              alt="clipArt shape21"
            />
          </div>
          <div class="shape22">
            <img
              src="assets/images/blog/clipArt/shape22.png"
              alt="clipArt shape22"
            />
          </div>
          <div class="shape23">
            <img
              src="assets/images/blog/clipArt/shape23.png"
              alt="clipArt shape23"
            />
          </div>

          <div class="av-column-12 text-center mt-5">
            <div class="av-post-pagination">
              {/* <!-- Pagination --> */}
              <nav
                class="navigation pagination"
                role="navigation"
                aria-label="Posts"
              >
                <h2 class="screen-reader-text">Posts navigation</h2>
                <div class="nav-links">
                  <a class="prev page-numbers" href=";">
                    <i class="fa fa-arrow-left"></i>
                  </a>
                  <span aria-current="page" class="page-numbers current">
                    1
                  </span>
                  <a class="page-numbers" href=";">
                    2
                  </a>
                  <a class="page-numbers" href=";">
                    3
                  </a>
                  <a class="next page-numbers" href=";">
                    <i class="fa fa-arrow-right"></i>
                  </a>
                </div>
              </nav>
              {/* <!-- Pagination --> */}
            </div>
          </div>
        </section>
        {/* <!-- End: Blog
=================================--> */}
      </div>
    </div>
  );
};

export default Blog;
