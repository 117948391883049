import React from "react";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PDFview as PDFAction } from "../Redux/Action";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import logo from "./logo.png";
import Container from "@mui/material/Container";
import "../MyProfile/PDFView.css";
import exportAsImage from "./exportToImage";
import Swal from "sweetalert2";

const PDFView = () => {
  const exportRef = useRef();
  const [view, setView] = useState("");
  const [load, setLoad] = useState("");
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [horoscope, setHoroscope] = useState("");

  const handleSubmit = () => {
    dispatch(PDFAction({ language: horoscope })).then((res) => {
      setView(res.data.data);
      if (res.data.data === "Detail not found") {
        Swal.fire({
          title: "User Details Incomplete!",
          text: "Please complete full details.",
          icon: "warning",
          confirmButtonText: "OK",
        });
        navigate("/myprofile");
      } else if (
        res.data.data.message === "Request failed with status code 429"
      ) {
        Swal.fire({
          title: "Too many requests!",
          text: "Please Try Again!",
          icon: "warning",
          confirmButtonText: "OK",
        });
        navigate("/myprofile");
      }
      setToggle(true);
    });
  };

  return (
    <div style={{ background: "white" }}>
      <Container
        style={{
          padding: "30px 0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid container mb={4}>
          <Grid item sm={12} lg={6} md={6} xs={12} pl={5} pr={2} pt={2} pb={2}>
            <div className="form-outline mb-4">
              <label>
                Choose the Language <span style={{ color: "red" }}>*</span>
              </label>
              <select
                name="langugae"
                required
                onChange={(event) => setHoroscope(event.target.value)}
                id="langugae"
              >
                <option value="">Select Language</option>
                <option value="en">English</option>
                <option value="ta">Tamil</option>
                <option value="ml">Malayalam</option>
                <option value="ka">Kannadam</option>
                <option value="hi">Hindi</option>
              </select>
            </div>{" "}
          </Grid>
          <Grid item sm={12} lg={6} md={6} xs={12} pl={5} pr={2} pt={2} pb={2}>
            <button
              type="button"
              className="btn btn-primary btn-block mt-4"
              style={{ float: "left" }}
              onClick={() => handleSubmit()}
            >
              View
            </button>
          </Grid>
        </Grid>
      </Container>
      {toggle === true ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              ref={exportRef}
              className="watermark-card"
              style={{
                width: "1024px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <img
                  src={logo}
                  alt="VVK Matrimony"
                  className="watermark"
                  style={{
                    width: "450px",
                    height: "450px",
                  }}
                />
                <div>
                  <img
                    src={logo}
                    alt="VVK Matrimony"
                    style={{ width: "150px", height: "150px" }}
                  />
                </div>
                <div style={{ padding: "20px 40px 0px 0px" }}>
                  <p style={{ color: "red" }}>Mobile: 98942 34066</p>
                  <p style={{ color: "red" }}>
                    Email: vvkmatrimony12@gmail.com
                  </p>
                  <p style={{ color: "red" }}>website: www.vvkmatrimony.com</p>
                </div>
              </div>
              <Grid container gap={1} className="detail_grid">
                <Grid
                  className="card-split"
                  item
                  xs={12}
                  lg={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "10px",
                  }}
                >
                  <Grid
                    container
                    sx={{ border: "1px solid #555", padding: "10px" }}
                  >
                    <Grid
                      item
                      xs={5.9}
                      lg={5.9}
                      sx={{
                        paddingRight: "5px",
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "15px",
                      }}
                    >
                      <div
                        className="forColumnCap"
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          //   width: "100%",
                          // columnGap: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "40%",
                            wordBreak: "break-word",
                          }}
                        >
                          {view.language === "ta" ? (
                            <b>பெயர்</b>
                          ) : view.language === "en" ? (
                            <b>Name</b>
                          ) : view.language === "ml" ? (
                            <b>പേര്</b>
                          ) : view.language === "hi" ? (
                            <b>नाम</b>
                          ) : view.language === "ka" ? (
                            <b>ಹೆಸರು</b>
                          ) : null}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "60%",
                            wordBreak: "break-word",
                            textTransform: "capitalize",
                          }}
                        >
                          <b>{view?.userName}</b>
                        </div>
                      </div>
                      <div
                        className="forColumnCap"
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          //   width: "100%",
                          // columnGap: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "40%",
                            wordBreak: "break-word",
                          }}
                        >
                          {view.language === "ta"
                            ? "பாலினம்"
                            : view.language === "en"
                            ? "Gender"
                            : view.language === "ml"
                            ? "ലിംഗഭേദം"
                            : view.language === "hi"
                            ? "लिंग"
                            : view.language === "ka"
                            ? " ಲಿಂಗ"
                            : null}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "60%",
                            wordBreak: "break-word",
                            textTransform: "capitalize",
                          }}
                        >
                          {view?.gender}
                        </div>
                      </div>
                      <div
                        className="forColumnCap"
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          //   width: "100%",
                          // columnGap: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "40%",
                            wordBreak: "break-word",
                          }}
                        >
                          {view.language === "ta"
                            ? "பிறந்த தேதி"
                            : view.language === "en"
                            ? "Date of Birth"
                            : view.language === "ml"
                            ? "ജനനത്തീയതി"
                            : view.language === "hi"
                            ? "जन्म की तारीख"
                            : view.language === "ka"
                            ? "ಹುಟ್ತಿದ ದಿನ"
                            : null}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "60%",
                            wordBreak: "break-word",
                            textTransform: "capitalize",
                          }}
                        >
                          {view?.dateOfBirth}
                        </div>
                      </div>
                      <div
                        className="forColumnCap"
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          //   width: "100%",
                          // columnGap: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "40%",
                            wordBreak: "break-word",
                          }}
                        >
                          {view.language === "ta"
                            ? "பிறந்த நேரம்"
                            : view.language === "en"
                            ? "Time of Birth"
                            : view.language === "ml"
                            ? " ജനന സമയം "
                            : view.language === "hi"
                            ? "जन्म समय "
                            : view.language === "ka"
                            ? "ಜನನ ಸಮಯ"
                            : null}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "60%",
                            wordBreak: "break-word",
                            textTransform: "capitalize",
                          }}
                        >
                          {view?.timeOfBirth}
                        </div>
                      </div>
                      <div
                        className="forColumnCap"
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          //   width: "100%",
                          // columnGap: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "40%",
                            wordBreak: "break-word",
                          }}
                        >
                          {view.language === "ta"
                            ? " பிறந்த இடம்"
                            : view.language === "en"
                            ? "Place of Birth"
                            : view.language === "ml"
                            ? " ജനനസ്ഥലം "
                            : view.language === "hi"
                            ? " जन्म स्थान  "
                            : view.language === "ka"
                            ? "ಹುಟ್ಟಿದ ಸ್ಥಳ"
                            : null}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "60%",
                            wordBreak: "break-word",
                            textTransform: "capitalize",
                          }}
                        >
                          {view?.placeOfBirth}
                        </div>
                      </div>
                      <div
                        className="forColumnCap"
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          //   width: "100%",
                          // columnGap: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "40%",
                            wordBreak: "break-word",
                          }}
                        >
                          {view.language === "ta"
                            ? " கல்வி தகுதி"
                            : view.language === "en"
                            ? "Educational Qualification"
                            : view.language === "ml"
                            ? " വിദ്യാഭ്യാസ യോഗ്യത "
                            : view.language === "hi"
                            ? "शैक्षिक योग्यता"
                            : view.language === "ka"
                            ? "ಶೈಕ್ಷಣಿಕ ಅರ್ಹತೆ"
                            : null}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "60%",
                            wordBreak: "break-word",
                            textTransform: "capitalize",
                          }}
                        >
                          {view?.educationalQualification}
                        </div>
                      </div>
                      <div
                        className="forColumnCap"
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          //   width: "100%",
                          // columnGap: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "40%",
                            wordBreak: "break-word",
                          }}
                        >
                          {view.language === "ta"
                            ? "தொழில்"
                            : view.language === "en"
                            ? "Profession"
                            : view.language === "ml"
                            ? "തൊഴിൽ"
                            : view.language === "hi"
                            ? "पेशा"
                            : view.language === "ka"
                            ? "ವೃತ್ತಿ"
                            : null}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "60%",
                            wordBreak: "break-word",
                            textTransform: "capitalize",
                          }}
                        >
                          {view?.profession}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={0.1} lg={0.1}>
                      <div
                        style={{
                          borderLeft: "1px solid #555",
                          height: "100%",
                        }}
                      ></div>
                    </Grid>

                    <Grid
                      item
                      xs={5.9}
                      lg={5.9}
                      sx={{
                        paddingLeft: "5px",
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "15px",
                      }}
                    >
                      <div
                        className="forColumnCap"
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          //   width: "100%",
                          // columnGap: "20px",
                        }}
                      >
                        <div style={{ display: "flex", width: "40%" }}>
                          {view.language === "ta"
                            ? "கோத்திரம்"
                            : view.language === "en"
                            ? "Gotharam"
                            : view.language === "ml"
                            ? "ഗോത്രം"
                            : view.language === "hi"
                            ? "गोत्र"
                            : view.language === "ka"
                            ? "ഗോത്രം"
                            : null}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "60%",
                            wordBreak: "break-word",
                            textTransform: "capitalize",
                          }}
                        >
                          {view?.gothram}
                        </div>
                      </div>
                      <div
                        className="forColumnCap"
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          //   width: "100%",
                          // columnGap: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "40%",
                            wordBreak: "break-word",
                          }}
                        >
                          {view.language === "ta"
                            ? "மதுலம்"
                            : view.language === "en"
                            ? "Madhulam"
                            : view.language === "ml"
                            ? "മധുലം"
                            : view.language === "hi"
                            ? "मधुलमगोत्र"
                            : view.language === "ka"
                            ? "ಮಧುಲಂ"
                            : null}{" "}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "60%",
                            wordBreak: "break-word",
                            textTransform: "capitalize",
                          }}
                        >
                          {view?.madhulam}
                        </div>
                      </div>
                      <div
                        className="forColumnCap"
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          //   width: "100%",
                          // columnGap: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "40%",
                            wordBreak: "break-word",
                          }}
                        >
                          {view.language === "ta"
                            ? "ராசி"
                            : view.language === "en"
                            ? "Rasi"
                            : view.language === "ml"
                            ? "രാശി"
                            : view.language === "hi"
                            ? "राशि"
                            : view.language === "ka"
                            ? "ರಾಶಿ"
                            : null}{" "}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "60%",
                            wordBreak: "break-word",
                            textTransform: "capitalize",
                          }}
                        >
                          {view?.rasi}
                        </div>
                      </div>
                      <div
                        className="forColumnCap"
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          //   width: "100%",
                          // columnGap: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "40%",
                            wordBreak: "break-word",
                          }}
                        >
                          {view.language === "ta"
                            ? "நட்சத்திரம்"
                            : view.language === "en"
                            ? "Star"
                            : view.language === "ml"
                            ? "നക്ഷത്രം"
                            : view.language === "hi"
                            ? "नक्षत्र"
                            : view.language === "ka"
                            ? "ನಕ್ಷತ್ರ"
                            : null}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "60%",
                            wordBreak: "break-word",
                            textTransform: "capitalize",
                          }}
                        >
                          {view?.star}
                        </div>
                      </div>
                      <div
                        className="forColumnCap"
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          //   width: "100%",
                          // columnGap: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "40%",
                            wordBreak: "break-word",
                          }}
                        >
                          {view.language === "ta"
                            ? "லக்னம்"
                            : view.language === "en"
                            ? "Lagnam"
                            : view.language === "ml"
                            ? "ലഗ്നം"
                            : view.language === "hi"
                            ? "लग्न"
                            : view.language === "ka"
                            ? "ನಕ್ಷತ್ರ"
                            : null}{" "}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "60%",
                            wordBreak: "break-word",
                            textTransform: "capitalize",
                          }}
                        >
                          {view?.selectLagnam}
                        </div>
                      </div>

                      <div
                        className="forColumnCap"
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          //   width: "100%",
                          // columnGap: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "40%",
                            wordBreak: "break-word",
                          }}
                        >
                          {view.language === "ta"
                            ? "நகரம்"
                            : view.language === "en"
                            ? "City"
                            : view.language === "ml"
                            ? "നഗരം"
                            : view.language === "hi"
                            ? "शहर"
                            : view.language === "ka"
                            ? "ನಗರ"
                            : null}{" "}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "60%",
                            wordBreak: "break-word",
                            textTransform: "capitalize",
                          }}
                        >
                          {view?.city}
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} lg={12}>
                    <Grid
                      container
                      sx={{ border: "1px solid #555", padding: "10px" }}
                    >
                      <Grid
                        item
                        xs={5.9}
                        lg={5.9}
                        sx={{
                          paddingRight: "5px",
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "15px",
                        }}
                      >
                        <div
                          className="forColumnCap"
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            //   width: "100%",
                            // columnGap: "20px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "40%",
                              wordBreak: "break-word",
                            }}
                          >
                            {view.language === "ta"
                              ? "தந்தையின் பெயர்"
                              : view.language === "en"
                              ? "Father Name"
                              : view.language === "ml"
                              ? "പിതാവിന്റെ പേര് "
                              : view.language === "hi"
                              ? "पिता का नाम "
                              : view.language === "ka"
                              ? " ತಂದೆಯ ಹೆಸರು "
                              : null}{" "}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              width: "60%",
                              wordBreak: "break-word",
                              textTransform: "capitalize",
                            }}
                          >
                            {view?.fatherName}
                          </div>
                        </div>
                        <div
                          className="forColumnCap"
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            //   width: "100%",
                            // columnGap: "20px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "40%",
                              wordBreak: "break-word",
                            }}
                          >
                            {view.language === "ta"
                              ? "தந்தை தொழில்"
                              : view.language === "en"
                              ? "Father Occupation"
                              : view.language === "ml"
                              ? "പിതാവിന്റെ തൊഴിൽ  "
                              : view.language === "hi"
                              ? "पिता का पेशा"
                              : view.language === "ka"
                              ? " ತಂದೆಯ ವೃತ್ತಿ"
                              : null}{" "}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              width: "60%",
                              wordBreak: "break-word",
                              textTransform: "capitalize",
                            }}
                          >
                            {view?.fatherOccupation}
                          </div>
                        </div>
                        <div
                          className="forColumnCap"
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            //   width: "100%",
                            // columnGap: "20px",
                          }}
                        >
                          <div style={{ display: "flex", width: "40%" }}>
                            {view.language === "ta"
                              ? "குடும்ப நிலை"
                              : view.language === "en"
                              ? "Family Status"
                              : view.language === "ml"
                              ? "കുടുംബ സ്ഥിതി"
                              : view.language === "hi"
                              ? "परिवार की स्थिति "
                              : view.language === "ka"
                              ? " ಕುಟುಂಬ ಸ್ಥಿತಿ"
                              : null}{" "}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              width: "60%",
                              wordBreak: "break-word",
                              textTransform: "capitalize",
                            }}
                          >
                            {view?.familyStatus}
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={0.1} lg={0.1}>
                        <div
                          style={{
                            borderLeft: "1px solid #555",
                            height: "100%",
                          }}
                        ></div>
                      </Grid>{" "}
                      <Grid
                        item
                        xs={5.9}
                        lg={5.9}
                        sx={{
                          paddingLeft: "5px",
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "15px",
                        }}
                      >
                        <div
                          className="forColumnCap"
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            //   width: "100%",
                            // columnGap: "20px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "40%",
                              wordBreak: "break-word",
                            }}
                          >
                            {view.language === "ta"
                              ? "தாய் பெயர்"
                              : view.language === "en"
                              ? "Mother Name"
                              : view.language === "ml"
                              ? "അമ്മയുടെ പേര് "
                              : view.language === "hi"
                              ? "मां का नाम"
                              : view.language === "ka"
                              ? "ತಾಯಿಯ ಹೆಸರು"
                              : null}{" "}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              width: "60%",
                              wordBreak: "break-word",
                              textTransform: "capitalize",
                            }}
                          >
                            {view?.motherName}
                          </div>
                        </div>
                        <div
                          className="forColumnCap"
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            //   width: "100%",
                            // columnGap: "20px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "40%",
                              wordBreak: "break-word",
                            }}
                          >
                            {view.language === "ta"
                              ? "தாய் தொழில்"
                              : view.language === "en"
                              ? "Mother Occupation"
                              : view.language === "ml"
                              ? "അമ്മയുടെ തൊഴിൽ"
                              : view.language === "hi"
                              ? "मां का पेशा"
                              : view.language === "ka"
                              ? "ತಾಯಿಯ ವೃತ್ತಿ "
                              : null}{" "}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              width: "60%",
                              wordBreak: "break-word",
                              textTransform: "capitalize",
                            }}
                          >
                            {view?.motherOccupation}
                          </div>
                        </div>{" "}
                        <div
                          className="forColumnCap"
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            //   width: "100%",
                            // columnGap: "20px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "40%",
                              wordBreak: "break-word",
                            }}
                          >
                            {view.language === "ta"
                              ? "குடும்ப வகை"
                              : view.language === "en"
                              ? "Family Type"
                              : view.language === "ml"
                              ? "കുടുംബ തരം"
                              : view.language === "hi"
                              ? "परिवार का प्रकार "
                              : view.language === "ka"
                              ? "ಕುಟುಂಬ ಪ್ರಕಾರ"
                              : null}{" "}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              width: "60%",
                              wordBreak: "break-word",
                              textTransform: "capitalize",
                            }}
                          >
                            {view?.familyType}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  columnGap={2}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="astroCards"
                >
                  <Grid
                    item
                    xs={5.5}
                    lg={5.5}
                    sm={5.5}
                    // sx={{ background: "red" }}
                    className="card1"
                  >
                    <div className="grid-item  item1">
                      {" "}
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.jupiter === "12" &&
                        view.language === "ta"
                          ? "குரு"
                          : view?.amsamDetails?.jupiter === "12" &&
                            view.language === "en"
                          ? "Jupiter"
                          : view?.amsamDetails?.jupiter === "12" &&
                            view.language === "ml"
                          ? "ഗുരു"
                          : view?.amsamDetails?.jupiter === "12" &&
                            view.language === "hi"
                          ? "गुरु"
                          : view?.amsamDetails?.jupiter === "12" &&
                            view.language === "ka"
                          ? "ಗುರು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.kethu === "12" &&
                        view.language === "ta"
                          ? "கேது"
                          : view?.amsamDetails?.kethu === "12" &&
                            view.language === "en"
                          ? "Kethu"
                          : view?.amsamDetails?.kethu === "12" &&
                            view.language === "ml"
                          ? "കേതു"
                          : view?.amsamDetails?.kethu === "12" &&
                            view.language === "hi"
                          ? "केतु"
                          : view?.amsamDetails?.kethu === "12" &&
                            view.language === "ka"
                          ? "ಕೇತು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.lagnam === "12" &&
                        view.language === "ta"
                          ? "லக்னம்"
                          : view?.amsamDetails?.lagnam === "12" &&
                            view.language === "en"
                          ? "Lagnam"
                          : view?.amsamDetails?.lagnam === "12" &&
                            view.language === "ml"
                          ? "ലഗ്നം"
                          : view?.amsamDetails?.lagnam === "12" &&
                            view.language === "hi"
                          ? "लग्न"
                          : view?.amsamDetails?.lagnam === "12" &&
                            view.language === "ka"
                          ? "ಲಗ್ನ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.mars === "12" &&
                        view.language === "ta"
                          ? "செவ்வாய்"
                          : view?.amsamDetails?.mars === "12" &&
                            view.language === "en"
                          ? "Mars"
                          : view?.amsamDetails?.mars === "12" &&
                            view.language === "ml"
                          ? "ചെവി"
                          : view?.amsamDetails?.mars === "12" &&
                            view.language === "hi"
                          ? "मंगल"
                          : view?.amsamDetails?.mars === "12" &&
                            view.language === "ka"
                          ? "ಕುಜ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.mercury === "12" &&
                        view.language === "ta"
                          ? "புதன்"
                          : view?.amsamDetails?.mercury === "12" &&
                            view.language === "en"
                          ? "Mercury"
                          : view?.amsamDetails?.mercury === "12" &&
                            view.language === "ml"
                          ? "ബുധൻ"
                          : view?.amsamDetails?.mercury === "12" &&
                            view.language === "hi"
                          ? "बुध"
                          : view?.amsamDetails?.mercury === "12" &&
                            view.language === "ka"
                          ? "ಬುಧ"
                          : null}{" "}
                      </span>{" "}
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.moon === "12" &&
                        view.language === "ta"
                          ? "சந்திரன்"
                          : view?.amsamDetails?.moon === "12" &&
                            view.language === "en"
                          ? "Moon"
                          : view?.amsamDetails?.moon === "12" &&
                            view.language === "ml"
                          ? "ചന്ദ്രൻ"
                          : view?.amsamDetails?.moon === "12" &&
                            view.language === "hi"
                          ? "चंद्र"
                          : view?.amsamDetails?.moon === "12" &&
                            view.language === "ka"
                          ? "ಚಂದ್ರ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.raghu === "12" &&
                        view.language === "ta"
                          ? "இராகு"
                          : view?.amsamDetails?.raghu === "12" &&
                            view.language === "en"
                          ? "Raghu"
                          : view?.amsamDetails?.raghu === "12" &&
                            view.language === "ml"
                          ? "രാഹു"
                          : view?.amsamDetails?.raghu === "12" &&
                            view.language === "hi"
                          ? "राहु"
                          : view?.amsamDetails?.raghu === "12" &&
                            view.language === "ka"
                          ? "ರಾಹು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.saturn === "12" &&
                        view.language === "ta"
                          ? "சனி"
                          : view?.amsamDetails?.saturn === "12" &&
                            view.language === "en"
                          ? "Saturn"
                          : view?.amsamDetails?.saturn === "12" &&
                            view.language === "ml"
                          ? "ശനി"
                          : view?.amsamDetails?.saturn === "12" &&
                            view.language === "hi"
                          ? "शनि"
                          : view?.amsamDetails?.saturn === "12" &&
                            view.language === "ka"
                          ? "ಶನಿ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.sun === "12" &&
                        view.language === "ta"
                          ? "சூரியன்"
                          : view?.amsamDetails?.sun === "12" &&
                            view.language === "en"
                          ? "Sun"
                          : view?.amsamDetails?.sun === "12" &&
                            view.language === "ml"
                          ? "സൂര്യൻ"
                          : view?.amsamDetails?.sun === "12" &&
                            view.language === "hi"
                          ? "सूर्य"
                          : view?.amsamDetails?.sun === "12" &&
                            view.language === "ka"
                          ? "ಸೂರ್ಯ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.venus === "12" &&
                        view.language === "ta"
                          ? "சுக்கிரன்"
                          : view?.amsamDetails?.venus === "12" &&
                            view.language === "en"
                          ? "Venus"
                          : view?.amsamDetails?.venus === "12" &&
                            view.language === "ml"
                          ? "ശുക്രൻ"
                          : view?.amsamDetails?.venus === "12" &&
                            view.language === "hi"
                          ? "शुक्र"
                          : view?.amsamDetails?.venus === "12" &&
                            view.language === "ka"
                          ? "ಶುಕ್ರ"
                          : null}
                      </span>
                      {/* ++++++++ */}
                    </div>

                    <div className="grid-item  item1">
                      {" "}
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.jupiter === "1" &&
                        view.language === "ta"
                          ? "குரு"
                          : view?.amsamDetails?.jupiter === "1" &&
                            view.language === "en"
                          ? "Jupiter"
                          : view?.amsamDetails?.jupiter === "1" &&
                            view.language === "ml"
                          ? "ഗുരു"
                          : view?.amsamDetails?.jupiter === "1" &&
                            view.language === "hi"
                          ? "गुरु"
                          : view?.amsamDetails?.jupiter === "1" &&
                            view.language === "ka"
                          ? "ಗುರು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.kethu === "1" &&
                        view.language === "ta"
                          ? "கேது"
                          : view?.amsamDetails?.kethu === "1" &&
                            view.language === "en"
                          ? "Kethu"
                          : view?.amsamDetails?.kethu === "1" &&
                            view.language === "ml"
                          ? "കേതു"
                          : view?.amsamDetails?.kethu === "1" &&
                            view.language === "hi"
                          ? "केतु"
                          : view?.amsamDetails?.kethu === "1" &&
                            view.language === "ka"
                          ? "ಕೇತು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.lagnam === "1" &&
                        view.language === "ta"
                          ? "லக்னம்"
                          : view?.amsamDetails?.lagnam === "1" &&
                            view.language === "en"
                          ? "Lagnam"
                          : view?.amsamDetails?.lagnam === "1" &&
                            view.language === "ml"
                          ? "ലഗ്നം"
                          : view?.amsamDetails?.lagnam === "1" &&
                            view.language === "hi"
                          ? "लग्न"
                          : view?.amsamDetails?.lagnam === "1" &&
                            view.language === "ka"
                          ? "ಲಗ್ನ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.mars === "1" &&
                        view.language === "ta"
                          ? "செவ்வாய்"
                          : view?.amsamDetails?.mars === "1" &&
                            view.language === "en"
                          ? "Mars"
                          : view?.amsamDetails?.mars === "1" &&
                            view.language === "ml"
                          ? "ചെവി"
                          : view?.amsamDetails?.mars === "1" &&
                            view.language === "hi"
                          ? "मंगल"
                          : view?.amsamDetails?.mars === "1" &&
                            view.language === "ka"
                          ? "ಕುಜ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.mercury === "1" &&
                        view.language === "ta"
                          ? "புதன்"
                          : view?.amsamDetails?.mercury === "1" &&
                            view.language === "en"
                          ? "Mercury"
                          : view?.amsamDetails?.mercury === "1" &&
                            view.language === "ml"
                          ? "ബുധൻ"
                          : view?.amsamDetails?.mercury === "1" &&
                            view.language === "hi"
                          ? "बुध"
                          : view?.amsamDetails?.mercury === "1" &&
                            view.language === "ka"
                          ? "ಬುಧ"
                          : null}{" "}
                      </span>{" "}
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.moon === "1" &&
                        view.language === "ta"
                          ? "சந்திரன்"
                          : view?.amsamDetails?.moon === "1" &&
                            view.language === "en"
                          ? "Moon"
                          : view?.amsamDetails?.moon === "1" &&
                            view.language === "ml"
                          ? "ചന്ദ്രൻ"
                          : view?.amsamDetails?.moon === "1" &&
                            view.language === "hi"
                          ? "चंद्र"
                          : view?.amsamDetails?.moon === "1" &&
                            view.language === "ka"
                          ? "ಚಂದ್ರ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.raghu === "1" &&
                        view.language === "ta"
                          ? "இராகு"
                          : view?.amsamDetails?.raghu === "1" &&
                            view.language === "en"
                          ? "Raghu"
                          : view?.amsamDetails?.raghu === "1" &&
                            view.language === "ml"
                          ? "രാഹു"
                          : view?.amsamDetails?.raghu === "1" &&
                            view.language === "hi"
                          ? "राहु"
                          : view?.amsamDetails?.raghu === "1" &&
                            view.language === "ka"
                          ? "ರಾಹು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.saturn === "1" &&
                        view.language === "ta"
                          ? "சனி"
                          : view?.amsamDetails?.saturn === "1" &&
                            view.language === "en"
                          ? "Saturn"
                          : view?.amsamDetails?.saturn === "1" &&
                            view.language === "ml"
                          ? "ശനി"
                          : view?.amsamDetails?.saturn === "1" &&
                            view.language === "hi"
                          ? "शनि"
                          : view?.amsamDetails?.saturn === "1" &&
                            view.language === "ka"
                          ? "ಶನಿ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.sun === "1" &&
                        view.language === "ta"
                          ? "சூரியன்"
                          : view?.amsamDetails?.sun === "1" &&
                            view.language === "en"
                          ? "Sun"
                          : view?.amsamDetails?.sun === "1" &&
                            view.language === "ml"
                          ? "സൂര്യൻ"
                          : view?.amsamDetails?.sun === "1" &&
                            view.language === "hi"
                          ? "सूर्य"
                          : view?.amsamDetails?.sun === "1" &&
                            view.language === "ka"
                          ? "ಸೂರ್ಯ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.venus === "1" &&
                        view.language === "ta"
                          ? "சுக்கிரன்"
                          : view?.amsamDetails?.venus === "1" &&
                            view.language === "en"
                          ? "Venus"
                          : view?.amsamDetails?.venus === "1" &&
                            view.language === "ml"
                          ? "ശുക്രൻ"
                          : view?.amsamDetails?.venus === "1" &&
                            view.language === "hi"
                          ? "शुक्र"
                          : view?.amsamDetails?.venus === "1" &&
                            view.language === "ka"
                          ? "ಶುಕ್ರ"
                          : null}
                      </span>
                    </div>
                    <div className="grid-item  item1">
                      {" "}
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.jupiter === "2" &&
                        view.language === "ta"
                          ? "குரு"
                          : view?.amsamDetails?.jupiter === "2" &&
                            view.language === "en"
                          ? "Jupiter"
                          : view?.amsamDetails?.jupiter === "2" &&
                            view.language === "ml"
                          ? "ഗുരു"
                          : view?.amsamDetails?.jupiter === "2" &&
                            view.language === "hi"
                          ? "गुरु"
                          : view?.amsamDetails?.jupiter === "2" &&
                            view.language === "ka"
                          ? "ಗುರು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.kethu === "2" &&
                        view.language === "ta"
                          ? "கேது"
                          : view?.amsamDetails?.kethu === "2" &&
                            view.language === "en"
                          ? "Kethu"
                          : view?.amsamDetails?.kethu === "2" &&
                            view.language === "ml"
                          ? "കേതു"
                          : view?.amsamDetails?.kethu === "2" &&
                            view.language === "hi"
                          ? "केतु"
                          : view?.amsamDetails?.kethu === "2" &&
                            view.language === "ka"
                          ? "ಕೇತು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.lagnam === "2" &&
                        view.language === "ta"
                          ? "லக்னம்"
                          : view?.amsamDetails?.lagnam === "2" &&
                            view.language === "en"
                          ? "Lagnam"
                          : view?.amsamDetails?.lagnam === "2" &&
                            view.language === "ml"
                          ? "ലഗ്നം"
                          : view?.amsamDetails?.lagnam === "2" &&
                            view.language === "hi"
                          ? "लग्न"
                          : view?.amsamDetails?.lagnam === "2" &&
                            view.language === "ka"
                          ? "ಲಗ್ನ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.mars === "2" &&
                        view.language === "ta"
                          ? "செவ்வாய்"
                          : view?.amsamDetails?.mars === "2" &&
                            view.language === "en"
                          ? "Mars"
                          : view?.amsamDetails?.mars === "2" &&
                            view.language === "ml"
                          ? "ചെവി"
                          : view?.amsamDetails?.mars === "2" &&
                            view.language === "hi"
                          ? "मंगल"
                          : view?.amsamDetails?.mars === "2" &&
                            view.language === "ka"
                          ? "ಕುಜ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.mercury === "2" &&
                        view.language === "ta"
                          ? "புதன்"
                          : view?.amsamDetails?.mercury === "2" &&
                            view.language === "en"
                          ? "Mercury"
                          : view?.amsamDetails?.mercury === "2" &&
                            view.language === "ml"
                          ? "ബുധൻ"
                          : view?.amsamDetails?.mercury === "2" &&
                            view.language === "hi"
                          ? "बुध"
                          : view?.amsamDetails?.mercury === "2" &&
                            view.language === "ka"
                          ? "ಬುಧ"
                          : null}{" "}
                      </span>{" "}
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.moon === "2" &&
                        view.language === "ta"
                          ? "சந்திரன்"
                          : view?.amsamDetails?.moon === "2" &&
                            view.language === "en"
                          ? "Moon"
                          : view?.amsamDetails?.moon === "2" &&
                            view.language === "ml"
                          ? "ചന്ദ്രൻ"
                          : view?.amsamDetails?.moon === "2" &&
                            view.language === "hi"
                          ? "चंद्र"
                          : view?.amsamDetails?.moon === "2" &&
                            view.language === "ka"
                          ? "ಚಂದ್ರ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.raghu === "2" &&
                        view.language === "ta"
                          ? "இராகு"
                          : view?.amsamDetails?.raghu === "2" &&
                            view.language === "en"
                          ? "Raghu"
                          : view?.amsamDetails?.raghu === "2" &&
                            view.language === "ml"
                          ? "രാഹു"
                          : view?.amsamDetails?.raghu === "2" &&
                            view.language === "hi"
                          ? "राहु"
                          : view?.amsamDetails?.raghu === "2" &&
                            view.language === "ka"
                          ? "ರಾಹು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.saturn === "2" &&
                        view.language === "ta"
                          ? "சனி"
                          : view?.amsamDetails?.saturn === "2" &&
                            view.language === "en"
                          ? "Saturn"
                          : view?.amsamDetails?.saturn === "2" &&
                            view.language === "ml"
                          ? "ശനി"
                          : view?.amsamDetails?.saturn === "2" &&
                            view.language === "hi"
                          ? "शनि"
                          : view?.amsamDetails?.saturn === "2" &&
                            view.language === "ka"
                          ? "ಶನಿ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.sun === "2" &&
                        view.language === "ta"
                          ? "சூரியன்"
                          : view?.amsamDetails?.sun === "2" &&
                            view.language === "en"
                          ? "Sun"
                          : view?.amsamDetails?.sun === "2" &&
                            view.language === "ml"
                          ? "സൂര്യൻ"
                          : view?.amsamDetails?.sun === "2" &&
                            view.language === "hi"
                          ? "सूर्य"
                          : view?.amsamDetails?.sun === "2" &&
                            view.language === "ka"
                          ? "ಸೂರ್ಯ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.venus === "2" &&
                        view.language === "ta"
                          ? "சுக்கிரன்"
                          : view?.amsamDetails?.venus === "2" &&
                            view.language === "en"
                          ? "Venus"
                          : view?.amsamDetails?.venus === "2" &&
                            view.language === "ml"
                          ? "ശുക്രൻ"
                          : view?.amsamDetails?.venus === "2" &&
                            view.language === "hi"
                          ? "शुक्र"
                          : view?.amsamDetails?.venus === "2" &&
                            view.language === "ka"
                          ? "ಶುಕ್ರ"
                          : null}
                      </span>
                    </div>
                    <div className="grid-item  item1">
                      {" "}
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.jupiter === "3" &&
                        view.language === "ta"
                          ? "குரு"
                          : view?.amsamDetails?.jupiter === "3" &&
                            view.language === "en"
                          ? "Jupiter"
                          : view?.amsamDetails?.jupiter === "3" &&
                            view.language === "ml"
                          ? "ഗുരു"
                          : view?.amsamDetails?.jupiter === "3" &&
                            view.language === "hi"
                          ? "गुरु"
                          : view?.amsamDetails?.jupiter === "3" &&
                            view.language === "ka"
                          ? "ಗುರು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.kethu === "3" &&
                        view.language === "ta"
                          ? "கேது"
                          : view?.amsamDetails?.kethu === "3" &&
                            view.language === "en"
                          ? "Kethu"
                          : view?.amsamDetails?.kethu === "3" &&
                            view.language === "ml"
                          ? "കേതു"
                          : view?.amsamDetails?.kethu === "3" &&
                            view.language === "hi"
                          ? "केतु"
                          : view?.amsamDetails?.kethu === "3" &&
                            view.language === "ka"
                          ? "ಕೇತು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.lagnam === "3" &&
                        view.language === "ta"
                          ? "லக்னம்"
                          : view?.amsamDetails?.lagnam === "3" &&
                            view.language === "en"
                          ? "Lagnam"
                          : view?.amsamDetails?.lagnam === "3" &&
                            view.language === "ml"
                          ? "ലഗ്നം"
                          : view?.amsamDetails?.lagnam === "3" &&
                            view.language === "hi"
                          ? "लग्न"
                          : view?.amsamDetails?.lagnam === "3" &&
                            view.language === "ka"
                          ? "ಲಗ್ನ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.mars === "3" &&
                        view.language === "ta"
                          ? "செவ்வாய்"
                          : view?.amsamDetails?.mars === "3" &&
                            view.language === "en"
                          ? "Mars"
                          : view?.amsamDetails?.mars === "3" &&
                            view.language === "ml"
                          ? "ചെവി"
                          : view?.amsamDetails?.mars === "3" &&
                            view.language === "hi"
                          ? "मंगल"
                          : view?.amsamDetails?.mars === "3" &&
                            view.language === "ka"
                          ? "ಕುಜ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.mercury === "3" &&
                        view.language === "ta"
                          ? "புதன்"
                          : view?.amsamDetails?.mercury === "3" &&
                            view.language === "en"
                          ? "Mercury"
                          : view?.amsamDetails?.mercury === "3" &&
                            view.language === "ml"
                          ? "ബുധൻ"
                          : view?.amsamDetails?.mercury === "3" &&
                            view.language === "hi"
                          ? "बुध"
                          : view?.amsamDetails?.mercury === "3" &&
                            view.language === "ka"
                          ? "ಬುಧ"
                          : null}{" "}
                      </span>{" "}
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.moon === "3" &&
                        view.language === "ta"
                          ? "சந்திரன்"
                          : view?.amsamDetails?.moon === "3" &&
                            view.language === "en"
                          ? "Moon"
                          : view?.amsamDetails?.moon === "3" &&
                            view.language === "ml"
                          ? "ചന്ദ്രൻ"
                          : view?.amsamDetails?.moon === "3" &&
                            view.language === "hi"
                          ? "चंद्र"
                          : view?.amsamDetails?.moon === "3" &&
                            view.language === "ka"
                          ? "ಚಂದ್ರ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.raghu === "3" &&
                        view.language === "ta"
                          ? "இராகு"
                          : view?.amsamDetails?.raghu === "3" &&
                            view.language === "en"
                          ? "Raghu"
                          : view?.amsamDetails?.raghu === "3" &&
                            view.language === "ml"
                          ? "രാഹു"
                          : view?.amsamDetails?.raghu === "3" &&
                            view.language === "hi"
                          ? "राहु"
                          : view?.amsamDetails?.raghu === "3" &&
                            view.language === "ka"
                          ? "ರಾಹು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.saturn === "3" &&
                        view.language === "ta"
                          ? "சனி"
                          : view?.amsamDetails?.saturn === "3" &&
                            view.language === "en"
                          ? "Saturn"
                          : view?.amsamDetails?.saturn === "3" &&
                            view.language === "ml"
                          ? "ശനി"
                          : view?.amsamDetails?.saturn === "3" &&
                            view.language === "hi"
                          ? "शनि"
                          : view?.amsamDetails?.saturn === "3" &&
                            view.language === "ka"
                          ? "ಶನಿ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.sun === "3" &&
                        view.language === "ta"
                          ? "சூரியன்"
                          : view?.amsamDetails?.sun === "3" &&
                            view.language === "en"
                          ? "Sun"
                          : view?.amsamDetails?.sun === "3" &&
                            view.language === "ml"
                          ? "സൂര്യൻ"
                          : view?.amsamDetails?.sun === "3" &&
                            view.language === "hi"
                          ? "सूर्य"
                          : view?.amsamDetails?.sun === "3" &&
                            view.language === "ka"
                          ? "ಸೂರ್ಯ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.venus === "3" &&
                        view.language === "ta"
                          ? "சுக்கிரன்"
                          : view?.amsamDetails?.venus === "3" &&
                            view.language === "en"
                          ? "Venus"
                          : view?.amsamDetails?.venus === "3" &&
                            view.language === "ml"
                          ? "ശുക്രൻ"
                          : view?.amsamDetails?.venus === "3" &&
                            view.language === "hi"
                          ? "शुक्र"
                          : view?.amsamDetails?.venus === "3" &&
                            view.language === "ka"
                          ? "ಶುಕ್ರ"
                          : null}
                      </span>
                    </div>
                    <div className="grid-item  item1">
                      {" "}
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.jupiter === "11" &&
                        view.language === "ta"
                          ? "குரு"
                          : view?.amsamDetails?.jupiter === "11" &&
                            view.language === "en"
                          ? "Jupiter"
                          : view?.amsamDetails?.jupiter === "11" &&
                            view.language === "ml"
                          ? "ഗുരു"
                          : view?.amsamDetails?.jupiter === "11" &&
                            view.language === "hi"
                          ? "गुरु"
                          : view?.amsamDetails?.jupiter === "11" &&
                            view.language === "ka"
                          ? "ಗುರು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.kethu === "11" &&
                        view.language === "ta"
                          ? "கேது"
                          : view?.amsamDetails?.kethu === "11" &&
                            view.language === "en"
                          ? "Kethu"
                          : view?.amsamDetails?.kethu === "11" &&
                            view.language === "ml"
                          ? "കേതു"
                          : view?.amsamDetails?.kethu === "11" &&
                            view.language === "hi"
                          ? "केतु"
                          : view?.amsamDetails?.kethu === "11" &&
                            view.language === "ka"
                          ? "ಕೇತು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.lagnam === "11" &&
                        view.language === "ta"
                          ? "லக்னம்"
                          : view?.amsamDetails?.lagnam === "11" &&
                            view.language === "en"
                          ? "Lagnam"
                          : view?.amsamDetails?.lagnam === "11" &&
                            view.language === "ml"
                          ? "ലഗ്നം"
                          : view?.amsamDetails?.lagnam === "11" &&
                            view.language === "hi"
                          ? "लग्न"
                          : view?.amsamDetails?.lagnam === "11" &&
                            view.language === "ka"
                          ? "ಲಗ್ನ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.mars === "11" &&
                        view.language === "ta"
                          ? "செவ்வாய்"
                          : view?.amsamDetails?.mars === "11" &&
                            view.language === "en"
                          ? "Mars"
                          : view?.amsamDetails?.mars === "11" &&
                            view.language === "ml"
                          ? "ചെവി"
                          : view?.amsamDetails?.mars === "11" &&
                            view.language === "hi"
                          ? "मंगल"
                          : view?.amsamDetails?.mars === "11" &&
                            view.language === "ka"
                          ? "ಕುಜ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.mercury === "11" &&
                        view.language === "ta"
                          ? "புதன்"
                          : view?.amsamDetails?.mercury === "11" &&
                            view.language === "en"
                          ? "Mercury"
                          : view?.amsamDetails?.mercury === "11" &&
                            view.language === "ml"
                          ? "ബുധൻ"
                          : view?.amsamDetails?.mercury === "11" &&
                            view.language === "hi"
                          ? "बुध"
                          : view?.amsamDetails?.mercury === "11" &&
                            view.language === "ka"
                          ? "ಬುಧ"
                          : null}{" "}
                      </span>{" "}
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.moon === "11" &&
                        view.language === "ta"
                          ? "சந்திரன்"
                          : view?.amsamDetails?.moon === "11" &&
                            view.language === "en"
                          ? "Moon"
                          : view?.amsamDetails?.moon === "11" &&
                            view.language === "ml"
                          ? "ചന്ദ്രൻ"
                          : view?.amsamDetails?.moon === "11" &&
                            view.language === "hi"
                          ? "चंद्र"
                          : view?.amsamDetails?.moon === "11" &&
                            view.language === "ka"
                          ? "ಚಂದ್ರ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.raghu === "11" &&
                        view.language === "ta"
                          ? "இராகு"
                          : view?.amsamDetails?.raghu === "11" &&
                            view.language === "en"
                          ? "Raghu"
                          : view?.amsamDetails?.raghu === "11" &&
                            view.language === "ml"
                          ? "രാഹു"
                          : view?.amsamDetails?.raghu === "11" &&
                            view.language === "hi"
                          ? "राहु"
                          : view?.amsamDetails?.raghu === "11" &&
                            view.language === "ka"
                          ? "ರಾಹು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.saturn === "11" &&
                        view.language === "ta"
                          ? "சனி"
                          : view?.amsamDetails?.saturn === "11" &&
                            view.language === "en"
                          ? "Saturn"
                          : view?.amsamDetails?.saturn === "11" &&
                            view.language === "ml"
                          ? "ശനി"
                          : view?.amsamDetails?.saturn === "11" &&
                            view.language === "hi"
                          ? "शनि"
                          : view?.amsamDetails?.saturn === "11" &&
                            view.language === "ka"
                          ? "ಶನಿ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.sun === "11" &&
                        view.language === "ta"
                          ? "சூரியன்"
                          : view?.amsamDetails?.sun === "11" &&
                            view.language === "en"
                          ? "Sun"
                          : view?.amsamDetails?.sun === "11" &&
                            view.language === "ml"
                          ? "സൂര്യൻ"
                          : view?.amsamDetails?.sun === "11" &&
                            view.language === "hi"
                          ? "सूर्य"
                          : view?.amsamDetails?.sun === "11" &&
                            view.language === "ka"
                          ? "ಸೂರ್ಯ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.venus === "11" &&
                        view.language === "ta"
                          ? "சுக்கிரன்"
                          : view?.amsamDetails?.venus === "11" &&
                            view.language === "en"
                          ? "Venus"
                          : view?.amsamDetails?.venus === "11" &&
                            view.language === "ml"
                          ? "ശുക്രൻ"
                          : view?.amsamDetails?.venus === "11" &&
                            view.language === "hi"
                          ? "शुक्र"
                          : view?.amsamDetails?.venus === "11" &&
                            view.language === "ka"
                          ? "ಶುಕ್ರ"
                          : null}
                      </span>
                    </div>
                    <div className="grid-item item6">Amsam</div>
                    <div className="grid-item  item1">
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.jupiter === "4" &&
                        view.language === "ta"
                          ? "குரு"
                          : view?.amsamDetails?.jupiter === "4" &&
                            view.language === "en"
                          ? "Jupiter"
                          : view?.amsamDetails?.jupiter === "4" &&
                            view.language === "ml"
                          ? "ഗുരു"
                          : view?.amsamDetails?.jupiter === "4" &&
                            view.language === "hi"
                          ? "गुरु"
                          : view?.amsamDetails?.jupiter === "4" &&
                            view.language === "ka"
                          ? "ಗುರು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.kethu === "4" &&
                        view.language === "ta"
                          ? "கேது"
                          : view?.amsamDetails?.kethu === "4" &&
                            view.language === "en"
                          ? "Kethu"
                          : view?.amsamDetails?.kethu === "4" &&
                            view.language === "ml"
                          ? "കേതു"
                          : view?.amsamDetails?.kethu === "4" &&
                            view.language === "hi"
                          ? "केतु"
                          : view?.amsamDetails?.kethu === "4" &&
                            view.language === "ka"
                          ? "ಕೇತು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.lagnam === "4" &&
                        view.language === "ta"
                          ? "லக்னம்"
                          : view?.amsamDetails?.lagnam === "4" &&
                            view.language === "en"
                          ? "Lagnam"
                          : view?.amsamDetails?.lagnam === "4" &&
                            view.language === "ml"
                          ? "ലഗ്നം"
                          : view?.amsamDetails?.lagnam === "4" &&
                            view.language === "hi"
                          ? "लग्न"
                          : view?.amsamDetails?.lagnam === "4" &&
                            view.language === "ka"
                          ? "ಲಗ್ನ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.mars === "4" &&
                        view.language === "ta"
                          ? "செவ்வாய்"
                          : view?.amsamDetails?.mars === "4" &&
                            view.language === "en"
                          ? "Mars"
                          : view?.amsamDetails?.mars === "4" &&
                            view.language === "ml"
                          ? "ചെവി"
                          : view?.amsamDetails?.mars === "4" &&
                            view.language === "hi"
                          ? "मंगल"
                          : view?.amsamDetails?.mars === "4" &&
                            view.language === "ka"
                          ? "ಕುಜ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.mercury === "4" &&
                        view.language === "ta"
                          ? "புதன்"
                          : view?.amsamDetails?.mercury === "4" &&
                            view.language === "en"
                          ? "Mercury"
                          : view?.amsamDetails?.mercury === "4" &&
                            view.language === "ml"
                          ? "ബുധൻ"
                          : view?.amsamDetails?.mercury === "4" &&
                            view.language === "hi"
                          ? "बुध"
                          : view?.amsamDetails?.mercury === "4" &&
                            view.language === "ka"
                          ? "ಬುಧ"
                          : null}{" "}
                      </span>{" "}
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.moon === "4" &&
                        view.language === "ta"
                          ? "சந்திரன்"
                          : view?.amsamDetails?.moon === "4" &&
                            view.language === "en"
                          ? "Moon"
                          : view?.amsamDetails?.moon === "4" &&
                            view.language === "ml"
                          ? "ചന്ദ്രൻ"
                          : view?.amsamDetails?.moon === "4" &&
                            view.language === "hi"
                          ? "चंद्र"
                          : view?.amsamDetails?.moon === "4" &&
                            view.language === "ka"
                          ? "ಚಂದ್ರ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.raghu === "4" &&
                        view.language === "ta"
                          ? "இராகு"
                          : view?.amsamDetails?.raghu === "4" &&
                            view.language === "en"
                          ? "Raghu"
                          : view?.amsamDetails?.raghu === "4" &&
                            view.language === "ml"
                          ? "രാഹു"
                          : view?.amsamDetails?.raghu === "4" &&
                            view.language === "hi"
                          ? "राहु"
                          : view?.amsamDetails?.raghu === "4" &&
                            view.language === "ka"
                          ? "ರಾಹು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.saturn === "4" &&
                        view.language === "ta"
                          ? "சனி"
                          : view?.amsamDetails?.saturn === "4" &&
                            view.language === "en"
                          ? "Saturn"
                          : view?.amsamDetails?.saturn === "4" &&
                            view.language === "ml"
                          ? "ശനി"
                          : view?.amsamDetails?.saturn === "4" &&
                            view.language === "hi"
                          ? "शनि"
                          : view?.amsamDetails?.saturn === "4" &&
                            view.language === "ka"
                          ? "ಶನಿ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.sun === "4" &&
                        view.language === "ta"
                          ? "சூரியன்"
                          : view?.amsamDetails?.sun === "4" &&
                            view.language === "en"
                          ? "Sun"
                          : view?.amsamDetails?.sun === "4" &&
                            view.language === "ml"
                          ? "സൂര്യൻ"
                          : view?.amsamDetails?.sun === "4" &&
                            view.language === "hi"
                          ? "सूर्य"
                          : view?.amsamDetails?.sun === "4" &&
                            view.language === "ka"
                          ? "ಸೂರ್ಯ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.venus === "4" &&
                        view.language === "ta"
                          ? "சுக்கிரன்"
                          : view?.amsamDetails?.venus === "4" &&
                            view.language === "en"
                          ? "Venus"
                          : view?.amsamDetails?.venus === "4" &&
                            view.language === "ml"
                          ? "ശുക്രൻ"
                          : view?.amsamDetails?.venus === "4" &&
                            view.language === "hi"
                          ? "शुक्र"
                          : view?.amsamDetails?.venus === "4" &&
                            view.language === "ka"
                          ? "ಶುಕ್ರ"
                          : null}
                      </span>
                    </div>
                    <div className="grid-item  item1">
                      {" "}
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.jupiter === "10" &&
                        view.language === "ta"
                          ? "குரு"
                          : view?.amsamDetails?.jupiter === "10" &&
                            view.language === "en"
                          ? "Jupiter"
                          : view?.amsamDetails?.jupiter === "10" &&
                            view.language === "ml"
                          ? "ഗുരു"
                          : view?.amsamDetails?.jupiter === "10" &&
                            view.language === "hi"
                          ? "गुरु"
                          : view?.amsamDetails?.jupiter === "10" &&
                            view.language === "ka"
                          ? "ಗುರು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.kethu === "10" &&
                        view.language === "ta"
                          ? "கேது"
                          : view?.amsamDetails?.kethu === "10" &&
                            view.language === "en"
                          ? "Kethu"
                          : view?.amsamDetails?.kethu === "10" &&
                            view.language === "ml"
                          ? "കേതു"
                          : view?.amsamDetails?.kethu === "10" &&
                            view.language === "hi"
                          ? "केतु"
                          : view?.amsamDetails?.kethu === "10" &&
                            view.language === "ka"
                          ? "ಕೇತು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.lagnam === "10" &&
                        view.language === "ta"
                          ? "லக்னம்"
                          : view?.amsamDetails?.lagnam === "10" &&
                            view.language === "en"
                          ? "Lagnam"
                          : view?.amsamDetails?.lagnam === "10" &&
                            view.language === "ml"
                          ? "ലഗ്നം"
                          : view?.amsamDetails?.lagnam === "10" &&
                            view.language === "hi"
                          ? "लग्न"
                          : view?.amsamDetails?.lagnam === "10" &&
                            view.language === "ka"
                          ? "ಲಗ್ನ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.mars === "10" &&
                        view.language === "ta"
                          ? "செவ்வாய்"
                          : view?.amsamDetails?.mars === "10" &&
                            view.language === "en"
                          ? "Mars"
                          : view?.amsamDetails?.mars === "10" &&
                            view.language === "ml"
                          ? "ചെവി"
                          : view?.amsamDetails?.mars === "10" &&
                            view.language === "hi"
                          ? "मंगल"
                          : view?.amsamDetails?.mars === "10" &&
                            view.language === "ka"
                          ? "ಕುಜ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.mercury === "10" &&
                        view.language === "ta"
                          ? "புதன்"
                          : view?.amsamDetails?.mercury === "10" &&
                            view.language === "en"
                          ? "Mercury"
                          : view?.amsamDetails?.mercury === "10" &&
                            view.language === "ml"
                          ? "ബുധൻ"
                          : view?.amsamDetails?.mercury === "10" &&
                            view.language === "hi"
                          ? "बुध"
                          : view?.amsamDetails?.mercury === "10" &&
                            view.language === "ka"
                          ? "ಬುಧ"
                          : null}{" "}
                      </span>{" "}
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.moon === "10" &&
                        view.language === "ta"
                          ? "சந்திரன்"
                          : view?.amsamDetails?.moon === "10" &&
                            view.language === "en"
                          ? "Moon"
                          : view?.amsamDetails?.moon === "10" &&
                            view.language === "ml"
                          ? "ചന്ദ്രൻ"
                          : view?.amsamDetails?.moon === "10" &&
                            view.language === "hi"
                          ? "चंद्र"
                          : view?.amsamDetails?.moon === "10" &&
                            view.language === "ka"
                          ? "ಚಂದ್ರ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.raghu === "10" &&
                        view.language === "ta"
                          ? "இராகு"
                          : view?.amsamDetails?.raghu === "10" &&
                            view.language === "en"
                          ? "Raghu"
                          : view?.amsamDetails?.raghu === "10" &&
                            view.language === "ml"
                          ? "രാഹു"
                          : view?.amsamDetails?.raghu === "10" &&
                            view.language === "hi"
                          ? "राहु"
                          : view?.amsamDetails?.raghu === "10" &&
                            view.language === "ka"
                          ? "ರಾಹು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.saturn === "10" &&
                        view.language === "ta"
                          ? "சனி"
                          : view?.amsamDetails?.saturn === "10" &&
                            view.language === "en"
                          ? "Saturn"
                          : view?.amsamDetails?.saturn === "10" &&
                            view.language === "ml"
                          ? "ശനി"
                          : view?.amsamDetails?.saturn === "10" &&
                            view.language === "hi"
                          ? "शनि"
                          : view?.amsamDetails?.saturn === "10" &&
                            view.language === "ka"
                          ? "ಶನಿ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.sun === "10" &&
                        view.language === "ta"
                          ? "சூரியன்"
                          : view?.amsamDetails?.sun === "10" &&
                            view.language === "en"
                          ? "Sun"
                          : view?.amsamDetails?.sun === "10" &&
                            view.language === "ml"
                          ? "സൂര്യൻ"
                          : view?.amsamDetails?.sun === "10" &&
                            view.language === "hi"
                          ? "सूर्य"
                          : view?.amsamDetails?.sun === "10" &&
                            view.language === "ka"
                          ? "ಸೂರ್ಯ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.venus === "10" &&
                        view.language === "ta"
                          ? "சுக்கிரன்"
                          : view?.amsamDetails?.venus === "10" &&
                            view.language === "en"
                          ? "Venus"
                          : view?.amsamDetails?.venus === "10" &&
                            view.language === "ml"
                          ? "ശുക്രൻ"
                          : view?.amsamDetails?.venus === "10" &&
                            view.language === "hi"
                          ? "शुक्र"
                          : view?.amsamDetails?.venus === "10" &&
                            view.language === "ka"
                          ? "ಶುಕ್ರ"
                          : null}
                      </span>
                    </div>
                    <div className="grid-item item9  item1">
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.jupiter === "5" &&
                        view.language === "ta"
                          ? "குரு"
                          : view?.amsamDetails?.jupiter === "5" &&
                            view.language === "en"
                          ? "Jupiter"
                          : view?.amsamDetails?.jupiter === "5" &&
                            view.language === "ml"
                          ? "ഗുരു"
                          : view?.amsamDetails?.jupiter === "5" &&
                            view.language === "hi"
                          ? "गुरु"
                          : view?.amsamDetails?.jupiter === "5" &&
                            view.language === "ka"
                          ? "ಗುರು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.kethu === "5" &&
                        view.language === "ta"
                          ? "கேது"
                          : view?.amsamDetails?.kethu === "5" &&
                            view.language === "en"
                          ? "Kethu"
                          : view?.amsamDetails?.kethu === "5" &&
                            view.language === "ml"
                          ? "കേതു"
                          : view?.amsamDetails?.kethu === "5" &&
                            view.language === "hi"
                          ? "केतु"
                          : view?.amsamDetails?.kethu === "5" &&
                            view.language === "ka"
                          ? "ಕೇತು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.lagnam === "5" &&
                        view.language === "ta"
                          ? "லக்னம்"
                          : view?.amsamDetails?.lagnam === "5" &&
                            view.language === "en"
                          ? "Lagnam"
                          : view?.amsamDetails?.lagnam === "5" &&
                            view.language === "ml"
                          ? "ലഗ്നം"
                          : view?.amsamDetails?.lagnam === "5" &&
                            view.language === "hi"
                          ? "लग्न"
                          : view?.amsamDetails?.lagnam === "5" &&
                            view.language === "ka"
                          ? "ಲಗ್ನ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.mars === "5" &&
                        view.language === "ta"
                          ? "செவ்வாய்"
                          : view?.amsamDetails?.mars === "5" &&
                            view.language === "en"
                          ? "Mars"
                          : view?.amsamDetails?.mars === "5" &&
                            view.language === "ml"
                          ? "ചെവി"
                          : view?.amsamDetails?.mars === "5" &&
                            view.language === "hi"
                          ? "मंगल"
                          : view?.amsamDetails?.mars === "5" &&
                            view.language === "ka"
                          ? "ಕುಜ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.mercury === "5" &&
                        view.language === "ta"
                          ? "புதன்"
                          : view?.amsamDetails?.mercury === "5" &&
                            view.language === "en"
                          ? "Mercury"
                          : view?.amsamDetails?.mercury === "5" &&
                            view.language === "ml"
                          ? "ബുധൻ"
                          : view?.amsamDetails?.mercury === "5" &&
                            view.language === "hi"
                          ? "बुध"
                          : view?.amsamDetails?.mercury === "5" &&
                            view.language === "ka"
                          ? "ಬುಧ"
                          : null}{" "}
                      </span>{" "}
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.moon === "5" &&
                        view.language === "ta"
                          ? "சந்திரன்"
                          : view?.amsamDetails?.moon === "5" &&
                            view.language === "en"
                          ? "Moon"
                          : view?.amsamDetails?.moon === "5" &&
                            view.language === "ml"
                          ? "ചന്ദ്രൻ"
                          : view?.amsamDetails?.moon === "5" &&
                            view.language === "hi"
                          ? "चंद्र"
                          : view?.amsamDetails?.moon === "5" &&
                            view.language === "ka"
                          ? "ಚಂದ್ರ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.raghu === "5" &&
                        view.language === "ta"
                          ? "இராகு"
                          : view?.amsamDetails?.raghu === "5" &&
                            view.language === "en"
                          ? "Raghu"
                          : view?.amsamDetails?.raghu === "5" &&
                            view.language === "ml"
                          ? "രാഹു"
                          : view?.amsamDetails?.raghu === "5" &&
                            view.language === "hi"
                          ? "राहु"
                          : view?.amsamDetails?.raghu === "5" &&
                            view.language === "ka"
                          ? "ರಾಹು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.saturn === "5" &&
                        view.language === "ta"
                          ? "சனி"
                          : view?.amsamDetails?.saturn === "5" &&
                            view.language === "en"
                          ? "Saturn"
                          : view?.amsamDetails?.saturn === "5" &&
                            view.language === "ml"
                          ? "ശനി"
                          : view?.amsamDetails?.saturn === "5" &&
                            view.language === "hi"
                          ? "शनि"
                          : view?.amsamDetails?.saturn === "5" &&
                            view.language === "ka"
                          ? "ಶನಿ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.sun === "5" &&
                        view.language === "ta"
                          ? "சூரியன்"
                          : view?.amsamDetails?.sun === "5" &&
                            view.language === "en"
                          ? "Sun"
                          : view?.amsamDetails?.sun === "5" &&
                            view.language === "ml"
                          ? "സൂര്യൻ"
                          : view?.amsamDetails?.sun === "5" &&
                            view.language === "hi"
                          ? "सूर्य"
                          : view?.amsamDetails?.sun === "5" &&
                            view.language === "ka"
                          ? "ಸೂರ್ಯ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.venus === "5" &&
                        view.language === "ta"
                          ? "சுக்கிரன்"
                          : view?.amsamDetails?.venus === "5" &&
                            view.language === "en"
                          ? "Venus"
                          : view?.amsamDetails?.venus === "5" &&
                            view.language === "ml"
                          ? "ശുക്രൻ"
                          : view?.amsamDetails?.venus === "5" &&
                            view.language === "hi"
                          ? "शुक्र"
                          : view?.amsamDetails?.venus === "5" &&
                            view.language === "ka"
                          ? "ಶುಕ್ರ"
                          : null}
                      </span>
                    </div>
                    <div className="grid-item  item1">
                      {" "}
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.jupiter === "9" &&
                        view.language === "ta"
                          ? "குரு"
                          : view?.amsamDetails?.jupiter === "9" &&
                            view.language === "en"
                          ? "Jupiter"
                          : view?.amsamDetails?.jupiter === "9" &&
                            view.language === "ml"
                          ? "ഗുരു"
                          : view?.amsamDetails?.jupiter === "9" &&
                            view.language === "hi"
                          ? "गुरु"
                          : view?.amsamDetails?.jupiter === "9" &&
                            view.language === "ka"
                          ? "ಗುರು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.kethu === "9" &&
                        view.language === "ta"
                          ? "கேது"
                          : view?.amsamDetails?.kethu === "9" &&
                            view.language === "en"
                          ? "Kethu"
                          : view?.amsamDetails?.kethu === "9" &&
                            view.language === "ml"
                          ? "കേതു"
                          : view?.amsamDetails?.kethu === "9" &&
                            view.language === "hi"
                          ? "केतु"
                          : view?.amsamDetails?.kethu === "9" &&
                            view.language === "ka"
                          ? "ಕೇತು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.lagnam === "9" &&
                        view.language === "ta"
                          ? "லக்னம்"
                          : view?.amsamDetails?.lagnam === "9" &&
                            view.language === "en"
                          ? "Lagnam"
                          : view?.amsamDetails?.lagnam === "9" &&
                            view.language === "ml"
                          ? "ലഗ്നം"
                          : view?.amsamDetails?.lagnam === "9" &&
                            view.language === "hi"
                          ? "लग्न"
                          : view?.amsamDetails?.lagnam === "9" &&
                            view.language === "ka"
                          ? "ಲಗ್ನ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.mars === "9" &&
                        view.language === "ta"
                          ? "செவ்வாய்"
                          : view?.amsamDetails?.mars === "9" &&
                            view.language === "en"
                          ? "Mars"
                          : view?.amsamDetails?.mars === "9" &&
                            view.language === "ml"
                          ? "ചെവി"
                          : view?.amsamDetails?.mars === "9" &&
                            view.language === "hi"
                          ? "मंगल"
                          : view?.amsamDetails?.mars === "9" &&
                            view.language === "ka"
                          ? "ಕುಜ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.mercury === "9" &&
                        view.language === "ta"
                          ? "புதன்"
                          : view?.amsamDetails?.mercury === "9" &&
                            view.language === "en"
                          ? "Mercury"
                          : view?.amsamDetails?.mercury === "9" &&
                            view.language === "ml"
                          ? "ബുധൻ"
                          : view?.amsamDetails?.mercury === "9" &&
                            view.language === "hi"
                          ? "बुध"
                          : view?.amsamDetails?.mercury === "9" &&
                            view.language === "ka"
                          ? "ಬುಧ"
                          : null}{" "}
                      </span>{" "}
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.moon === "9" &&
                        view.language === "ta"
                          ? "சந்திரன்"
                          : view?.amsamDetails?.moon === "9" &&
                            view.language === "en"
                          ? "Moon"
                          : view?.amsamDetails?.moon === "9" &&
                            view.language === "ml"
                          ? "ചന്ദ്രൻ"
                          : view?.amsamDetails?.moon === "9" &&
                            view.language === "hi"
                          ? "चंद्र"
                          : view?.amsamDetails?.moon === "9" &&
                            view.language === "ka"
                          ? "ಚಂದ್ರ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.raghu === "9" &&
                        view.language === "ta"
                          ? "இராகு"
                          : view?.amsamDetails?.raghu === "9" &&
                            view.language === "en"
                          ? "Raghu"
                          : view?.amsamDetails?.raghu === "9" &&
                            view.language === "ml"
                          ? "രാഹു"
                          : view?.amsamDetails?.raghu === "9" &&
                            view.language === "hi"
                          ? "राहु"
                          : view?.amsamDetails?.raghu === "9" &&
                            view.language === "ka"
                          ? "ರಾಹು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.saturn === "9" &&
                        view.language === "ta"
                          ? "சனி"
                          : view?.amsamDetails?.saturn === "9" &&
                            view.language === "en"
                          ? "Saturn"
                          : view?.amsamDetails?.saturn === "9" &&
                            view.language === "ml"
                          ? "ശനി"
                          : view?.amsamDetails?.saturn === "9" &&
                            view.language === "hi"
                          ? "शनि"
                          : view?.amsamDetails?.saturn === "9" &&
                            view.language === "ka"
                          ? "ಶನಿ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.sun === "9" &&
                        view.language === "ta"
                          ? "சூரியன்"
                          : view?.amsamDetails?.sun === "9" &&
                            view.language === "en"
                          ? "Sun"
                          : view?.amsamDetails?.sun === "9" &&
                            view.language === "ml"
                          ? "സൂര്യൻ"
                          : view?.amsamDetails?.sun === "9" &&
                            view.language === "hi"
                          ? "सूर्य"
                          : view?.amsamDetails?.sun === "9" &&
                            view.language === "ka"
                          ? "ಸೂರ್ಯ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.venus === "9" &&
                        view.language === "ta"
                          ? "சுக்கிரன்"
                          : view?.amsamDetails?.venus === "9" &&
                            view.language === "en"
                          ? "Venus"
                          : view?.amsamDetails?.venus === "9" &&
                            view.language === "ml"
                          ? "ശുക്രൻ"
                          : view?.amsamDetails?.venus === "9" &&
                            view.language === "hi"
                          ? "शुक्र"
                          : view?.amsamDetails?.venus === "9" &&
                            view.language === "ka"
                          ? "ಶುಕ್ರ"
                          : null}
                      </span>
                    </div>
                    <div className="grid-item  item1">
                      {" "}
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.jupiter === "8" &&
                        view.language === "ta"
                          ? "குரு"
                          : view?.amsamDetails?.jupiter === "8" &&
                            view.language === "en"
                          ? "Jupiter"
                          : view?.amsamDetails?.jupiter === "8" &&
                            view.language === "ml"
                          ? "ഗുരു"
                          : view?.amsamDetails?.jupiter === "8" &&
                            view.language === "hi"
                          ? "गुरु"
                          : view?.amsamDetails?.jupiter === "8" &&
                            view.language === "ka"
                          ? "ಗುರು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.kethu === "8" &&
                        view.language === "ta"
                          ? "கேது"
                          : view?.amsamDetails?.kethu === "8" &&
                            view.language === "en"
                          ? "Kethu"
                          : view?.amsamDetails?.kethu === "8" &&
                            view.language === "ml"
                          ? "കേതു"
                          : view?.amsamDetails?.kethu === "8" &&
                            view.language === "hi"
                          ? "केतु"
                          : view?.amsamDetails?.kethu === "8" &&
                            view.language === "ka"
                          ? "ಕೇತು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.lagnam === "8" &&
                        view.language === "ta"
                          ? "லக்னம்"
                          : view?.amsamDetails?.lagnam === "10" &&
                            view.language === "en"
                          ? "Lagnam"
                          : view?.amsamDetails?.lagnam === "10" &&
                            view.language === "ml"
                          ? "ലഗ്നം"
                          : view?.amsamDetails?.lagnam === "10" &&
                            view.language === "hi"
                          ? "लग्न"
                          : view?.amsamDetails?.lagnam === "10" &&
                            view.language === "ka"
                          ? "ಲಗ್ನ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.mars === "8" &&
                        view.language === "ta"
                          ? "செவ்வாய்"
                          : view?.amsamDetails?.mars === "8" &&
                            view.language === "en"
                          ? "Mars"
                          : view?.amsamDetails?.mars === "8" &&
                            view.language === "ml"
                          ? "ചെവി"
                          : view?.amsamDetails?.mars === "8" &&
                            view.language === "hi"
                          ? "मंगल"
                          : view?.amsamDetails?.mars === "8" &&
                            view.language === "ka"
                          ? "ಕುಜ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.mercury === "8" &&
                        view.language === "ta"
                          ? "புதன்"
                          : view?.amsamDetails?.mercury === "8" &&
                            view.language === "en"
                          ? "Mercury"
                          : view?.amsamDetails?.mercury === "8" &&
                            view.language === "ml"
                          ? "ബുധൻ"
                          : view?.amsamDetails?.mercury === "8" &&
                            view.language === "hi"
                          ? "बुध"
                          : view?.amsamDetails?.mercury === "8" &&
                            view.language === "ka"
                          ? "ಬುಧ"
                          : null}{" "}
                      </span>{" "}
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.moon === "8" &&
                        view.language === "ta"
                          ? "சந்திரன்"
                          : view?.amsamDetails?.moon === "8" &&
                            view.language === "en"
                          ? "Moon"
                          : view?.amsamDetails?.moon === "8" &&
                            view.language === "ml"
                          ? "ചന്ദ്രൻ"
                          : view?.amsamDetails?.moon === "8" &&
                            view.language === "hi"
                          ? "चंद्र"
                          : view?.amsamDetails?.moon === "8" &&
                            view.language === "ka"
                          ? "ಚಂದ್ರ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.raghu === "8" &&
                        view.language === "ta"
                          ? "இராகு"
                          : view?.amsamDetails?.raghu === "8" &&
                            view.language === "en"
                          ? "Raghu"
                          : view?.amsamDetails?.raghu === "8" &&
                            view.language === "ml"
                          ? "രാഹു"
                          : view?.amsamDetails?.raghu === "8" &&
                            view.language === "hi"
                          ? "राहु"
                          : view?.amsamDetails?.raghu === "8" &&
                            view.language === "ka"
                          ? "ರಾಹು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.saturn === "8" &&
                        view.language === "ta"
                          ? "சனி"
                          : view?.amsamDetails?.saturn === "8" &&
                            view.language === "en"
                          ? "Saturn"
                          : view?.amsamDetails?.saturn === "8" &&
                            view.language === "ml"
                          ? "ശനി"
                          : view?.amsamDetails?.saturn === "8" &&
                            view.language === "hi"
                          ? "शनि"
                          : view?.amsamDetails?.saturn === "8" &&
                            view.language === "ka"
                          ? "ಶನಿ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.sun === "8" &&
                        view.language === "ta"
                          ? "சூரியன்"
                          : view?.amsamDetails?.sun === "8" &&
                            view.language === "en"
                          ? "Sun"
                          : view?.amsamDetails?.sun === "8" &&
                            view.language === "ml"
                          ? "സൂര്യൻ"
                          : view?.amsamDetails?.sun === "8" &&
                            view.language === "hi"
                          ? "सूर्य"
                          : view?.amsamDetails?.sun === "8" &&
                            view.language === "ka"
                          ? "ಸೂರ್ಯ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.venus === "8" &&
                        view.language === "ta"
                          ? "சுக்கிரன்"
                          : view?.amsamDetails?.venus === "8" &&
                            view.language === "en"
                          ? "Venus"
                          : view?.amsamDetails?.venus === "8" &&
                            view.language === "ml"
                          ? "സൂര്യൻ"
                          : view?.amsamDetails?.venus === "8" &&
                            view.language === "hi"
                          ? "सूर्य"
                          : view?.amsamDetails?.venus === "8" &&
                            view.language === "ka"
                          ? "ಸೂರ್ಯ"
                          : null}
                      </span>
                    </div>
                    <div className="grid-item  item1">
                      {" "}
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.jupiter === "7" &&
                        view.language === "ta"
                          ? "குரு"
                          : view?.amsamDetails?.jupiter === "7" &&
                            view.language === "en"
                          ? "Jupiter"
                          : view?.amsamDetails?.jupiter === "7" &&
                            view.language === "ml"
                          ? "ഗുരു"
                          : view?.amsamDetails?.jupiter === "7" &&
                            view.language === "hi"
                          ? "गुरु"
                          : view?.amsamDetails?.jupiter === "7" &&
                            view.language === "ka"
                          ? "ಗುರು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.kethu === "7" &&
                        view.language === "ta"
                          ? "கேது"
                          : view?.amsamDetails?.kethu === "7" &&
                            view.language === "en"
                          ? "Kethu"
                          : view?.amsamDetails?.kethu === "7" &&
                            view.language === "ml"
                          ? "കേതു"
                          : view?.amsamDetails?.kethu === "7" &&
                            view.language === "hi"
                          ? "केतु"
                          : view?.amsamDetails?.kethu === "7" &&
                            view.language === "ka"
                          ? "ಕೇತು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.lagnam === "7" &&
                        view.language === "ta"
                          ? "லக்னம்"
                          : view?.amsamDetails?.lagnam === "7" &&
                            view.language === "en"
                          ? "Lagnam"
                          : view?.amsamDetails?.lagnam === "7" &&
                            view.language === "ml"
                          ? "ലഗ്നം"
                          : view?.amsamDetails?.lagnam === "7" &&
                            view.language === "hi"
                          ? "लग्न"
                          : view?.amsamDetails?.lagnam === "7" &&
                            view.language === "ka"
                          ? "ಲಗ್ನ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.mars === "7" &&
                        view.language === "ta"
                          ? "செவ்வாய்"
                          : view?.amsamDetails?.mars === "7" &&
                            view.language === "en"
                          ? "Mars"
                          : view?.amsamDetails?.mars === "7" &&
                            view.language === "ml"
                          ? "ചെവി"
                          : view?.amsamDetails?.mars === "7" &&
                            view.language === "hi"
                          ? "मंगल"
                          : view?.amsamDetails?.mars === "7" &&
                            view.language === "ka"
                          ? "ಕುಜ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.mercury === "7" &&
                        view.language === "ta"
                          ? "புதன்"
                          : view?.amsamDetails?.mercury === "7" &&
                            view.language === "en"
                          ? "Mercury"
                          : view?.amsamDetails?.mercury === "7" &&
                            view.language === "ml"
                          ? "ബുധൻ"
                          : view?.amsamDetails?.mercury === "7" &&
                            view.language === "hi"
                          ? "बुध"
                          : view?.amsamDetails?.mercury === "7" &&
                            view.language === "ka"
                          ? "ಬುಧ"
                          : null}{" "}
                      </span>{" "}
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.moon === "7" &&
                        view.language === "ta"
                          ? "சந்திரன்"
                          : view?.amsamDetails?.moon === "7" &&
                            view.language === "en"
                          ? "Moon"
                          : view?.amsamDetails?.moon === "7" &&
                            view.language === "ml"
                          ? "ചന്ദ്രൻ"
                          : view?.amsamDetails?.moon === "7" &&
                            view.language === "hi"
                          ? "चंद्र"
                          : view?.amsamDetails?.moon === "7" &&
                            view.language === "ka"
                          ? "ಚಂದ್ರ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.raghu === "7" &&
                        view.language === "ta"
                          ? "இராகு"
                          : view?.amsamDetails?.raghu === "7" &&
                            view.language === "en"
                          ? "Raghu"
                          : view?.amsamDetails?.raghu === "7" &&
                            view.language === "ml"
                          ? "രാഹു"
                          : view?.amsamDetails?.raghu === "7" &&
                            view.language === "hi"
                          ? "राहु"
                          : view?.amsamDetails?.raghu === "7" &&
                            view.language === "ka"
                          ? "ರಾಹು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.saturn === "7" &&
                        view.language === "ta"
                          ? "சனி"
                          : view?.amsamDetails?.saturn === "7" &&
                            view.language === "en"
                          ? "Saturn"
                          : view?.amsamDetails?.saturn === "7" &&
                            view.language === "ml"
                          ? "ശനി"
                          : view?.amsamDetails?.saturn === "7" &&
                            view.language === "hi"
                          ? "शनि"
                          : view?.amsamDetails?.saturn === "7" &&
                            view.language === "ka"
                          ? "ಶನಿ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.sun === "7" &&
                        view.language === "ta"
                          ? "சூரியன்"
                          : view?.amsamDetails?.sun === "7" &&
                            view.language === "en"
                          ? "Sun"
                          : view?.amsamDetails?.sun === "7" &&
                            view.language === "ml"
                          ? "സൂര്യൻ"
                          : view?.amsamDetails?.sun === "7" &&
                            view.language === "hi"
                          ? "सूर्य"
                          : view?.amsamDetails?.sun === "7" &&
                            view.language === "ka"
                          ? "ಸೂರ್ಯ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.venus === "7" &&
                        view.language === "ta"
                          ? "சுக்கிரன்"
                          : view?.amsamDetails?.venus === "7" &&
                            view.language === "en"
                          ? "Venus"
                          : view?.amsamDetails?.venus === "7" &&
                            view.language === "ml"
                          ? "ശുക്രൻ"
                          : view?.amsamDetails?.venus === "7" &&
                            view.language === "hi"
                          ? "शुक्र"
                          : view?.amsamDetails?.venus === "7" &&
                            view.language === "ka"
                          ? "ಶುಕ್ರ"
                          : null}
                      </span>
                    </div>
                    <div className="grid-item  item1">
                      {" "}
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.jupiter === "6" &&
                        view.language === "ta"
                          ? "குரு"
                          : view?.amsamDetails?.jupiter === "6" &&
                            view.language === "en"
                          ? "Jupiter"
                          : view?.amsamDetails?.jupiter === "6" &&
                            view.language === "ml"
                          ? "ഗുരു"
                          : view?.amsamDetails?.jupiter === "6" &&
                            view.language === "hi"
                          ? "गुरु"
                          : view?.amsamDetails?.jupiter === "6" &&
                            view.language === "ka"
                          ? "ಗುರು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.kethu === "6" &&
                        view.language === "ta"
                          ? "கேது"
                          : view?.amsamDetails?.kethu === "6" &&
                            view.language === "en"
                          ? "Kethu"
                          : view?.amsamDetails?.kethu === "6" &&
                            view.language === "ml"
                          ? "കേതു"
                          : view?.amsamDetails?.kethu === "6" &&
                            view.language === "hi"
                          ? "केतु"
                          : view?.amsamDetails?.kethu === "6" &&
                            view.language === "ka"
                          ? "ಕೇತು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.lagnam === "6" &&
                        view.language === "ta"
                          ? "லக்னம்"
                          : view?.amsamDetails?.lagnam === "6" &&
                            view.language === "en"
                          ? "Lagnam"
                          : view?.amsamDetails?.lagnam === "6" &&
                            view.language === "ml"
                          ? "ലഗ്നം"
                          : view?.amsamDetails?.lagnam === "6" &&
                            view.language === "hi"
                          ? "लग्न"
                          : view?.amsamDetails?.lagnam === "6" &&
                            view.language === "ka"
                          ? "ಲಗ್ನ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.mars === "6" &&
                        view.language === "ta"
                          ? "செவ்வாய்"
                          : view?.amsamDetails?.mars === "6" &&
                            view.language === "en"
                          ? "Mars"
                          : view?.amsamDetails?.mars === "6" &&
                            view.language === "ml"
                          ? "ചെവി"
                          : view?.amsamDetails?.mars === "6" &&
                            view.language === "hi"
                          ? "मंगल"
                          : view?.amsamDetails?.mars === "6" &&
                            view.language === "ka"
                          ? "ಕುಜ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.mercury === "6" &&
                        view.language === "ta"
                          ? "புதன்"
                          : view?.amsamDetails?.mercury === "6" &&
                            view.language === "en"
                          ? "Mercury"
                          : view?.amsamDetails?.mercury === "6" &&
                            view.language === "ml"
                          ? "ബുധൻ"
                          : view?.amsamDetails?.mercury === "6" &&
                            view.language === "hi"
                          ? "बुध"
                          : view?.amsamDetails?.mercury === "6" &&
                            view.language === "ka"
                          ? "ಬುಧ"
                          : null}{" "}
                      </span>{" "}
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.moon === "6" &&
                        view.language === "ta"
                          ? "சந்திரன்"
                          : view?.amsamDetails?.moon === "6" &&
                            view.language === "en"
                          ? "Moon"
                          : view?.amsamDetails?.moon === "6" &&
                            view.language === "ml"
                          ? "ചന്ദ്രൻ"
                          : view?.amsamDetails?.moon === "6" &&
                            view.language === "hi"
                          ? "चंद्र"
                          : view?.amsamDetails?.moon === "6" &&
                            view.language === "ka"
                          ? "ಚಂದ್ರ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.raghu === "6" &&
                        view.language === "ta"
                          ? "இராகு"
                          : view?.amsamDetails?.raghu === "6" &&
                            view.language === "en"
                          ? "Raghu"
                          : view?.amsamDetails?.raghu === "6" &&
                            view.language === "ml"
                          ? "രാഹു"
                          : view?.amsamDetails?.raghu === "6" &&
                            view.language === "hi"
                          ? "राहु"
                          : view?.amsamDetails?.raghu === "6" &&
                            view.language === "ka"
                          ? "ರಾಹು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.saturn === "6" &&
                        view.language === "ta"
                          ? "சனி"
                          : view?.amsamDetails?.saturn === "6" &&
                            view.language === "en"
                          ? "Saturn"
                          : view?.amsamDetails?.saturn === "6" &&
                            view.language === "ml"
                          ? "ശനി"
                          : view?.amsamDetails?.saturn === "6" &&
                            view.language === "hi"
                          ? "शनि"
                          : view?.amsamDetails?.saturn === "6" &&
                            view.language === "ka"
                          ? "ಶನಿ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.sun === "6" &&
                        view.language === "ta"
                          ? "சூரியன்"
                          : view?.amsamDetails?.sun === "6" &&
                            view.language === "en"
                          ? "Sun"
                          : view?.amsamDetails?.sun === "6" &&
                            view.language === "ml"
                          ? "സൂര്യൻ"
                          : view?.amsamDetails?.sun === "6" &&
                            view.language === "hi"
                          ? "सूर्य"
                          : view?.amsamDetails?.sun === "6" &&
                            view.language === "ka"
                          ? "ಸೂರ್ಯ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.amsamDetails?.venus === "6" &&
                        view.language === "ta"
                          ? "சுக்கிரன்"
                          : view?.amsamDetails?.venus === "6" &&
                            view.language === "en"
                          ? "Venus"
                          : view?.amsamDetails?.venus === "6" &&
                            view.language === "ml"
                          ? "ശുക്രൻ"
                          : view?.amsamDetails?.venus === "6" &&
                            view.language === "hi"
                          ? "शुक्र"
                          : view?.amsamDetails?.venus === "6" &&
                            view.language === "ka"
                          ? "ಶುಕ್ರ"
                          : null}
                      </span>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={5.5}
                    lg={5.5}
                    sm={5.5}
                    // sx={{ background: "red" }}
                    className="card1"
                  >
                    <div className="grid-item  item1">
                      {" "}
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.jupiter === "12" &&
                        view.language === "ta"
                          ? "குரு"
                          : view?.rasiDetails?.jupiter === "12" &&
                            view.language === "en"
                          ? "Jupiter"
                          : view?.rasiDetails?.jupiter === "12" &&
                            view.language === "ml"
                          ? "ഗുരു"
                          : view?.rasiDetails?.jupiter === "12" &&
                            view.language === "hi"
                          ? "गुरु"
                          : view?.rasiDetails?.jupiter === "12" &&
                            view.language === "ka"
                          ? "ಗುರು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.kethu === "12" &&
                        view.language === "ta"
                          ? "கேது"
                          : view?.rasiDetails?.kethu === "12" &&
                            view.language === "en"
                          ? "Kethu"
                          : view?.rasiDetails?.kethu === "12" &&
                            view.language === "ml"
                          ? "കേതു"
                          : view?.rasiDetails?.kethu === "12" &&
                            view.language === "hi"
                          ? "केतु"
                          : view?.rasiDetails?.kethu === "12" &&
                            view.language === "ka"
                          ? "ಕೇತು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.lagnam === "12" &&
                        view.language === "ta"
                          ? "லக்னம்"
                          : view?.rasiDetails?.lagnam === "12" &&
                            view.language === "en"
                          ? "Lagnam"
                          : view?.rasiDetails?.lagnam === "12" &&
                            view.language === "ml"
                          ? "ലഗ്നം"
                          : view?.rasiDetails?.lagnam === "12" &&
                            view.language === "hi"
                          ? "लग्न"
                          : view?.rasiDetails?.lagnam === "12" &&
                            view.language === "ka"
                          ? "ಲಗ್ನ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.mars === "12" &&
                        view.language === "ta"
                          ? "செவ்வாய்"
                          : view?.rasiDetails?.mars === "12" &&
                            view.language === "en"
                          ? "Mars"
                          : view?.rasiDetails?.mars === "12" &&
                            view.language === "ml"
                          ? "ചെവി"
                          : view?.rasiDetails?.mars === "12" &&
                            view.language === "hi"
                          ? "मंगल"
                          : view?.rasiDetails?.mars === "12" &&
                            view.language === "ka"
                          ? "ಕುಜ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.mercury === "12" &&
                        view.language === "ta"
                          ? "புதன்"
                          : view?.rasiDetails?.mercury === "12" &&
                            view.language === "en"
                          ? "Mercury"
                          : view?.rasiDetails?.mercury === "12" &&
                            view.language === "ml"
                          ? "ബുധൻ"
                          : view?.rasiDetails?.mercury === "12" &&
                            view.language === "hi"
                          ? "बुध"
                          : view?.rasiDetails?.mercury === "12" &&
                            view.language === "ka"
                          ? "ಬುಧ"
                          : null}{" "}
                      </span>{" "}
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.moon === "12" &&
                        view.language === "ta"
                          ? "சந்திரன்"
                          : view?.rasiDetails?.moon === "12" &&
                            view.language === "en"
                          ? "Moon"
                          : view?.rasiDetails?.moon === "12" &&
                            view.language === "ml"
                          ? "ചന്ദ്രൻ"
                          : view?.rasiDetails?.moon === "12" &&
                            view.language === "hi"
                          ? "चंद्र"
                          : view?.rasiDetails?.moon === "12" &&
                            view.language === "ka"
                          ? "ಚಂದ್ರ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.raghu === "12" &&
                        view.language === "ta"
                          ? "இராகு"
                          : view?.rasiDetails?.raghu === "12" &&
                            view.language === "en"
                          ? "Raghu"
                          : view?.rasiDetails?.raghu === "12" &&
                            view.language === "ml"
                          ? "രാഹു"
                          : view?.rasiDetails?.raghu === "12" &&
                            view.language === "hi"
                          ? "राहु"
                          : view?.rasiDetails?.raghu === "12" &&
                            view.language === "ka"
                          ? "ರಾಹು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.saturn === "12" &&
                        view.language === "ta"
                          ? "சனி"
                          : view?.rasiDetails?.saturn === "12" &&
                            view.language === "en"
                          ? "Saturn"
                          : view?.rasiDetails?.saturn === "12" &&
                            view.language === "ml"
                          ? "ശനി"
                          : view?.rasiDetails?.saturn === "12" &&
                            view.language === "hi"
                          ? "शनि"
                          : view?.rasiDetails?.saturn === "12" &&
                            view.language === "ka"
                          ? "ಶನಿ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.sun === "12" &&
                        view.language === "ta"
                          ? "சூரியன்"
                          : view?.rasiDetails?.sun === "12" &&
                            view.language === "en"
                          ? "Sun"
                          : view?.rasiDetails?.sun === "12" &&
                            view.language === "ml"
                          ? "സൂര്യൻ"
                          : view?.rasiDetails?.sun === "12" &&
                            view.language === "hi"
                          ? "सूर्य"
                          : view?.rasiDetails?.sun === "12" &&
                            view.language === "ka"
                          ? "ಸೂರ್ಯ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.venus === "12" &&
                        view.language === "ta"
                          ? "சுக்கிரன்"
                          : view?.rasiDetails?.venus === "12" &&
                            view.language === "en"
                          ? "Venus"
                          : view?.rasiDetails?.venus === "12" &&
                            view.language === "ml"
                          ? "ശുക്രൻ"
                          : view?.rasiDetails?.venus === "12" &&
                            view.language === "hi"
                          ? "शुक्र"
                          : view?.rasiDetails?.venus === "12" &&
                            view.language === "ka"
                          ? "ಶುಕ್ರ"
                          : null}
                      </span>
                      {/* ++++++++ */}
                    </div>

                    <div className="grid-item  item1">
                      {" "}
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.jupiter === "1" &&
                        view.language === "ta"
                          ? "குரு"
                          : view?.rasiDetails?.jupiter === "1" &&
                            view.language === "en"
                          ? "Jupiter"
                          : view?.rasiDetails?.jupiter === "1" &&
                            view.language === "ml"
                          ? "ഗുരു"
                          : view?.rasiDetails?.jupiter === "1" &&
                            view.language === "hi"
                          ? "गुरु"
                          : view?.rasiDetails?.jupiter === "1" &&
                            view.language === "ka"
                          ? "ಗುರು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.kethu === "1" &&
                        view.language === "ta"
                          ? "கேது"
                          : view?.rasiDetails?.kethu === "1" &&
                            view.language === "en"
                          ? "Kethu"
                          : view?.rasiDetails?.kethu === "1" &&
                            view.language === "ml"
                          ? "കേതു"
                          : view?.rasiDetails?.kethu === "1" &&
                            view.language === "hi"
                          ? "केतु"
                          : view?.rasiDetails?.kethu === "1" &&
                            view.language === "ka"
                          ? "ಕೇತು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.lagnam === "1" &&
                        view.language === "ta"
                          ? "லக்னம்"
                          : view?.rasiDetails?.lagnam === "1" &&
                            view.language === "en"
                          ? "Lagnam"
                          : view?.rasiDetails?.lagnam === "1" &&
                            view.language === "ml"
                          ? "ലഗ്നം"
                          : view?.rasiDetails?.lagnam === "1" &&
                            view.language === "hi"
                          ? "लग्न"
                          : view?.rasiDetails?.lagnam === "1" &&
                            view.language === "ka"
                          ? "ಲಗ್ನ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.mars === "1" &&
                        view.language === "ta"
                          ? "செவ்வாய்"
                          : view?.rasiDetails?.mars === "1" &&
                            view.language === "en"
                          ? "Mars"
                          : view?.rasiDetails?.mars === "1" &&
                            view.language === "ml"
                          ? "ചെവി"
                          : view?.rasiDetails?.mars === "1" &&
                            view.language === "hi"
                          ? "मंगल"
                          : view?.rasiDetails?.mars === "1" &&
                            view.language === "ka"
                          ? "ಕುಜ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.mercury === "1" &&
                        view.language === "ta"
                          ? "புதன்"
                          : view?.rasiDetails?.mercury === "1" &&
                            view.language === "en"
                          ? "Mercury"
                          : view?.rasiDetails?.mercury === "1" &&
                            view.language === "ml"
                          ? "ബുധൻ"
                          : view?.rasiDetails?.mercury === "1" &&
                            view.language === "hi"
                          ? "बुध"
                          : view?.rasiDetails?.mercury === "1" &&
                            view.language === "ka"
                          ? "ಬುಧ"
                          : null}{" "}
                      </span>{" "}
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.moon === "1" &&
                        view.language === "ta"
                          ? "சந்திரன்"
                          : view?.rasiDetails?.moon === "1" &&
                            view.language === "en"
                          ? "Moon"
                          : view?.rasiDetails?.moon === "1" &&
                            view.language === "ml"
                          ? "ചന്ദ്രൻ"
                          : view?.rasiDetails?.moon === "1" &&
                            view.language === "hi"
                          ? "चंद्र"
                          : view?.rasiDetails?.moon === "1" &&
                            view.language === "ka"
                          ? "ಚಂದ್ರ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.raghu === "1" &&
                        view.language === "ta"
                          ? "இராகு"
                          : view?.rasiDetails?.raghu === "1" &&
                            view.language === "en"
                          ? "Raghu"
                          : view?.rasiDetails?.raghu === "1" &&
                            view.language === "ml"
                          ? "രാഹു"
                          : view?.rasiDetails?.raghu === "1" &&
                            view.language === "hi"
                          ? "राहु"
                          : view?.rasiDetails?.raghu === "1" &&
                            view.language === "ka"
                          ? "ರಾಹು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.saturn === "1" &&
                        view.language === "ta"
                          ? "சனி"
                          : view?.rasiDetails?.saturn === "1" &&
                            view.language === "en"
                          ? "Saturn"
                          : view?.rasiDetails?.saturn === "1" &&
                            view.language === "ml"
                          ? "ശനി"
                          : view?.rasiDetails?.saturn === "1" &&
                            view.language === "hi"
                          ? "शनि"
                          : view?.rasiDetails?.saturn === "1" &&
                            view.language === "ka"
                          ? "ಶನಿ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.sun === "1" &&
                        view.language === "ta"
                          ? "சூரியன்"
                          : view?.rasiDetails?.sun === "1" &&
                            view.language === "en"
                          ? "Sun"
                          : view?.rasiDetails?.sun === "1" &&
                            view.language === "ml"
                          ? "സൂര്യൻ"
                          : view?.rasiDetails?.sun === "1" &&
                            view.language === "hi"
                          ? "सूर्य"
                          : view?.rasiDetails?.sun === "1" &&
                            view.language === "ka"
                          ? "ಸೂರ್ಯ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.venus === "1" &&
                        view.language === "ta"
                          ? "சுக்கிரன்"
                          : view?.rasiDetails?.venus === "1" &&
                            view.language === "en"
                          ? "Venus"
                          : view?.rasiDetails?.venus === "1" &&
                            view.language === "ml"
                          ? "ശുക്രൻ"
                          : view?.rasiDetails?.venus === "1" &&
                            view.language === "hi"
                          ? "शुक्र"
                          : view?.rasiDetails?.venus === "1" &&
                            view.language === "ka"
                          ? "ಶುಕ್ರ"
                          : null}
                      </span>
                    </div>
                    <div className="grid-item  item1">
                      {" "}
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.jupiter === "2" &&
                        view.language === "ta"
                          ? "குரு"
                          : view?.rasiDetails?.jupiter === "2" &&
                            view.language === "en"
                          ? "Jupiter"
                          : view?.rasiDetails?.jupiter === "2" &&
                            view.language === "ml"
                          ? "ഗുരു"
                          : view?.rasiDetails?.jupiter === "2" &&
                            view.language === "hi"
                          ? "गुरु"
                          : view?.rasiDetails?.jupiter === "2" &&
                            view.language === "ka"
                          ? "ಗುರು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.kethu === "2" &&
                        view.language === "ta"
                          ? "கேது"
                          : view?.rasiDetails?.kethu === "2" &&
                            view.language === "en"
                          ? "Kethu"
                          : view?.rasiDetails?.kethu === "2" &&
                            view.language === "ml"
                          ? "കേതു"
                          : view?.rasiDetails?.kethu === "2" &&
                            view.language === "hi"
                          ? "केतु"
                          : view?.rasiDetails?.kethu === "2" &&
                            view.language === "ka"
                          ? "ಕೇತು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.lagnam === "2" &&
                        view.language === "ta"
                          ? "லக்னம்"
                          : view?.rasiDetails?.lagnam === "2" &&
                            view.language === "en"
                          ? "Lagnam"
                          : view?.rasiDetails?.lagnam === "2" &&
                            view.language === "ml"
                          ? "ലഗ്നം"
                          : view?.rasiDetails?.lagnam === "2" &&
                            view.language === "hi"
                          ? "लग्न"
                          : view?.rasiDetails?.lagnam === "2" &&
                            view.language === "ka"
                          ? "ಲಗ್ನ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.mars === "2" &&
                        view.language === "ta"
                          ? "செவ்வாய்"
                          : view?.rasiDetails?.mars === "2" &&
                            view.language === "en"
                          ? "Mars"
                          : view?.rasiDetails?.mars === "2" &&
                            view.language === "ml"
                          ? "ചെവി"
                          : view?.rasiDetails?.mars === "2" &&
                            view.language === "hi"
                          ? "मंगल"
                          : view?.rasiDetails?.mars === "2" &&
                            view.language === "ka"
                          ? "ಕುಜ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.mercury === "2" &&
                        view.language === "ta"
                          ? "புதன்"
                          : view?.rasiDetails?.mercury === "2" &&
                            view.language === "en"
                          ? "Mercury"
                          : view?.rasiDetails?.mercury === "2" &&
                            view.language === "ml"
                          ? "ബുധൻ"
                          : view?.rasiDetails?.mercury === "2" &&
                            view.language === "hi"
                          ? "बुध"
                          : view?.rasiDetails?.mercury === "2" &&
                            view.language === "ka"
                          ? "ಬುಧ"
                          : null}{" "}
                      </span>{" "}
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.moon === "2" &&
                        view.language === "ta"
                          ? "சந்திரன்"
                          : view?.rasiDetails?.moon === "2" &&
                            view.language === "en"
                          ? "Moon"
                          : view?.rasiDetails?.moon === "2" &&
                            view.language === "ml"
                          ? "ചന്ദ്രൻ"
                          : view?.rasiDetails?.moon === "2" &&
                            view.language === "hi"
                          ? "चंद्र"
                          : view?.rasiDetails?.moon === "2" &&
                            view.language === "ka"
                          ? "ಚಂದ್ರ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.raghu === "2" &&
                        view.language === "ta"
                          ? "இராகு"
                          : view?.rasiDetails?.raghu === "2" &&
                            view.language === "en"
                          ? "Raghu"
                          : view?.rasiDetails?.raghu === "2" &&
                            view.language === "ml"
                          ? "രാഹു"
                          : view?.rasiDetails?.raghu === "2" &&
                            view.language === "hi"
                          ? "राहु"
                          : view?.rasiDetails?.raghu === "2" &&
                            view.language === "ka"
                          ? "ರಾಹು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.saturn === "2" &&
                        view.language === "ta"
                          ? "சனி"
                          : view?.rasiDetails?.saturn === "2" &&
                            view.language === "en"
                          ? "Saturn"
                          : view?.rasiDetails?.saturn === "2" &&
                            view.language === "ml"
                          ? "ശനി"
                          : view?.rasiDetails?.saturn === "2" &&
                            view.language === "hi"
                          ? "शनि"
                          : view?.rasiDetails?.saturn === "2" &&
                            view.language === "ka"
                          ? "ಶನಿ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.sun === "2" &&
                        view.language === "ta"
                          ? "சூரியன்"
                          : view?.rasiDetails?.sun === "2" &&
                            view.language === "en"
                          ? "Sun"
                          : view?.rasiDetails?.sun === "2" &&
                            view.language === "ml"
                          ? "സൂര്യൻ"
                          : view?.rasiDetails?.sun === "2" &&
                            view.language === "hi"
                          ? "सूर्य"
                          : view?.rasiDetails?.sun === "2" &&
                            view.language === "ka"
                          ? "ಸೂರ್ಯ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.venus === "2" &&
                        view.language === "ta"
                          ? "சுக்கிரன்"
                          : view?.rasiDetails?.venus === "2" &&
                            view.language === "en"
                          ? "Venus"
                          : view?.rasiDetails?.venus === "2" &&
                            view.language === "ml"
                          ? "ശുക്രൻ"
                          : view?.rasiDetails?.venus === "2" &&
                            view.language === "hi"
                          ? "शुक्र"
                          : view?.rasiDetails?.venus === "2" &&
                            view.language === "ka"
                          ? "ಶುಕ್ರ"
                          : null}
                      </span>
                    </div>
                    <div className="grid-item  item1">
                      {" "}
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.jupiter === "3" &&
                        view.language === "ta"
                          ? "குரு"
                          : view?.rasiDetails?.jupiter === "3" &&
                            view.language === "en"
                          ? "Jupiter"
                          : view?.rasiDetails?.jupiter === "3" &&
                            view.language === "ml"
                          ? "ഗുരു"
                          : view?.rasiDetails?.jupiter === "3" &&
                            view.language === "hi"
                          ? "गुरु"
                          : view?.rasiDetails?.jupiter === "3" &&
                            view.language === "ka"
                          ? "ಗುರು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.kethu === "3" &&
                        view.language === "ta"
                          ? "கேது"
                          : view?.rasiDetails?.kethu === "3" &&
                            view.language === "en"
                          ? "Kethu"
                          : view?.rasiDetails?.kethu === "3" &&
                            view.language === "ml"
                          ? "കേതു"
                          : view?.rasiDetails?.kethu === "3" &&
                            view.language === "hi"
                          ? "केतु"
                          : view?.rasiDetails?.kethu === "3" &&
                            view.language === "ka"
                          ? "ಕೇತು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.lagnam === "3" &&
                        view.language === "ta"
                          ? "லக்னம்"
                          : view?.rasiDetails?.lagnam === "3" &&
                            view.language === "en"
                          ? "Lagnam"
                          : view?.rasiDetails?.lagnam === "3" &&
                            view.language === "ml"
                          ? "ലഗ്നം"
                          : view?.rasiDetails?.lagnam === "3" &&
                            view.language === "hi"
                          ? "लग्न"
                          : view?.rasiDetails?.lagnam === "3" &&
                            view.language === "ka"
                          ? "ಲಗ್ನ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.mars === "3" &&
                        view.language === "ta"
                          ? "செவ்வாய்"
                          : view?.rasiDetails?.mars === "3" &&
                            view.language === "en"
                          ? "Mars"
                          : view?.rasiDetails?.mars === "3" &&
                            view.language === "ml"
                          ? "ചെവി"
                          : view?.rasiDetails?.mars === "3" &&
                            view.language === "hi"
                          ? "मंगल"
                          : view?.rasiDetails?.mars === "3" &&
                            view.language === "ka"
                          ? "ಕುಜ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.mercury === "3" &&
                        view.language === "ta"
                          ? "புதன்"
                          : view?.rasiDetails?.mercury === "3" &&
                            view.language === "en"
                          ? "Mercury"
                          : view?.rasiDetails?.mercury === "3" &&
                            view.language === "ml"
                          ? "ബുധൻ"
                          : view?.rasiDetails?.mercury === "3" &&
                            view.language === "hi"
                          ? "बुध"
                          : view?.rasiDetails?.mercury === "3" &&
                            view.language === "ka"
                          ? "ಬುಧ"
                          : null}{" "}
                      </span>{" "}
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.moon === "3" &&
                        view.language === "ta"
                          ? "சந்திரன்"
                          : view?.rasiDetails?.moon === "3" &&
                            view.language === "en"
                          ? "Moon"
                          : view?.rasiDetails?.moon === "3" &&
                            view.language === "ml"
                          ? "ചന്ദ്രൻ"
                          : view?.rasiDetails?.moon === "3" &&
                            view.language === "hi"
                          ? "चंद्र"
                          : view?.rasiDetails?.moon === "3" &&
                            view.language === "ka"
                          ? "ಚಂದ್ರ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.raghu === "3" &&
                        view.language === "ta"
                          ? "இராகு"
                          : view?.rasiDetails?.raghu === "3" &&
                            view.language === "en"
                          ? "Raghu"
                          : view?.rasiDetails?.raghu === "3" &&
                            view.language === "ml"
                          ? "രാഹു"
                          : view?.rasiDetails?.raghu === "3" &&
                            view.language === "hi"
                          ? "राहु"
                          : view?.rasiDetails?.raghu === "3" &&
                            view.language === "ka"
                          ? "ರಾಹು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.saturn === "3" &&
                        view.language === "ta"
                          ? "சனி"
                          : view?.rasiDetails?.saturn === "3" &&
                            view.language === "en"
                          ? "Saturn"
                          : view?.rasiDetails?.saturn === "3" &&
                            view.language === "ml"
                          ? "ശനി"
                          : view?.rasiDetails?.saturn === "3" &&
                            view.language === "hi"
                          ? "शनि"
                          : view?.rasiDetails?.saturn === "3" &&
                            view.language === "ka"
                          ? "ಶನಿ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.sun === "3" &&
                        view.language === "ta"
                          ? "சூரியன்"
                          : view?.rasiDetails?.sun === "3" &&
                            view.language === "en"
                          ? "Sun"
                          : view?.rasiDetails?.sun === "3" &&
                            view.language === "ml"
                          ? "സൂര്യൻ"
                          : view?.rasiDetails?.sun === "3" &&
                            view.language === "hi"
                          ? "सूर्य"
                          : view?.rasiDetails?.sun === "3" &&
                            view.language === "ka"
                          ? "ಸೂರ್ಯ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.venus === "3" &&
                        view.language === "ta"
                          ? "சுக்கிரன்"
                          : view?.rasiDetails?.venus === "3" &&
                            view.language === "en"
                          ? "Venus"
                          : view?.rasiDetails?.venus === "3" &&
                            view.language === "ml"
                          ? "ശുക്രൻ"
                          : view?.rasiDetails?.venus === "3" &&
                            view.language === "hi"
                          ? "शुक्र"
                          : view?.rasiDetails?.venus === "3" &&
                            view.language === "ka"
                          ? "ಶುಕ್ರ"
                          : null}
                      </span>
                    </div>
                    <div className="grid-item  item1">
                      {" "}
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.jupiter === "11" &&
                        view.language === "ta"
                          ? "குரு"
                          : view?.rasiDetails?.jupiter === "11" &&
                            view.language === "en"
                          ? "Jupiter"
                          : view?.rasiDetails?.jupiter === "11" &&
                            view.language === "ml"
                          ? "ഗുരു"
                          : view?.rasiDetails?.jupiter === "11" &&
                            view.language === "hi"
                          ? "गुरु"
                          : view?.rasiDetails?.jupiter === "11" &&
                            view.language === "ka"
                          ? "ಗುರು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.kethu === "11" &&
                        view.language === "ta"
                          ? "கேது"
                          : view?.rasiDetails?.kethu === "11" &&
                            view.language === "en"
                          ? "Kethu"
                          : view?.rasiDetails?.kethu === "11" &&
                            view.language === "ml"
                          ? "കേതു"
                          : view?.rasiDetails?.kethu === "11" &&
                            view.language === "hi"
                          ? "केतु"
                          : view?.rasiDetails?.kethu === "11" &&
                            view.language === "ka"
                          ? "ಕೇತು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.lagnam === "11" &&
                        view.language === "ta"
                          ? "லக்னம்"
                          : view?.rasiDetails?.lagnam === "11" &&
                            view.language === "en"
                          ? "Lagnam"
                          : view?.rasiDetails?.lagnam === "11" &&
                            view.language === "ml"
                          ? "ലഗ്നം"
                          : view?.rasiDetails?.lagnam === "11" &&
                            view.language === "hi"
                          ? "लग्न"
                          : view?.rasiDetails?.lagnam === "11" &&
                            view.language === "ka"
                          ? "ಲಗ್ನ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.mars === "11" &&
                        view.language === "ta"
                          ? "செவ்வாய்"
                          : view?.rasiDetails?.mars === "11" &&
                            view.language === "en"
                          ? "Mars"
                          : view?.rasiDetails?.mars === "11" &&
                            view.language === "ml"
                          ? "ചെവി"
                          : view?.rasiDetails?.mars === "11" &&
                            view.language === "hi"
                          ? "मंगल"
                          : view?.rasiDetails?.mars === "11" &&
                            view.language === "ka"
                          ? "ಕುಜ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.mercury === "11" &&
                        view.language === "ta"
                          ? "புதன்"
                          : view?.rasiDetails?.mercury === "11" &&
                            view.language === "en"
                          ? "Mercury"
                          : view?.rasiDetails?.mercury === "11" &&
                            view.language === "ml"
                          ? "ബുധൻ"
                          : view?.rasiDetails?.mercury === "11" &&
                            view.language === "hi"
                          ? "बुध"
                          : view?.rasiDetails?.mercury === "11" &&
                            view.language === "ka"
                          ? "ಬುಧ"
                          : null}{" "}
                      </span>{" "}
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.moon === "11" &&
                        view.language === "ta"
                          ? "சந்திரன்"
                          : view?.rasiDetails?.moon === "11" &&
                            view.language === "en"
                          ? "Moon"
                          : view?.rasiDetails?.moon === "11" &&
                            view.language === "ml"
                          ? "ചന്ദ്രൻ"
                          : view?.rasiDetails?.moon === "11" &&
                            view.language === "hi"
                          ? "चंद्र"
                          : view?.rasiDetails?.moon === "11" &&
                            view.language === "ka"
                          ? "ಚಂದ್ರ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.raghu === "11" &&
                        view.language === "ta"
                          ? "இராகு"
                          : view?.rasiDetails?.raghu === "11" &&
                            view.language === "en"
                          ? "Raghu"
                          : view?.rasiDetails?.raghu === "11" &&
                            view.language === "ml"
                          ? "രാഹു"
                          : view?.rasiDetails?.raghu === "11" &&
                            view.language === "hi"
                          ? "राहु"
                          : view?.rasiDetails?.raghu === "11" &&
                            view.language === "ka"
                          ? "ರಾಹು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.saturn === "11" &&
                        view.language === "ta"
                          ? "சனி"
                          : view?.rasiDetails?.saturn === "11" &&
                            view.language === "en"
                          ? "Saturn"
                          : view?.rasiDetails?.saturn === "11" &&
                            view.language === "ml"
                          ? "ശനി"
                          : view?.rasiDetails?.saturn === "11" &&
                            view.language === "hi"
                          ? "शनि"
                          : view?.rasiDetails?.saturn === "11" &&
                            view.language === "ka"
                          ? "ಶನಿ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.sun === "11" &&
                        view.language === "ta"
                          ? "சூரியன்"
                          : view?.rasiDetails?.sun === "11" &&
                            view.language === "en"
                          ? "Sun"
                          : view?.rasiDetails?.sun === "11" &&
                            view.language === "ml"
                          ? "സൂര്യൻ"
                          : view?.rasiDetails?.sun === "11" &&
                            view.language === "hi"
                          ? "सूर्य"
                          : view?.rasiDetails?.sun === "11" &&
                            view.language === "ka"
                          ? "ಸೂರ್ಯ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.venus === "11" &&
                        view.language === "ta"
                          ? "சுக்கிரன்"
                          : view?.rasiDetails?.venus === "11" &&
                            view.language === "en"
                          ? "Venus"
                          : view?.rasiDetails?.venus === "11" &&
                            view.language === "ml"
                          ? "ശുക്രൻ"
                          : view?.rasiDetails?.venus === "11" &&
                            view.language === "hi"
                          ? "शुक्र"
                          : view?.rasiDetails?.venus === "11" &&
                            view.language === "ka"
                          ? "ಶುಕ್ರ"
                          : null}
                      </span>
                    </div>
                    <div className="grid-item item6">Rasi</div>
                    <div className="grid-item  item1">
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.jupiter === "4" &&
                        view.language === "ta"
                          ? "குரு"
                          : view?.rasiDetails?.jupiter === "4" &&
                            view.language === "en"
                          ? "Jupiter"
                          : view?.rasiDetails?.jupiter === "4" &&
                            view.language === "ml"
                          ? "ഗുരു"
                          : view?.rasiDetails?.jupiter === "4" &&
                            view.language === "hi"
                          ? "गुरु"
                          : view?.rasiDetails?.jupiter === "4" &&
                            view.language === "ka"
                          ? "ಗುರು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.kethu === "4" &&
                        view.language === "ta"
                          ? "கேது"
                          : view?.rasiDetails?.kethu === "4" &&
                            view.language === "en"
                          ? "Kethu"
                          : view?.rasiDetails?.kethu === "4" &&
                            view.language === "ml"
                          ? "കേതു"
                          : view?.rasiDetails?.kethu === "4" &&
                            view.language === "hi"
                          ? "केतु"
                          : view?.rasiDetails?.kethu === "4" &&
                            view.language === "ka"
                          ? "ಕೇತು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.lagnam === "4" &&
                        view.language === "ta"
                          ? "லக்னம்"
                          : view?.rasiDetails?.lagnam === "4" &&
                            view.language === "en"
                          ? "Lagnam"
                          : view?.rasiDetails?.lagnam === "4" &&
                            view.language === "ml"
                          ? "ലഗ്നം"
                          : view?.rasiDetails?.lagnam === "4" &&
                            view.language === "hi"
                          ? "लग्न"
                          : view?.rasiDetails?.lagnam === "4" &&
                            view.language === "ka"
                          ? "ಲಗ್ನ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.mars === "4" &&
                        view.language === "ta"
                          ? "செவ்வாய்"
                          : view?.rasiDetails?.mars === "4" &&
                            view.language === "en"
                          ? "Mars"
                          : view?.rasiDetails?.mars === "4" &&
                            view.language === "ml"
                          ? "ചെവി"
                          : view?.rasiDetails?.mars === "4" &&
                            view.language === "hi"
                          ? "मंगल"
                          : view?.rasiDetails?.mars === "4" &&
                            view.language === "ka"
                          ? "ಕುಜ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.mercury === "4" &&
                        view.language === "ta"
                          ? "புதன்"
                          : view?.rasiDetails?.mercury === "4" &&
                            view.language === "en"
                          ? "Mercury"
                          : view?.rasiDetails?.mercury === "4" &&
                            view.language === "ml"
                          ? "ബുധൻ"
                          : view?.rasiDetails?.mercury === "4" &&
                            view.language === "hi"
                          ? "बुध"
                          : view?.rasiDetails?.mercury === "4" &&
                            view.language === "ka"
                          ? "ಬುಧ"
                          : null}{" "}
                      </span>{" "}
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.moon === "4" &&
                        view.language === "ta"
                          ? "சந்திரன்"
                          : view?.rasiDetails?.moon === "4" &&
                            view.language === "en"
                          ? "Moon"
                          : view?.rasiDetails?.moon === "4" &&
                            view.language === "ml"
                          ? "ചന്ദ്രൻ"
                          : view?.rasiDetails?.moon === "4" &&
                            view.language === "hi"
                          ? "चंद्र"
                          : view?.rasiDetails?.moon === "4" &&
                            view.language === "ka"
                          ? "ಚಂದ್ರ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.raghu === "4" &&
                        view.language === "ta"
                          ? "இராகு"
                          : view?.rasiDetails?.raghu === "4" &&
                            view.language === "en"
                          ? "Raghu"
                          : view?.rasiDetails?.raghu === "4" &&
                            view.language === "ml"
                          ? "രാഹു"
                          : view?.rasiDetails?.raghu === "4" &&
                            view.language === "hi"
                          ? "राहु"
                          : view?.rasiDetails?.raghu === "4" &&
                            view.language === "ka"
                          ? "ರಾಹು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.saturn === "4" &&
                        view.language === "ta"
                          ? "சனி"
                          : view?.rasiDetails?.saturn === "4" &&
                            view.language === "en"
                          ? "Saturn"
                          : view?.rasiDetails?.saturn === "4" &&
                            view.language === "ml"
                          ? "ശനി"
                          : view?.rasiDetails?.saturn === "4" &&
                            view.language === "hi"
                          ? "शनि"
                          : view?.rasiDetails?.saturn === "4" &&
                            view.language === "ka"
                          ? "ಶನಿ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.sun === "4" &&
                        view.language === "ta"
                          ? "சூரியன்"
                          : view?.rasiDetails?.sun === "4" &&
                            view.language === "en"
                          ? "Sun"
                          : view?.rasiDetails?.sun === "4" &&
                            view.language === "ml"
                          ? "സൂര്യൻ"
                          : view?.rasiDetails?.sun === "4" &&
                            view.language === "hi"
                          ? "सूर्य"
                          : view?.rasiDetails?.sun === "4" &&
                            view.language === "ka"
                          ? "ಸೂರ್ಯ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.venus === "4" &&
                        view.language === "ta"
                          ? "சுக்கிரன்"
                          : view?.rasiDetails?.venus === "4" &&
                            view.language === "en"
                          ? "Venus"
                          : view?.rasiDetails?.venus === "4" &&
                            view.language === "ml"
                          ? "ശുക്രൻ"
                          : view?.rasiDetails?.venus === "4" &&
                            view.language === "hi"
                          ? "शुक्र"
                          : view?.rasiDetails?.venus === "4" &&
                            view.language === "ka"
                          ? "ಶುಕ್ರ"
                          : null}
                      </span>
                    </div>
                    <div className="grid-item  item1">
                      {" "}
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.jupiter === "10" &&
                        view.language === "ta"
                          ? "குரு"
                          : view?.rasiDetails?.jupiter === "10" &&
                            view.language === "en"
                          ? "Jupiter"
                          : view?.rasiDetails?.jupiter === "10" &&
                            view.language === "ml"
                          ? "ഗുരു"
                          : view?.rasiDetails?.jupiter === "10" &&
                            view.language === "hi"
                          ? "गुरु"
                          : view?.rasiDetails?.jupiter === "10" &&
                            view.language === "ka"
                          ? "ಗುರು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.kethu === "10" &&
                        view.language === "ta"
                          ? "கேது"
                          : view?.rasiDetails?.kethu === "10" &&
                            view.language === "en"
                          ? "Kethu"
                          : view?.rasiDetails?.kethu === "10" &&
                            view.language === "ml"
                          ? "കേതു"
                          : view?.rasiDetails?.kethu === "10" &&
                            view.language === "hi"
                          ? "केतु"
                          : view?.rasiDetails?.kethu === "10" &&
                            view.language === "ka"
                          ? "ಕೇತು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.lagnam === "10" &&
                        view.language === "ta"
                          ? "லக்னம்"
                          : view?.rasiDetails?.lagnam === "10" &&
                            view.language === "en"
                          ? "Lagnam"
                          : view?.rasiDetails?.lagnam === "10" &&
                            view.language === "ml"
                          ? "ലഗ്നം"
                          : view?.rasiDetails?.lagnam === "10" &&
                            view.language === "hi"
                          ? "लग्न"
                          : view?.rasiDetails?.lagnam === "10" &&
                            view.language === "ka"
                          ? "ಲಗ್ನ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.mars === "10" &&
                        view.language === "ta"
                          ? "செவ்வாய்"
                          : view?.rasiDetails?.mars === "10" &&
                            view.language === "en"
                          ? "Mars"
                          : view?.rasiDetails?.mars === "10" &&
                            view.language === "ml"
                          ? "ചെവി"
                          : view?.rasiDetails?.mars === "10" &&
                            view.language === "hi"
                          ? "मंगल"
                          : view?.rasiDetails?.mars === "10" &&
                            view.language === "ka"
                          ? "ಕುಜ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.mercury === "10" &&
                        view.language === "ta"
                          ? "புதன்"
                          : view?.rasiDetails?.mercury === "10" &&
                            view.language === "en"
                          ? "Mercury"
                          : view?.rasiDetails?.mercury === "10" &&
                            view.language === "ml"
                          ? "ബുധൻ"
                          : view?.rasiDetails?.mercury === "10" &&
                            view.language === "hi"
                          ? "बुध"
                          : view?.rasiDetails?.mercury === "10" &&
                            view.language === "ka"
                          ? "ಬುಧ"
                          : null}{" "}
                      </span>{" "}
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.moon === "10" &&
                        view.language === "ta"
                          ? "சந்திரன்"
                          : view?.rasiDetails?.moon === "10" &&
                            view.language === "en"
                          ? "Moon"
                          : view?.rasiDetails?.moon === "10" &&
                            view.language === "ml"
                          ? "ചന്ദ്രൻ"
                          : view?.rasiDetails?.moon === "10" &&
                            view.language === "hi"
                          ? "चंद्र"
                          : view?.rasiDetails?.moon === "10" &&
                            view.language === "ka"
                          ? "ಚಂದ್ರ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.raghu === "10" &&
                        view.language === "ta"
                          ? "இராகு"
                          : view?.rasiDetails?.raghu === "10" &&
                            view.language === "en"
                          ? "Raghu"
                          : view?.rasiDetails?.raghu === "10" &&
                            view.language === "ml"
                          ? "രാഹു"
                          : view?.rasiDetails?.raghu === "10" &&
                            view.language === "hi"
                          ? "राहु"
                          : view?.rasiDetails?.raghu === "10" &&
                            view.language === "ka"
                          ? "ರಾಹು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.saturn === "10" &&
                        view.language === "ta"
                          ? "சனி"
                          : view?.rasiDetails?.saturn === "10" &&
                            view.language === "en"
                          ? "Saturn"
                          : view?.rasiDetails?.saturn === "10" &&
                            view.language === "ml"
                          ? "ശനി"
                          : view?.rasiDetails?.saturn === "10" &&
                            view.language === "hi"
                          ? "शनि"
                          : view?.rasiDetails?.saturn === "10" &&
                            view.language === "ka"
                          ? "ಶನಿ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.sun === "10" &&
                        view.language === "ta"
                          ? "சூரியன்"
                          : view?.rasiDetails?.sun === "10" &&
                            view.language === "en"
                          ? "Sun"
                          : view?.rasiDetails?.sun === "10" &&
                            view.language === "ml"
                          ? "സൂര്യൻ"
                          : view?.rasiDetails?.sun === "10" &&
                            view.language === "hi"
                          ? "सूर्य"
                          : view?.rasiDetails?.sun === "10" &&
                            view.language === "ka"
                          ? "ಸೂರ್ಯ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.venus === "10" &&
                        view.language === "ta"
                          ? "சுக்கிரன்"
                          : view?.rasiDetails?.venus === "10" &&
                            view.language === "en"
                          ? "Venus"
                          : view?.rasiDetails?.venus === "10" &&
                            view.language === "ml"
                          ? "ശുക്രൻ"
                          : view?.rasiDetails?.venus === "10" &&
                            view.language === "hi"
                          ? "शुक्र"
                          : view?.rasiDetails?.venus === "10" &&
                            view.language === "ka"
                          ? "ಶುಕ್ರ"
                          : null}
                      </span>
                    </div>
                    <div className="grid-item item9  item1">
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.jupiter === "5" &&
                        view.language === "ta"
                          ? "குரு"
                          : view?.rasiDetails?.jupiter === "5" &&
                            view.language === "en"
                          ? "Jupiter"
                          : view?.rasiDetails?.jupiter === "5" &&
                            view.language === "ml"
                          ? "ഗുരു"
                          : view?.rasiDetails?.jupiter === "5" &&
                            view.language === "hi"
                          ? "गुरु"
                          : view?.rasiDetails?.jupiter === "5" &&
                            view.language === "ka"
                          ? "ಗುರು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.kethu === "5" &&
                        view.language === "ta"
                          ? "கேது"
                          : view?.rasiDetails?.kethu === "5" &&
                            view.language === "en"
                          ? "Kethu"
                          : view?.rasiDetails?.kethu === "5" &&
                            view.language === "ml"
                          ? "കേതു"
                          : view?.rasiDetails?.kethu === "5" &&
                            view.language === "hi"
                          ? "केतु"
                          : view?.rasiDetails?.kethu === "5" &&
                            view.language === "ka"
                          ? "ಕೇತು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.lagnam === "5" &&
                        view.language === "ta"
                          ? "லக்னம்"
                          : view?.rasiDetails?.lagnam === "5" &&
                            view.language === "en"
                          ? "Lagnam"
                          : view?.rasiDetails?.lagnam === "5" &&
                            view.language === "ml"
                          ? "ലഗ്നം"
                          : view?.rasiDetails?.lagnam === "5" &&
                            view.language === "hi"
                          ? "लग्न"
                          : view?.rasiDetails?.lagnam === "5" &&
                            view.language === "ka"
                          ? "ಲಗ್ನ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.mars === "5" &&
                        view.language === "ta"
                          ? "செவ்வாய்"
                          : view?.rasiDetails?.mars === "5" &&
                            view.language === "en"
                          ? "Mars"
                          : view?.rasiDetails?.mars === "5" &&
                            view.language === "ml"
                          ? "ചെവി"
                          : view?.rasiDetails?.mars === "5" &&
                            view.language === "hi"
                          ? "मंगल"
                          : view?.rasiDetails?.mars === "5" &&
                            view.language === "ka"
                          ? "ಕುಜ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.mercury === "5" &&
                        view.language === "ta"
                          ? "புதன்"
                          : view?.rasiDetails?.mercury === "5" &&
                            view.language === "en"
                          ? "Mercury"
                          : view?.rasiDetails?.mercury === "5" &&
                            view.language === "ml"
                          ? "ബുധൻ"
                          : view?.rasiDetails?.mercury === "5" &&
                            view.language === "hi"
                          ? "बुध"
                          : view?.rasiDetails?.mercury === "5" &&
                            view.language === "ka"
                          ? "ಬುಧ"
                          : null}{" "}
                      </span>{" "}
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.moon === "5" &&
                        view.language === "ta"
                          ? "சந்திரன்"
                          : view?.rasiDetails?.moon === "5" &&
                            view.language === "en"
                          ? "Moon"
                          : view?.rasiDetails?.moon === "5" &&
                            view.language === "ml"
                          ? "ചന്ദ്രൻ"
                          : view?.rasiDetails?.moon === "5" &&
                            view.language === "hi"
                          ? "चंद्र"
                          : view?.rasiDetails?.moon === "5" &&
                            view.language === "ka"
                          ? "ಚಂದ್ರ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.raghu === "5" &&
                        view.language === "ta"
                          ? "இராகு"
                          : view?.rasiDetails?.raghu === "5" &&
                            view.language === "en"
                          ? "Raghu"
                          : view?.rasiDetails?.raghu === "5" &&
                            view.language === "ml"
                          ? "രാഹു"
                          : view?.rasiDetails?.raghu === "5" &&
                            view.language === "hi"
                          ? "राहु"
                          : view?.rasiDetails?.raghu === "5" &&
                            view.language === "ka"
                          ? "ರಾಹು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.saturn === "5" &&
                        view.language === "ta"
                          ? "சனி"
                          : view?.rasiDetails?.saturn === "5" &&
                            view.language === "en"
                          ? "Saturn"
                          : view?.rasiDetails?.saturn === "5" &&
                            view.language === "ml"
                          ? "ശനി"
                          : view?.rasiDetails?.saturn === "5" &&
                            view.language === "hi"
                          ? "शनि"
                          : view?.rasiDetails?.saturn === "5" &&
                            view.language === "ka"
                          ? "ಶನಿ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.sun === "5" &&
                        view.language === "ta"
                          ? "சூரியன்"
                          : view?.rasiDetails?.sun === "5" &&
                            view.language === "en"
                          ? "Sun"
                          : view?.rasiDetails?.sun === "5" &&
                            view.language === "ml"
                          ? "സൂര്യൻ"
                          : view?.rasiDetails?.sun === "5" &&
                            view.language === "hi"
                          ? "सूर्य"
                          : view?.rasiDetails?.sun === "5" &&
                            view.language === "ka"
                          ? "ಸೂರ್ಯ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.venus === "5" &&
                        view.language === "ta"
                          ? "சுக்கிரன்"
                          : view?.rasiDetails?.venus === "5" &&
                            view.language === "en"
                          ? "Venus"
                          : view?.rasiDetails?.venus === "5" &&
                            view.language === "ml"
                          ? "ശുക്രൻ"
                          : view?.rasiDetails?.venus === "5" &&
                            view.language === "hi"
                          ? "शुक्र"
                          : view?.rasiDetails?.venus === "5" &&
                            view.language === "ka"
                          ? "ಶುಕ್ರ"
                          : null}
                      </span>
                    </div>
                    <div className="grid-item  item1">
                      {" "}
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.jupiter === "9" &&
                        view.language === "ta"
                          ? "குரு"
                          : view?.rasiDetails?.jupiter === "9" &&
                            view.language === "en"
                          ? "Jupiter"
                          : view?.rasiDetails?.jupiter === "9" &&
                            view.language === "ml"
                          ? "ഗുരു"
                          : view?.rasiDetails?.jupiter === "9" &&
                            view.language === "hi"
                          ? "गुरु"
                          : view?.rasiDetails?.jupiter === "9" &&
                            view.language === "ka"
                          ? "ಗುರು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.kethu === "9" &&
                        view.language === "ta"
                          ? "கேது"
                          : view?.rasiDetails?.kethu === "9" &&
                            view.language === "en"
                          ? "Kethu"
                          : view?.rasiDetails?.kethu === "9" &&
                            view.language === "ml"
                          ? "കേതു"
                          : view?.rasiDetails?.kethu === "9" &&
                            view.language === "hi"
                          ? "केतु"
                          : view?.rasiDetails?.kethu === "9" &&
                            view.language === "ka"
                          ? "ಕೇತು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.lagnam === "9" &&
                        view.language === "ta"
                          ? "லக்னம்"
                          : view?.rasiDetails?.lagnam === "9" &&
                            view.language === "en"
                          ? "Lagnam"
                          : view?.rasiDetails?.lagnam === "9" &&
                            view.language === "ml"
                          ? "ലഗ്നം"
                          : view?.rasiDetails?.lagnam === "9" &&
                            view.language === "hi"
                          ? "लग्न"
                          : view?.rasiDetails?.lagnam === "9" &&
                            view.language === "ka"
                          ? "ಲಗ್ನ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.mars === "9" &&
                        view.language === "ta"
                          ? "செவ்வாய்"
                          : view?.rasiDetails?.mars === "9" &&
                            view.language === "en"
                          ? "Mars"
                          : view?.rasiDetails?.mars === "9" &&
                            view.language === "ml"
                          ? "ചെവി"
                          : view?.rasiDetails?.mars === "9" &&
                            view.language === "hi"
                          ? "मंगल"
                          : view?.rasiDetails?.mars === "9" &&
                            view.language === "ka"
                          ? "ಕುಜ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.mercury === "9" &&
                        view.language === "ta"
                          ? "புதன்"
                          : view?.rasiDetails?.mercury === "9" &&
                            view.language === "en"
                          ? "Mercury"
                          : view?.rasiDetails?.mercury === "9" &&
                            view.language === "ml"
                          ? "ബുധൻ"
                          : view?.rasiDetails?.mercury === "9" &&
                            view.language === "hi"
                          ? "बुध"
                          : view?.rasiDetails?.mercury === "9" &&
                            view.language === "ka"
                          ? "ಬುಧ"
                          : null}{" "}
                      </span>{" "}
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.moon === "9" &&
                        view.language === "ta"
                          ? "சந்திரன்"
                          : view?.rasiDetails?.moon === "9" &&
                            view.language === "en"
                          ? "Moon"
                          : view?.rasiDetails?.moon === "9" &&
                            view.language === "ml"
                          ? "ചന്ദ്രൻ"
                          : view?.rasiDetails?.moon === "9" &&
                            view.language === "hi"
                          ? "चंद्र"
                          : view?.rasiDetails?.moon === "9" &&
                            view.language === "ka"
                          ? "ಚಂದ್ರ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.raghu === "9" &&
                        view.language === "ta"
                          ? "இராகு"
                          : view?.rasiDetails?.raghu === "9" &&
                            view.language === "en"
                          ? "Raghu"
                          : view?.rasiDetails?.raghu === "9" &&
                            view.language === "ml"
                          ? "രാഹു"
                          : view?.rasiDetails?.raghu === "9" &&
                            view.language === "hi"
                          ? "राहु"
                          : view?.rasiDetails?.raghu === "9" &&
                            view.language === "ka"
                          ? "ರಾಹು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.saturn === "9" &&
                        view.language === "ta"
                          ? "சனி"
                          : view?.rasiDetails?.saturn === "9" &&
                            view.language === "en"
                          ? "Saturn"
                          : view?.rasiDetails?.saturn === "9" &&
                            view.language === "ml"
                          ? "ശനി"
                          : view?.rasiDetails?.saturn === "9" &&
                            view.language === "hi"
                          ? "शनि"
                          : view?.rasiDetails?.saturn === "9" &&
                            view.language === "ka"
                          ? "ಶನಿ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.sun === "9" &&
                        view.language === "ta"
                          ? "சூரியன்"
                          : view?.rasiDetails?.sun === "9" &&
                            view.language === "en"
                          ? "Sun"
                          : view?.rasiDetails?.sun === "9" &&
                            view.language === "ml"
                          ? "സൂര്യൻ"
                          : view?.rasiDetails?.sun === "9" &&
                            view.language === "hi"
                          ? "सूर्य"
                          : view?.rasiDetails?.sun === "9" &&
                            view.language === "ka"
                          ? "ಸೂರ್ಯ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.venus === "9" &&
                        view.language === "ta"
                          ? "சுக்கிரன்"
                          : view?.rasiDetails?.venus === "9" &&
                            view.language === "en"
                          ? "Venus"
                          : view?.rasiDetails?.venus === "9" &&
                            view.language === "ml"
                          ? "ശുക്രൻ"
                          : view?.rasiDetails?.venus === "9" &&
                            view.language === "hi"
                          ? "शुक्र"
                          : view?.rasiDetails?.venus === "9" &&
                            view.language === "ka"
                          ? "ಶುಕ್ರ"
                          : null}
                      </span>
                    </div>
                    <div className="grid-item  item1">
                      {" "}
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.jupiter === "8" &&
                        view.language === "ta"
                          ? "குரு"
                          : view?.rasiDetails?.jupiter === "8" &&
                            view.language === "en"
                          ? "Jupiter"
                          : view?.rasiDetails?.jupiter === "8" &&
                            view.language === "ml"
                          ? "ഗുരു"
                          : view?.rasiDetails?.jupiter === "8" &&
                            view.language === "hi"
                          ? "गुरु"
                          : view?.rasiDetails?.jupiter === "8" &&
                            view.language === "ka"
                          ? "ಗುರು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.kethu === "8" &&
                        view.language === "ta"
                          ? "கேது"
                          : view?.rasiDetails?.kethu === "8" &&
                            view.language === "en"
                          ? "Kethu"
                          : view?.rasiDetails?.kethu === "8" &&
                            view.language === "ml"
                          ? "കേതു"
                          : view?.rasiDetails?.kethu === "8" &&
                            view.language === "hi"
                          ? "केतु"
                          : view?.rasiDetails?.kethu === "8" &&
                            view.language === "ka"
                          ? "ಕೇತು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.lagnam === "8" &&
                        view.language === "ta"
                          ? "லக்னம்"
                          : view?.rasiDetails?.lagnam === "8" &&
                            view.language === "en"
                          ? "Lagnam"
                          : view?.rasiDetails?.lagnam === "8" &&
                            view.language === "ml"
                          ? "ലഗ്നം"
                          : view?.rasiDetails?.lagnam === "8" &&
                            view.language === "hi"
                          ? "लग्न"
                          : view?.rasiDetails?.lagnam === "8" &&
                            view.language === "ka"
                          ? "ಲಗ್ನ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.mars === "8" &&
                        view.language === "ta"
                          ? "செவ்வாய்"
                          : view?.rasiDetails?.mars === "8" &&
                            view.language === "en"
                          ? "Mars"
                          : view?.rasiDetails?.mars === "8" &&
                            view.language === "ml"
                          ? "ചെവി"
                          : view?.rasiDetails?.mars === "8" &&
                            view.language === "hi"
                          ? "मंगल"
                          : view?.rasiDetails?.mars === "8" &&
                            view.language === "ka"
                          ? "ಕುಜ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.mercury === "8" &&
                        view.language === "ta"
                          ? "புதன்"
                          : view?.rasiDetails?.mercury === "8" &&
                            view.language === "en"
                          ? "Mercury"
                          : view?.rasiDetails?.mercury === "8" &&
                            view.language === "ml"
                          ? "ബുധൻ"
                          : view?.rasiDetails?.mercury === "8" &&
                            view.language === "hi"
                          ? "बुध"
                          : view?.rasiDetails?.mercury === "8" &&
                            view.language === "ka"
                          ? "ಬುಧ"
                          : null}{" "}
                      </span>{" "}
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.moon === "8" &&
                        view.language === "ta"
                          ? "சந்திரன்"
                          : view?.rasiDetails?.moon === "8" &&
                            view.language === "en"
                          ? "Moon"
                          : view?.rasiDetails?.moon === "8" &&
                            view.language === "ml"
                          ? "ചന്ദ്രൻ"
                          : view?.rasiDetails?.moon === "8" &&
                            view.language === "hi"
                          ? "चंद्र"
                          : view?.rasiDetails?.moon === "8" &&
                            view.language === "ka"
                          ? "ಚಂದ್ರ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.raghu === "8" &&
                        view.language === "ta"
                          ? "இராகு"
                          : view?.rasiDetails?.raghu === "8" &&
                            view.language === "en"
                          ? "Raghu"
                          : view?.rasiDetails?.raghu === "8" &&
                            view.language === "ml"
                          ? "രാഹു"
                          : view?.rasiDetails?.raghu === "8" &&
                            view.language === "hi"
                          ? "राहु"
                          : view?.rasiDetails?.raghu === "8" &&
                            view.language === "ka"
                          ? "ರಾಹು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.saturn === "8" &&
                        view.language === "ta"
                          ? "சனி"
                          : view?.rasiDetails?.saturn === "8" &&
                            view.language === "en"
                          ? "Saturn"
                          : view?.rasiDetails?.saturn === "8" &&
                            view.language === "ml"
                          ? "ശനി"
                          : view?.rasiDetails?.saturn === "8" &&
                            view.language === "hi"
                          ? "शनि"
                          : view?.rasiDetails?.saturn === "8" &&
                            view.language === "ka"
                          ? "ಶನಿ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.sun === "8" &&
                        view.language === "ta"
                          ? "சூரியன்"
                          : view?.rasiDetails?.sun === "8" &&
                            view.language === "en"
                          ? "Sun"
                          : view?.rasiDetails?.sun === "8" &&
                            view.language === "ml"
                          ? "സൂര്യൻ"
                          : view?.rasiDetails?.sun === "8" &&
                            view.language === "hi"
                          ? "सूर्य"
                          : view?.rasiDetails?.sun === "8" &&
                            view.language === "ka"
                          ? "ಸೂರ್ಯ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.venus === "8" &&
                        view.language === "ta"
                          ? "சுக்கிரன்"
                          : view?.rasiDetails?.venus === "8" &&
                            view.language === "en"
                          ? "Venus"
                          : view?.rasiDetails?.venus === "8" &&
                            view.language === "ml"
                          ? "സൂര്യൻ"
                          : view?.rasiDetails?.venus === "8" &&
                            view.language === "hi"
                          ? "सूर्य"
                          : view?.rasiDetails?.venus === "8" &&
                            view.language === "ka"
                          ? "ಸೂರ್ಯ"
                          : null}
                      </span>
                    </div>
                    <div className="grid-item  item1">
                      {" "}
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.jupiter === "7" &&
                        view.language === "ta"
                          ? "குரு"
                          : view?.rasiDetails?.jupiter === "7" &&
                            view.language === "en"
                          ? "Jupiter"
                          : view?.rasiDetails?.jupiter === "7" &&
                            view.language === "ml"
                          ? "ഗുരു"
                          : view?.rasiDetails?.jupiter === "7" &&
                            view.language === "hi"
                          ? "गुरु"
                          : view?.rasiDetails?.jupiter === "7" &&
                            view.language === "ka"
                          ? "ಗುರು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.kethu === "7" &&
                        view.language === "ta"
                          ? "கேது"
                          : view?.rasiDetails?.kethu === "7" &&
                            view.language === "en"
                          ? "Kethu"
                          : view?.rasiDetails?.kethu === "7" &&
                            view.language === "ml"
                          ? "കേതു"
                          : view?.rasiDetails?.kethu === "7" &&
                            view.language === "hi"
                          ? "केतु"
                          : view?.rasiDetails?.kethu === "7" &&
                            view.language === "ka"
                          ? "ಕೇತು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.lagnam === "7" &&
                        view.language === "ta"
                          ? "லக்னம்"
                          : view?.rasiDetails?.lagnam === "7" &&
                            view.language === "en"
                          ? "Lagnam"
                          : view?.rasiDetails?.lagnam === "7" &&
                            view.language === "ml"
                          ? "ലഗ്നം"
                          : view?.rasiDetails?.lagnam === "7" &&
                            view.language === "hi"
                          ? "लग्न"
                          : view?.rasiDetails?.lagnam === "7" &&
                            view.language === "ka"
                          ? "ಲಗ್ನ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.mars === "7" &&
                        view.language === "ta"
                          ? "செவ்வாய்"
                          : view?.rasiDetails?.mars === "7" &&
                            view.language === "en"
                          ? "Mars"
                          : view?.rasiDetails?.mars === "7" &&
                            view.language === "ml"
                          ? "ചെവി"
                          : view?.rasiDetails?.mars === "7" &&
                            view.language === "hi"
                          ? "मंगल"
                          : view?.rasiDetails?.mars === "7" &&
                            view.language === "ka"
                          ? "ಕುಜ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.mercury === "7" &&
                        view.language === "ta"
                          ? "புதன்"
                          : view?.rasiDetails?.mercury === "7" &&
                            view.language === "en"
                          ? "Mercury"
                          : view?.rasiDetails?.mercury === "7" &&
                            view.language === "ml"
                          ? "ബുധൻ"
                          : view?.rasiDetails?.mercury === "7" &&
                            view.language === "hi"
                          ? "बुध"
                          : view?.rasiDetails?.mercury === "7" &&
                            view.language === "ka"
                          ? "ಬುಧ"
                          : null}{" "}
                      </span>{" "}
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.moon === "7" &&
                        view.language === "ta"
                          ? "சந்திரன்"
                          : view?.rasiDetails?.moon === "7" &&
                            view.language === "en"
                          ? "Moon"
                          : view?.rasiDetails?.moon === "7" &&
                            view.language === "ml"
                          ? "ചന്ദ്രൻ"
                          : view?.rasiDetails?.moon === "7" &&
                            view.language === "hi"
                          ? "चंद्र"
                          : view?.rasiDetails?.moon === "7" &&
                            view.language === "ka"
                          ? "ಚಂದ್ರ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.raghu === "7" &&
                        view.language === "ta"
                          ? "இராகு"
                          : view?.rasiDetails?.raghu === "7" &&
                            view.language === "en"
                          ? "Raghu"
                          : view?.rasiDetails?.raghu === "7" &&
                            view.language === "ml"
                          ? "രാഹു"
                          : view?.rasiDetails?.raghu === "7" &&
                            view.language === "hi"
                          ? "राहु"
                          : view?.rasiDetails?.raghu === "7" &&
                            view.language === "ka"
                          ? "ರಾಹು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.saturn === "7" &&
                        view.language === "ta"
                          ? "சனி"
                          : view?.rasiDetails?.saturn === "7" &&
                            view.language === "en"
                          ? "Saturn"
                          : view?.rasiDetails?.saturn === "7" &&
                            view.language === "ml"
                          ? "ശനി"
                          : view?.rasiDetails?.saturn === "7" &&
                            view.language === "hi"
                          ? "शनि"
                          : view?.rasiDetails?.saturn === "7" &&
                            view.language === "ka"
                          ? "ಶನಿ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.sun === "7" &&
                        view.language === "ta"
                          ? "சூரியன்"
                          : view?.rasiDetails?.sun === "7" &&
                            view.language === "en"
                          ? "Sun"
                          : view?.rasiDetails?.sun === "7" &&
                            view.language === "ml"
                          ? "സൂര്യൻ"
                          : view?.rasiDetails?.sun === "7" &&
                            view.language === "hi"
                          ? "सूर्य"
                          : view?.rasiDetails?.sun === "7" &&
                            view.language === "ka"
                          ? "ಸೂರ್ಯ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.venus === "7" &&
                        view.language === "ta"
                          ? "சுக்கிரன்"
                          : view?.rasiDetails?.venus === "7" &&
                            view.language === "en"
                          ? "Venus"
                          : view?.rasiDetails?.venus === "7" &&
                            view.language === "ml"
                          ? "ശുക്രൻ"
                          : view?.rasiDetails?.venus === "7" &&
                            view.language === "hi"
                          ? "शुक्र"
                          : view?.rasiDetails?.venus === "7" &&
                            view.language === "ka"
                          ? "ಶುಕ್ರ"
                          : null}
                      </span>
                    </div>
                    <div className="grid-item  item1">
                      {" "}
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.jupiter === "6" &&
                        view.language === "ta"
                          ? "குரு"
                          : view?.rasiDetails?.jupiter === "6" &&
                            view.language === "en"
                          ? "Jupiter"
                          : view?.rasiDetails?.jupiter === "6" &&
                            view.language === "ml"
                          ? "ഗുരു"
                          : view?.rasiDetails?.jupiter === "6" &&
                            view.language === "hi"
                          ? "गुरु"
                          : view?.rasiDetails?.jupiter === "6" &&
                            view.language === "ka"
                          ? "ಗುರು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.kethu === "6" &&
                        view.language === "ta"
                          ? "கேது"
                          : view?.rasiDetails?.kethu === "6" &&
                            view.language === "en"
                          ? "Kethu"
                          : view?.rasiDetails?.kethu === "6" &&
                            view.language === "ml"
                          ? "കേതു"
                          : view?.rasiDetails?.kethu === "6" &&
                            view.language === "hi"
                          ? "केतु"
                          : view?.rasiDetails?.kethu === "6" &&
                            view.language === "ka"
                          ? "ಕೇತು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.lagnam === "6" &&
                        view.language === "ta"
                          ? "லக்னம்"
                          : view?.rasiDetails?.lagnam === "6" &&
                            view.language === "en"
                          ? "Lagnam"
                          : view?.rasiDetails?.lagnam === "6" &&
                            view.language === "ml"
                          ? "ലഗ്നം"
                          : view?.rasiDetails?.lagnam === "6" &&
                            view.language === "hi"
                          ? "लग्न"
                          : view?.rasiDetails?.lagnam === "6" &&
                            view.language === "ka"
                          ? "ಲಗ್ನ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.mars === "6" &&
                        view.language === "ta"
                          ? "செவ்வாய்"
                          : view?.rasiDetails?.mars === "6" &&
                            view.language === "en"
                          ? "Mars"
                          : view?.rasiDetails?.mars === "6" &&
                            view.language === "ml"
                          ? "ചെവി"
                          : view?.rasiDetails?.mars === "6" &&
                            view.language === "hi"
                          ? "मंगल"
                          : view?.rasiDetails?.mars === "6" &&
                            view.language === "ka"
                          ? "ಕುಜ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.mercury === "6" &&
                        view.language === "ta"
                          ? "புதன்"
                          : view?.rasiDetails?.mercury === "6" &&
                            view.language === "en"
                          ? "Mercury"
                          : view?.rasiDetails?.mercury === "6" &&
                            view.language === "ml"
                          ? "ബുധൻ"
                          : view?.rasiDetails?.mercury === "6" &&
                            view.language === "hi"
                          ? "बुध"
                          : view?.rasiDetails?.mercury === "6" &&
                            view.language === "ka"
                          ? "ಬುಧ"
                          : null}{" "}
                      </span>{" "}
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.moon === "6" &&
                        view.language === "ta"
                          ? "சந்திரன்"
                          : view?.rasiDetails?.moon === "6" &&
                            view.language === "en"
                          ? "Moon"
                          : view?.rasiDetails?.moon === "6" &&
                            view.language === "ml"
                          ? "ചന്ദ്രൻ"
                          : view?.rasiDetails?.moon === "6" &&
                            view.language === "hi"
                          ? "चंद्र"
                          : view?.rasiDetails?.moon === "6" &&
                            view.language === "ka"
                          ? "ಚಂದ್ರ"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.raghu === "6" &&
                        view.language === "ta"
                          ? "இராகு"
                          : view?.rasiDetails?.raghu === "6" &&
                            view.language === "en"
                          ? "Raghu"
                          : view?.rasiDetails?.raghu === "6" &&
                            view.language === "ml"
                          ? "രാഹു"
                          : view?.rasiDetails?.raghu === "6" &&
                            view.language === "hi"
                          ? "राहु"
                          : view?.rasiDetails?.raghu === "6" &&
                            view.language === "ka"
                          ? "ರಾಹು"
                          : null}{" "}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.saturn === "6" &&
                        view.language === "ta"
                          ? "சனி"
                          : view?.rasiDetails?.saturn === "6" &&
                            view.language === "en"
                          ? "Saturn"
                          : view?.rasiDetails?.saturn === "6" &&
                            view.language === "ml"
                          ? "ശനി"
                          : view?.rasiDetails?.saturn === "6" &&
                            view.language === "hi"
                          ? "शनि"
                          : view?.rasiDetails?.saturn === "6" &&
                            view.language === "ka"
                          ? "ಶನಿ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.sun === "6" &&
                        view.language === "ta"
                          ? "சூரியன்"
                          : view?.rasiDetails?.sun === "6" &&
                            view.language === "en"
                          ? "Sun"
                          : view?.rasiDetails?.sun === "6" &&
                            view.language === "ml"
                          ? "സൂര്യൻ"
                          : view?.rasiDetails?.sun === "6" &&
                            view.language === "hi"
                          ? "सूर्य"
                          : view?.rasiDetails?.sun === "6" &&
                            view.language === "ka"
                          ? "ಸೂರ್ಯ"
                          : null}
                      </span>
                      <span style={{ color: "#000" }}>
                        {view?.rasiDetails?.venus === "6" &&
                        view.language === "ta"
                          ? "சுக்கிரன்"
                          : view?.rasiDetails?.venus === "6" &&
                            view.language === "en"
                          ? "Venus"
                          : view?.rasiDetails?.venus === "6" &&
                            view.language === "ml"
                          ? "ശുക്രൻ"
                          : view?.rasiDetails?.venus === "6" &&
                            view.language === "hi"
                          ? "शुक्र"
                          : view?.rasiDetails?.venus === "6" &&
                            view.language === "ka"
                          ? "ಶುಕ್ರ"
                          : null}
                      </span>
                    </div>
                  </Grid>
                </Grid>

                {/* ***************************** */}
                <Grid
                  container
                  sx={{ padding: "10px" }}
                  gap={1}
                  className="card-split"
                >
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    sx={{ border: "1px solid black", padding: "10px" }}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={5.9}
                        lg={5.9}
                        sx={{
                          paddingRight: "5px",
                          display: "flex",
                          flexWrap: "nowrap",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "40%",
                            wordBreak: "break-word",
                          }}
                        >
                          {view.language === "ta"
                            ? "முகவரி"
                            : view.language === "en"
                            ? "Address "
                            : view.language === "ml"
                            ? "വിലാസം "
                            : view.language === "hi"
                            ? "पता"
                            : view.language === "ka"
                            ? "ವಿಳಾಸ"
                            : null}{" "}
                        </div>
                        <div
                          style={{
                            width: "60%",
                            wordBreak: "break-word",
                          }}
                        >
                          {" "}
                          <p style={{ textTransform: "capitalize" }}>
                            {view?.city},
                          </p>
                          <p style={{ textTransform: "capitalize" }}>
                            {view?.state},
                          </p>
                          <p style={{ textTransform: "capitalize" }}>
                            {view?.country}.
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={0.1} lg={0.1}>
                        <div
                          style={{
                            borderLeft: "1px solid #555",
                            height: "100%",
                          }}
                        ></div>
                      </Grid>{" "}
                      <Grid
                        item
                        xs={5.9}
                        lg={5.9}
                        sx={{
                          paddingLeft: "5px",
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "15px",
                        }}
                      >
                        <div
                          className="forColumnCap"
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            //   width: "100%",
                            // columnGap: "20px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "40%",
                              wordBreak: "break-word",
                            }}
                          >
                            {view.language === "ta"
                              ? "vvk ஐடி"
                              : view.language === "en"
                              ? "Marimony ID"
                              : view.language === "ml"
                              ? "vvk ഐഡി "
                              : view.language === "hi"
                              ? "vvk आईडी"
                              : view.language === "ka"
                              ? "vvk ಐಡಿ"
                              : null}{" "}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              width: "60%",
                              wordBreak: "break-word",
                            }}
                          >
                            {view?.matrimonyId}
                          </div>
                        </div>
                        <div
                          className="forColumnCap"
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            //   width: "100%",
                            // columnGap: "20px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "40%",
                              wordBreak: "break-word",
                            }}
                          >
                            {view.language === "ta"
                              ? "தொலைபேசி எண்"
                              : view.language === "en"
                              ? "Phone No."
                              : view.language === "ml"
                              ? "ഫോൺ നമ്പർ"
                              : view.language === "hi"
                              ? "फ़ोन नंबर"
                              : view.language === "ka"
                              ? "ಫೋನ್ ಸಂಖ್ಯೆ"
                              : null}{" "}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              width: "60%",
                              wordBreak: "break-word",
                            }}
                          >
                            {view?.phoneNumber}
                          </div>
                        </div>

                        <div
                          className="forColumnCap"
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            //   width: "100%",
                            // columnGap: "20px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "40%",
                              wordBreak: "break-word",
                            }}
                          >
                            {view.language === "ta"
                              ? "ஆண்டு வருமானம்"
                              : view.language === "en"
                              ? "Annual Income"
                              : view.language === "ml"
                              ? "വാർഷിക വരുമാനം"
                              : view.language === "hi"
                              ? "वार्षिक आय"
                              : view.language === "ka"
                              ? "ವಾರ್ಷಿಕ ಆದಾಯ"
                              : null}{" "}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              width: "60%",
                              wordBreak: "break-word",
                            }}
                          >
                            {view?.annualIncome}
                          </div>
                        </div>
                      </Grid>
                    </Grid>{" "}
                  </Grid>
                </Grid>
              </Grid>

              <div style={{ color: "red", padding: "0px 0px 20px 40px" }}>
                {/* <p>
                  Note: No commissions / charges will be collected, if marriage
                  settled through vvkmatrimony
                </p> */}
                <p>Please verify the profile by yourself</p>
              </div>
            </Card>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "30px 0px",
            }}
          >
            <Button
              style={{
                backgroundColor: "#1A56DB",
                marginTop: "10px",
                marginBottom: "20px",
                paddingTop: "10px",
                color: "white",
                paddingBottom: "10px",
                fontFamily: "poppins ",
                paddingLeft: "20px",
                paddingRight: "20px",
                fontWeight: "bolder",
                alignSelf: "center",
                borderRadius: "30px",
                textAlign: "center",
                fontSize: "16px",
              }}
              onClick={() => exportAsImage(exportRef.current, "VVK_Horoscope")}
            >
              Download
            </Button>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default PDFView;
