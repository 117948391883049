import React from "react";
import {Link} from "react-router-dom"
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { recentprofile, AddIntrest } from "../Redux/Action";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// import Skeleton from '@mui/material/Skeleton';
import Swal from "sweetalert2";
import { Skeleton } from "../Common/Skeleton";
// import UserMeta from './UserMeta';

const NewMatches = () => {
  const dispatch = useDispatch();
  const Token = localStorage.getItem("Authtok");
  const [filters, setFilters] = useState({
    maritalStatus: [],
    motherTongue: [],
    profileCreated: [],
    religion: [],
    caste: [],
    subCaste: [],
  });
  const [filteredUsers, setFilteredUsers] = useState(null);

  const recent = useSelector(({ recentprofile }) => recentprofile.payload);

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(recent(filters)).then((res) => {
      // setFilters(res.data.data)
      setFilteredUsers(res.data.data);
    });
  };

  const handleChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: [
        ...filters[e.target.name],
        e.target.value.toLowerCase(),
      ],
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    Promise.allSettled([dispatch(recentprofile())]);
  }, []);

  return (
    <div>
      {/* <!--===// Start: Bride
        =================================--> */}
 <section id="breadcrumb-section" class="breadcrumb-area breadcrumb-left">
        <div class="av-container">
          <div class="av-columns-area">
            <div class="av-column-12">
              <div class="breadcrumb-content">
                <div class="breadcrumb-heading wow fadeInLeft">
                  <h2> New Matches</h2>
                </div>
                <ol class="breadcrumb-list wow fadeInRight">
                  <li>
                    <a href="/">
                      <i class="fa fa-home"></i> Home Page
                    </a>{" "}
                    &nbsp;-&nbsp;
                  </li>
                  <li class="active"> New Matches</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="post-section"
        class="post-section av-py-default"
        style={{ backgroundColor: "#fff" }}
      >
        <div class="av-container">
          <div class="av-columns-area">
            <div class="av-column-12">
              <div class="heading-default text-center wow fadeInUp">
                <h3>
                  New <span class="primary-color">Matches</span>
                </h3>
                <span class="separator">
                  <span>
                    <span></span>
                  </span>
                </span>
                {/* <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p> */}
              </div>
            </div>
          </div>
          {/* user meta start */}
          {/* <UserMeta /> */}
          {/* user meta end */}
          {recent === "Data Not Found!" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",fontSize:"30px",fontWeight:"600"
              }}
            >
              No Profile Found
            </div>
          ) : (
             <div class="av-columns-area">
        
            {/* profile list */}
            <div class="av-column-12">
              <div class="av-container">
                <div class="av-columns-area">
                  {Array.isArray(recent) && recent.length !== 0 ? (
                    recent.map((x) => (
                      <div class="av-column-4 av-sm-column-6">
                        <div class="team-member">
                          <div class="team-thumb" tabIndex="0">
                            <p
                              style={{
                                margin: "0px",
                                padding: "10px",
                                color: "#252525",
                              }}
                            >
                              {x.profileId} |{" "}
                              <a href=";">
                                <span style={{ color: "#00a03a" }}>
                                  {x.age}Yrs
                                </span>
                              </a>
                            </p>
                            <Link
                                to={
                                  Token ? "/profiledetails/" + x.id : "/login"
                                }
                              > <img
                              src={process.env.REACT_APP_URL + x.images}
                              alt="team_item"
                              title=""
                              style={{ aspectRatio: "1/1", objectFit: "cover" }}
                            /></Link>
                            <div
                              class="team-thumber"
                              style={{ cursor: "pointer" }}
                            >
                              <aside class="widget widget_social_widget">
                                <a
                                  onClick={() =>
                                    dispatch(
                                      AddIntrest({ interestProfileId: x.id })
                                    ).then((res) => {
                                      Swal.fire(res.data.data);
                                    })
                                  }
                                >
                                  <div
                                    class="cards-title"
                                    style={{ color: "#fff" }}
                                  >
                                    Send Interest
                                  </div>
                                </a>
                              </aside>
                            </div>
                          </div>
                          <div class="team-info">
                            <h5 style={{ textTransform: "capitalize" }}>
                              {x.userName.length > 10
                                ? x.userName.substring(0, 10) + "..."
                                : x.userName}
                            </h5>
                            <a
                              href={
                                Token ? "/profiledetails/" + x.id : "/login"
                              }
                            >
                              <p>View Full Profile</p>
                            </a>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    // <h3 style={{ textAlign: "center" }}>No Profile Match</h3>
                    <Skeleton />
                  )}
                </div>
              </div>
            </div>
          </div>)}
        </div>
      </section>
      {/* <!-- End: Bride
        =================================--> */}
    </div>
  );
};

export default NewMatches;
{
  /* <!-- Pagination --> */
}
{
  /* <div class="av-column-12 text-center mt-5">
<div class="av-post-pagination">
<nav class="navigation pagination" role="navigation" aria-label="Posts">
    <h2 class="screen-reader-text">Posts navigation</h2>
    <div class="nav-links">
        <a class="prev page-numbers" href=";"><i class="fa fa-arrow-left"></i></a>
        <span aria-current="page" class="page-numbers current">1</span>
        <a class="page-numbers" href=";">2</a>
        <a class="page-numbers" href=";">3</a>
        <a class="next page-numbers" href=";"><i class="fa fa-arrow-right"></i></a>
    </div>
</nav>
</div>
</div> */
}
{
  /* <!-- Pagination --> */
}
