import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { payNows } from "../Redux/Action";
import { Link } from "react-router-dom";
import Loader from "../Common/Loader";
import paymet_Error from "../Images/paymet_Error.jpg";
import paymet_Success from "../Images/paymet_Success.jpg";

function PaymentStatus() {
  const dispatch = useDispatch();

  const [load, setLoad] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState(null);

  const [queryParams] = useSearchParams();

  const orderId = queryParams.get("order_id");

  const navigate = useNavigate();

  useEffect(() => {
    if (orderId) {
      dispatch(payNows({ orderId: orderId }))
        .then((res) => {
          setPaymentStatus(res.data.data);
        })
        .catch((err) => {
          setPaymentStatus(err.response.data.error.message);
        })
        .finally(function () {
          setLoad(false);
        });
    } else {
      navigate("/");
    }
  }, []);

  return load ? (
    <>
      <Loader />
    </>
  ) : (
    <div style={{ background: "#002e34" }}>
      {
        // <!-- End Page Title Area -->
        // <!-- Start Trending product Area -->
      }
      <section
        className="trending-product-area ptb-100"
        style={{ padding: "150px 0px" }}
      >
        <div className="container">
          <div className="row justify-content-center g-5">
            <h2
              className="text-center col-12 pt-5 pb-2"
              style={{
                color: "whitesmoke",
                textShadow: `3px 3px 2px ${
                  paymentStatus === "Membership updated successfully"
                    ? "#46fa4687"
                    : "#fa464687"
                } `,
              }}
            >
              {paymentStatus}
            </h2>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "20px 0px",
              }}
            >
              <Link
                style={{
                  fontWeight: "bold",
                }}
                className="default-btn order-btn pb-4"
                to="/"
              >
                Go back to Home
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PaymentStatus;
