import React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { loginuser } from "../Redux/Action";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../Common/Loader";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import Swal from "sweetalert2";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

const Login = () => {
  const navigate = useNavigate();

  const [login, setLogin] = useState("");
  const [load, setLoad] = useState(true);
  const dispatch = useDispatch();
  const [err, setErr] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(""); // State variable for email error
  const [passwordError, setPasswordError] = useState(""); // State variable for password error
  const [email, setEmail] = useState("");
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleChangePassword = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    // Check if the password meets the minimum length requirement (3 characters)
    if (newPassword.length < 3) {
      setPasswordError("Minimum Password length should be 3 characters");
    } else {
      setPasswordError(""); // Reset the error message
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    // Reset error messages
    setEmailError("");
    setPasswordError("");

    if (!isValidEmail(email)) {
      setEmailError("Please provide valid email address");
    } else {
      dispatch(loginuser({ email: email, password: password }))
        .then((res) => {
          if (res.data.data === "Verification link sent to your mail") {
            // console.log(res.data.data);
            Swal.fire("Verification link sent to your mail");
          } else if (res.data.status === 429) {
            setErr(res.data.message);
          }
          localStorage.setItem("Authtok", res.data.data.token);
          navigate("/personaldetail");
          window.location.reload();
        })
        .catch((err) => {
          setErr(err.response.data.error.message);
          console.log("err", err.response.data.error.message);
        });
    }
  };

  useEffect(() => {
    localStorage.removeItem("Authtok");
    setLoad(false);
  }, []);
  // Helper function to validate email format
  const isValidEmail = (email) => {
    // Add your email validation logic here
    // For a simple check, you can use a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return load ? (
    <Loader />
  ) : (
    <div>
      <section
        id="about-section"
        class="about-section av-py-default shapes-section"
        style={{
          backgroundColor: "#fff",
          backgroundImage: "url('/assets/images/register.jpeg')",
        }}
      >
        <div className="av-container">
          <div className="av-columns-area">
            <div className="av-column-10 mx-auto">
              <div id="comments" className="comments-area">
                <div id="respond" className="comment-respond">
                  <h3 id="reply-title" className="comment-reply-title">
                    Login Your Account{" "}
                    <small>
                      <Link
                        rel="nofollow"
                        id="cancel-comment-reply-link"
                        to="#"
                        style={{ display: "none" }}
                      >
                        Cancel reply
                      </Link>
                    </small>
                  </h3>
                  <div className="form-outline mb-4">
                    <label className="form-label" for="form2Example1">
                      Email
                    </label>
                    <br />
                    <input
                      type="email"
                      name="email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                        // Reset email error
                        setEmailError("");
                      }}
                      style={{ background: "white" }}
                      required
                      id="form2Example1"
                      className="form-control"
                      placeholder="Enter your email"
                    />
                    {/* Display email error */}
                    {emailError && (
                      <p className="text-danger" style={{ color: "red" }}>
                        {emailError}
                      </p>
                    )}
                  </div>

                  <div className="form-outline">
                    <label className="form-label" htmlFor="form2Example2">
                      Password
                    </label>
                    <br />
                    <div className="input-group" style={{ display: "flex" }}>
                      <input
                        type={passwordVisible ? "text" : "password"}
                        id="form2Example2"
                        required
                        name="password"
                        value={password}
                        onChange={handleChangePassword}
                        className="form-control"
                        placeholder="Enter your password"
                        style={{ background: "white" }}
                      />
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        style={{
                          borderRadius: "none",
                          background: "none",
                          marginLeft: "-50px",
                          border: "none",
                          fontSize: "20px",
                        }}
                        onClick={togglePasswordVisibility}
                      >
                        {passwordVisible ? (
                          <BsEyeSlash style={{ color: "red" }} />
                        ) : (
                          <BsEye style={{ color: "red" }} />
                        )}
                      </button>
                    </div>
                    {/* Display password error */}
                    {passwordError && (
                      <p className="text-danger" style={{ color: "red" }}>
                        {passwordError}
                      </p>
                    )}
                  </div>
                  {/* <span
                    style={{
                      backgroundColor:"#F5F6FA",
                      display: err ? "flex" : 'none',
                      color: "#E62B47",
                      marginLeft: "10px",
                      padding:"10px",
                      marginBottom: "1rem",

                    }}
                  >
                    {err}
                  </span> */}
                  <Stack
                    sx={{ width: "100%", margin: "20px 0px 0px 0px" }}
                    spacing={2}
                  >
                    {err ? (
                      <Alert autoHideDuration={6000} severity="error">
                        {err}
                      </Alert>
                    ) : (
                      false
                    )}
                  </Stack>
                  <div
                    className="row mb-4"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="col d-flex justify-content-center">
                      <div className="form-check">
                        <label
                          className="form-check-label"
                          for="form2Example31"
                        >
                          <a href="/signup">SignUp?</a>
                        </label>
                      </div>
                    </div>

                    <div className="col forgot">
                      <a href="/forgotpassword">Forgot password?</a>
                    </div>
                  </div>
                  <button
                    className="btn btn-primary btn-block mb-4"
                    onClick={handleSubmit}
                  >
                    Sign in
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
