import React from "react";
import { useLocation } from "react-router-dom";

const Birthchart = () => {
  const location = useLocation();
  const birthcharts = location.state?.birthcharts;


  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h4 style={{margin:"30px 0px"}}>Birth Chart</h4>
        <img src={process.env.REACT_APP_URL + birthcharts} alt="Birth Chart" style={{margin:"20px 0px"}} />
      </div>
    </>
  );
};

export default Birthchart;
