import React from "react";
import { Link } from "react-router-dom";
import { error } from "../error/404 error with people holding the numbers-pana.svg";

const Error404 = () => {
  function error() {
    window.location.reload();
  }
  return (
    <div>
      {/* <!--===// Start: 404 Page
        =================================--> */}
      <section
        id="section404"
        class="section404 shapes-section"
        style={{ backgroundColor: "#fff" }}
      >
        <div class="av-container">
          <div class="av-columns-area wow fadeInUp">
            <div class="av-column-6 text-center mx-auto">
              <div
                class="card404"
                style={{
                  backgroundImage:
                    "url('/assets/images/404 error with people holding the numbers-pana.svg')",
                }}
              >
                {/* <img src={error} alt="" /> */}
                {/* <h1>4<span class="card404icon"><i class="fa fa-wrench"></i></span>4</h1> */}
                <h4>Page Not Found</h4>
                
                <div class="card404-btn mt-3">
                  {/* <a href=";" class="av-btn av-btn-primary av-btn-bubble">Back <i class="fa fa-arrow-right"></i> <span class="bubble_effect"><span class="circle top-left"></span> <span class="circle top-left"></span> <span class="circle top-left"></span> <span class="button effect-button"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span></span></a> */}
                  <a
                    onClick={error}
                    class="av-btn av-btn-secondary av-btn-bubble"
                  >
                    Retry <i class="fa fa-arrow-right"></i>{" "}
                    <span class="bubble_effect">
                      <span class="circle top-left"></span>{" "}
                      <span class="circle top-left"></span>{" "}
                      <span class="circle top-left"></span>{" "}
                      <span class="button effect-button"></span>{" "}
                      <span class="circle bottom-right"></span>{" "}
                      <span class="circle bottom-right"></span>{" "}
                      <span class="circle bottom-right"></span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="shape24">
          <img src="assets/images/page404/img01.png" alt="image" />
        </div>
        <div class="shape25">
          <img src="assets/images/page404/img02.png" alt="image" />
        </div>
        <div class="shape26">
          <img src="assets/images/page404/img03.png" alt="image" />
        </div>
        <div class="shape27">
          <img src="assets/images/page404/img04.png" alt="image" />
        </div>
        <div class="shape28">
          <img src="assets/images/page404/img05.png" alt="image" />
        </div>
        <div class="shape29">
          <img src="assets/images/page404/img06.png" alt="image" />
        </div>
        <div class="shape30">
          <img src="assets/images/page404/img07.png" alt="image" />
        </div>
        <div class="shape31">
          <img src="assets/images/page404/img08.png" alt="image" />
        </div>
        <div class="shape32">
          <img src="assets/images/page404/img09.png" alt="image" />
        </div>
        <div class="shape33">
          <img src="assets/images/page404/img10.png" alt="image" />
        </div>
        <div class="shape34">
          <img src="assets/images/page404/img11.png" alt="image" />
        </div>
        <div class="shape35">
          <img src="assets/images/page404/img12.png" alt="image" />
        </div>
        <div class="shape36">
          <img src="assets/images/page404/img13.png" alt="image" />
        </div>
      </section>
      {/* <!-- End: 404 Page
        =================================--> */}
    </div>
  );
};

export default Error404;
