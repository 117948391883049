import React from 'react'

const Awards = () => {
  return (
    <div>
                {/* <!--===// Start: Awards
        =================================--> */}
        <section id="gallery-section" class="gallery-section gallery-one av-py-default" style={{backgroundColor:"#fff"}}>
            <div class="av-container">
                <div class="av-columns-area">
                    <div class="av-column-12">
                        <div class="heading-default text-center wow fadeInUp">
                            <h3>Our <span class="primary-color">Gallery</span></h3>
                            <span class="separator"><span><span></span></span></span>
                            <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        </div>
                    </div>
                </div>
                <div class="av-columns-area">
                    <div class="av-column-6 av-md-column-6 av-load-item av-load-3 text-center">
                        <figure class="gallery-item">
                            <div class="gallery-icon landscape">
                                <div class="image">
                                    <img src="assets/images/gallery/vin.png" class="attachment-thumbnail size-thumbnail" alt="gallery_item"/>
                                </div>
                                <div class="gallery-popup-icon mgf-popup">
                                    <a href="assets/images/gallery/vin.png" class="image" title="This is a gallery"><i class="fa fa-search"></i></a>
                                    <a href=";" class="link"><i class="fa fa-link"></i></a>
                                </div>
                            </div>
                            <figcaption class="wp-caption-text gallery-caption">
                                <h4>VVK Matrimony</h4>
                                <p>Lorem Ipsum is simply dummy text</p>
                            </figcaption>
                        </figure>
                    </div>
                    <div class="av-column-6 av-md-column-6 av-load-item av-load-3">
                        <figure class="gallery-item">
                            <div class="gallery-icon landscape">
                                <div class="image">
                                    <img src="assets/images/gallery/vin.png" class="attachment-thumbnail size-thumbnail" alt="gallery_item"/>
                                </div>
                                <div class="gallery-popup-icon mgf-popup">
                                    <a href="assets/images/gallery/gallery2.jpg" class="image" title="This is a gallery"><i class="fa fa-search"></i></a>
                                    <a href=";" class="link"><i class="fa fa-link"></i></a>
                                </div>
                            </div>
                            <figcaption class="wp-caption-text gallery-caption">
                            <h4>VVK Matrimony</h4>
                                <p>Lorem Ipsum is simply dummy text</p>
                            </figcaption>
                        </figure>
                    </div>
                    <div class="av-column-6 av-md-column-6 av-load-item av-load-3">
                        <figure class="gallery-item">
                            <div class="gallery-icon landscape">
                                <div class="image">
                                    <img src="assets/images/gallery/vin.png" class="attachment-thumbnail size-thumbnail" alt="gallery_item"/>
                                </div>
                                <div class="gallery-popup-icon mgf-popup">
                                    <a href="assets/images/gallery/vin.png" class="image" title="This is a gallery"><i class="fa fa-search"></i></a>
                                    <a href=";" class="link"><i class="fa fa-link"></i></a>
                                </div>
                            </div>
                            <figcaption class="wp-caption-text gallery-caption">
                            <h4>VVK Matrimony</h4>
                                <p>Lorem Ipsum is simply dummy text</p>
                            </figcaption>
                        </figure>
                    </div>
                    <div class="av-column-6 av-md-column-6 av-load-item av-load-3">
                        <figure class="gallery-item">
                            <div class="gallery-icon landscape">
                                <div class="image">
                                    <img src="assets/images/gallery/vin.png" class="attachment-thumbnail size-thumbnail" alt="gallery_item"/>
                                </div>
                                <div class="gallery-popup-icon mgf-popup">
                                    <a href="assets/images/gallery/vin.png" class="image" title="This is a gallery"><i class="fa fa-search"></i></a>
                                    <a href=";" class="link"><i class="fa fa-link"></i></a>
                                </div>
                            </div>
                            <figcaption class="wp-caption-text gallery-caption">
                            <h4>VVK Matrimony</h4>
                                <p>Lorem Ipsum is simply dummy text</p>
                            </figcaption>
                        </figure>
                    </div>
                    <div class="av-column-6 av-md-column-6 av-load-item av-load-3">
                        <figure class="gallery-item">
                            <div class="gallery-icon landscape">
                                <div class="image">
                                    <img src="assets/images/gallery/vin.png" class="attachment-thumbnail size-thumbnail" alt="gallery_item"/>
                                </div>
                                <div class="gallery-popup-icon mgf-popup">
                                    <a href="assets/images/gallery/vin.png" class="image" title="This is a gallery"><i class="fa fa-search"></i></a>
                                    <a href=";" class="link"><i class="fa fa-link"></i></a>
                                </div>
                            </div>
                            <figcaption class="wp-caption-text gallery-caption">
                            <h4>VVK Matrimony</h4>
                                <p>Lorem Ipsum is simply dummy text</p>
                            </figcaption>
                        </figure>
                    </div>
                    <div class="av-column-6 av-md-column-6 av-load-item av-load-3">
                        <figure class="gallery-item">
                            <div class="gallery-icon landscape">
                                <div class="image">
                                    <img src="assets/images/gallery/vin.png" class="attachment-thumbnail size-thumbnail" alt="gallery_item"/>
                                </div>
                                <div class="gallery-popup-icon mgf-popup">
                                    <a href="assets/images/gallery/vin.png" class="image" title="This is a gallery"><i class="fa fa-search"></i></a>
                                    <a href=";" class="link"><i class="fa fa-link"></i></a>
                                </div>
                            </div>
                            <figcaption class="wp-caption-text gallery-caption">
                            <h4>VVK Matrimony</h4>
                                <p>Lorem Ipsum is simply dummy text</p>
                            </figcaption>
                        </figure>
                    </div>
                    <div class="av-column-12 mt-5 text-center">
                        <a href=";" class="av-btn av-btn-primary av-btn-bubble av-load-more">Load More <i class="fa fa-spinner"></i> <span class="bubble_effect"><span class="circle top-left"></span> <span class="circle top-left"></span> <span class="circle top-left"></span> <span class="button effect-button"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span></span></a>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- End: Awards
        =================================--> */}
    </div>
  )
}

export default Awards