// import { profilelist } from "../Action";
import {
  personal,
  profile,
  userlog,
  profdet,
  horoscopeauto,
  proof,
  family,
  partner,
  viewprofile,
  list,
  viewfullprofile,
  banprof,
  recommend,
  contact,
  prepof,
  visitprofile,
  normalprof,
  natchathiraPro,
  recentprof,
  completion,
  searchprofile,
  horoscopeUpdate,
  searchlocation,
  searchmartailstatus,
  searchforeginintrest,
  successtestimony,
  homerecentprof,
  logout,
  pdfview,
  membership,
  Hidematch,
  reportAcc,
  feedbackacc,
  addIntrestUser,
  AddFavourite,
  AddSuccess,
  AddContactProfile,
  ListFavourite,
  ListHidematch,
  SingleUserprofile,
  // profileUpdate,
  personalityUpdate,
  professionUpdate,
  familydetUpdate,
  PartnerPreferenceUpdate,
  Membership,
  membershipsum,
  forgotuser,
  verifyotp,
  imageupdate,
  metauser,
  getprefpartner,
  getfamdet,
  getpersonal,
  getprofdetails,
  verifymail,
  otpverify,
  getad,
  horomaches,
  regularSearch,
  filter,
  amsam,
  rasi,
  birthmatch,
  birthchart,
  banner,
  userdoc,
  accountDelete,
} from "../ActionType";

const initialState = {};

//User
export const profileuser = (state = initialState, actions) => {
  switch (actions.type) {
    case profile.login.success:
      return {
        status: true,
        payload: actions.data,
      };
    case profile.login.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
//delete
export const DeleteAccount = (state = initialState, actions) => {
  switch (actions.type) {
    case accountDelete.Delete.success:
      return {
        status: true,
        payload: actions.data,
      };
    case accountDelete.Delete.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
//banner
export const Banner = (state = initialState, actions) => {
  switch (actions.type) {
    case banner.banner.success:
      return {
        status: true,
        payload: actions.data,
      };
    case banner.banner.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
//image update
export const Updateimage = (state = initialState, actions) => {
  switch (actions.type) {
    case imageupdate.image.success:
      return {
        status: true,
        payload: actions.data,
      };
    case imageupdate.image.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

//Update profileuser
export const Updateprofileuser = (state = initialState, actions) => {
  switch (actions.type) {
    case profile.login.success:
      return {
        status: true,
        payload: actions.data,
      };
    case profile.login.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

//Usermeta
export const Usermeta = (state = initialState, actions) => {
  switch (actions.type) {
    case metauser.user.success:
      return {
        status: true,
        payload: actions.data,
      };
    case metauser.user.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const Usermetadetails = (state = initialState, actions) => {
  switch (actions.type) {
    case metauser.details.success:
      return {
        status: true,
        payload: actions.data,
      };
    case metauser.details.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
//report
export const Reportaccount = (state = initialState, actions) => {
  switch (actions.type) {
    case reportAcc.report.success:
      return {
        status: true,
        payload: actions.data,
      };
    case reportAcc.report.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

//feedback
export const Feedbackaccount = (state = initialState, actions) => {
  switch (actions.type) {
    case feedbackacc.feedback.success:
      return {
        status: true,
        payload: actions.data,
      };
    case feedbackacc.feedback.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// personal details
export const personaldetails = (state = initialState, actions) => {
  switch (actions.type) {
    case personal.login.success:
      return {
        status: true,
        payload: actions.data,
      };
    case personal.login.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const Viewcontactprofiles = (state = initialState, actions) => {
  switch (actions.type) {
    case contact.list.success:
      return {
        status: true,
        payload: actions.data,
      };
    case contact.list.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
//get personal details
export const getpersonaldetails = (state = initialState, actions) => {
  switch (actions.type) {
    case getpersonal.pesonaldetail.success:
      return {
        status: true,
        payload: actions.data,
      };
    case getpersonal.pesonaldetail.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// update personal details
export const Updatepersonaldetails = (state = initialState, actions) => {
  switch (actions.type) {
    case personalityUpdate.Personality.success:
      return {
        status: true,
        payload: actions.data,
      };
    case personalityUpdate.Personality.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
// details pdf view
export const PDFview = (state = initialState, actions) => {
  switch (actions.type) {
    case pdfview.view.success:
      return {
        status: true,
        payload: actions.data,
      };
    case pdfview.view.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// login user
export const loginuser = (state = initialState, actions) => {
  switch (actions.type) {
    case userlog.login.success:
      return {
        status: true,
        payload: actions.data,
      };
    case userlog.login.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// otp verify
export const Otpverify = (state = initialState, actions) => {
  switch (actions.type) {
    case verifyotp.otp.success:
      return {
        status: true,
        payload: actions.data,
      };
    case verifyotp.otp.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// mobile number otp verify
export const Otpverifynumber = (state = initialState, actions) => {
  switch (actions.type) {
    case otpverify.otpnumber.success:
      return {
        status: true,
        payload: actions.data,
      };
    case otpverify.otpnumber.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// Forgotpassword
export const Forgotpassword = (state = initialState, actions) => {
  switch (actions.type) {
    case forgotuser.forgot.success:
      return {
        status: true,
        payload: actions.data,
      };
    case forgotuser.forgot.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// mailverify
export const Mailverify = (state = initialState, actions) => {
  switch (actions.type) {
    case verifymail.mail.success:
      return {
        status: true,
        payload: actions.data,
      };
    case verifymail.mail.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// profession details
export const profession = (state = initialState, actions) => {
  switch (actions.type) {
    case profdet.login.success:
      return {
        status: true,
        payload: actions.data,
      };
    case profdet.login.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

//get profession details
export const getprofession = (state = initialState, actions) => {
  switch (actions.type) {
    case getprofdetails.detailprof.success:
      return {
        status: true,
        payload: actions.data,
      };
    case getprofdetails.detailprof.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

//update profession details
export const Updateprofession = (state = initialState, actions) => {
  switch (actions.type) {
    case professionUpdate.Profession.success:
      return {
        status: true,
        payload: actions.data,
      };
    case professionUpdate.Profession.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// Horoscope Details Automatically
export const horoscopeA = (state = initialState, actions) => {
  switch (actions.type) {
    case horoscopeauto.login.success:
      return {
        status: true,
        payload: actions.data,
      };
    case horoscopeauto.login.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// idproof Details
export const idproofdet = (state = initialState, actions) => {
  switch (actions.type) {
    case proof.login.success:
      return {
        status: true,
        payload: actions.data,
      };
    case proof.login.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// family Details
export const familydet = (state = initialState, actions) => {
  switch (actions.type) {
    case family.login.success:
      return {
        status: true,
        payload: actions.data,
      };
    case family.login.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

//get family Details
export const getfamilydet = (state = initialState, actions) => {
  switch (actions.type) {
    case getfamdet.famdet.success:
      return {
        status: true,
        payload: actions.data,
      };
    case getfamdet.famdet.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

//update family Details
export const Updatefamilydet = (state = initialState, actions) => {
  switch (actions.type) {
    case familydetUpdate.familydet.success:
      return {
        status: true,
        payload: actions.data,
      };
    case familydetUpdate.familydet.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
//update family Details
export const Updatehoroscopedet = (state = initialState, actions) => {
  switch (actions.type) {
    case horoscopeUpdate.horoscopedet.success:
      return {
        status: true,
        payload: actions.data,
      };
    case horoscopeUpdate.horoscopedet.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
// partner preference
export const partnerperf = (state = initialState, actions) => {
  switch (actions.type) {
    case partner.login.success:
      return {
        status: true,
        payload: actions.data,
      };
    case partner.login.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

//get partner preference
export const getpartnerperf = (state = initialState, actions) => {
  switch (actions.type) {
    case getprefpartner.getpartner.success:
      return {
        status: true,
        payload: actions.data,
      };
    case getprefpartner.getpartner.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// update partner preference
export const Updatepartnerperf = (state = initialState, actions) => {
  switch (actions.type) {
    case PartnerPreferenceUpdate.PartnerPre.success:
      return {
        status: true,
        payload: actions.data,
      };
    case PartnerPreferenceUpdate.PartnerPre.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// View profile list
export const Profilelist = (state = initialState, actions) => {
  switch (actions.type) {
    case viewprofile.login.success:
      return {
        status: true,
        payload: actions.data,
      };
    case viewprofile.login.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const horolist = (state = initialState, actions) => {
  switch (actions.type) {
    case viewprofile.horo.success:
      return {
        status: true,
        payload: actions.data,
      };
    case viewprofile.horo.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// View profilefull list
export const Allprofilelist = (state = initialState, actions) => {
  switch (actions.type) {
    case viewfullprofile.get.success:
      return {
        status: true,
        payload: actions.data,
      };
    case viewfullprofile.get.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// View completion score
export const getCompletion = (state = initialState, actions) => {
  switch (actions.type) {
    case completion.get.success:
      return {
        status: true,
        payload: actions.data,
      };
    case completion.get.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// View profilefull list
export const SingleProfile = (state = initialState, actions) => {
  switch (actions.type) {
    case SingleUserprofile.Singleuser.success:
      return {
        status: true,
        payload: actions.data,
      };
    case SingleUserprofile.Singleuser.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// recommend Profile
export const recommendprofilee = (state = initialState, actions) => {
  switch (actions.type) {
    case recommend.recommendprofile.success:
      return {
        status: true,
        payload: actions.data,
      };
    case recommend.recommendprofile.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// Banner Profile
export const bannerprofile = (state = initialState, actions) => {
  switch (actions.type) {
    case banprof.bannerprofile.success:
      return {
        status: true,
        payload: actions.data,
      };
    case banprof.bannerprofile.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// premium Profile
export const premiumprofile = (state = initialState, actions) => {
  switch (actions.type) {
    case prepof.premiumprofile.success:
      return {
        status: true,
        payload: actions.data,
      };
    case prepof.premiumprofile.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const nakshatraprofile = (state = initialState, actions) => {
  switch (actions.type) {
    case natchathiraPro.natchathiraprofile.success:
      return {
        status: true,
        payload: actions.data,
      };
    case natchathiraPro.natchathiraprofile.success:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
// Normal Profile
export const normalprofile = (state = initialState, actions) => {
  switch (actions.type) {
    case normalprof.normalprofile.success:
      return {
        status: true,
        payload: actions.data,
      };
    case normalprof.normalprofile.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// recent Profile
export const recentprofile = (state = initialState, actions) => {
  switch (actions.type) {
    case recentprof.recentprofile.success:
      return {
        status: true,
        payload: actions.data,
      };
    case recentprof.recentprofile.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// home recent Profile
export const Homerecentprofile = (state = initialState, actions) => {
  switch (actions.type) {
    case homerecentprof.homerecent.success:
      return {
        status: true,
        payload: actions.data,
      };
    case homerecentprof.homerecent.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// search Profile
export const searchoro = (state = initialState, actions) => {
  switch (actions.type) {
    case searchprofile.searchoro.success:
      return {
        status: true,
        payload: actions.data,
      };
    case searchprofile.searchoro.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// search locatoin
export const searchloc = (state = initialState, actions) => {
  switch (actions.type) {
    case searchlocation.searchs.success:
      return {
        status: true,
        payload: actions.data,
      };
    case searchlocation.searchs.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// search martial status
export const searchmar = (state = initialState, actions) => {
  switch (actions.type) {
    case searchmartailstatus.searchm.success:
      return {
        status: true,
        payload: actions.data,
      };
    case searchmartailstatus.searchm.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// search foreign intrest
export const searchfor = (state = initialState, actions) => {
  switch (actions.type) {
    case searchforeginintrest.searchf.success:
      return {
        status: true,
        payload: actions.data,
      };
    case searchforeginintrest.searchf.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// search foreign intrest
export const successstory = (state = initialState, actions) => {
  switch (actions.type) {
    case successtestimony.story.success:
      return {
        status: true,
        payload: actions.data,
      };
    case successtestimony.story.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

//logout
export const logoutUser = (state = initialState, actions) => {
  switch (actions.type) {
    case logout.logoutUser.success:
      return {
        status: true,
        payload: actions.data,
      };
    case logout.logoutUser.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// Membership plan
export const MembershipPlans = (state = initialState, actions) => {
  switch (actions.type) {
    case membership.plans.success:
      return {
        status: true,
        payload: actions.data,
      };
    case membership.plans.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

//AddIntrest
export const AddIntrest = (state = initialState, actions) => {
  switch (actions.type) {
    case addIntrestUser.AddIn.success:
      return {
        status: true,
        payload: actions.data,
      };
    case addIntrestUser.AddIn.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const Addvisitprofile = (state = initialState, actions) => {
  switch (actions.type) {
    case visitprofile.addprofile.success:
      return {
        status: true,
        payload: actions.data,
      };
    case visitprofile.addprofile.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const Viwevisitprofile = (state = initialState, actions) => {
  switch (actions.type) {
    case visitprofile.viewprofile.success:
      return {
        status: true,
        payload: actions.data,
      };
    case visitprofile.viewprofile.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const Viewlikeprofile = (state = initialState, actions) => {
  switch (actions.type) {
    case list.like.success:
      return {
        status: true,
        payload: actions.data,
      };
    case list.like.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const Viwevisitlist = (state = initialState, actions) => {
  switch (actions.type) {
    case list.visit.success:
      return {
        status: true,
        payload: actions.data,
      };
    case list.visit.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const Viewinterestprofile = (state = initialState, actions) => {
  switch (actions.type) {
    case list.interest.success:
      return {
        status: true,
        payload: actions.data,
      };
    case list.interest.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const Viewinterestedprofile = (state = initialState, actions) => {
  switch (actions.type) {
    case list.interestrecive.success:
      return {
        status: true,
        payload: actions.data,
      };
    case list.interestrecive.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const FavProfile = (state = initialState, actions) => {
  switch (actions.type) {
    case list.fav.success:
      return {
        status: true,
        payload: actions.data,
      };
    case list.fav.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
//subcription
export const Subcription = (state = initialState, actions) => {
  switch (actions.type) {
    case membership.subcription.success:
      return {
        status: true,
        payload: actions.data,
      };
    case membership.subcription.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
// hide Profile
export const Hideprofile = (state = initialState, actions) => {
  switch (actions.type) {
    case Hidematch.hide.success:
      return {
        status: true,
        payload: actions.data,
      };
    case Hidematch.hide.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
//AddLikedProfile
export const Likedprofile = (state = initialState, actions) => {
  switch (actions.type) {
    case AddFavourite.Addfav.success:
      return {
        status: true,
        payload: actions.data,
      };
    case AddFavourite.Addfav.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

//ListShortlist
export const ListShortlist = (state = initialState, actions) => {
  switch (actions.type) {
    case ListFavourite.Listfav.success:
      return {
        status: true,
        payload: actions.data,
      };
    case ListFavourite.Listfav.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

//Add AddContactProfile
export const ContactProfile = (state = initialState, actions) => {
  switch (actions.type) {
    case AddContactProfile.Addcontact.success:
      return {
        status: true,
        payload: actions.data,
      };
    case AddContactProfile.Addcontact.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

//AddIntrest
export const AddSuccessStory = (state = initialState, actions) => {
  switch (actions.type) {
    case AddSuccess.Story.success:
      return {
        status: true,
        payload: actions.data,
      };
    case AddSuccess.Story.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

//ListHideProfile
export const ListHideProfile = (state = initialState, actions) => {
  switch (actions.type) {
    case ListHidematch.Listhide.success:
      return {
        status: true,
        payload: actions.data,
      };
    case ListHidematch.Listhide.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

//ListHideProfile
export const Buymembership = (state = initialState, actions) => {
  switch (actions.type) {
    case Membership.planMembership.success:
      return {
        status: true,
        payload: actions.data,
      };
    case Membership.planMembership.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// membership summary
export const Membershipsummary = (state = initialState, actions) => {
  switch (actions.type) {
    case membershipsum.summary.success:
      return {
        status: true,
        payload: actions.data,
      };
    case membershipsum.summary.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// advertisement
export const advertisement = (state = initialState, actions) => {
  switch (actions.type) {
    case getad.ad.success:
      return {
        status: true,
        payload: actions.data,
      };
    case getad.ad.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// regular search
export const regSearch = (state = initialState, actions) => {
  switch (actions.type) {
    case regularSearch.regular.success:
      return {
        status: true,
        payload: actions.data,
      };
    case regularSearch.regular.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// filterProfile
export const filterProfile = (state = initialState, actions) => {
  switch (actions.type) {
    case filter.fprofile.success:
      return {
        status: true,
        payload: actions.data,
      };
    case filter.fprofile.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// astrology rasi
export const amsamhoro = (state = initialState, actions) => {
  switch (actions.type) {
    case amsam.horoamsam.success:
      return {
        status: true,
        payload: actions.data,
      };
    case amsam.horoamsam.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// astrology amsam
export const rasihoro = (state = initialState, actions) => {
  switch (actions.type) {
    case rasi.hororasi.success:
      return {
        status: true,
        payload: actions.data,
      };
    case rasi.hororasi.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
// horoscope matchs
export const HoroscopeMatches = (state = initialState, actions) => {
  switch (actions.type) {
    case horomaches.horomatches.success:
      return {
        status: true,
        payload: actions.data,
      };
    case horomaches.horomatches.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
// horoscope matchs
export const BirthMatches = (state = initialState, actions) => {
  switch (actions.type) {
    case birthmatch.birthmatches.success:
      return {
        status: true,
        payload: actions.data,
      };
    case birthmatch.birthmatches.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
// birth chart
export const BirthChart = (state = initialState, actions) => {
  switch (actions.type) {
    case birthchart.birthcharts.success:
      return {
        status: true,
        payload: actions.data,
      };
    case birthchart.birthcharts.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const Document = (state = initialState, actions) => {
  switch (actions.type) {
    case userdoc.docs.success:
      return {
        status: true,
        payload: actions.data,
      };
    case userdoc.docs.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
