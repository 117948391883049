import { combineReducers } from "redux";
import {
  profileuser,
  personaldetails,
  loginuser,
  profession,
  horoscopeA,
  idproofdet,
  familydet,
  partnerperf,
  Profilelist,
  Allprofilelist,
  recommendprofilee,
  Addvisitprofile,
  Viwevisitprofile,
  bannerprofile,
  premiumprofile,
  nakshatraprofile,
  normalprofile,
  recentprofile,
  searchoro,
  searchloc,
  searchmar,
  searchfor,
  getCompletion,
  successstory,
  Homerecentprofile,
  logoutUser,
  MembershipPlans,
  Hideprofile,
  Reportaccount,
  Feedbackaccount,
  AddIntrest,
  Likedprofile,
  AddSuccessStory,
  ContactProfile,
  ListShortlist,
  ListHideProfile,
  SingleProfile,
  Updateprofileuser,
  Updatepersonaldetails,
  Updateprofession,
  Updatefamilydet,
  Updatepartnerperf,
  Buymembership,
  Membershipsummary,
  Forgotpassword,
  Otpverify,
  Updateimage,
  Usermeta,
  Usermetadetails,
  getpartnerperf,
  getfamilydet,
  PDFview,
  getpersonaldetails,
  getprofession,
  Mailverify,
  Otpverifynumber,
  advertisement,
  regSearch,
  filterProfile,
  amsamhoro,
  HoroscopeMatches,
  Updatehoroscopedet,
  Viewcontactprofiles,
  BirthMatches,
  rasihoro,
  BirthChart,
  Banner,
  Document,
  Viewinterestprofile,
  Viewinterestedprofile,
  Viewlikeprofile,
  Viwevisitlist,
  FavProfile,
  DeleteAccount,
  Subcription,
  horolist,
} from "./Reducer";

export default combineReducers({
  profileuser,
  personaldetails,
  Viewinterestedprofile,
  loginuser,
  profession,
  horoscopeA,
  idproofdet,
  familydet,
  Viewinterestprofile,
  partnerperf,
  DeleteAccount,
  Profilelist,
  Allprofilelist,
  recommendprofilee,
  bannerprofile,
  premiumprofile,
  nakshatraprofile,
  normalprofile,
  recentprofile,
  searchoro,
  searchloc,
  searchmar,
  searchfor,
  successstory,
  PDFview,
  getCompletion,
  Updatehoroscopedet,
  Homerecentprofile,
  logoutUser,
  MembershipPlans,
  Hideprofile,
  Reportaccount,
  Feedbackaccount,
  AddIntrest,
  Viwevisitprofile,
  Addvisitprofile,
  Likedprofile,
  AddSuccessStory,
  ContactProfile,
  ListShortlist,
  ListHideProfile,
  Viewlikeprofile,
  SingleProfile,
  Updateprofileuser,
  Updatepersonaldetails,
  Updateprofession,
  Updatefamilydet,
  Updatepartnerperf,
  Buymembership,
  Membershipsummary,
  Forgotpassword,
  Otpverify,
  Updateimage,
  Usermeta,
  Usermetadetails,
  getpartnerperf,
  getfamilydet,
  Banner,
  getpersonaldetails,
  getprofession,
  Mailverify,
  Otpverifynumber,
  advertisement,
  regSearch,
  filterProfile,
  amsamhoro,
  rasihoro,
  HoroscopeMatches,
  Viewcontactprofiles,
  BirthMatches,
  BirthChart,
  Document,
  Viwevisitlist,
  FavProfile,
  Subcription,
  horolist,
});
