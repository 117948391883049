import React, { useEffect } from "react";
// import { Link } from 'react-router-dom'
import { useDispatch } from "react-redux";
// import { Membershipsummary } from '../Redux/Action'

const OrderSummary = () => {
  // const dipatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ backgroundColor: "#fff" }}>
      {/* <!--===// Start: Breadcrumb
        =================================--> */}
      <section id="breadcrumb-section" class="breadcrumb-area breadcrumb-left">
        <div class="av-container">
          <div class="av-columns-area">
            <div class="av-column-12">
              <div class="breadcrumb-content">
                <div class="breadcrumb-heading wow fadeInLeft">
                  <h2>Order Summary</h2>
                </div>
                <ol class="breadcrumb-list wow fadeInRight">
                  <li>
                    <a href="/">
                      <i class="fa fa-home"></i> Home Page
                    </a>{" "}
                    &nbsp;-&nbsp;
                  </li>
                  <li class="active">Order Summary</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: Breadcrumb
        =================================--> */}

      {/* start order summary */}
      <section id="post-section" class="post-section av-py-default">
        <div class="av-container">
          <div class="av-columns-area">
            <div class="av-column-12">
              <div class="heading-default text-center wow fadeInUp">
                <h3>
                  Order <span class="primary-color">Summary</span>
                </h3>
                <span class="separator">
                  <span>
                    <span></span>
                  </span>
                </span>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p>
              </div>
            </div>
          </div>
          <div class="av-columns-area">
            <div class="av-column-12">
              <div class="sidebar">
                <aside id="widget-categories" class="widget widget_categories">
                  <h5 class="widget-title">
                    Select Your Payment Method <span></span>
                  </h5>
                  <ul>
                    <li class="cat-item">
                      <a title="Posts in this category test image and text alignment.">
                        Diamond - 1 Year
                      </a>{" "}
                      <span style={{ fontFamily: "none", fontSize: "20px" }}>
                        ₹ 7000
                      </span>
                    </li>
                    {/* <li class="cat-item"><a title="Posts in this category test the aside post format.">Total</a> <span style={{ fontFamily: "none", fontSize: "20px" }}>₹ 7000 </span></li> */}
                    <li class="cat-item">
                      <a title="Posts in this category test the audio post format.">
                        GST 18%
                      </a>{" "}
                      <span style={{ fontFamily: "none", fontSize: "20px" }}>
                        ₹ 1260
                      </span>
                    </li>
                    <li class="cat-item">
                      <a>You have to pay</a>{" "}
                      <span style={{ fontWeight: "bold", fontSize: "25px" }}>
                        ₹ 8260
                      </span>
                    </li>
                  </ul>
                </aside>
              </div>
              <div class="pricing-btn" style={{ direction: "rtl" }}>
                <a href="#" class="av-btn av-btn-primary av-btn-bubble">
                  {" "}
                  Pay Securely{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end order summary */}

      {/* <!--===// Start: Contact Form
        =================================--> */}
      {
        //  <section id="contactform-section" class="contactform-section av-pb-default" style={{ backgroundColor: "#fff" }}>
        //     <div class="contactform-cards" style={{marginTop:"0"}}>
        //         <div class="av-container">
        //             <div class="av-columns-area">
        //                 <div class="av-column-6">
        //                     <div class="contactform-cards-item" style={{backgroundImage: "url('assets/images/contact/card01.jpg')"}}>
        //                         <div class="cards-area">
        //                             <div class="cards-icon"><i class="fa fa-wechat"></i></div>
        //                             <div class="cards-title" style={{fontSize:"25px"}}>WhattsApp Chat</div>
        //                             <div class="cards-btn"><a href="#" class="av-btn av-btn-primary av-btn-bubble">Start Chat <i class="fa fa-arrow-right"></i> <span class="bubble_effect"><span class="circle top-left"></span> <span class="circle top-left"></span> <span class="circle top-left"></span> <span class="button effect-button"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span></span></a></div>
        //                         </div>
        //                     </div>
        //                 </div>
        //                 <div class="av-column-6">
        //                     <div class="contactform-cards-item" style={{backgroundImage: "url('assets/images/contact/card02.jpg')"}}>
        //                         <div class="cards-area">
        //                         <div class="cards-icon"><i class="fa fa-wechat"></i></div>
        //                             <div class="cards-title" style={{fontSize:"25px"}}>WhattsApp Chat</div>
        //                             {/* <div class="cards-btn">
        //                                 <aside class="share-toolkit widget widget_social_widget">
        //                                     <a href="#" class="toolkit-hover"><i class="fa fa-share-alt"></i></a>
        //                                     <ul>
        //                                         <li><a href="javascript:void(0);"><i class="fa fa-facebook"></i></a></li>
        //                                         <li><a href="javascript:void(0);"><i class="fa fa-twitter"></i></a></li>
        //                                         <li><a href="javascript:void(0);"><i class="fa fa-linkedin"></i></a></li>
        //                                         <li><a href="javascript:void(0);"><i class="fa fa-instagram"></i></a></li>
        //                                     </ul>
        //                                 </aside>
        //                             </div> */}
        //                             <div class="cards-btn"><a href="#" class="av-btn av-btn-primary av-btn-bubble">Start Chat <i class="fa fa-arrow-right"></i> <span class="bubble_effect"><span class="circle top-left"></span> <span class="circle top-left"></span> <span class="circle top-left"></span> <span class="button effect-button"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span></span></a></div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </section>
      }
      {/* <!-- End: Contact Form
        =================================--> */}
    </div>
  );
};

export default OrderSummary;
