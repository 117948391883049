import React from "react";

const Loader = () => {
  return (
    <div>
      {/* <!--===// Start: Preloader
    =================================--> */}
      <div className="prealoader">
        <div className="load-circle">
          <span className="one"></span>
        </div>
      </div>
      {/* <!-- End: Preloader
    =================================--> */}
    </div>
  );
};

export default Loader;
