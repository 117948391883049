import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { familydet } from "../Redux/Action";
import Alert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { getCompletion } from "../Redux/Action";
import Swal from "sweetalert2";
import Stack from "@mui/material/Stack";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const Register6 = () => {
  const [familydetails, setFamilydetails] = useState("");
  const [err, setErr] = useState("");
  const [load, setLoad] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(familydet(familydetails))
      .then((res) => {
        navigate("/horoscopedetail");
      })
      .catch((e) => {
        setErr(e.response.data.error.message);
      });
  };
  const [father, setFather] = useState("");
  const [fatherocc, setFatherocc] = useState("");
  const [mother, setMother] = useState("");
  const [motherocc, setMotherocc] = useState("");
  const handleChange = (e) => {
    if (e.target.name === "fatherName") {
      if (!/^[A-Za-z\s]+$/.test(e.target.value)) {
        setFather("Please enter only letters (A-Z, a-z)");
      } else {
        setFather("");
      }
    }
    if (e.target.name === "fatherOccupation") {
      if (!/^[A-Za-z\s]+$/.test(e.target.value)) {
        setFatherocc("Please enter only letters (A-Z, a-z)");
      } else {
        setFatherocc("");
      }
    }
    if (e.target.name === "motherName") {
      if (!/^[A-Za-z\s]+$/.test(e.target.value)) {
        setMother("Please enter only letters (A-Z, a-z)");
      } else {
        setMother("");
      }
    }
    if (e.target.name === "motherOccupation") {
      if (!/^[A-Za-z\s]+$/.test(e.target.value)) {
        setMotherocc("Please enter only letters (A-Z, a-z)");
      } else {
        setMotherocc("");
      }
    }
    setFamilydetails({ ...familydetails, [e.target.name]: e.target.value });
  };
  const completion = useSelector(({ getCompletion }) => getCompletion.payload);
  // console.log(completion, "completion");

  useEffect(() => {
    dispatch(getCompletion()).then((res) => {
      if (
        res.data.data.familyDetails === "Family details missing" ||
        res.data.data.Score === 0
      ) {
        setLoad(false);
      } else {
        navigate("/myprofile");
        window.location.reload();
      }
    });
  }, []);
  return (
    <div>
      {/* <!--===// Start: registeration Form
        =================================--> */}
      <section
        id="post-section"
        className="post-section av-py-default"
        style={{
          backgroundColor: "#fff",
          backgroundImage: "url('/assets/images/register.jpeg')",
        }}
      >
        <div className="av-container">
          <div className="av-columns-area">
            <div className="av-column-12 mx-auto">
              <div id="comments" className="comments-area">
                <div id="respond" className="comment-respond">
                  <form onSubmit={handleSubmit}>
                    {/* <!-- Email input --> */}
                    <h3 id="reply-title" className="comment-reply-title">
                      Family Details{" "}
                      <small>
                        <a
                          rel="nofollow"
                          id="cancel-comment-reply-link"
                          href="#"
                          style={{ display: "none" }}
                        >
                          Cancel reply
                        </a>
                      </small>
                    </h3>
                    <div className="form-outline mb-4">
                      <label>Enter Your Fathers Name</label>
                      <input
                        type="text"
                        name="fatherName"
                        onChange={handleChange}
                        id="form2Example1"
                        placeholder="Father Name"
                        pattern="[A-Za-z\s]+"
                        title="Please enter only letters (A-Z, a-z)"
                        className={`form-control mt-3 ${
                          father ? "is-invalid" : ""
                        }`}
                      />
                      {father && (
                        <div className="invalid-feedback" style={{color:"red"}}>{father}</div>
                      )}
                    </div>
                    <div className="form-outline mb-4">
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="fatherAlive"
                        onChange={handleChange}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Alive"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="Passed away"
                        />
                      </RadioGroup>
                    </div>
                    <div className="form-outline mb-4">
                      <label>Enter Your Father Occupation</label>
                      <input
                        type="text"
                        name="fatherOccupation"
                        onChange={handleChange}
                        id="form2Example1"
                        placeholder="Father Occupation"
                        pattern="[A-Za-z\s]+"
                        title="Please enter only letters (A-Z, a-z)"
                        className={`form-control mt-3 ${
                          fatherocc ? "is-invalid" : ""
                        }`}
                      />
                      {fatherocc && (
                        <div className="invalid-feedback" style={{color:"red"}}>{fatherocc}</div>
                      )}
                    </div>
                    <div className="form-outline mb-4">
                      <label>Enter Your Mother's Name</label>
                      <input
                        type="text"
                        name="motherName"
                        onChange={handleChange}
                        id="form2Example1"
                        placeholder="Mother Name"
                        pattern="[A-Za-z\s]+"
                        title="Please enter only letters (A-Z, a-z)"
                        className={`form-control mt-3 ${
                          mother ? "is-invalid" : ""
                        }`}
                      />
                      {mother && (
                        <div className="invalid-feedback" style={{color:"red"}}>{mother}</div>
                      )}
                    </div>
                    <div className="form-outline mb-4">
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="motherAlive"
                        onChange={handleChange}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Alive"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="Passed away"
                        />
                      </RadioGroup>
                    </div>
                    <div className="form-outline mb-4">
                      <label>Enter Your Mother Occupation</label>
                      <input
                        type="text"
                        name="motherOccupation"
                        onChange={handleChange}
                        id="form2Example1"
                        placeholder="Mother Occupation"
                        pattern="[A-Za-z\s]+"
                        title="Please enter only letters (A-Z, a-z)"
                        className={`form-control mt-3 ${
                          motherocc ? "is-invalid" : ""
                        }`}
                      />
                      {motherocc && (
                        <div className="invalid-feedback" style={{color:"red"}}>{motherocc}</div>
                      )}
                    </div>
                    <div className="form-outline mb-4">
                      <label>
                        Choose Your Family Type{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        name="familyType"
                        onChange={handleChange}
                        required
                        id="familyType"
                      >
                        <option value="Choose Gender">
                          Select Family Type{" "}
                        </option>
                        <option value="Join family">Join Family</option>
                        <option value="Nuclear family">Nuclear Family</option>
                      </select>
                    </div>
                    <div className="form-outline mb-4">
                      <label>
                        Choose Your Finacial Status{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        name="financialStatus"
                        onChange={handleChange}
                        required
                        id="financialStatus"
                      >
                        <option value="Choose Gender">
                          Select Family Status
                        </option>
                        <option value="rich">Rich</option>
                        <option value="very rich">Very Rich</option>

                        <option value="lower middle class">
                          Lower Middle class
                        </option>
                        <option value="middle class">Middle class</option>
                        <option value="upper middle class">
                          Upper Middle class
                        </option>
                        <option value="affluent">Affluent</option>
                      </select>
                    </div>
                    <div className="form-outline mb-4">
                      <label>
                        Choose Your Family Values{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        name="familyValues"
                        onChange={handleChange}
                        required
                        id="familyValues"
                      >
                        <option value="Choose Gender">
                          Select Family Values
                        </option>
                        <option value="orthodox">Orthodox</option>
                        <option value="traditional">Traditional</option>
                        <option value="middle class">Middle class</option>
                        <option value="moderate">Moderate</option>
                        <option value="liberal">Liberal</option>
                      </select>
                    </div>
                    <div className="form-outline mb-4">
                      <label>Choose Your Sibilings</label>
                      <select
                        name="siblingDetails"
                        onChange={handleChange}
                        id="siblingDetails"
                      >
                        <option value="Choose Gender">Select Sibilings</option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="More than 5">More than 5</option>
                      </select>
                    </div>
                    <Stack sx={{ width: "100%" }} spacing={2}>
                      {err ? (
                        <Alert autoHideDuration={6000} severity="error">
                          {err}
                        </Alert>
                      ) : (
                        false
                      )}
                    </Stack>
                    <div
                      className="av-column-12"
                      style={{ paddingBottom: "1px" }}
                    >
                      <a>
                        {" "}
                        <button
                          type="submit"
                          className="btn btn-primary btn-block mb-4"
                          style={{ float: "left" }}
                        >
                          Next
                        </button>
                      </a>
                      <a href="/horoscopedetail">
                        {" "}
                        <button
                          type="button"
                          className="btn btn-primary btn-block mb-4"
                          style={{ float: "right" }}
                        >
                          Skip
                        </button>
                      </a>
                    </div>
                    {/* <!-- Register buttons --> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: registration Form
        =================================--> */}
    </div>
  );
};

export default Register6;
