import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { profileuser } from "../Redux/Action";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Swal from "sweetalert2";
import Stack from "@mui/material/Stack";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Register = () => {
  const [profile, setProfile] = useState({});
  const [err, setErr] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [image, setImage] = useState();
  const [ageError, setAgeError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const handleRegisterSubmit = () => {
    const formData = new FormData();

    formData.append("image", image);
    Object.entries(profile).map(([key, value]) => formData.append(key, value));
    dispatch(profileuser(formData))
      .then((res) => {
        setErr(res.data.data);
        Swal.fire(res.data.data);
        setTimeout(() => {
          setErr("");
          navigate("/login");
        }, 3000);
      })
      .catch((err) => {
        setErr(err.data);
        setTimeout(() => {
          setErr("");
        }, 3000);
      });
  };

  const handleImage = (e) => {
    setImage(e.target.files[0]);
  };

  const handleRegisterChange = (e) => {
    if (e.target.name === "age" && e.target.value < 0) {
      return;
    }

    if (e.target.name === "phone") {
      if (!/^[0-9]{10}$/.test(e.target.value)) {
        setPhoneError("Please enter a valid 10-digit phone number");
      } else {
        setPhoneError("");
      }
    }

    if (e.target.name === "age") {
      const enteredAge = e.target.value.trim(); // Remove leading and trailing whitespace
      if (/^\d{1,2}$/.test(enteredAge)) {
        // Check if the input consists of 1 or 2 digits
        const ageValue = parseInt(enteredAge, 10);
        if (ageValue < 18) {
          setAgeError("Age must be 18 or older");
        } else {
          setAgeError("");
        }
      } else {
        setAgeError("Invalid age");
      }
    }
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };
  // console.log(profile);
  return (
    <div>
      {/* <!--===// Start: registeration Form
        =================================--> */}
      <section
        id="post-section"
        className="post-section av-py-default"
        style={{
          backgroundColor: "#fff",
          backgroundImage: "url('/assets/images/register.jpeg')",
        }}
      >
        <div className="av-container">
          <div className="av-columns-area">
            <div className="av-column-12 mx-auto">
              <div id="comments" className="comments-area">
                <div id="respond" className="comment-respond">
                  <form>
                    {/* <!-- Email input --> */}
                    <h3 id="reply-title" className="comment-reply-title">
                      Personal Details{" "}
                      <small>
                        <a
                          rel="nofollow"
                          id="cancel-comment-reply-link"
                          href="#"
                          style={{ display: "none" }}
                        >
                          Cancel reply
                        </a>
                      </small>
                    </h3>
                    <div className="form-outline mb-2">
                      <label>
                        Enter Username <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        name="userName"
                        id="usename"
                        onChange={handleRegisterChange}
                        required
                        className="form-control"
                        placeholder="Username"
                      />
                    </div>
                    <br />
                    <div className="form-outline mb-2">
                      <label>
                        Enter Email <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        required
                        onChange={handleRegisterChange}
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>{" "}
                    <br />
                    {/* <!-- Password input --> */}
                    <div className="form-outline mb-2">
                      <label>
                        Enter Password <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        onChange={handleRegisterChange}
                        required
                        className="form-control "
                        placeholder="Password"
                      />
                    </div>
                    <br />
                    <div className="form-outline mb-2">
                      <label>
                        Enter Your Phone Number{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        required
                        onChange={handleRegisterChange}
                        className={`form-control  ${
                          phoneError ? "is-invalid" : ""
                        }`}
                        maxLength="10"
                        placeholder="Phone Number"
                      />
                      {phoneError && (
                        <div className="invalid-feedback" style={{color:"red"}}>{phoneError}</div>
                      )}
                    </div>
                    <br />
                    <div className="form-outline mb-2">
                      <label>
                        Enter Your Date of Birth{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="date"
                        name="dob"
                        id="dob"
                        onChange={handleRegisterChange}
                        required
                        className="form-control "
                        placeholder="Dob"
                      />
                    </div>
                    <br />
                    <div className="form-outline mb-2">
                      <label>
                        Enter Your Age <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="number"
                        name="age"
                        id="age"
                        onChange={handleRegisterChange}
                        required
                        className={`form-control ${
                          ageError ? "is-invalid" : ""
                        }`}
                        maxLength={2}
                        placeholder="Your Age"
                      />
                      {ageError && (
                        <div className="invalid-feedback" style={{color:"red"}}>{ageError}</div>
                      )}
                    </div>
                    <br />
                    <div className="form-outline mb-2">
                      <label>
                        Enter Country Name{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        name="country"
                        onChange={handleRegisterChange}
                        required
                        id="country"
                        className="form-control "
                        placeholder="Country Name"
                      />
                    </div>
                    <br />
                    <div className="form-outline mb-2">
                      <label>
                        Enter State Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        name="state"
                        onChange={handleRegisterChange}
                        required
                        id="state"
                        className="form-control "
                        placeholder="State Name"
                      />
                    </div>
                    <br />
                    <div className="form-outline mb-2">
                      <label>
                        Enter City Name <span style={{ color: "red" }}>*</span>
                      </label>

                      <input
                        type="text"
                        name="city"
                        onChange={handleRegisterChange}
                        required
                        id="city"
                        className="form-control"
                        placeholder="City Name"
                      />
                    </div>
                    <br />
                    <div className="form-outline mb-2">
                      <label>
                        Choose Your Gender{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        name="gender"
                        onChange={handleRegisterChange}
                        required
                        id="gender"
                      >
                        <option>Choose Gender</option>
                        <option value="MALE">Male</option>
                        <option value="FEMALE">Female</option>
                      </select>
                    </div>
                    <br />
                    <div className="form-outline ">
                      <label>
                        Choose Your Profile Type{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        name="profileType"
                        onChange={handleRegisterChange}
                        required
                        id="profileType"
                      >
                        <option value="Choose Profile">
                          Choose Profile Type
                        </option>
                        <option value="PRIVATE">Private</option>
                        <option value="PUBLIC">Public</option>
                      </select>
                    </div>
                    {/* <div className="form-outline mb-2">
                      <select
                        name="membershipType"
                        onChange={handleRegisterChange}
                        id="membershipType"
                      >
                        <option value="Choose Membership">
                          Choose Membership
                        </option>
                        <option value="PREMIUM">PREMIUM</option>
                        <option value="NORMAL">NORMAL</option>
                      </select>
                    </div> */}
                    <br />
                    <div className="form-outline mb-2">
                      <label>Upload Your Profile Image</label>
                      <input
                        type="file"
                        name="image"
                        id="profilePicture"
                        onChange={handleImage}
                        className="form-control "
                        style={{ padding: "15px" }}
                      />
                    </div>
                    <p style={{ color: "#E82E44" }}>
                      (Upload a Image and File Size up to 4,089 KB & File Type
                      JPG or JPEG or PNG)
                    </p>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="   I agree to the Privacy Policy and Terms & Conditions."
                      name="wp-comment-cookies-consent"
                    />
                    {/* <span
                    style={{
                      backgroundColor:"#ef5350",
                      display: err ? "flex" : 'none',
                      color: "white",
                      marginLeft: "10px",
                      padding:"10px",
                      marginBottom: "1rem",

                    }}
                  >
                    {err ? err : false}
                    </span> */}
                    <Stack
                      sx={{ width: "100%", margin: "20px 0px" }}
                      spacing={2}
                    >
                      {err ? (
                        <Alert autoHideDuration={6000} severity="error">
                          {err}
                        </Alert>
                      ) : (
                        false
                      )}
                    </Stack>
                    <div
                      className="av-column-12"
                      style={{ paddingBottom: "1px" }}
                    >
                      <a
                      //  href='/register3'
                      >
                        {" "}
                        <button
                          type="button"
                          className="btn btn-primary btn-block mb-2"
                          style={{ float: "left" }}
                          onClick={() => handleRegisterSubmit()}
                        >
                          Submit
                        </button>
                      </a>
                      {/* <a href='/register3'> <button
                      type="button"
                      className="btn btn-primary btn-block mb-2"
                      style={{float:"right"}}
                    >
                      Skip
                    </button></a> */}
                    </div>
                    {/* <!-- Register buttons --> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: registration Form
        =================================--> */}
    </div>
  );
};

export default Register;
