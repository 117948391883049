import React from "react";
import { useSelector } from "react-redux";
// import { advertisement } from "../Redux/Action";
import { Skeleton } from "../Common/Skeleton";
import MdPhone from "@mui/icons-material/Phone";
import AboveFooterDetails from "./AboveFooterDetails";

const OurServices = () => {
  const ads = useSelector(({ advertisement }) => advertisement.payload);

  return (
    <div>
      {/* <!--===// Start: Breadcrumb
        =================================--> */}
      <section id="breadcrumb-section" class="breadcrumb-area breadcrumb-left">
        <div class="av-container">
          <div class="av-columns-area">
            <div class="av-column-12">
              <div class="breadcrumb-content">
                <div class="breadcrumb-heading wow fadeInLeft">
                  <h2>About Us</h2>
                </div>
                <ol class="breadcrumb-list wow fadeInRight">
                  <li>
                    <a href="/">
                      <i class="fa fa-home"></i> Home Page
                    </a>{" "}
                    &nbsp;-&nbsp;
                  </li>
                  <li class="active">About Us</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: Breadcrumb
        =================================--> */}

      {/* <!--===// Start: our services
        =================================--> */}
      <section
        id="team-section"
        className="team-section av-py-default team-home shapes-sections"
        style={{ backgroundColor: "#fff", paddingBottom: "0" }}
      >
        {" "}
        <section
          id="timeline-section"
          class="timeline-section av-py-default shapes-sections"
          style={{ backgroundColor: "#fff" }}
        >
          <div class="av-container">
            <div class="av-column-12">
              <div class="heading-default text-center wow fadeInUp">
                <h3>
                  About <span class="primary-color">Us</span>
                </h3>
                <span class="separator">
                  <span>
                    <span></span>
                  </span>
                </span>
              </div>
            </div>
            <div class="av-columns-area">
              <div class="av-column-12 timeline">
                <div class="av-columns-area timeline-item">
                  <div
                    class="av-xs-column-12 av-md-column-12 order-3 order-md-1 timeline-content wow fadeInRight "
                    style={{ lineHeight: "40px" }}
                  >
                    Welcome to vvkmatrimony.com, the leading online platform for
                    matchmaking and marriage services. Our mission is to help
                    our members find their life partners with ease and
                    convenience, using our advanced technology and personalized
                    services. At vvkmatrimony.com, we understand that finding a
                    life partner is one of the most important and personal
                    decisions of one's life. That's why we provide a safe,
                    secure, and user-friendly online platform for our members to
                    connect, interact, and explore potential matches from all
                    over the world. Our platform is designed to offer a seamless
                    and hassle-free experience, with features like advanced
                    search, chat, and privacy settings, to make the matchmaking
                    process more efficient and effective. Our team comprises of
                    experienced professionals from the matrimonial and
                    technology sectors, who are dedicated to delivering the best
                    possible services to our members. We constantly strive to
                    improve and innovate our services to meet the evolving needs
                    of our members and provide the best possible user
                    experience. We take pride in our commitment to data privacy
                    and security, and we ensure that our platform is fully
                    compliant with all relevant regulations and best practices.
                    Our members can be assured that their personal information
                    and communications are kept confidential and secure. Thank
                    you for choosing vvkmatrimony.com as your partner in the
                    search for a life partner. We are committed to helping you
                    find your soulmate and building a lifetime of happiness and
                    love.
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div class='shape1'>
        <img src='assets/images/service/clipArt/shape1.png' alt='image' />
      </div>
      <div class='shape2'>
        <img src='assets/images/service/clipArt/shape2.png' alt='image' />
      </div>
      <div class='shape3'>
        <img src='assets/images/service/clipArt/shape3.png' alt='image' />
      </div>
      <div class='shape4'>
        <img src='assets/images/service/clipArt/shape4.png' alt='image' />
      </div>
      <div class='shapetimeline1'>
        <img src='assets/images/timeline/timeline1.png' alt='image' />
      </div>
      <div class='shapetimeline2'>
        <img src='assets/images/timeline/timeline2.png' alt='image' />
      </div>
      <div class='shapetimeline3'>
        <img src='assets/images/timeline/timeline3.png' alt='image' />
      </div> */}
        </section>
      </section>
      {/* <!-- End: our services
        =================================--> */}
    </div>
  );
};

export default OurServices;
