import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { rasihoro } from "../Redux/Action";

const Register30 = () => {
  const [date, setDate] = useState([]);
  // const [number, setnumber] = useState("");
  const [err, setErr] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let amsa =
    Array.isArray(date) &&
    date.map((v, i) => {
      const datas = v.data.toString();
      let values = v.number.toString();
      if (values.length > 2) {
        values = values.substring(0, 2);
      }
      values = values.replace(/\n/g, "");

      return { [datas]: values };
    });
  let obj = amsa.reduce((acc, curr) => {
    return { ...acc, ...curr };
  }, {});

  function allowDrop(event) {
    event.preventDefault();
  }

  function drag(event) {
    event.dataTransfer.setData("text", event.target.id);
  }

  function drop(event) {
    event.preventDefault();
    var data = event.dataTransfer.getData("text");
    var target = event.target;
    var number = target.innerText;
    setDate((prevDate) => [...prevDate, { data, number }]);
    target.appendChild(document.getElementById(data));
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(rasihoro(obj))
      .then((res) => {
        navigate("/amsa");
        window.location.reload();
      })
      .catch((e) => {
        setErr(e.response.data.error.message);
      });
  };
  return (
    <div>
      <section
        id="post-section"
        className="post-section av-py-default"
        style={{
          backgroundColor: "#fff",
          backgroundImage: "url('/assets/images/register.jpeg')",
        }}
      >
        <div className="av-container">
          <div className="av-columns-area">
            <div className="av-column-12 mx-auto">
              <div iv id="comments" className="comments-area">
                <div id="respond" className="comment-respond">
                  <h3 id="reply-title" className="comment-reply-title">
                    Lets Generate Horoscope Manually{" "}
                    <small>
                      <a
                        rel="nofollow"
                        id="cancel-comment-reply-link"
                        href="#"
                        style={{ display: "none" }}
                      >
                        Cancel reply
                      </a>
                    </small>
                  </h3>
                  <p>Drag and Drop the planet names in corresponding boxes.</p>
                  <div className="av-columns-area">
                    <div className="av-column-3">
                      <div className="sidebar">
                        <aside
                          id="widget-categories"
                          className="widget widget_categories"
                        >
                          <h6 className="widget-title">
                            Rasi Chart <span></span>
                          </h6>
                          <ul>
                            <li className="cat-item">
                              <div id="sun" draggable="true" onDragStart={drag}>
                                Sun
                              </div>{" "}
                            </li>
                            <li className="cat-item">
                              <div
                                id="moon"
                                draggable="true"
                                onDragStart={drag}
                              >
                                Moon
                              </div>{" "}
                            </li>
                            <li className="cat-item">
                              <div
                                id="raghu"
                                draggable="true"
                                onDragStart={drag}
                              >
                                {" "}
                                Raghu
                              </div>{" "}
                            </li>
                            <li className="cat-item">
                              <div
                                id="kethu"
                                draggable="true"
                                onDragStart={drag}
                              >
                                Ketu
                              </div>
                            </li>
                            <li className="cat-item">
                              <div
                                id="mars"
                                draggable="true"
                                onDragStart={drag}
                              >
                                Mars
                              </div>
                            </li>
                            <li className="cat-item">
                              <div
                                id="venus"
                                draggable="true"
                                onDragStart={drag}
                              >
                                Venus
                              </div>
                            </li>
                            <li className="cat-item">
                              <div
                                id="jupiter"
                                draggable="true"
                                onDragStart={drag}
                              >
                                Jupiter
                              </div>
                            </li>
                            <li className="cat-item">
                              <div
                                id="mercury"
                                draggable="true"
                                onDragStart={drag}
                              >
                                Mercury
                              </div>
                            </li>
                            <li className="cat-item">
                              <div
                                id="saturn"
                                draggable="true"
                                onDragStart={drag}
                              >
                                Saturn
                              </div>
                            </li>
                            <li className="cat-item">
                              <div
                                id="lagnam"
                                draggable="true"
                                onDragStart={drag}
                              >
                                Lagnam
                              </div>
                            </li>
                          </ul>
                        </aside>
                      </div>
                    </div>
                    <div className="av-column-9" style={{ marginTop: "70px" }}>
                      <div className="av-columns-area">
                        <div className="av-column-9">
                          {/* <fieldset style={{width:"200%"}}> */}
                          <table style={{ width: "100%", height: "100px" }}>
                            <tbody>
                              <tr style={{ backgroundColor: "#fff" }}>
                                <td
                                  onDrop={drop}
                                  onDragOver={allowDrop}
                                  style={{
                                    width: "50%",
                                    height: "100px",
                                    fontWeight: "bold",
                                    color: "#EB3142",
                                  }}
                                  id="div2"
                                >
                                  <span style={{ color: "#000" }}>12</span>
                                </td>
                                <td
                                  onDrop={drop}
                                  onDragOver={allowDrop}
                                  style={{
                                    width: "50%",
                                    height: "100px",
                                    fontWeight: "bold",
                                    color: "#EB3142",
                                  }}
                                >
                                  <span style={{ color: "#000" }}>1</span>
                                </td>
                                <td
                                  onDrop={drop}
                                  onDragOver={allowDrop}
                                  style={{
                                    width: "50%",
                                    height: "100px",
                                    fontWeight: "bold",
                                    color: "#EB3142",
                                  }}
                                >
                                  <span style={{ color: "#000" }}>2</span>
                                </td>
                                <td
                                  onDrop={drop}
                                  onDragOver={allowDrop}
                                  style={{
                                    width: "50%",
                                    height: "100px",
                                    fontWeight: "bold",
                                    color: "#EB3142",
                                  }}
                                >
                                  <span style={{ color: "#000" }}>3</span>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  onDrop={drop}
                                  onDragOver={allowDrop}
                                  style={{
                                    width: "50%",
                                    height: "100px",
                                    fontWeight: "bold",
                                    color: "#EB3142",
                                  }}
                                >
                                  <span style={{ color: "#000" }}>11</span>
                                </td>
                                <td
                                  colSpan="2"
                                  rowSpan="2"
                                  style={{
                                    width: "50%",
                                    height: "100px",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    fontSize: "25px",
                                    color: "#EB3142",
                                  }}
                                >
                                  Rasi
                                </td>
                                <td
                                  onDrop={drop}
                                  onDragOver={allowDrop}
                                  style={{
                                    width: "50%",
                                    height: "100px",
                                    fontWeight: "bold",
                                    color: "#EB3142",
                                  }}
                                >
                                  <span style={{ color: "#000" }}>4</span>
                                </td>
                                {/* <td onDrop={drop} onDragOver={allowDrop} style={{width:"50%",height:"100px",fontWeight:"bold",color:"#EB3142"}}></td> */}
                              </tr>
                              <tr>
                                <td
                                  onDrop={drop}
                                  onDragOver={allowDrop}
                                  style={{
                                    width: "50%",
                                    height: "100px",
                                    fontWeight: "bold",
                                    color: "#EB3142",
                                  }}
                                >
                                  <span style={{ color: "#000" }}>10</span>
                                </td>
                                <td
                                  onDrop={drop}
                                  onDragOver={allowDrop}
                                  colSpan="2"
                                  style={{
                                    width: "50%",
                                    height: "100px",
                                    fontWeight: "bold",
                                    color: "#EB3142",
                                  }}
                                >
                                  <span style={{ color: "#000" }}>5</span>
                                </td>
                                {/* <td onDrop={drop} onDragOver={allowDrop} style={{width:"50%",height:"100px"}}></td>
                              <td onDrop={drop} onDragOver={allowDrop} style={{width:"50%",height:"100px",fontWeight:"bold"}}></td> */}
                              </tr>
                              <tr>
                                <td
                                  onDrop={drop}
                                  onDragOver={allowDrop}
                                  style={{
                                    width: "50%",
                                    height: "100px",
                                    fontWeight: "bold",
                                    color: "#EB3142",
                                  }}
                                >
                                  <span style={{ color: "#000" }}>9</span>
                                </td>
                                <td
                                  onDrop={drop}
                                  onDragOver={allowDrop}
                                  style={{
                                    width: "50%",
                                    height: "100px",
                                    fontWeight: "bold",
                                    color: "#EB3142",
                                  }}
                                >
                                  <span style={{ color: "#000" }}>8</span>
                                </td>
                                <td
                                  onDrop={drop}
                                  onDragOver={allowDrop}
                                  style={{
                                    width: "50%",
                                    height: "100px",
                                    fontWeight: "bold",
                                    color: "#EB3142",
                                  }}
                                >
                                  <span style={{ color: "#000" }}>7</span>
                                </td>
                                <td
                                  onDrop={drop}
                                  onDragOver={allowDrop}
                                  style={{
                                    width: "50%",
                                    height: "100px",
                                    fontWeight: "bold",
                                    color: "#EB3142",
                                  }}
                                >
                                  <span style={{ color: "#000" }}>6</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                 
                  <div
                    className="av-column-12"
                    style={{ paddingBottom: "1px" }}
                  >
                    <button
                      onClick={handleSubmit}
                      type="button"
                      className="btn btn-primary btn-block mb-4"
                      style={{ float: "left" }}
                    >
                      Next
                    </button>

                    <a href="/amsa">
                      {" "}
                      <button
                        type="button"
                        className="btn btn-primary btn-block mb-4"
                        style={{ float: "right" }}
                      >
                        Skip
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Register30;
