import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { horoscopeA } from "../Redux/Action";

const Register32 = ({ history }) => {
  const [horoscope, setHoroscope] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [regerror, setRegerror] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(horoscopeA(horoscope)).then((res) => {
      setRegerror(res.data.data);
      navigate("/partnerdetail");
    });
  };
  const [place, setPlace] = useState("");
  const [gotharamer, setGotharamer] = useState("");

  const handleChange = (e) => {
    if (e.target.name === "placeOfBirth") {
      if (!/^[A-Za-z\s]+$/.test(e.target.value)) {
        setPlace("Please enter only letters (A-Z, a-z)");
      } else {
        setPlace("");
      }
    }
    if (e.target.name === "gothram") {
      if (!/^[A-Za-z\s]+$/.test(e.target.value)) {
        setGotharamer("Please enter only letters (A-Z, a-z)");
      } else {
        setGotharamer("");
      }
    }
    setHoroscope({ ...horoscope, [e.target.name]: e.target.value });
  };
  return (
    <div>
      <section
        id="post-section"
        className="post-section av-py-default"
        style={{
          backgroundColor: "#fff",
          backgroundImage: "url('/assets/images/register.jpeg')",
        }}
      >
        <div className="av-container">
          <div className="av-columns-area">
            <div className="av-column-12 mx-auto">
              <div id="comments" className="comments-area">
                <div id="respond" className="comment-respond">
                  <form onSubmit={handleSubmit}>
                    {/* <!-- Email input --> */}
                    <h3 id="reply-title" className="comment-reply-title">
                      Lets Generate Horoscope Automatically{" "}
                      <small>
                        <a
                          rel="nofollow"
                          id="cancel-comment-reply-link"
                          href="#"
                          style={{ display: "none" }}
                        >
                          Cancel reply
                        </a>
                      </small>
                    </h3>
                    <div className="form-outline mb-4">
                      <label>
                        Enter Your Zodiac Sign{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        name="zodiacSign"
                        onChange={handleChange}
                        required
                        id="zordicSign"
                      >
                        <option value="Choose Profile">Select Rasi</option>
                        <option value="Mesham">Mesham</option>
                        <option value="Rishabam">Rishabam</option>
                        <option value="Midhunam">Midhunam</option>
                        <option value="Kadagam">Kadagam</option>
                        <option value="Simmam">Simmam</option>
                        <option value="Thiruvadhirai">Thiruvadhirai</option>
                        <option value="Kanni">Kanni</option>
                        <option value="Thulam">Thulam</option>
                        <option value="Viruchigam">Viruchigam</option>
                        <option value="Dhanus">Dhanus</option>
                        <option value="Magaram">Magaram</option>
                        <option value="Kumbam">Kumbam</option>
                        <option value="Meenam">Meenam</option>
                      </select>
                    </div>
                    <div className="form-outline mb-4">
                      <label>
                        Enter Your Zodiac Star{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        name="zodiacStar"
                        onChange={handleChange}
                        required
                        id="zodiacStar"
                      >
                        <option value="Choose Gender">Select Star</option>
                        <option value="Ashwini">Ashwini</option>
                        <option value="Barani">Barani</option>
                        <option value="Krithikai">Krithikai</option>
                        <option value="Rohini">Rohini</option>
                        <option value="Mirugasiridam">Mirugasiridam</option>
                        <option value="Thiruvadhirai">Thiruvadhirai</option>
                        <option value="Punarpoosam">Punarpoosam</option>
                        <option value="Poosam">Poosam</option>
                        <option value="Ayilyam">Ayilyam</option>
                        <option value="Magam">Magam</option>
                        <option value="Pooram">Pooram</option>
                        <option value="Rohini">Rohini</option>
                        <option value="Poosam">Poosam</option>
                        <option value="Uthiram">Uthiram</option>
                        <option value="Astham">Astham</option>
                        <option value="Chithirai">Chithirai</option>
                        <option value="Swathi">Swathi</option>
                        <option value="Visagam">Visagam</option>
                        <option value="Anusham">Anusham</option>
                        <option value="Kettai">Kettai</option>
                        <option value="Mulam">Mulam</option>
                        <option value="Puradam">Puradam</option>
                        <option value="Uthiradam">Uthiradam</option>
                        <option value="Thiruvonam">Thiruvonam</option>
                        <option value="Avittam">Avittam</option>
                        <option value="Sadayam">Sadayam</option>
                        <option value="Purattadhi">Purattadhi</option>
                        <option value="Uttrttadhi">Uttrttadhi</option>
                        <option value="Revathi">Revathi</option>
                      </select>
                    </div>
                    <div className="form-outline mb-4">
                      <label>
                        Enter Your Padham{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        name="padham"
                        onChange={handleChange}
                        required
                        id="padham"
                      >
                        <option value="Choose Profile">Select Padham</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select>
                    </div>
                    <div className="form-outline mb-4">
                      <label>
                        Enter Your Place of Birth{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        name="placeOfBirth"
                        onChange={handleChange}
                        required
                        id="placeOfBirth"
                        placeholder="Place of Birth"
                        pattern="[A-Za-z\s]+"
                        title="Please enter only letters (A-Z, a-z)"
                        className={`form-control mt-3 ${
                          place ? "is-invalid" : ""
                        }`}
                      />
                      {place && (
                        <div
                          className="invalid-feedback"
                          style={{ color: "red" }}
                        >
                          {place}
                        </div>
                      )}
                    </div>

                    <div
                      className="form-outline mb-4"
                      style={{ backgroundColor: "#f5f6fa" }}
                    >
                      <label>
                        Enter Your Time of Birth{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="time"
                        name="timeOfBirth"
                        onChange={handleChange}
                        id="timeOfBirth"
                        required
                        className="form-control"
                        placeholder="Time of Birth"
                      />
                    </div>
                    <div className="form-outline mb-4">
                      <label>
                        Enter Your Date of Birth{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="date"
                        name="dateOfBirth"
                        onChange={handleChange}
                        required
                        id="dateOfBirth"
                        className="form-control"
                        placeholder="Dob"
                      />
                    </div>
                    <div className="form-outline mb-4">
                      <label>
                        Choose Your Rahu/Kethu{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        name="rahuKetu"
                        required
                        onChange={handleChange}
                        id="rahuKetu"
                      >
                        <option value="Choose Rahu/kethu">
                          Select Rahu/Kethu
                        </option>
                        <option value="raghu">Raghu Dhosam</option>
                        <option value="kethu">Kethu Dhosam</option>
                        <option value="raghuKethu">Ragu-Kethu Dhosam</option>
                        <option value="raKePari">
                          Ragu-Kethu Dhosam Parikaram Seiyapattathu
                        </option>
                        <option value="sevvai">Sevvai Dhosam</option>
                        <option value="sevvaiPari">
                          Sevvai Dhosam Parikaram Seiyapattathu
                        </option>
                        <option value="noDhosam">Dhasam Illai</option>
                        <option value="sutha">Suththa Jathagam</option>
                      </select>
                    </div>
                    <div className="form-outline mb-4">
                      <label>
                        Enter Your Select Lagnam{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        name="selectLagnam"
                        onChange={handleChange}
                        required
                        id="selectLagnam"
                      >
                        <option value="Choose Profile">Select Lagnam</option>
                        <option value="Mesham">Mesham</option>
                        <option value="Rishabam">Rishabam</option>
                        <option value="Midhunam">Midhunam</option>
                        <option value="Kadagam">Kadagam</option>
                        <option value="Simmam">Simmam</option>
                        <option value="Thiruvadhirai">Thiruvadhirai</option>
                        <option value="Kanni">Kanni</option>
                        <option value="Thulam">Thulam</option>
                        <option value="Viruchigam">Viruchigam</option>
                        <option value="Dhanus">Dhanus</option>
                        <option value="Magaram">Magaram</option>
                        <option value="Kumbam">Kumbam</option>
                        <option value="Meenam">Meenam</option>
                      </select>
                    </div>
                    <div className="form-outline mb-4">
                      <label>
                        Enter Your Madhulam{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        name="madhulam"
                        onChange={handleChange}
                        required
                        id="madhulam"
                        className="form-control"
                        placeholder="Madhulam"
                      />
                    </div>
                    <div className="form-outline mb-4">
                      <label>
                        Enter Your Gothram{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        name="gothram"
                        onChange={handleChange}
                        required
                        id="gothram"
                        placeholder="Gothram"
                        pattern="[A-Za-z\s]+"
                        title="Please enter only letters (A-Z, a-z)"
                        className={`form-control mt-3 ${
                          gotharamer ? "is-invalid" : ""
                        }`}
                      />
                      {gotharamer && (
                        <div
                          className="invalid-feedback"
                          style={{ color: "red" }}
                        >
                          {gotharamer}
                        </div>
                      )}
                    </div>

                    {/* <h1 style={{textAlign:"center"}}>OR</h1> */}

                    <div
                      className="av-column-12"
                      style={{ paddingBottom: "1px" }}
                    >
                      <a
                      // href='/register5'
                      >
                        {" "}
                        <button
                          type="submit"
                          className="btn btn-primary btn-block mb-4"
                          style={{ float: "left" }}
                        >
                          Next
                        </button>
                      </a>

                      <a href="/partnerdetail">
                        {" "}
                        <button
                          type="button"
                          className="btn btn-primary btn-block mb-4"
                          style={{ float: "right" }}
                        >
                          Skip
                        </button>
                      </a>
                    </div>
                    {/* <!-- Register buttons --> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Register32;
