import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Usermeta, Usermetadetails } from "../Redux/Action";

const UserMeta = () => {
  const Token = localStorage.getItem("Authtok");
  const dispatch = useDispatch();
  const [load, setLoad] = useState(true);
  const [like, setLike] = useState("");

  useEffect(() => {
    if (Token) {
      dispatch(Usermetadetails()).then((res) => setLoad(false));
    }
  }, [Token]);

  useEffect(() => {
    if (Token) {
      Promise.allSettled([
        dispatch(Usermeta()).then((res) => {
          setLike(res.data.data);
        }),
      ]);
    }
  }, [Token, dispatch]);
  return (
    <div>
      {/* user meta start */}
      {Token && like ? (
        <div
          className="av-column-12"
          style={{ backgroundColor: "#fff", paddingBottom: "50px" }}
        >
          {/* <div className="heading-default heading-white text-left wow fadeInUp">
                            <h3>Why Choose <span className="primary-color">Gradiant?</span></h3>
                            <span className="separator"><span><span></span></span></span>
                            <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        </div> */}
          <div
            className="av-columns-area funfact-wrapper"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="av-column-3 av-sm-column-6">
              <a href="/likelist" style={{ textDecoration: "none" }}>
                <div className="funfact-item">
                  <i className="fa fa-umbrella"></i>
                  <h3>
                    <span className="counter">{like?.profileLikes}</span> +
                  </h3>
                  <p>Profile Likes</p>
                </div>
              </a>
            </div>
            <div className="av-column-3 av-sm-column-6">
              <a href="/visitlist" style={{ textDecoration: "none" }}>
                <div className="funfact-item">
                  <i
                    className="fa fa-hand-peace-o"
                    style={{ fontSize: "44px" }}
                  ></i>
                  <h3>
                    <span className="counter">{like?.profileVisits}</span> +
                  </h3>
                  <p>Profile Visits</p>
                </div>
              </a>
            </div>
            <div className="av-column-3 av-sm-column-6">
              <a href="/interestrec" style={{ textDecoration: "none" }}>
                <div className="funfact-item">
                  <i className="fa fa-smile-o"></i>
                  <h3>
                    <span className="counter">{like?.interestReceived}</span> +
                  </h3>
                  <p>Intrest Received</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      ) : null}
      {/* user meta end */}
    </div>
  );
};

export default UserMeta;
