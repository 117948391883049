import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { personaldetails, getCompletion } from "../Redux/Action";
import Loader from "../Common/Loader";
import Alert from "@mui/material/Alert";
import Swal from "sweetalert2";
import Stack from "@mui/material/Stack";

const Registration2 = () => {
  const [personal, setPersonal] = useState({
    height: "",
    complexion: "",
    bodyType: "",
    physicalStatus: "",
    eyeWear: "",
    aboutMySelf: "",
    hobbies: "",
    weight: "",
    bloodGroup: "",
    eatingHabits: "",
    drinkingHabits: "",
    smokingHabits: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [load, setLoad] = useState(true);
  const [regerror, setRegerror] = useState("");
  // const [details, setDetails] = useState([]);
  const completion = useSelector(({ getCompletion }) => getCompletion.payload);
  // console.log(completion, "completion");

  useEffect(() => {
    dispatch(getCompletion()).then((res) => {
      if (
        res.data.data.userPersonality === "User personality details missing" ||
        res.data.data.Score === 0
      ) {
        setLoad(false);
      } else {
        navigate("/");
        window.location.reload();
      }
    });
  }, []);
  if (completion) {
    if (completion.status === "unauthorized") {
      navigate("/login");
    } else if (completion.status === "failed") {
      navigate("/login");
    }
  }

  const handleRegisterSubmit = (e) => {
    e.preventDefault();
    dispatch(personaldetails(personal))
      .then((res) => {
        setRegerror(res.data.data);
        navigate("/edu&prof");
      })
      .catch((e) => {
        if (e.res && e.res.Error === "UnAuthorized") {
          setRegerror("Please login...!");
          navigate("/login");
        }
      });
  };

  const handleRegisterChange = (e) => {
    if (e.target.name === "height" && e.target.value < 0) {
      return;
    }
    if (e.target.name === "weight" && e.target.value < 0) {
      return;
    }
    setPersonal({ ...personal, [e.target.name]: e.target.value });
  };

  return !completion ? (
    <Loader />
  ) : (
    <div>
      {/* <!--===// Start: registeration2 Form
        =================================--> */}
      <section
        id="post-section"
        className="post-section av-py-default"
        style={{
          backgroundColor: "#fff",
          backgroundImage: "url('/assets/images/register.jpeg')",
        }}
      >
        <div className="av-container">
          <div className="av-columns-area">
            <div className="av-column-12 mx-auto">
              <div id="comments" className="comments-area">
                <div id="respond" className="comment-respond">
                  <form onSubmit={handleRegisterSubmit}>
                    {/* <!-- Email input --> */}
                    <h3 id="reply-title" className="comment-reply-title">
                      Personal Details{" "}
                      <small>
                        <a
                          rel="nofollow"
                          id="cancel-comment-reply-link"
                          href=";"
                          style={{ display: "none" }}
                        >
                          Cancel reply
                        </a>
                      </small>
                    </h3>
                    <div className="form-outline mb-4">
                      <label>
                        Enter Your Height(cm){" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        name="height"
                        placeholder="Height"
                        type="number"
                        value={personal.height}
                        onChange={handleRegisterChange}
                        id="Height"
                        required
                        minLength="2"
                        maxLength="3"
                      />
                    </div>
                    <div className="form-outline mb-4">
                      <label>
                        Enter Your Weight(kg){" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        placeholder="Enter Weight"
                        type="number"
                        name="weight"
                        value={personal.weight}
                        onChange={handleRegisterChange}
                        id="weight"
                        required
                        minLength="2"
                        maxLength="3"
                      />
                    </div>
                    <div className="form-outline mb-4">
                      <label>
                        Enter Your Blood Group{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        name="bloodGroup"
                        required
                        value={personal.bloodGroup}
                        onChange={handleRegisterChange}
                        id="bloodGroup"
                      >
                        <option value="volvo">Select Blood Group</option>
                        <option value="A-">A-</option>
                        <option value="A+">A+</option>
                        <option value="B-">B-</option>
                        <option value="B+">B+</option>
                        <option value="O-">O-</option>
                        <option value="O+">O+</option>
                        <option value="AB-">AB-</option>
                        <option value="AB+">AB+</option>
                      </select>
                    </div>
                    <div className="form-outline mb-4">
                      <label>
                        Choose Your Complexion{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        name="complexion"
                        value={personal.complexion}
                        onChange={handleRegisterChange}
                        required
                        id="complexion"
                      >
                        <option value="Complexion">Select Complexion</option>
                        <option value="very fair">Very Fair</option>
                        <option value="fair">Fair</option>
                        <option value="wheatish">Wheatish</option>
                        <option value="wheatish brownn">Wheatish Brownn</option>
                        <option value="dark">Dark </option>
                      </select>
                    </div>
                    <div className="form-outline mb-4">
                      <label>Choose Your Body Type</label>
                      <select
                        name="bodyType"
                        value={personal.bodyType}
                        onChange={handleRegisterChange}
                        id="bodytype"
                      >
                        <option value="Body Type">Select Body Type</option>
                        <option value="slim">Slim</option>

                        <option value="normal">Average</option>
                        <option value="fat">Fat</option>
                      </select>
                    </div>
                    <div className="form-outline mb-4">
                      <label>
                        Choose Your Physical Status{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        name="physicalStatus"
                        value={personal.physicalStatus}
                        onChange={handleRegisterChange}
                        id="physicalStatus"
                        required
                      >
                        <option value="Physical Status">
                          Select Physical Status
                        </option>
                        <option value="normal">Normal</option>
                        <option value="physically challenged">
                          Physically Challenged
                        </option>
                      </select>
                    </div>
                    <div className="form-outline mb-4">
                      <label>
                        Choose Your Eye Wear{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        name="eyeWear"
                        required
                        value={personal.eyeWear}
                        onChange={handleRegisterChange}
                        id="eyewear"
                      >
                        <option value="Eye Wear">Select Eye Wear</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                    <div className="form-outline mb-4">
                      <label>
                        Choose Your Drinking Habits{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        name="drinkingHabits"
                        value={personal.drinkingHabits}
                        onChange={handleRegisterChange}
                        id="drinkinghabits"
                        required
                      >
                        <option value="Drinking Habits">
                          Select Drinking Habits
                        </option>
                        <option value="Never Drinks">Never Drink</option>
                        <option value="Drinks Socially">
                          Drink Occasionally
                        </option>
                        <option value="Drinks Regularly">
                          Drink Regularly
                        </option>
                        <option value="Not Specified">Not Specified</option>
                      </select>
                    </div>
                    <div className="form-outline mb-4">
                      <label>
                        Choose Your Eating Habits{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        name="eatingHabits"
                        value={personal.eatingHabits}
                        onChange={handleRegisterChange}
                        id="eatinghabits"
                        required
                      >
                        <option value="Eating Habits">
                          Select Eating Habits
                        </option>
                        <option value="Non-Vegetarian">Non-Vegetarian</option>
                        <option value="Vegetarian">Vegetarian</option>
                        <option value="Eggetarian">Eggetarian</option>
                        <option value="Not Specified">Not Specified</option>
                      </select>
                    </div>
                    <div className="form-outline mb-4">
                      <label>
                        Choose Your Smoking Habits{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        name="smokingHabits"
                        value={personal.smokingHabits}
                        onChange={handleRegisterChange}
                        id="smokinghabits"
                        required
                      >
                        <option value="Smoking Habits">
                          Select Smoking Habits
                        </option>
                        <option value="Never Smokes">Never Smokes</option>
                        <option value="Smokes Occasionally">
                          Smoke Occasionally
                        </option>
                        <option value="Smokes Regularly">
                          Smoke Regularly
                        </option>
                        <option value="Not Specified">Not Specified</option>
                      </select>
                    </div>
                    <div className="form-outline mb-4">
                      <label>
                        Share about myself{" "}
                        <span style={{ color: "red" }}>*</span>{" "}
                      </label>
                      <span className="wpcf7-form-control-wrap your-message">
                        <textarea
                          placeholder="About Myself"
                          name="aboutMySelf"
                          required
                          value={personal.aboutMySelf}
                          onChange={handleRegisterChange}
                          cols="40"
                          rows="6"
                          className="wpcf7-form-control wpcf7-textarea"
                          aria-invalid="false"
                          maxLength={255}
                        ></textarea>
                      </span>
                    </div>
                    <div className="form-outline mb-4">
                      <label>
                        <span className="wpcf7-form-control-wrap your-message">
                          <label>Enter Your Hobbies</label>
                          <textarea
                            placeholder="Hobbies"
                            name="hobbies"
                            value={personal.hobbies}
                            onChange={handleRegisterChange}
                            cols="3"
                            rows="3"
                            className="wpcf7-form-control wpcf7-textarea"
                            aria-invalid="false"
                            maxLength={255}
                          ></textarea>
                        </span>
                      </label>
                    </div>

                    <Stack sx={{ width: "100%" }} spacing={2}>
                      {regerror ? (
                        <Alert autoHideDuration={6000} severity="error">
                          {regerror}
                        </Alert>
                      ) : (
                        false
                      )}
                    </Stack>
                    <div
                      className="av-column-12"
                      style={{ paddingBottom: "1px" }}
                    >
                      {" "}
                      <button
                        type="submit"
                        className="btn btn-primary btn-block mb-4"
                        style={{ float: "left" }}
                      >
                        Next
                      </button>
                      {/* <a href='/register3'> <button
                      type="button"
                      className="btn btn-primary btn-block mb-4"
                      style={{float:"right"}}
                    >
                      Skip
                    </button></a> */}
                    </div>
                    {/* <!-- Register buttons --> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: registration2 Form
        =================================--> */}
    </div>
  );
};

export default Registration2;
