import React from "react";
import { useState, useEffect } from "react";
import { successstory, advertisement } from "../Redux/Action";
import { useDispatch, useSelector } from "react-redux";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Skeleton } from "../Common/Skeleton";
import MdPhone from "@mui/icons-material/Phone";

const SuccessStory = () => {
  const [load, setLoad] = useState(true);
  const dispatch = useDispatch();
  const successTestimonial = useSelector(
    ({ successstory }) => successstory.payload
  );
  const ads = useSelector(({ advertisement }) => advertisement.payload);

  useEffect(() => {
    window.scrollTo(0, 0);
    Promise.allSettled([
      dispatch(advertisement()),
      dispatch(successstory()),
    ]).then((res) => setLoad(false));
  }, []);

  const Truncate = (string, number) => {
    if (!string) {
      return null;
    }
    if (string.length <= number) {
      return string;
    }
    return string.slice(0, number) + "...";
  };

  return (
    <div>
      {/* <!--===// Start: success story
        =================================--> */}

      <section
        id="post-section"
        className="post-section av-py-default blog-home shapes-section"
        style={{ paddingBottom: "0px" }}
      >
        {successTestimonial === "Success story not found!" ? null : (
          <div className="av-container">
            <div className="av-columns-area">
              <div className="av-column-12">
                <div className="heading-default text-center wow fadeInUp">
                  <h3>
                    Happy<span className="primary-color"> Stories</span>
                  </h3>
                  <span className="separator">
                    <span>
                      <span></span>
                    </span>
                  </span>
                  {/* <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p> */}
                </div>
              </div>
            </div>

            {Array.isArray(successTestimonial) &&
            successTestimonial.length !== 0 ? (
              <OwlCarousel
                className="owl-theme"
                loop
                margin={10}
                nav
                responsive={{
                  0: {
                    items: 1,
                  },
                  600: {
                    items: 2, 
                  },
                  1000: {
                    items: 3, 
                  },
                }}
              >
                {successTestimonial.map((x) => (
                  <div className="items" key={x.id}>
                    <div className="av-column-12 av-sm-column-8 av-filter-item all year2000 year2010 year2015 year2020">
                      <figure className="portfolio-item ">
                        <div className="portfolio">
                          <div className="portfolio" tabIndex="0">
                            <div className="portfolio-img">
                              <img
                                src={process.env.REACT_APP_URL + x.image}
                                alt="Bride and Groom at VVK matrimonial"
                                style={{ aspectRatio: "1/1",objectFit:"cover" }}
                              />
                            </div>
                            <div className="portfolio-thumber">
                              <div className="portfolio-thumb-content">
                                {/* <a
                                          href={process.env.REACT_APP_URL + x.image}
                                          className="image"
                                          title="VVK matrimonial Bride and Groom photo"
                                        >
                                          <i className="fa fa-search-plus"></i>
                                        </a> */}
                              </div>
                            </div>
                          </div>
                          <div className="portfolio-caption">
                            <h6 style={{ textTransform: "capitalize" }}>
                              {x.brideName} & {x.groomName}
                            </h6>
                          </div>
                        </div>
                      </figure>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            ) : (
              false
            )}

            {/* <div className='item'>
        <h4>2</h4>
    </div>
    <div className='item'>
        <h4>3</h4>
    </div>
    <div className='item'>
        <h4>4</h4>
    </div> */}
          </div>
        )}
      
      </section>
      {/* <!-- End: success story
        =================================--> */}

      {/* <!--===// Start: our services
        =================================--> */}
      <section
        id="team-section"
        className="team-section av-py-default team-home shapes-section"
        style={{ backgroundColor: "#fff", paddingBottom: "80px" }}
      >
        <div className="av-container">
          {ads === "Data not found!" ? null : (
            <div className="av-columns-area">
              <div className="av-column-12">
                <div className="heading-default text-center wow fadeInUp">
                  <h3>
                    <span className="primary-color">Advertisement</span>
                  </h3>
                  <span className="separator">
                    <span>
                      <span></span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          )}
          <div className="av-columns-area">
            {Array.isArray(ads) && ads.length !== 0 ? (
              ads.map((x) => (
                <div className="av-column-3 av-sm-column-6">
                  <a style={{ textDecoration: "none" }}>
                    <div className="team-member">
                      <div className="team-thumb" tabIndex="0">
                        <a>
                          <img
                            src={process.env.REACT_APP_URL + x.image}
                            alt="team_item"
                            title=""
                            style={{ aspectRatio: "1/1",objectFit:"cover" }}
                          />
                        </a>
                        <div
                          className="team-thumber"
                          style={{ cursor: "pointer" }}
                        >
                          <aside className="widget widget_social_widget">
                            <a href="tel:{x.phone}" className="contact-info">
                              <div
                                className="cards-title"
                                style={{ color: "#fff" }}
                              >
                                <MdPhone style={{ color: "#fff" }} />
                              </div>
                            </a>
                          </aside>
                        </div>
                      </div>
                      <div className="team-info">
                        <a href={x.link} target="_blank">
                          {x.name}

                          <p>{x.contactPerson}</p>
                        </a>
                      </div>
                    </div>
                  </a>
                </div>
              ))
            ) : (
              <Skeleton />
            )}
          </div>
        </div>
 
      </section>
      {/* <!-- End: our services
        =================================--> */}
    </div>
  );
};

export default SuccessStory;
