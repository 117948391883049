import React from "react";
import { AiFillEdit } from "react-icons/ai";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaDownload } from "react-icons/fa";
import { Form } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Allprofilelist,
  profilelist,
  Updateimage,
  getpersonaldetails,
  getCompletion,
  getfamilydet,
  horolist,
  // profileuser,
  Updatehoroscopedet,
  getprofession,
  Updateprofileuser,
  getpartnerperf,
  Updatepersonaldetails,
  Updateprofession,
  DeleteAccount,
  Updatefamilydet,
  Updatepartnerperf,
  BirthMatches,
  BirthChart,
  Document,
  Subcription,
} from "../Redux/Action";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { IoMdClose } from "react-icons/io";
import FormControlLabel from "@mui/material/FormControlLabel";
import Swal from "sweetalert2";
import Tooltip from "@mui/material/Tooltip";
import "./profile.css";
import "./BirthDetails";
import Loader from "../Common/Loader";
import { emphasize, styled } from "@mui/material/styles";
import ProgressBar from "@ramonak/react-progress-bar";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import { profile } from "../Redux/ActionType";
import { setHeaderToken } from "../Axios/Service";
import moment from "moment";
import { BiBlock } from "react-icons/bi";
import Button from "@mui/material/Button";
import Buttons from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";

const casteOptions = [
  "24 Manai Telugu Chettiar",
  " Aaru Nattu Vellala",
  " Achirapakkam Chettiar",
  " Adi Dravidar",
  " Agamudayar / Arcot / Thuluva Vellala",
  "Agaram Vellan Chettiar",
  "Ahirwar",
  "Arunthathiyar",
  "Ayira Vysya",
  "Badaga",
  "Bairwa",
  "Balai",
  "Beri Chettiar",
  "Boyar",
  "Brahmin - Anaviln Desai",
  "Brahmin - Baidhiki/Vaidhiki",
  "Brahmin - Bardai",
  "Brahmin - Bhargav",
  "Brahmin - Gurukkal",
  "Brahmin - Iyengar",
  "Brahmin - lyer",
  "Brahmin - Khadayata",
  "Brahmin - Khedaval",
  "Brahmin - Mevada",
  "Brahmin - Others",
  "Brahmin - Rajgor",
  "Brahmin - Rarhi/Radhi",
  "Brahmin - Sarua",
  "Brahmin - Shri Gaud",
  "Brahmin - Tapodhan",
  "Brahmin - Valam",
  "Brahmin - Zalora",
  "Chattada Sri Vaishnava",
  "Cherakula Vellalar",
  "Chettiar",
  "Dasapalanjika / Kannada Saineegar",
  "Desikar",
  "Desikar Thanjavur",
  "Devandra Kula Vellalar",
  "Devanga Chettiar",
  "Devar/Thevar/Mukkulathor",
  "Dhanak",
  "Elur Chetty",
  "Gandla / Ganiga",
  "Gounder",
  "Gounder - Kongu Vellala Gounder",
  "Gounder - Nattu Gounder",
  "Gounder - Others -",
  "Gounder - Urali Gounder",
  "Gounder - Vanniya Kula Kshatriyar",
  "Gounder - Vettuva Gounder",
  "Gramani",
  "Gurukkal Brahmin",
  "Illaththu Pillai",
  "Intercaste",
  "Isai Vellalar",
  "Iyengar Brahmin",
  "Iyer Brahmin",
  "Julaha",
  "Kamma Naidu",
  "Kanakkan Padanna",
  "Kandara",
  "Karkathar",
  "Karuneegar",
  "Kasukara",
  "Kerala Mudali",
  "Khatik",
  "Kodikal Pillai",
  "Kongu Chettiar",
  "Kongu Nadar",
  "Kongu Vellala Gounder",
  "Kori/Koli",
  "Krishnavaka",
  "Kshatriya Raju",
  "Kulalar",
  "Kuravan",
  "Kuruhini Chetty",
  "Kurumbar",
  "Kuruva",
  "Manjapudur Chettiar",
  "Mannan/Velan / Vannan",
  "Maruthuvar",
  "Meenavar",
  "Meghwal",
  "Mudaliyar",
  "Mukkulathor",
  "Muthuraja / Mutharaiyar",
  "Nadar",
  "Naicker",
  "Naicker - Others",
  "Naicker - Vanniya Kula Kshatriyar",
  "Naidu",
  "Nanjil Mudali",
  "Nanjil Nattu Vellalar",
  "Nanjil Vellalar",
  "Nanjil pillai",
  "Nankudi Vellalar",
  "Nattu Gounder",
  "Nattukottai Chettiar",
  "Othuvaar",
  "Padmashali",
  "Pallan / Devandra Kula Vella",
  "Panan",
  "Pandaram",
  "Pandiya Vellalar",
  "Pannirandam Chettiar",
  "Paravan / Bharatar",
  "Parkavakulam / Udayar",
  "Parvatha Rajakulam",
  "Paswan / Dusadh",
  "Pattinavar",
  "Pattusali",
  "Pillai",
  "Poundra",
  "Pulaya/Cheruman",
  "Reddy",
  "Rohit / Chamar",
  "SC",
  "ST",
  "Sadhu Chetty",
  "Saiva Pillai Thanjavur",
  "Saiva Pillai Tirunelveli",
  "Saiva Vellan chettiar",
  "Saliyar",
  "Samagar",
  "Sambava",
  "Satnami",
  "Senai Thalaivar",
  "Senguntha Mudaliyar",
  "Sengunthar/Kaikolar",
  "Shilpkar",
  "Sonkar",
  "Sourashtra",
  "Sozhia Chetty",
  "Sozhiya Vellalar",
  "Telugupatti",
  "Thandan",
  "Thondai Mandala Vellalar",
  "Urali Gounder",
  "Vadambar",
  "Vadugan",
  "Valluvan",
  "Vaniya Chettiar",
  "Vannar",
  "Vannia Kula Kshatriyar",
  "Veera Saivam",
  "Veerakodi Vellala",
  "Vellalar",
  " Vellan Chettiars",
  "Vettuva Gounder",
  "Vishwakarma",
  "Vokkaliga",
  " Yadav",
  " Yadava Naidu",
];

const subCasteOptions = {
  "Brahmin - Iyengar": [
    "Thenkalai",
    "Vadakalai",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
  ],
  "Brahmin - lyer": [
    "Ashtasahasram",
    "Brahacharanam",
    "Vadama",
    "Vathima",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
  ],
  "Brahmin - Others": [
    "Brahmin - Anavil",
    "Brahmin - Anaviln Desai",
    "Brahmin - Audichya",
    "Brahmin - Khedaval",
    "Bardai",
    "Bhargav",
    "Brahmin - Gaur",
    "Khadayata",
    "Mevada",
    "Modh",
    "Nagar",
    "Rajgor",
    "Brahmin - Saraswat",
    "Brahmin - Shri Gaud",
    "Shri Mali",
    "Tapodhan",
    "Valam",
    "Zalora",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
    "Barendra",
    "Panda",
    "Rarhi/Radhi",
    "Danua",
    "Halua",
    "Jhadua",
    "Sarua",
  ],
  Chettiar: [
    "24 Manai Telugu Chettiar",
    "24 Manai Telugu Chettiar 16 Veedu",
    "24 Manai Telugu Chettiar 8 Veedu",
    "Achirapakkam Chettiar",
    "Agaram Vellan Chettiar",
    "Ayira Vysya",
    "Beri Chettiar",
    "Devanga Chettiar",
    "Elur",
    "Gandla / Ganiga",
    "Kasukara",
    "Arya Vysya",
    "Kongu Chettiar",
    "Kuruhini Chetty",
    "Manjapudur Chettiar",
    "Nattukottai Chettiar",
    "Padma Saliar",
    "Pannirandam Chettiar",
    "Parvatha Rajakulam",
    "Pattinavar",
    "Pattusali",
    "Sadhu Chetty",
    "Senai Thalaivar",
    "Sozhia Chetty",
    "Telugupatti",
    "Vadambar",
    "Vaniya Chettiar",
    "Vellan Chettiars",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
  ],
  "Devar/Thevar/Mukkulathor": [
    "Agamudayar",
    "Ambalakarar",
    "Appanad Kondayamkottai Maravar",
    "Easanattu Kallar",
    "Kallar",
    "Maniyakarar",
    "Maravar",
    "Piramalai Kallar",
    "Rajakula Agamudayar",
    "Sembanad Maravar",
    "Servai",
    "Thanjavur Kallar",
    "Vallambar",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
  ],
  Gounder: [
    "Gounder - Kongu Vellala Gounder",
    "Gounder - Urali Gounder",
    "Gounder - Vanniya Kula Kshatriyar",
    "Gounder - Vettuva Gounder",
    "Nattu Gounder",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
  ],
  "Iyengar Brahmin": [
    "Thenkalai",
    "Vadakalai",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
  ],
  "Iyer Brahmin": [
    "Ashtasahasram",
    "Brahacharanam",
    "Vadama",
    "Vathima",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
  ],
  "Kongu Vellala Gounder": [
    "Aadai",
    "Aadhi",
    "Aanthai",
    "Aavan",
    "Alagan",
    "Andai",
    "Andhuvan",
    "Cheran",
    "Devendran",
    "Eenjan",
    "Ennai",
    "Kaadai",
    "Kaari",
    "Kanakkan",
    "Kannan",
    "Kannandhai",
    "Keeran",
    "Koorai",
    "Koventhar",
    "Kuzhlaayan",
    "Maadai",
    "Maniyan",
    "Medhi",
    "Muthan",
    "Muzhlukkadhan",
    "Nattu Gounder",
    "Odhaalar",
    "Paandian",
    "Padaithalaiyan",
    "Panaiyan",
    "Panangadai",
    "Pannai",
    "Pannan",
    "Pavalan",
    "Payiran",
    "Perunkudi",
    "Pillan",
    "Podiyan",
    "Ponnan",
    "Poochadhai Poodhiyan",
    "Poosan",
    "Perizhanthan",
    "Sathandhai",
    "Sedan",
    "Sellan",
    "Sempoothan",
    "Sengannan",
    "Sengunni",
    "Seralan",
    "Sevadi",
    "Thodai",
    "Thooran",
    "Vannakkan",
    "Veliyan",
    "Vellamban",
    "Venduvan",
    "Viliyan",
    "Villi",
  ],
  Mudaliyar: [
    "Agamudayar/Arcot/Thuluva Vellala",
    "Isai Vellalar",
    "Kerala Mudali",
    "Kongu Vellala Gounder",
    "Saiva Pillai Tirunelveli",
    "Sengunthar/Kaikolar",
    "Sozhiya Vellalar",
    "Thondai Mandala Vellala",
    "Veerakodi Vellala",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
  ],
  Mukkulathor: [
    "Mukkulathor",
    "Agamudayar",
    "Ambalakarar",
    "Appanad Kondayamkottai Maravar",
    "Easanattu Kallar",
    "Kallar",
    "Maniyakarar",
    "Maravar",
    "Piramalai Kallar",
    "Rajakula Agamudayar",
    "Sembanad Maravar",
    "Servai",
    "Thanjavur Kallar",
    "Vallambar",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
  ],
  Nadar: [
    "Gramani/Sanar",
    "Kongu Nadar",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
  ],
  Naidu: [
    "Balija/Balija Naidu",
    "Ediga/Goud",
    "Gajula",
    "Gavara",
    "Kamma",
    "Kapu",
    "Munnuru Kapu",
    "Mutharaja",
    "Naidu",
    "Perika",
    "Raja Kambalathu Naicker",
    "Reddy",
    "Shetty Balija",
    "Surya Balija",
    "Telaga",
    "Turupu Kapu",
    "Vada Balija",
    "Vadugan",
    "Velama",
    "Yadava",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
  ],
  Pillai: [
    "Aaru Nattu Vellala",
    "Agamudayar/Arcot/Thuluva Vellala",
    "Cherakula Vellalar",
    "Desikar",
    "Desikar Thanjavur",
    "Illaththu Pillai",
    "Isai Vellalar",
    "Karkathar",
    "Kodikal Pillai",
    "Nanjil Mudali",
    "Nanjil pillai",
    "Nankudi Vellalar",
    "Othuvaar",
    "Pandiya Vellalar",
    "Saiva Pillai Thanjavur",
    "Saiva Pillai Tirunelveli",
    "Sengunthar/Kaikolar",
    "Sozhiya Vellalar",
    "Thondai Mandala Vellala",
    "Veerakodi Vellala",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
  ],
  Reddy: [
    "Ayodhi",
    "Chowdary",
    "Desuru",
    "Ganjam",
    "Gone Kapu",
    "Gudati",
    "Kapu",
    "Motati",
    "Palle",
    "Panta",
    "Pedakanti",
    "Poknati",
    "Reddiyar",
    "Vanni",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
    "Bhoomanchi Reddy",
    "Gandla",
    "Palnati",
    "Sajjana",
    "Velanati",
  ],
  Vellalar: [
    "Aaru Nattu Vellala",
    "Agamudayar/Arcot/Thuluva Vellala",
    "Cherakula Vellalar",
    "Desikar",
    "Desikar Thanjavur",
    "Illaththu Pillai",
    "Isai Vellalar",
    "Karkathar",
    "Kodikal Pillai",
    "Nanjil Mudali",
    "Nanjil pillai",
    "Nankudi Vellalar",
    "Othuvaar",
    "Pandiya Vellalar",
    "Saiva Pillai Thanjavur",
    "Saiva Pillai Tirunelveli",
    "Sengunthar/Kaikolar",
    "Sozhiya Vellalar",
    "Thondai Mandala Vellala",
    "Veerakodi Vellala",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
  ],
  Vishwakarma: [
    "Black Smith",
    "Carpentry (Vadrangi, Vadla)",
    "Goldsmiths",
    "Sculptor (Shilpi)",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
  ],
  Yadav: [
    "Doddi",
    "Erragolla",
    "Karna",
    "Kuruma",
    "Pakanati",
    "Puja",
    "Yadava Golla",
    "Ala Golla",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
    "Aheer/Ahir",
    "Dadhor",
    "Das",
    "Gadri/Gadariya",
    "Gawli",
    "Goala/Gola/Golla",
    "Gop/Gopal/Gopala",
    "Goriya",
    "Gouda",
    "Gwala",
    "Gwalvanshi",
    "Jadav",
    "Konar",
    "Krishnauth",
    "Kurubas/Gollas",
    "Mandal",
    "Manjrauth",
    "Nandavanshi",
    "Raut",
    "Suryavanshi",
    "Thethwar",
    "Yadav",
    "Yaduvanshi",
  ],
};
const Profile = () => {
  const [viewprofile, setViewprofile] = useState("");
  const [toggle, setToggle] = useState(false);
  const [pencilClicked, setPencilClicked] = useState(false);
  const [load, setLoad] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const Cid = id;

  const list = useSelector(({ Allprofilelist }) => Allprofilelist.payload);

  const docs = useSelector(({ Document }) => Document.payload);

  const basicAdd = useSelector(({ getpartnerperf }) => getpartnerperf.payload);
  // caste sub caste
  const subCasteOptionsLowercase = {};
  for (const key in subCasteOptions) {
    if (subCasteOptions.hasOwnProperty(key)) {
      const options = subCasteOptions[key].map((option) => {
        return option.charAt(0).toLowerCase() + option.slice(1);
      });
      subCasteOptionsLowercase[key] = options;
    }
  }
  const lowercaseCasteOptions = casteOptions.map((option) =>
    option.toLowerCase()
  );

  //preference functionality
  const [open, setOpen] = React.useState(false);
  const [del, setDel] = React.useState(false);

  const [scroll, setScroll] = React.useState("paper");
  const [prefaddress, setPrefaddress] = useState("");
  const [opens, setOpens] = useState(false);

  const handleOpen = () => {
    setOpens(true);
  };

  const handleClosed = () => {
    setOpens(false);
  };
  const handlePencilClick = () => {
    setPencilClicked(true);
  };
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleDeleteOpen = (scrollType) => () => {
    setDel(true);
    setScroll(scrollType);
  };
  const handleDel = () => {
    setDel(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleprefChange = (e) => {
    if (e.target.name === "age" && e.target.value < 0) {
      return;
    }
    if (e.target.name === "height" && e.target.value < 0) {
      return;
    }
    setPrefaddress({ ...prefaddress, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    dispatch(getpartnerperf()).then((res) => {
      setPrefaddress(res.data.data);
    });
  }, []);

  const handleSUbmit = () => {
    setOpen(false);
    dispatch(Updatepartnerperf(prefaddress)).then((res) => {
      Swal.fire(res.data.data);
    });
  };
  const handleDelete = () => {
    setDel(false);
    dispatch(DeleteAccount()).then((res) => {
      Swal.fire(res.data.data);
      navigate("/login");
    });
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  // family functionality
  const [familyopen, setFamilyopen] = React.useState(false);
  const [familyscroll, setFamilyscroll] = React.useState();
  const [familyadd, setFamilyadd] = useState("");

  const handleClickFamilyOpen = (scrollType) => () => {
    setFamilyopen(true);
    setFamilyscroll(scrollType);
  };

  const [horoscopeopen, setHoroscopeopen] = React.useState(false);
  const [horoscopescroll, setHoroscopescroll] = React.useState();
  const [horoscopeadd, setHoroscopeadd] = useState("");
  const handleClickHoroscopeOpen = (scrollType) => () => {
    setHoroscopeopen(true);
    setHoroscopescroll(scrollType);
  };
  useEffect(() => {
    dispatch(horolist()).then((res) => {
      setHoroscopeadd(res.data.data);
    });
  }, []);

  useEffect(() => {
    dispatch(getfamilydet()).then((res) => {
      setFamilyadd(res.data.data);
    });
  }, []);

  const handlefamChange = (e) => {
    setFamilyadd({ ...familyadd, [e.target.name]: e.target.value });
  };
  const handlehoroChange = (e) => {
    setHoroscopeadd({ ...horoscopeadd, [e.target.name]: e.target.value });
  };

  const handleFamilyClose = () => {
    setFamilyopen(false);
    dispatch(Updatefamilydet(familyadd)).then((res) => {
      Swal.fire(res.data.data);
    });
  };
  const handleHoroscopeClose = () => {
    setHoroscopeopen(false);
    dispatch(Updatehoroscopedet(horoscopeadd)).then((res) => {
      Swal.fire(res.data.data);
    });
  };
  const familydescriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (familyopen) {
      const { current: descriptionElement } = familydescriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [familyopen]);
  const horoscopedescriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (horoscopeopen) {
      const { current: descriptionElement } = horoscopedescriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [familyopen]);
  //basic preference
  const handleChange = (e) => {
    if (e.target.name === "age" && e.target.value < 0) {
      return;
    }
    setBasicaddress({ ...basicaddress, [e.target.name]: e.target.value });
  };

  const [basicaddress, setBasicaddress] = useState("");

  useEffect(() => {
    dispatch(profilelist()).then((res) => {
      setBasicaddress(res.data.data);
    });
  }, []);
  const [basicopen, setBasicopen] = React.useState(false);
  const [basicscroll, setBasicscroll] = React.useState();

  const handleClickBasicOpen = (scrollType) => () => {
    setBasicopen(true);
    setBasicscroll(scrollType);
  };
  const handleBasicClose = () => {
    setBasicopen(false);
  };
  const [birthopen, setBirthopen] = React.useState(false);
  const handlebirthdetailOpen = (scrollType) => () => {
    setBirthopen(true);
    setBasicscroll(scrollType);
  };
  const handleBirthdetailClose = () => {
    setBirthopen(false);
  };
  const [birthchart, setBirthchart] = React.useState(false);
  const handlebirthchartOpen = (scrollType) => () => {
    setBirthchart(true);
    setBasicscroll(scrollType);
  };
  const handleBirthchartClose = () => {
    setBirthchart(false);
  };
  const handleBasicSubmit = () => {
    setBasicopen(false);
    dispatch(Updateprofileuser(basicaddress)).then((res) => {
      Swal.fire(res.data.data);
      dispatch(Allprofilelist())
        .then((res) => setLoad(false))
        .catch((e) => {});
    });
  };

  const basicdescriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (basicopen) {
      const { current: descriptionElement } = basicdescriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [basicopen]);

  // personal functionality
  const [personalopen, setPersonalopen] = React.useState(false);
  const [perosnalscroll, setPersonalscroll] = React.useState();

  const [personaladd, setPersonaladd] = useState();
  useEffect(() => {
    dispatch(getpersonaldetails()).then((res) => {
      setPersonaladd(res.data.data);
    });
  }, []);

  const handlepersChange = (e) => {
    if (e.target.name === "height" && e.target.value < 0) {
      return;
    }
    if (e.target.name === "weight" && e.target.value < 0) {
      return;
    }
    setPersonaladd({ ...personaladd, [e.target.name]: e.target.value });
  };
  const [birthdetail, setBirthDetail] = useState("");
  const [ayanamsa, setAyanamsa] = useState("");
  const [la, setLa] = useState("");
  const [datetime, setDatetime] = useState("");
  const [city, setCity] = useState("");
  const [coordinates, setCoordinates] = useState(null);

  const handleInputChange = (event) => {
    const newCity = event.target.value;

    setCity(newCity);

    const API_KEY = "fcbb9e747bd746efa6925c27abab6962";
    const API_URL = `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(
      newCity
    )}&key=${API_KEY}`;

    axios
      .get(API_URL)
      .then((response) => {
        const { lat, lng } = response.data.results[0].geometry;
        setCoordinates(
          `${parseFloat(lat).toFixed(2)},${parseFloat(lng).toFixed(2)}`
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const convertedDatetime = moment(datetime).toISOString();
  const handleBirthdetailSubmit = () => {
    setBirthopen(false);
    dispatch(
      BirthMatches({
        ayanamsa: ayanamsa,
        coordinates: coordinates,
        datetime: convertedDatetime,
        la: la,
      })
    ).then((res) => {
      if (res.data.data.message === "Request failed with status code 400") {
        Swal.fire({
          title: "Too many requests!",
          text: "Please Try Again!",
          icon: "warning",
          confirmButtonText: "OK",
        });
        navigate("/myprofile");
      } else {
        setBirthDetail(res.data.data);
        navigate("/birthdetail", { state: { birthdetail: res.data.data } });
      }
    });
  };

  const [birthcharts, setBirthcharts] = useState("");
  const [ayanamsaChart, setAyanamsaChart] = useState("");
  const [laChart, setLaChart] = useState("");
  const [datetimeChart, setDatetimeChart] = useState("");
  const [cityChart, setCityChart] = useState("");
  const [chartstyle, setChartstyle] = useState("");
  const [charttype, setCharttype] = useState("");
  const [coordinatesChart, setCoordinatesChart] = useState(null);
  const handleInputChangeChart = (event) => {
    const newCity = event.target.value;

    setCityChart(newCity);

    const API_KEY = "fcbb9e747bd746efa6925c27abab6962";
    const API_URL = `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(
      newCity
    )}&key=${API_KEY}`;

    axios
      .get(API_URL)
      .then((response) => {
        const { lat, lng } = response.data.results[0].geometry;
        setCoordinatesChart(
          `${parseFloat(lat).toFixed(2)},${parseFloat(lng).toFixed(2)}`
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const convertedDatetimeChart = moment(datetimeChart).toISOString();
  const handleBirthchartSubmit = () => {
    setBirthchart(false);
    dispatch(
      BirthChart({
        ayanamsa: ayanamsaChart,
        coordinates: coordinatesChart,
        datetime: convertedDatetimeChart,
        la: laChart,
        format: "svg",
        chart_style: chartstyle,
        chart_type: charttype,
      })
    ).then((res) => {
      if (res.data.data.message === "Request failed with status code 400") {
        Swal.fire({
          title: "Too many requests!",
          text: "Please Try Again!",
          icon: "warning",
          confirmButtonText: "OK",
        });
        navigate("/myprofile");
      } else {
        setBirthcharts(res.data.data);
        navigate("/birthchart", { state: { birthcharts: res.data.data } });
      }
    });
  };

  const handleClickPersonalOpen = (scrollType) => () => {
    setPersonalopen(true);
    setPersonalscroll(scrollType);
  };
  const handlePersonalClose = () => {
    setPersonalopen(false);
  };
  const [image, setImage] = useState("");
  const [err, setErr] = useState("");

  const handlePersonalSubmit = () => {
    setPersonalopen(false);
    dispatch(Updatepersonaldetails(personaladd)).then((res) => {
      setLoad(false);
      dispatch(Allprofilelist())
        .then((res) => setLoad(false))
        .catch((e) => {
          console.log(e);
        });
    });
  };

  const personaldescriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (personalopen) {
      const { current: descriptionElement } = personaldescriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [personalopen]);

  // profession functionality
  const [professionopen, setProfessionopen] = React.useState(false);
  const [professionscroll, setProfessionscroll] = React.useState();
  const [professionadd, setProfessionadd] = useState("");

  const handleprofChange = (e) => {
    setProfessionadd({ ...professionadd, [e.target.name]: e.target.value });
  };
  const [caste, setCaste] = useState("");
  const [subCaste, setSubCaste] = useState("");

  const handleCasteChange = (e) => {
    const selectedCaste = e.target.value;
    setCaste(selectedCaste);
    setSubCaste(""); // Reset sub-caste when caste changes
    setProfessionadd({ ...professionadd, caste: selectedCaste, subCaste: "" });
  };

  const handleSubCasteChange = (e) => {
    const selectedSubCaste = e.target.value;
    setSubCaste(selectedSubCaste);
    setProfessionadd({ ...professionadd, subCaste: selectedSubCaste });
  };

  useEffect(() => {
    dispatch(getprofession()).then((res) => {
      setProfessionadd(res.data.data);
    });
  }, []);

  const handleClickProfessionOpen = (scrollType) => () => {
    setProfessionopen(true);
    setProfessionscroll(scrollType);
  };
  const handleProfessionClose = () => {
    setProfessionopen(false);
  };
  const handleProfessionSubmit = () => {
    setProfessionopen(false);
    dispatch(Updateprofession(professionadd)).then((res) => {
      Swal.fire(res.data.data);
    });
  };

  const professiondescriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (professionopen) {
      const { current: descriptionElement } = professiondescriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [professionopen]);

  if (list) {
    if (list.status === "unauthorized") {
      navigate("/login");
    } else if (list.status === "failed") {
      navigate("/error");
    } else if (list.status === "timeout") {
      navigate("/error");
    }
  }

  useEffect(() => {
    setHeaderToken(localStorage.getItem("Authtok"));
    dispatch(Allprofilelist())
      .then((res) => setLoad(false))
      .catch((e) => {
        console.log(e);
      });
  }, []);
  useEffect(() => {
    setHeaderToken(localStorage.getItem("Authtok"));
    dispatch(Document())
      .then((res) => setLoad(false))
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
      theme.palette.mode === "light"
        ? theme.palette.grey[100]
        : theme.palette.grey[800];
    return {
      backgroundColor,
      height: theme.spacing(3),
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightRegular,
      "&:hover, &:focus": {
        backgroundColor: emphasize(backgroundColor, 0.06),
      },
      "&:active": {
        boxShadow: theme.shadows[1],
        backgroundColor: emphasize(backgroundColor, 0.12),
      },
    };
  });
  const fileInputRef = useRef(null);

  const [selectedImage, setSelectedImage] = useState(null);

  const style = {
    watermark: {
      position: "absolute",
      bottom: "0",
      right: "5",
      background: "rgb(0, 0, 0)",
      background: "rgba(0, 0, 0, 0.5)",
      color: "#f1f1f1",
      left: "15%",
      width: "0%",
      height: "100%",
      opacity: "0.5",
      padding: "20px",
    },
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);

  const handleDateChange = (range) => {
    setDateRange(range);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  const handleUpload = () => {
    const formData = new FormData();
    formData.append("image", selectedFile);
    dispatch(Updateimage(formData)).then((res) => {
      if (res.data.data === "Image updated successfully") {
        Swal.fire("Please wait for Admin Verification!");
        dispatch(Allprofilelist())
          .then((res) => setLoad(false))
          .catch((e) => {
            console.log(e);
          });
      }
    });
  };
  const [brithmatch, setBirthmatch] = useState("");

  const completion = useSelector(({ getCompletion }) => getCompletion.payload);

  useEffect(() => {
    dispatch(getCompletion()).then((res) => setLoad(false));
  }, []);

  return list && list.status === "ok" ? (
    <div>
      <BirthChart data={birthdetail} />
      {/* <!--===// Start: About Section
        =================================--> */}
      <section
        id="about-section"
        className="about-section av-py-default shapes-section"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="av-container">
          <div className="av-columns-area">
            <div className="av-column-6 mb-4 mb-av-0">
              <div className="about-content">
                <img
                  src={process.env.REACT_APP_URL + list.data.profile.images}
                  alt="about_item"
                  style={{
                    width: "50%",
                    height: "250px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                  class="imgs"
                />
                {list.data.profile.imageVerified === "0" ? (
                  <span style={{ color: "#e1254c" }}>
                    Please wait for admin approval
                  </span>
                ) : null}
                <div
                  className="ups"
                  style={{ display: "flex", marginLeft: "80px" }}
                >
                  <Form.Group
                    controlId="formFile"
                    className="mt-4  formFile"
                    style={{ border: "1px solid red", width: "80%" }}
                  >
                    <Form.Control
                      type="file"
                      className="files"
                      onChange={handleFileChange}
                    />
                  </Form.Group>
                  <div
                    style={{ textAlign: "center", marginTop: "20px" }}
                    className="upload"
                  >
                    <Tooltip
                      title={
                        pencilClicked
                          ? "Please Upload Image"
                          : "Choose an Image"
                      }
                    >
                      <button
                        className="btn-warning mt-1"
                        onClick={handleUpload}
                        style={{ marginLeft: "-70px", padding: "10px " }}
                      >
                        Upload
                      </button>
                    </Tooltip>
                  </div>
                </div>
                {list.data.profile?.membershipPlan === null ? (
                  <a
                    href="/subscriptionhistory"
                    style={{ textTransform: "capitalize", marginTop: "20px" }}
                  >
                    {" "}
                    <button
                      class="but"
                      style={{ margin: "10px 50px 0px 50px" }}
                    >
                      View Membership Plans
                    </button>
                  </a>
                ) : (
                  <div class="cards" style={{ marginLeft: "70px" }}>
                    <Card
                      style={{
                        margin: "20px 0px",
                        width: "400px",
                        padding: "20px",
                      }}
                      class="post-items mem"
                    >
                      <h6
                        style={{
                          color: "#e1254c",
                          padding: "10px 50px 0px 50px",
                        }}
                      >
                        Membership Plan Details
                      </h6>

                      <p
                        style={{
                          textAlign: "left",
                          padding: "10px 30px 0px 30px",
                        }}
                      >
                        Plan Name :{" "}
                        <span style={{ fontWeight: "600" }}>
                          {list?.data?.profile?.membershipPlan?.planName}
                        </span>
                        <br />
                        Duration From :{" "}
                        <span style={{ fontWeight: "600" }}>
                          {moment(
                            list?.data?.subscription?.durationFrom
                          ).format("DD-MM-YYYY")}
                        </span>
                        <br />
                        Duration To :{" "}
                        <span style={{ fontWeight: "600" }}>
                          {moment(list?.data?.subscription?.durationTo).format(
                            "DD-MM-YYYY"
                          )}
                        </span>
                      </p>

                      <a
                        href="/subscriptionhistory"
                        style={{ textTransform: "capitalize" }}
                      >
                        {" "}
                        <button
                          class="but"
                          style={{ margin: "10px 50px 0px 50px" }}
                        >
                          View Membership Plans
                        </button>
                      </a>
                    </Card>
                  </div>
                )}
              </div>
            </div>
            <div className="av-column-6">
              <div className="about-panel">
                <div className="heading-title">
                  {/* <h6>We Are Consultio</h6> */}
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      {" "}
                      <h5 style={{ textTransform: "capitalize" }}>
                        {list.data.profile.userName} &nbsp;&nbsp;
                        {/* <Link to={"tel:" + list.data.profile.phone}>
                          <BsFillTelephoneFill style={{ color: "#d81956" }} />
                        </Link> */}
                      </h5>
                    </div>
                  </div>
                  <p
                    style={{
                      margin: "0px",
                      padding: "10px",
                      color: "#252525",
                      paddingLeft: "0px",
                    }}
                  >
                    {list.data.profile.profileId}
                    {list.data.userDetails.profileCreatedBy === null ||
                    list.data.userDetails ===
                      "User details not completed" ? null : (
                      <span>
                        {" "}
                        &nbsp;|&nbsp; Profile Created by{" "}
                        {list.data.userDetails.profileCreatedBy}
                      </span>
                    )}
                  </p>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <ul className="av-list-check">
                    <li>{list.data.profile.email}</li>
                    <li>
                      Date of Birth{" "}
                      <span style={{ fontWeight: "600", color: "#d81956" }}>
                        {moment(list.data.profile.dob).format("DD-MM-YYYY")}
                      </span>{" "}
                    </li>

                    <li>
                      Age{" "}
                      <span style={{ fontWeight: "600" }}>
                        {list.data.profile.age} Yrs
                      </span>{" "}
                    </li>
                    {
                      // {list.data.userDetails != "User details not completed" ? (
                      //   <li style={{ textTransform: "capitalize" }}>
                      //     Studied {list.data.userDetails.educationalQualification}
                      //   </li>
                      // ) : null}
                    }
                    <li>
                      Lives in{" "}
                      <span style={{ textTransform: "capitalize" }}>
                        {list.data.profile.city}
                      </span>
                      ,{" "}
                      <span style={{ textTransform: "capitalize" }}>
                        {list.data.profile.state}
                      </span>
                      ,
                      <span style={{ textTransform: "capitalize" }}>
                        {" "}
                        {list.data.profile.country}
                      </span>
                    </li>

                    <li>
                      Profile Type -{" "}
                      <span style={{ fontWeight: "600" }}>
                        {list.data.profile.profileType}
                      </span>
                    </li>

                    {list.data.profile.membershipType == null ? null : (
                      <ButtonGroup aria-label="Basic example">
                        <Buttons
                          className="bdetail"
                          onClick={handlebirthdetailOpen()}
                        >
                          Create Your Birth Details
                        </Buttons>
                        <Buttons
                          className="bchart"
                          style={{ marginLeft: "10px" }}
                          onClick={handlebirthchartOpen()}
                        >
                          {" "}
                          Create Your Birth Chart
                        </Buttons>
                        <Link to="/pdfview">
                          <Buttons
                            className="bhoro"
                            style={{ marginTop: "10px" }}
                          >
                            <FaDownload style={{ color: "white" }} /> Generate
                            Horoscope
                          </Buttons>
                        </Link>
                      </ButtonGroup>
                    )}
                  </ul>
                  <a onClick={handleClickBasicOpen()}>
                    <AiFillEdit style={{ fontSize: "20px" }} />
                  </a>
                </div>

                <div className="about-wrapper">
                  <div className="skills-wrapper">
                    <div className="skill-panel">
                      <div
                        className="skill-heading"
                        style={{ marginBottom: "20px" }}
                      >
                        Your Profile Completeness
                      </div>
                      <div
                        onClick={() => setToggle(!toggle)}
                        style={{ cursor: "pointer" }}
                      >
                        <ProgressBar
                          completed={
                            completion && completion.Score
                              ? completion.Score
                              : 0
                          }
                          bgColor="#000"
                          height="20px"
                          borderRadius="2px"
                          labelAlignment="center"
                          baseBgColor="#d0d0d0"
                          labelColor="#fdf7f7"
                          labelSize="10px"
                        />
                      </div>

                      <ul class="list-group">
                        {completion?.userDetails === "User details pending" ? (
                          <li>
                            <span
                              style={{
                                fontWeight: "600",
                                color: "#f84233",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                Swal.fire({
                                  title: "User Details Pending!",
                                  text: "Please update your user details.",
                                  icon: "warning",
                                  confirmButtonText: "OK",
                                });
                              }}
                            >
                              {completion.userDetails}
                            </span>
                          </li>
                        ) : null}

                        {completion?.userRegistration ===
                        "User basic details pending" ? (
                          <li>
                            <span
                              style={{
                                fontWeight: "600",
                                color: "#f84233",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                Swal.fire({
                                  title: "User Basic Details Pending!",
                                  text: "Please update your user basic details.",
                                  icon: "warning",
                                  confirmButtonText: "OK",
                                });
                              }}
                            >
                              {completion.userRegistration}
                            </span>
                          </li>
                        ) : null}
                        {completion?.userPersonality ===
                        "User personality details pending" ? (
                          <li>
                            <span
                              style={{
                                fontWeight: "600",
                                color: "#f84233",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                Swal.fire({
                                  title: "User Personality Details Pending!",
                                  text: "Please update your user personality details.",
                                  icon: "warning",
                                  confirmButtonText: "OK",
                                });
                              }}
                            >
                              {completion.userPersonality}
                            </span>
                          </li>
                        ) : null}
                        {completion?.userDocuments ===
                        "User document details pending" ? (
                          <li>
                            <span
                              style={{
                                fontWeight: "600",
                                color: "#f84233",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                Swal.fire({
                                  title: "User Document Details Pending!",
                                  text: "Please update your user document details.",
                                  icon: "warning",
                                  confirmButtonText: "OK",
                                });
                              }}
                            >
                              {completion.userDocuments}
                            </span>
                          </li>
                        ) : null}
                        {completion?.partnerPreference ===
                        "Partner preference details pending" ? (
                          <li>
                            <span
                              style={{
                                fontWeight: "600",
                                color: "#f84233",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                Swal.fire({
                                  title: "Patner Preference Details Pending!",
                                  text: "Please update your patner preference details.",
                                  icon: "warning",
                                  confirmButtonText: "OK",
                                });
                              }}
                            >
                              {completion.partnerPreference}
                            </span>
                          </li>
                        ) : null}
                        {completion?.familyDetails ===
                        "Family details missing" ? (
                          <a href="/familydetail">
                            {" "}
                            <li>
                              <span
                                style={{
                                  fontWeight: "600",
                                  color: "#f84233",
                                }}
                              >
                                {completion?.familyDetails}
                              </span>
                            </li>
                          </a>
                        ) : null}
                        {completion?.horoscopeDetails ===
                        "Horoscope details pending" ? (
                          <li>
                            <span
                              style={{
                                fontWeight: "600",
                                color: "#f84233",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                Swal.fire({
                                  title: "Horoscope Details Pending!",
                                  text: "Please update your horoscope details.",
                                  icon: "warning",
                                  confirmButtonText: "OK",
                                });
                              }}
                            >
                              {completion.horoscopeDetails}
                            </span>
                          </li>
                        ) : null}
                        {completion?.rasiChart ===
                        "Rasi chart details pending" ? (
                          <li>
                            <span
                              style={{
                                fontWeight: "600",
                                color: "#f84233",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                Swal.fire({
                                  title: "Rasi Chart Details Pending!",
                                  text: "Please update your rasi chart details.",
                                  icon: "warning",
                                  confirmButtonText: "OK",
                                });
                              }}
                            >
                              {completion.rasiChart}
                            </span>
                          </li>
                        ) : null}
                        {completion?.amsamChart ===
                        "Amsam chart details pending" ? (
                          <li>
                            <span
                              style={{
                                fontWeight: "600",
                                color: "#f84233",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                Swal.fire({
                                  title: "Amsa Chart Details Pending!",
                                  text: "Please update your amsa chart details.",
                                  icon: "warning",
                                  confirmButtonText: "OK",
                                });
                              }}
                            >
                              {completion.amsamChart}
                            </span>
                          </li>
                        ) : null}
                        {completion?.userDetails === "User details missing" ? (
                          <a href="/edu&prof">
                            {" "}
                            <li>
                              <span
                                style={{
                                  fontWeight: "600",
                                  color: "#f84233",
                                }}
                              >
                                {completion.userDetails}
                              </span>
                            </li>
                          </a>
                        ) : null}

                        {completion?.userPersonality ===
                        "User personality details missing" ? (
                          <a href="/personaldetail">
                            {" "}
                            <li>
                              <span
                                style={{
                                  fontWeight: "600",
                                  color: "#f84233",
                                }}
                              >
                                {completion.userPersonality}
                              </span>
                            </li>
                          </a>
                        ) : null}
                        {completion?.userDocuments ===
                        "User document details missing" ? (
                          <a href="/idproof">
                            {" "}
                            <li>
                              <span
                                style={{
                                  fontWeight: "600",
                                  color: "#f84233",
                                }}
                              >
                                {completion.userDocuments}
                              </span>
                            </li>
                          </a>
                        ) : null}
                        {completion?.partnerPreference ===
                        "Partner preference details missing" ? (
                          <a href="/partnerdetail">
                            {" "}
                            <li>
                              <span
                                style={{
                                  fontWeight: "600",
                                  color: "#f84233",
                                }}
                              >
                                {completion.partnerPreference}
                              </span>
                            </li>
                          </a>
                        ) : null}
                        {completion?.familyDetails ===
                        "Family details missing" ? (
                          <a href="/familydetail">
                            {" "}
                            <li>
                              <span
                                style={{
                                  fontWeight: "600",
                                  color: "#f84233",
                                }}
                              >
                                {completion.familyDetails}
                              </span>
                            </li>
                          </a>
                        ) : null}
                        {completion?.horoscopeDetails ===
                        "Horoscope details missing" ? (
                          <a href="/horoscopeauto">
                            {" "}
                            <li>
                              <span
                                style={{
                                  fontWeight: "600",
                                  color: "#f84233",
                                }}
                              >
                                {completion.horoscopeDetails}
                              </span>
                            </li>
                          </a>
                        ) : null}
                        {completion?.rasiChart ===
                        "Rasi chart details missing" ? (
                          <a href="/register30">
                            {" "}
                            <li>
                              <span
                                style={{
                                  fontWeight: "600",
                                  color: "#f84233",
                                }}
                              >
                                {completion.rasiChart}
                              </span>
                            </li>
                          </a>
                        ) : null}
                        {completion?.amsamChart ===
                        "Amsam chart details missing" ? (
                          <a href="/amsa">
                            {" "}
                            <li>
                              <span
                                style={{
                                  fontWeight: "600",
                                  color: "#f84233",
                                }}
                              >
                                {completion.amsamChart}
                              </span>
                            </li>
                          </a>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: About Section
        =================================--> */}

      {/* <!--===// Start: profile details
        =================================--> */}
      <section
        id="post-section"
        className="post-section av-py-default"
        style={{ backgroundColor: "#fff", paddingTop: "0px" }}
      >
        <div className="av-container">
          <div className="av-columns-area">
            <div className="av-column-12">
              <article className="post-items">
                <figure className="post-image post-image-absolute">
                  <div className="featured-image">
                    <a href="#" className="post-hover">
                      {/* <img src="assets/images/blog/blogFullSize/img01.jpg" alt="post_item"/> */}
                    </a>
                  </div>
                </figure>
                {list.data.personalityDetails ===
                "Personality details not completed" ? null : (
                  <div className="post-content">
                    {/* <span className="post-date"> <a href=";"><span>08</span>July 2021</a> </span> */}
                    <h5
                      className="post-title"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <a rel="bookmark">About Me</a>
                      <a onClick={handleClickPersonalOpen()}>
                        <AiFillEdit style={{ fontSize: "20px" }} />
                      </a>
                    </h5>
                    <div className="post-footer">
                      <p>{list.data.personalityDetails.aboutMySelf}</p>
                      {/* <a href=";" className="more-link">Read More</a>
                                    <span className="post-count">01</span> */}
                      <ul className="av-list-check">
                        {list.data.personalityDetails.weight ? (
                          <li>
                            Weight -{list.data.personalityDetails.weight}kg
                          </li>
                        ) : null}
                        <li>
                          Height -{list.data.personalityDetails.height}inch
                        </li>

                        {list.data.personalityDetails.bloodGroup ? (
                          <li>
                            Blood Group -{" "}
                            {list.data.personalityDetails.bloodGroup}
                          </li>
                        ) : null}

                        {list.data.personalityDetails.physicalStatus ? (
                          <li>
                            Physical Status -{" "}
                            <span style={{ textTransform: "capitalize" }}>
                              {list.data.personalityDetails.physicalStatus}
                            </span>
                          </li>
                        ) : null}
                        {list.data.personalityDetails.eatingHabits ? (
                          <li>
                            Eating Habits -{" "}
                            <span style={{ textTransform: "capitalize" }}>
                              {list.data.personalityDetails.eatingHabits}
                            </span>
                          </li>
                        ) : null}
                        {list.data.personalityDetails.drinkingHabits ? (
                          <li>
                            Drinking Habits -{" "}
                            <span style={{ textTransform: "capitalize" }}>
                              {list.data.personalityDetails.drinkingHabits}
                            </span>
                          </li>
                        ) : null}
                        {list.data.personalityDetails.smokingHabits ? (
                          <li>
                            Smoking Habits -{" "}
                            <span style={{ textTransform: "capitalize" }}>
                              {list.data.personalityDetails.smokingHabits}
                            </span>
                          </li>
                        ) : null}
                        {list.data.personalityDetails.hobbies ? (
                          <li>
                            Hobbies -{" "}
                            <span style={{ textTransform: "capitalize" }}>
                              {list.data.personalityDetails.hobbies
                                .split(" ")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(" ")}
                            </span>
                          </li>
                        ) : null}
                        {list.data.personalityDetails.smokingHabits ? (
                          <li>
                            Smoking Habits -{" "}
                            <span style={{ textTransform: "capitalize" }}>
                              {list.data.personalityDetails.smokingHabits}
                            </span>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                )}
              </article>
            </div>
            <div className="av-column-12">
              <article className="post-items">
                <figure className="post-image post-image-absolute">
                  <div className="featured-image">
                    <a href=";" className="post-hover">
                      {/* <img src="assets/images/blog/blogFullSize/img02.jpg" alt="post_item"/> */}
                    </a>
                  </div>
                </figure>
                {list.data.familyDetails ===
                "Family details not completed" ? null : (
                  <div className="post-content">
                    {/* <span className="post-date"> <a href=";"><span>08</span>July 2021</a> </span> */}
                    <h5
                      className="post-title"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <a rel="bookmark">Family Details</a>
                      <a onClick={handleClickFamilyOpen()}>
                        <AiFillEdit style={{ fontSize: "20px" }} />
                      </a>
                    </h5>
                    <div className="post-footer">
                      {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> */}
                      {/* <a href=";" className="more-link">Read More</a>
                                    <span className="post-count">02</span> */}
                      <ul className="av-list-check">
                        <li style={{ textTransform: "capitalize" }}>
                          Father's Name - {list.data.familyDetails.fatherName}(
                          {list.data.familyDetails.fatherAlive === "yes"
                            ? "Alive"
                            : "Passed away"}
                          )
                        </li>
                        <li style={{ textTransform: "capitalize" }}>
                          Mother's Name - {list.data.familyDetails.motherName}(
                          {list.data.familyDetails.motherAlive === "yes"
                            ? "Alive"
                            : "Passed away"}
                          )
                        </li>
                        {list.data.familyDetails.fatherAlive === "yes" ? (
                          <li style={{ textTransform: "capitalize" }}>
                            Father's Occupation -{" "}
                            {list.data.familyDetails.fatherOccupation}
                          </li>
                        ) : null}
                        {list.data.familyDetails.motherAlive === "yes" ? (
                          <li style={{ textTransform: "capitalize" }}>
                            Mother's Occupation -{" "}
                            {list.data.familyDetails.motherOccupation}
                          </li>
                        ) : null}

                        <li style={{ textTransform: "capitalize" }}>
                          Family Type - {list.data.familyDetails.familyType}
                        </li>
                        <li style={{ textTransform: "capitalize" }}>
                          Finacial Status -{" "}
                          {list.data.familyDetails.financialStatus}
                        </li>
                        <li style={{ textTransform: "capitalize" }}>
                          No. of Sibilings -{" "}
                          {list.data.familyDetails.siblingDetails}
                        </li>
                        {
                          //   <a href="/premium">
                          //   <li>Request Gothram</li>
                          // </a>
                        }
                      </ul>
                    </div>
                  </div>
                )}
              </article>
            </div>
            <div className="av-column-12">
              <article className="post-items">
                <figure className="post-image post-image-absolute">
                  <div className="featured-image">
                    <a href=";" className="post-hover">
                      {/* <img src="assets/images/blog/blogFullSize/img02.jpg" alt="post_item"/> */}
                    </a>
                  </div>
                </figure>
                {list.data.horoscopeDetails ===
                "Horoscope details not completed" ? null : (
                  <div className="post-content">
                    {/* <span className="post-date"> <a href=";"><span>08</span>July 2021</a> </span> */}
                    <h5
                      className="post-title"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <a rel="bookmark">Horoscope Details</a>
                      <a onClick={handleClickHoroscopeOpen()}>
                        <AiFillEdit style={{ fontSize: "20px" }} />
                      </a>
                    </h5>
                    <div className="post-footer">
                      {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> */}
                      {/* <a href=";" className="more-link">Read More</a>
                                    <span className="post-count">02</span> */}
                      <ul className="av-list-check">
                        <li style={{ textTransform: "capitalize" }}>
                          Zodiac Sign -{list.data.horoscopeDetails.zodiacSign}
                        </li>
                        <li style={{ textTransform: "capitalize" }}>
                          Zodiac Star-
                          {list.data.horoscopeDetails.zodiacStar}
                        </li>

                        <li style={{ textTransform: "capitalize" }}>
                          Time Of Birth -{" "}
                          {list.data.horoscopeDetails.timeOfBirth}
                        </li>

                        <li style={{ textTransform: "capitalize" }}>
                          Place Of Birth-{" "}
                          {list.data.horoscopeDetails.placeOfBirth}
                        </li>

                        <li style={{ textTransform: "capitalize" }}>
                          Gothram - {list.data.horoscopeDetails.gothram}
                        </li>
                        <li style={{ textTransform: "capitalize" }}>
                          Madhulam - {list.data.horoscopeDetails.madhulam}
                        </li>
                        <li style={{ textTransform: "capitalize" }}>
                          Lagnam - {list.data.horoscopeDetails.selectLagnam}
                        </li>
                        {
                          //   <a href="/premium">
                          //   <li>Request Gothram</li>
                          // </a>
                        }
                      </ul>
                    </div>
                  </div>
                )}
              </article>
            </div>
            <div className="av-column-12">
              <article className="post-items">
                <figure className="post-image post-image-absolute">
                  <div className="featured-image">
                    <a href=";" className="post-hover">
                      {/* <img src="assets/images/blog/blogFullSize/img03.jpg" alt="post_item"/> */}
                    </a>
                  </div>
                </figure>
                {list.data.userDetails ===
                "User details not completed" ? null : (
                  <div className="post-content">
                    {/* <span className="post-date"> <a href=";"><span>10</span>July 2021</a> </span> */}
                    <h5
                      className="post-title"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <a href=";" rel="bookmark">
                        Her professional details & Religious details
                      </a>
                      <a onClick={handleClickProfessionOpen()}>
                        <AiFillEdit style={{ fontSize: "20px" }} />
                      </a>
                    </h5>
                    <div className="post-footer">
                      {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> */}
                      {/* <a href=";" className="more-link">Read More</a>
                                    <span className="post-count">03</span> */}
                      <ul className="av-list-check">
                        <li>
                          Marrital Status -{" "}
                          <span style={{ textTransform: "capitalize" }}>
                            {list.data.userDetails.maritalStatus}
                          </span>{" "}
                        </li>

                        <li>
                          Religion -{" "}
                          <span style={{ textTransform: "capitalize" }}>
                            {list.data.userDetails.religion}{" "}
                          </span>
                        </li>
                        <li>
                          Caste -{" "}
                          <span style={{ textTransform: "capitalize" }}>
                            {list.data.userDetails.caste}
                          </span>{" "}
                        </li>
                        <li>
                          Sub-Caste -{" "}
                          <span style={{ textTransform: "capitalize" }}>
                            {list.data.userDetails.subCaste}{" "}
                          </span>
                        </li>
                        <li>
                          Educational Qualification -{" "}
                          <span style={{ textTransform: "capitalize" }}>
                            {" "}
                            {list.data.userDetails.educationalQualification}
                          </span>{" "}
                        </li>
                        <li>
                          Working in -{" "}
                          <span style={{ textTransform: "capitalize" }}>
                            {list.data.userDetails.professionDesignation}/
                            {list.data.userDetails.profession}
                          </span>
                        </li>

                        <li>
                          Working Location -{" "}
                          <span style={{ textTransform: "capitalize" }}>
                            {list.data.userDetails.professionLocation}
                          </span>
                        </li>
                        <li>
                          Earns ₹ {list.data.userDetails.annualIncome}&nbsp;
                          Annually
                        </li>

                        <a
                          href={
                            process.env.REACT_APP_URL + "/" + docs?.data?.aadhar
                          }
                          target="_blank"
                        >
                          <li>View ID Proof</li>
                        </a>

                        <a
                          href={
                            process.env.REACT_APP_URL +
                            "/" +
                            docs?.data?.educationDetails
                          }
                          target="_blank"
                        >
                          <li style={{ marginTop: "10px" }}>
                            View Educational Document
                          </li>
                        </a>
                        <a
                          href={
                            process.env.REACT_APP_URL +
                            "/" +
                            docs?.data?.horoscope
                          }
                          target="_blank"
                        >
                          <li style={{ marginTop: "10px" }}>View Horoscope</li>
                        </a>
                      </ul>
                    </div>
                  </div>
                )}
              </article>
            </div>
            <div className="av-column-12">
              <article className="post-items">
                <figure className="post-image post-image-absolute">
                  <div className="featured-image">
                    <a href=";" className="post-hover">
                      {/* <img src="assets/images/blog/blogFullSize/img04.jpg" alt="post_item"/> */}
                    </a>
                  </div>
                </figure>
                {
                  // <div className="post-content">
                  //   {/* <span className="post-date"> <a href=";"><span>11</span>July 2021</a> </span> */}
                  //   <h5
                  //     className="post-title"
                  //     style={{ display: "flex", justifyContent: "space-between" }}
                  //   >
                  //     <a rel="bookmark">About her loved ones</a>
                  //     <a onClick={handleClickFamilyOpen()}>
                  //       <AiFillEdit style={{ fontSize: "20px" }} />
                  //     </a>
                  //   </h5>
                  //   <div className="post-footer">
                  //     {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> */}
                  //     {/* <a href=";" className="more-link">Read More</a>
                  //                     <span className="post-count">04</span> */}
                  //     <ul className="av-list-check">
                  //       <li>
                  //         {list.data.familyDetails.familyType},{" "}
                  //         {list.data.familyDetails.financialStatus} &{" "}
                  //         {list.data.familyDetails.familyValues}
                  //       </li>
                  //       {
                  //         //   <a href="/premium">
                  //         //   <li>Request Family Details</li>
                  //         // </a>
                  //       }
                  //     </ul>
                  //   </div>
                  // </div>
                }
              </article>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: profile details
        =================================--> */}

      {/* start-partner preference */}
      <section
        id="post-section"
        className="post-section av-py-default"
        style={{ backgroundColor: "#fff", paddingTop: "0px" }}
      >
        <div className="av-container">
          <div className="av-columns-area">
            <div className="av-column-12">
              <div className="sidebar">
                <aside id="widget-text" className="widget widget_text">
                  {list?.data?.profile?.gender === "MALE" ? (
                    <h6 className="widget-title">
                      See how well your profile matches his preferences{" "}
                      <span></span>
                    </h6>
                  ) : (
                    <h6 className="widget-title">
                      See how well your profile matches her preferences{" "}
                      <span></span>
                    </h6>
                  )}
                  <div className="textwidget">
                    <div className="textwidget_img">
                      <img
                        src={
                          process.env.REACT_APP_URL + list.data.profile.images
                        }
                        alt=""
                        className="rounded-circle"
                        style={{
                          height: "140px",
                          width: "200px",
                          objectFit: "cover",
                        }}
                      />
                      <div className="circles-spin">
                        <div className="circle-one"></div>
                      </div>
                    </div>
                    <h6>Well Hi There, I'm {list.data.profile.userName}</h6>
                    <p>The best thing to hold onto in life is each other.</p>
                  </div>
                </aside>
                <aside
                  id="widget-latest-posts"
                  className="widget widget_latest_posts"
                >
                  {list.data.partnerDetails ===
                  "Partner details not completed" ? null : (
                    <div className="widget-content">
                      <article className="latest_posts post-items">
                        <figure className="post-image">
                          <div className="featured-image">
                            <a
                              onClick={handleClickOpen("paper")}
                              className="post-hover"
                            >
                              {/* <img
                              src="assets/images/blog/blogFullSize/img01.jpg"
                              alt="post_item"
                            /> */}{" "}
                              <AiFillEdit style={{ fontSize: "20px" }} />
                            </a>
                          </div>
                        </figure>
                        <div className="post-content">
                          <span className="count-link"></span>
                          <h5
                            className="post-title"
                            style={{ fontSize: "20px" }}
                          >
                            <a>Basic Partner Preferences</a>
                          </h5>
                          <div className="post-meta">
                            <div className="post-list">
                              <ul className="post-categories">
                                <li>
                                  <a
                                    style={{
                                      fontSize: "17px",
                                      color: "#00000099",
                                      fontWeight: "700",
                                      background: "none",
                                    }}
                                  >
                                    Age
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <span className="post-date">
                              {/* <i className="fa fa-clock-o"></i>{" "} */} -
                              &nbsp;
                              <time
                                dateTime="2020-07-02"
                                className="meta-info"
                                style={{ fontSize: "20px" }}
                              >
                                {list.data.partnerDetails.age}
                              </time>
                            </span>
                          </div>
                          <div className="post-meta">
                            <div className="post-list">
                              <ul className="post-categories">
                                <li>
                                  <a
                                    style={{
                                      fontSize: "17px",
                                      color: "#00000099",
                                      fontWeight: "700",
                                      background: "none",
                                    }}
                                  >
                                    Annual Income
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <span className="post-date">
                              {/* <i className="fa fa-clock-o"></i>{" "} */} -
                              &nbsp;
                              <time
                                dateTime="2020-07-02"
                                className="meta-info"
                                style={{ fontSize: "20px" }}
                              >
                                {list.data.partnerDetails.annualIncome}
                              </time>
                            </span>
                          </div>

                          <div className="post-meta">
                            <div className="post-list">
                              <ul className="post-categories">
                                <li>
                                  <a href="#"></a>
                                  <a
                                    href="#"
                                    style={{
                                      fontSize: "17px",
                                      color: "#00000099",
                                      fontWeight: "700",
                                      background: "none",
                                    }}
                                  >
                                    Martial Status
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <span className="post-date">
                              {/* <i className="fa fa-clock-o"></i>{" "} */} -
                              &nbsp;
                              <time
                                dateTime="2020-07-02"
                                className="meta-info"
                                style={{
                                  fontSize: "20px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {list.data.partnerDetails.maritalStatus}
                              </time>
                            </span>
                          </div>
                          <div className="post-meta">
                            <div className="post-list">
                              <ul className="post-categories">
                                <li>
                                  <a
                                    href="#"
                                    style={{
                                      fontSize: "17px",
                                      color: "#00000099",
                                      fontWeight: "700",
                                      background: "none",
                                    }}
                                  >
                                    Foreign Intrest
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <span className="post-date">
                              {/* <i className="fa fa-clock-o"></i>{" "} */} -
                              &nbsp;
                              <time
                                dateTime="2020-07-02"
                                className="meta-info"
                                style={{
                                  fontSize: "20px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {list.data.partnerDetails.foreignInterest}
                              </time>
                            </span>
                          </div>
                          <div className="post-meta">
                            <div className="post-list">
                              <ul className="post-categories">
                                <li>
                                  <a
                                    href="#"
                                    style={{
                                      fontSize: "17px",
                                      color: "#00000099",
                                      fontWeight: "700",
                                      background: "none",
                                    }}
                                  >
                                    Height
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <span className="post-date">
                              {/* <i className="fa fa-clock-o"></i>{" "} */} -
                              &nbsp;
                              <time
                                dateTime="2020-07-02"
                                className="meta-info"
                                style={{ fontSize: "20px" }}
                              >
                                {list.data.partnerDetails.height}
                              </time>
                            </span>
                          </div>
                          <div className="post-meta">
                            <div className="post-list">
                              <ul className="post-categories">
                                <li>
                                  <a
                                    href="#"
                                    style={{
                                      fontSize: "17px",
                                      color: "#00000099",
                                      fontWeight: "700",
                                      background: "none",
                                    }}
                                  >
                                    Rahu Ketu
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <span className="post-date">
                              {/* <i className="fa fa-clock-o"></i>{" "} */} -
                              &nbsp;
                              <time
                                dateTime="2020-07-02"
                                className="meta-info"
                                style={{
                                  fontSize: "20px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {list.data.partnerDetails.rahuKetu === "raghu"
                                  ? "Raghu Dhosam"
                                  : false}
                                {list.data.partnerDetails.rahuKetu === "kethu"
                                  ? "Kethu Dhosam"
                                  : false}
                                {list.data.partnerDetails.rahuKetu ===
                                "raghuKethu"
                                  ? "Raghu Kethu Dhosam"
                                  : false}
                                {list.data.partnerDetails.rahuKetu ===
                                "raKePari"
                                  ? "Ragu-Kethu Dhosam Parikaram Seiyapattathu"
                                  : false}
                                {list.data.partnerDetails.rahuKetu === "sevvai"
                                  ? "Sevvai Dhosam"
                                  : false}
                                {list.data.partnerDetails.rahuKetu ===
                                "sevvaiPari"
                                  ? "Sevvai Dhosam Parikaram Seiyapattathu"
                                  : false}{" "}
                                {list.data.partnerDetails.rahuKetu ===
                                "noDhosam"
                                  ? "No Dhosam"
                                  : false}{" "}
                                {list.data.partnerDetails.rahuKetu === "sutha"
                                  ? "Suththa Jathagam"
                                  : false}
                              </time>
                            </span>
                          </div>
                          <div className="post-meta">
                            <div className="post-list">
                              <ul className="post-categories">
                                <li>
                                  <a
                                    href="#"
                                    style={{
                                      fontSize: "17px",
                                      color: "#00000099",
                                      fontWeight: "700",
                                      background: "none",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    Dhosam
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <span className="post-date">
                              {/* <i className="fa fa-clock-o"></i>{" "} */} -
                              &nbsp;
                              <time
                                dateTime="2020-07-02"
                                className="meta-info"
                                style={{
                                  fontSize: "20px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {list.data.partnerDetails.dhosam}
                              </time>
                            </span>
                          </div>
                          <div className="post-meta">
                            <div className="post-list">
                              <ul className="post-categories">
                                <li>
                                  <a href="#"></a>
                                  <a
                                    href="#"
                                    style={{
                                      fontSize: "17px",
                                      color: "#00000099",
                                      fontWeight: "700",
                                      background: "none",
                                    }}
                                  >
                                    Our Expectations
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <span className="post-date">
                              {/* <i className="fa fa-clock-o"></i>{" "} */}
                              &nbsp;
                              <time
                                dateTime="2020-07-02"
                                className="meta-info"
                                style={{
                                  fontSize: "20px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {list.data.partnerDetails.weLookingFor}
                              </time>
                            </span>
                          </div>
                        </div>
                      </article>
                    </div>
                  )}
                </aside>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="post-section"
        className="post-section av-py-default"
        style={{ backgroundColor: "#fff", paddingTop: "0px" }}
      >
        <div className="av-container">
          <div className="av-columns-area">
            <div className="av-column-12">
              <div className="sidebar">
                <aside
                  id="widget-latest-posts"
                  className="widget widget_latest_posts"
                >
                  <div className="widget-content">
                    <article className="latest_posts post-items">
                      <figure className="post-image">
                        <div className="featured-image"></div>
                      </figure>
                      <div className="post-content">
                        <span className="count-link"></span>
                        <h5 className="post-title" style={{ fontSize: "20px" }}>
                          <a
                            onClick={handleDeleteOpen("paper")}
                            className="post-hover"
                          >
                            Delete account
                          </a>
                        </h5>
                      </div>
                    </article>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end-partner preference */}

      {/* start family details modal */}
      <Dialog
        open={familyopen}
        onClose={handleFamilyClose}
        scroll={familyscroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        {" "}
        <DialogTitle>
          <div style={{ float: "right" }}>
            <IoMdClose style={{ color: "#e1254c" }} onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent dividers={familyscroll} sx={{ background: "#f5f6fa;" }}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={familydescriptionElementRef}
            tabIndex={-1}
          >
            <div className="av-container">
              <div className="av-columns-area">
                <div className="av-column-12 mx-auto">
                  <div id="comments" className="comments-area">
                    <div
                      id="respond"
                      className="comment-respond"
                      style={{ padding: "0px" }}
                    >
                      <form>
                        {/* <!-- Email input --> */}
                        <h3 id="reply-title" className="comment-reply-title">
                          Update Family Details{" "}
                          <small>
                            <a
                              rel="nofollow"
                              id="cancel-comment-reply-link"
                              href="#"
                              style={{ display: "none" }}
                            >
                              Cancel reply
                            </a>
                          </small>
                        </h3>
                        <div className="form-outline mb-4">
                          <label>Father Name</label>
                          <input
                            type="text"
                            name="fatherName"
                            value={familyadd.fatherName}
                            onChange={handlefamChange}
                            id="form2Example1"
                            className="form-control"
                            placeholder="Father Name"
                            style={{ textTransform: "capitalize" }}
                          />
                        </div>
                        <div className="form-outline mb-4">
                          <label>Father Alive</label>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="fatherAlive"
                            value={familyadd.fatherAlive}
                            onChange={handlefamChange}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <FormControlLabel
                              value="yes"
                              control={<Radio />}
                              label="Alive"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="Passed away"
                            />
                          </RadioGroup>
                        </div>
                        <div className="form-outline mb-4">
                          <label>Father Occupation</label>
                          <input
                            type="text"
                            name="fatherOccupation"
                            value={familyadd.fatherOccupation}
                            onChange={handlefamChange}
                            id="form2Example1"
                            className="form-control"
                            placeholder="Father Occupation"
                            style={{ textTransform: "capitalize" }}
                          />
                        </div>
                        <div className="form-outline mb-4">
                          <label> Mother Name</label>
                          <input
                            type="text"
                            name="motherName"
                            value={familyadd.motherName}
                            onChange={handlefamChange}
                            id="form2Example1"
                            className="form-control"
                            placeholder="Mother Name"
                            style={{ textTransform: "capitalize" }}
                          />
                        </div>
                        <div className="form-outline mb-4">
                          <label>Mother Alive</label>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="motherAlive"
                            value={familyadd.motherAlive}
                            onChange={handlefamChange}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <FormControlLabel
                              value="yes"
                              control={<Radio />}
                              label="Alive"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="Passed away"
                            />
                          </RadioGroup>
                        </div>
                        <div className="form-outline mb-4">
                          <label>Mother Occupation</label>
                          <input
                            type="text"
                            name="motherOccupation"
                            value={familyadd.motherOccupation}
                            onChange={handlefamChange}
                            id="form2Example1"
                            className="form-control"
                            placeholder="Mother Occupation"
                            style={{ textTransform: "capitalize" }}
                          />
                        </div>
                        <div className="form-outline mb-4">
                          <label>Family Types</label>
                          <select
                            name="familyType"
                            value={familyadd.familyType}
                            onChange={handlefamChange}
                            required
                            id="familyType"
                          >
                            <option value="Choose Family Type">
                              Choose Family Type
                            </option>
                            <option value="Join family">Join</option>
                            <option value="Nuclear family">Nuclear</option>
                          </select>
                        </div>
                        <div className="form-outline mb-4">
                          <label>Family Status</label>
                          <select
                            name="financialStatus"
                            value={familyadd.financialStatus}
                            onChange={handlefamChange}
                            required
                            id="financialStatus"
                          >
                            <option value="Choose family status">
                              Choose Family Status
                            </option>
                            <option value="rich">Rich</option>
                            <option value="very rich">Very Rich</option>
                            <option value="middle class">Middle class</option>
                            <option value="lower middle class">
                              Lower Middle class
                            </option>
                            <option value="affluent">Affluent</option>
                            <option value="upper middle class">
                              Upper Middle class
                            </option>
                          </select>
                        </div>
                        <div className="form-outline mb-4">
                          <label>Family Type</label>
                          <select
                            name="familyValues"
                            value={familyadd.familyValues}
                            onChange={handlefamChange}
                            required
                            id="familyValues"
                          >
                            <option value="Choose Family Type">
                              Choose Family Type
                            </option>
                            <option value="orthodox">Orthodox</option>
                            <option value="traditional">Traditional</option>
                            <option value="middle class">Middle class</option>
                            <option value="moderate">Moderate</option>
                            <option value="liberal">Liberal</option>
                          </select>
                        </div>
                        <div className="form-outline mb-4">
                          <label>Sibilings</label>
                          <select
                            name="siblingDetails"
                            value={familyadd.siblingDetails}
                            onChange={handlefamChange}
                            id="siblingDetails"
                          >
                            <option value="Choose sibilings">
                              Choose Sibilings
                            </option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="More than 5">More than 5</option>
                          </select>
                        </div>
                        {/* <div className="av-column-12" style={{ paddingBottom: "1px" }}>
                      <a href="/register7">
                        {" "}
                        <button
                          type="submit"
                          className="btn btn-primary btn-block mb-4"
                          style={{ float: "left" }}
                        >
                          Next
                        </button>
                      </a>
                      <a href="/register7">
                        {" "}
                        <button
                          type="submit"
                          className="btn btn-primary btn-block mb-4"
                          style={{ float: "right" }}
                        >
                          Skip
                        </button>
                      </a>
                    </div> */}
                        {/* <!-- Register buttons --> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            onClick={handleFamilyClose}
            sx={{ color: "#fff" }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {/* end family details modal */}
      {/* start horoscope details modal */}
      <Dialog
        open={horoscopeopen}
        onClose={handleHoroscopeClose}
        scroll={horoscopescroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle>
          <div style={{ float: "right" }}>
            <IoMdClose style={{ color: "#e1254c" }} onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent
          dividers={horoscopescroll}
          sx={{ background: "#f5f6fa;" }}
        >
          <DialogContentText
            id="scroll-dialog-description"
            ref={horoscopedescriptionElementRef}
            tabIndex={-1}
          >
            <div className="av-container">
              <div className="av-columns-area">
                <div className="av-column-12 mx-auto">
                  <div id="comments" className="comments-area">
                    <div
                      id="respond"
                      className="comment-respond"
                      style={{ padding: "0px" }}
                    >
                      <form>
                        {/* <!-- Email input --> */}
                        <h3 id="reply-title" className="comment-reply-title">
                          Update Horoscope Details{" "}
                          <small>
                            <a
                              rel="nofollow"
                              id="cancel-comment-reply-link"
                              href="#"
                              style={{ display: "none" }}
                            >
                              Cancel reply
                            </a>
                          </small>
                        </h3>
                        <div className="form-outline mb-4">
                          <label>
                            Enter Your Zodiac Sign{" "}
                            <span style={{ color: "#e1254c" }}>*</span>
                          </label>
                          <select
                            name="zodiacSign"
                            value={horoscopeadd.zodiacSign}
                            onChange={handlehoroChange}
                            id="zordicSign"
                          >
                            <option value="Choose Profile">Select Rasi</option>
                            <option value="Mesham">Mesham</option>
                            <option value="Rishabam">Rishabam</option>
                            <option value="Midhunam">Midhunam</option>
                            <option value="Kadagam">Kadagam</option>
                            <option value="Simmam">Simmam</option>
                            <option value="Thiruvadhirai">Thiruvadhirai</option>
                            <option value="Kanni">Kanni</option>
                            <option value="Thulam">Thulam</option>
                            <option value="Viruchigam">Viruchigam</option>
                            <option value="Dhanus">Dhanus</option>
                            <option value="Magaram">Magaram</option>
                            <option value="Kumbam">Kumbam</option>
                            <option value="Meenam">Meenam</option>
                          </select>
                        </div>
                        <div className="form-outline mb-4">
                          <label>
                            Enter Your Zodiac Star{" "}
                            <span style={{ color: "#e1254c" }}>*</span>
                          </label>
                          <select
                            name="zodiacStar"
                            value={horoscopeadd.zodiacStar}
                            onChange={handlehoroChange}
                            id="zodiacStar"
                          >
                            <option value="Choose Gender">Select Star</option>
                            <option value="Ashwini">Ashwini</option>
                            <option value="Barani">Barani</option>
                            <option value="Krithikai">Krithikai</option>
                            <option value="Rohini">Rohini</option>
                            <option value="Mirugasiridam">Mirugasiridam</option>
                            <option value="Thiruvadhirai">Thiruvadhirai</option>
                            <option value="Punarpoosam">Punarpoosam</option>
                            <option value="Poosam">Poosam</option>
                            <option value="Ayilyam">Ayilyam</option>
                            <option value="Magam">Magam</option>
                            <option value="Pooram">Pooram</option>
                            <option value="Rohini">Rohini</option>
                            <option value="Poosam">Poosam</option>
                            <option value="Uthiram">Uthiram</option>
                            <option value="Astham">Astham</option>
                            <option value="Chithirai">Chithirai</option>
                            <option value="Swathi">Swathi</option>
                            <option value="Visagam">Visagam</option>
                            <option value="Anusham">Anusham</option>
                            <option value="Kettai">Kettai</option>
                            <option value="Mulam">Mulam</option>
                            <option value="Puradam">Puradam</option>
                            <option value="Uthiradam">Uthiradam</option>
                            <option value="Thiruvonam">Thiruvonam</option>
                            <option value="Avittam">Avittam</option>
                            <option value="Sadayam">Sadayam</option>
                            <option value="Purattadhi">Purattadhi</option>
                            <option value="Uttrttadhi">Uttrttadhi</option>
                            <option value="Revathi">Revathi</option>
                          </select>
                        </div>
                        <div className="form-outline mb-4">
                          <label>
                            Enter Your Padham{" "}
                            <span style={{ color: "#e1254c" }}>*</span>
                          </label>
                          <select
                            name="padham"
                            value={horoscopeadd.padham}
                            onChange={handlehoroChange}
                            id="padham"
                          >
                            <option value="Choose Profile">Padham</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                          </select>
                        </div>
                        <div className="form-outline mb-4">
                          <label>
                            Enter Your Place of Birth{" "}
                            <span style={{ color: "#e1254c" }}>*</span>
                          </label>
                          <input
                            type="text"
                            name="placeOfBirth"
                            value={horoscopeadd.placeOfBirth}
                            onChange={handlehoroChange}
                            id="placeOfBirth"
                            className="form-control"
                            placeholder="Place of Birth"
                            style={{ textTransform: "capitalize" }}
                          />
                        </div>

                        <div
                          className="form-outline mb-4"
                          style={{ backgroundColor: "#f5f6fa" }}
                        >
                          <label>
                            Enter Your Time of Birth{" "}
                            <span style={{ color: "#e1254c" }}>*</span>
                          </label>
                          <input
                            type="time"
                            name="timeOfBirth"
                            value={horoscopeadd.timeOfBirth}
                            onChange={handlehoroChange}
                            id="timeOfBirth"
                            className="form-control"
                            placeholder="Time of Birth"
                          />
                        </div>
                        <div className="form-outline mb-4">
                          <label>
                            Enter Your Date of Birth{" "}
                            <span style={{ color: "#e1254c" }}>*</span>
                          </label>
                          <input
                            type="date"
                            name="dateOfBirth"
                            value={horoscopeadd.dateOfBirth}
                            onChange={handlehoroChange}
                            id="dateOfBirth"
                            className="form-control"
                            placeholder="Dob"
                          />
                        </div>

                        <div className="form-outline mb-4">
                          <label>
                            Select Lagnam{" "}
                            <span style={{ color: "#e1254c" }}>*</span>
                          </label>
                          <select
                            name="selectLagnam"
                            value={horoscopeadd.selectLagnam}
                            onChange={handlehoroChange}
                            id="selectLagnam"
                          >
                            <option value="Choose Profile">
                              Select Lagnam
                            </option>
                            <option value="Mesham">Mesham</option>
                            <option value="Rishabam">Rishabam</option>
                            <option value="Midhunam">Midhunam</option>
                            <option value="Kadagam">Kadagam</option>
                            <option value="Simmam">Simmam</option>
                            <option value="Thiruvadhirai">Thiruvadhirai</option>
                            <option value="Kanni">Kanni</option>
                            <option value="Thulam">Thulam</option>
                            <option value="Viruchigam">Viruchigam</option>
                            <option value="Dhanus">Dhanus</option>
                            <option value="Magaram">Magaram</option>
                            <option value="Kumbam">Kumbam</option>
                            <option value="Meenam">Meenam</option>
                          </select>
                        </div>
                        <div className="form-outline mb-4">
                          <label>
                            Enter Your Madhulam{" "}
                            <span style={{ color: "#e1254c" }}>*</span>
                          </label>
                          <input
                            type="text"
                            name="madhulam"
                            value={horoscopeadd.madhulam}
                            onChange={handlehoroChange}
                            id="madhulam"
                            className="form-control"
                            placeholder="Madhulam"
                            style={{ textTransform: "capitalize" }}
                          />
                        </div>
                        <div className="form-outline mb-4">
                          <label>
                            Enter Your Gothram{" "}
                            <span style={{ color: "#e1254c" }}>*</span>
                          </label>
                          <input
                            type="text"
                            name="gothram"
                            value={horoscopeadd.gothram}
                            onChange={handlehoroChange}
                            id="gothram"
                            className="form-control"
                            placeholder="Gothram"
                            style={{ textTransform: "capitalize" }}
                          />
                        </div>

                        {/* <div className="av-column-12" style={{ paddingBottom: "1px" }}>
                      <a href="/register7">
                        {" "}
                        <button
                          type="submit"
                          className="btn btn-primary btn-block mb-4"
                          style={{ float: "left" }}
                        >
                          Next
                        </button>
                      </a>
                      <a href="/register7">
                        {" "}
                        <button
                          type="submit"
                          className="btn btn-primary btn-block mb-4"
                          style={{ float: "right" }}
                        >
                          Skip
                        </button>
                      </a>
                    </div> */}
                        {/* <!-- Register buttons --> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            onClick={handleHoroscopeClose}
            sx={{ color: "#fff" }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {/* end horoscope details modal */}
      <Dialog
        open={birthopen}
        onClose={handleBirthdetailClose}
        scroll={basicscroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{ width: "100%" }}
      >
        <DialogTitle>
          <div style={{ float: "right" }}>
            <IoMdClose style={{ color: "#e1254c" }} onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent dividers={basicscroll} sx={{ background: "#f5f6fa;" }}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={basicdescriptionElementRef}
            tabIndex={-1}
          >
            <div className="av-container">
              <div className="av-columns-area">
                <div className="av-column-12 mx-auto">
                  <div id="comments" className="comments-area">
                    <div
                      id="respond"
                      className="comment-respond"
                      style={{ padding: "0px" }}
                    >
                      <form>
                        {/* <!-- Email input --> */}
                        <h3 id="reply-title" className="comment-reply-title">
                          Birth Details{" "}
                          <small>
                            <a
                              rel="nofollow"
                              id="cancel-comment-reply-link"
                              href="#"
                              style={{ display: "none" }}
                            >
                              Cancel reply
                            </a>
                          </small>
                        </h3>
                        <div className="form-outline mb-4">
                          <label>Ayanamsa</label>
                          <select
                            name="ayanamsa"
                            onChange={(e) => setAyanamsa(e.target.value)}
                            required
                            id="Ayanamsa"
                          >
                            <option value="Ayanamsa">Choose Ayanamsa</option>
                            <option value="1">Lahiri</option>
                            <option value="2">Raman</option>
                            <option value="3">KP</option>
                          </select>
                        </div>

                        <div className="form-outline mb-4">
                          <label>Language</label>
                          <select
                            name="la"
                            onChange={(e) => setLa(e.target.value)}
                            required
                            id="Language"
                          >
                            <option value="Language">Choose Language</option>
                            <option value="en">English</option>
                            <option value="te">Telungu</option>
                            <option value="ml">Malayalam</option>
                            <option value="hi">Hindi</option>
                            <option value="ta">Tamil</option>
                          </select>
                        </div>

                        <div className="form-outline mb-4">
                          <label>Select Your Birth Date and Time</label>
                          <input
                            type="datetime-local"
                            id="datetime"
                            name="datetime"
                            onChange={(e) => setDatetime(e.target.value)}
                          />
                        </div>

                        <div className="form-outline mb-4">
                          <label>Enter City</label>
                          <input
                            value={city}
                            onChange={handleInputChange}
                            id="city"
                            placeholder="Enter City"
                            type="text"
                          />
                        </div>

                        <div
                          className="av-column-12"
                          style={{ paddingBottom: "1px" }}
                        ></div>
                        {/* <!-- Register buttons --> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            onClick={handleBirthdetailSubmit}
            sx={{ color: "#fff" }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {/* start birth details modal*/}
      <Dialog
        open={birthchart}
        onClose={handleBirthchartClose}
        scroll={basicscroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{ width: "100%" }}
      >
        <DialogTitle>
          <div style={{ float: "right" }}>
            <IoMdClose style={{ color: "#e1254c" }} onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent dividers={basicscroll} sx={{ background: "#f5f6fa;" }}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={basicdescriptionElementRef}
            tabIndex={-1}
          >
            <div className="av-container">
              <div className="av-columns-area">
                <div className="av-column-12 mx-auto">
                  <div id="comments" className="comments-area">
                    <div
                      id="respond"
                      className="comment-respond"
                      style={{ padding: "0px" }}
                    >
                      <form>
                        {/* <!-- Email input --> */}
                        <h3 id="reply-title" className="comment-reply-title">
                          Birth Chart{" "}
                          <small>
                            <a
                              rel="nofollow"
                              id="cancel-comment-reply-link"
                              href="#"
                              style={{ display: "none" }}
                            >
                              Cancel reply
                            </a>
                          </small>
                        </h3>
                        <div className="form-outline mb-4">
                          <label>Ayanamsa</label>
                          <select
                            name="ayanamsaChart"
                            onChange={(e) => setAyanamsaChart(e.target.value)}
                            required
                            id="Ayanamsa"
                          >
                            <option value="Ayanamsa">Choose Ayanamsa</option>
                            <option value="1">Lahiri</option>
                            <option value="2">Raman</option>
                            <option value="3">KP</option>
                          </select>
                        </div>

                        <div className="form-outline mb-4">
                          <label>Language</label>
                          <select
                            name="laChart"
                            onChange={(e) => setLaChart(e.target.value)}
                            required
                            id="Language"
                          >
                            <option value="Language">Choose Language</option>
                            <option value="en">English</option>
                            <option value="te">Telungu</option>
                            <option value="ml">Malayalam</option>
                            <option value="hi">Hindi</option>
                            <option value="ta">Tamil</option>
                          </select>
                        </div>

                        <div className="form-outline mb-4">
                          <label>Chart Type</label>
                          <select
                            name="charttype"
                            onChange={(e) => setCharttype(e.target.value)}
                            required
                            id="Language"
                          >
                            <option value="Language">Choose Chart Type</option>
                            <option value="rasi">Rasi</option>
                            <option value="navamsa">Navamsa</option>
                            <option value="lagna">Lagna</option>
                            <option value="trimsamsa">Trimsamsa</option>
                            <option value="drekkana">Drekkana</option>
                            <option value="chaturthamsa">Chaturthamsa</option>
                            <option value="dasamsa">Dasamsa</option>
                            <option value="ashtamsa">Astamsa</option>
                            <option value="dwadasamsa">Dwadasamsa</option>
                            <option value="shodasamsa">Shodasamsa</option>
                            <option value="hora">Hora</option>
                            <option value="akshavedamsa">Akshavedamsa</option>
                            <option value="shashtyamsa">Shashtyamsa</option>
                            <option value="panchamsa">Panchamsa</option>
                            <option value="khavedamsa">Khavedamsa</option>
                            <option value="saptavimsamsa">Saptavimsamsa</option>
                            <option value="shashtamsa">Shashtamsa</option>
                            <option value="chaturvimsamsa">
                              Chaturvimsamsa
                            </option>
                            <option value="saptamsa">Saptamsa</option>
                            <option value="vimsamsa">Vimsamsa</option>
                          </select>
                        </div>

                        <div className="form-outline mb-4">
                          <label>Chart Style</label>
                          <select
                            name="chartstyle"
                            onChange={(e) => setChartstyle(e.target.value)}
                            required
                            id="Language"
                          >
                            <option value="Chart Style">
                              Choose Chart Style
                            </option>
                            <option value="south-indian">South Indian</option>
                            <option value="north-indian">North Indian</option>
                            <option value="eszast-indian">East Indian</option>
                          </select>
                        </div>
                        <br />
                        <div className="form-outline mb-4">
                          <label>Select Birth Date and Time</label>
                          <input
                            type="datetime-local"
                            id="datetime"
                            name="datetimeChart"
                            onChange={(e) => setDatetimeChart(e.target.value)}
                          />
                        </div>

                        <div className="form-outline mb-4">
                          <label>Enter City</label>
                          <input
                            value={cityChart}
                            style={{ textTransform: "capitalize" }}
                            onChange={handleInputChangeChart}
                            id="city"
                            placeholder="Enter City"
                            type="text"
                          />
                        </div>

                        {/* <div className="form-outline mb-2">
                      <input
                        type="file"
                        name="image"
                        id="profilePicture"
                        className="form-control"
                        style={{ padding: "15px" }}
                      />
                    </div>
                    <p style={{ color: "#E82E44" }}>
                      (Upload a Image and File Size up to 4,089 KB & File Type
                      JPG or JPEG or PNG)
                    </p>
                    <p className="comment-form-cookies-consent">
                      <input
                        id="wp-comment-cookies-consent"
                        name="wp-comment-cookies-consent"
                        type="checkbox"
                      />
                      <label for="wp-comment-cookies-consent">
                        I agree to the Privacy Policy and Terms & Conditions.
                      </label>
                    </p> */}
                        {/* <span
                style={{
                  backgroundColor:"#ef5350",
                  display: err ? "flex" : 'none',
                  color: "white",
                  marginLeft: "10px",
                  padding:"10px",
                  marginBottom: "1rem",

                }}
              >
                {err ? err : false}
                </span> */}
                        <div
                          className="av-column-12"
                          style={{ paddingBottom: "1px" }}
                        >
                          {/* <a
                  >
                    {" "}
                    <button
                      type='button'
                      className="btn btn-primary btn-block mb-4"
                      style={{ float: "left" }}
                    >
                      Submit
                    </button>
                  </a> */}
                          {/* <a href='/register3'> <button
                  type="button"
                  className="btn btn-primary btn-block mb-4"
                  style={{float:"right"}}
                >
                  Skip
                </button></a> */}
                        </div>
                        {/* <!-- Register buttons --> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            onClick={handleBirthchartSubmit}
            sx={{ color: "#fff" }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {/* start birth chart modal*/}
      <Dialog
        open={basicopen}
        onClose={handleBasicClose}
        scroll={basicscroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle>
          <div style={{ float: "right" }}>
            <IoMdClose style={{ color: "#e1254c" }} onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent dividers={basicscroll} sx={{ background: "#f5f6fa;" }}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={basicdescriptionElementRef}
            tabIndex={-1}
          >
            <div className="av-container">
              <div className="av-columns-area">
                <div className="av-column-12 mx-auto">
                  <div id="comments" className="comments-area">
                    <div
                      id="respond"
                      className="comment-respond"
                      style={{ padding: "0px" }}
                    >
                      <form>
                        {/* <!-- Email input --> */}
                        <h3 id="reply-title" className="comment-reply-title">
                          Update Basic Details{" "}
                          <small>
                            <a
                              rel="nofollow"
                              id="cancel-comment-reply-link"
                              href="#"
                              style={{ display: "none" }}
                            >
                              Cancel reply
                            </a>
                          </small>
                        </h3>
                        <div className="form-outline mb-4">
                          <label>Enter Your Name</label>
                          <input
                            type="text"
                            name="userName"
                            id="form2Example1"
                            value={basicaddress.userName}
                            onChange={handleChange}
                            className="form-control"
                            style={{ textTransform: "capitalize" }}
                            placeholder="Username"
                          />
                        </div>

                        <div className="form-outline mb-4">
                          <label>Enter Age</label>
                          <input
                            type="number"
                            name="age"
                            id="form2Example1"
                            value={basicaddress.age}
                            onChange={handleChange}
                            className="form-control"
                            maxlength="10"
                            placeholder="Enter Your Age"
                          />
                        </div>

                        <div className="form-outline mb-4">
                          <label>Enter Country</label>
                          <input
                            name="country"
                            value={basicaddress.country}
                            onChange={handleChange}
                            id="country"
                            placeholder="Enter Country"
                            style={{ textTransform: "capitalize" }}
                            type="text"
                          />
                        </div>

                        <div className="form-outline mb-4">
                          <label>Enter State</label>
                          <input
                            name="state"
                            value={basicaddress.state}
                            onChange={handleChange}
                            id="state"
                            style={{ textTransform: "capitalize" }}
                            placeholder="Enter State"
                            type="text"
                          />
                        </div>

                        <div className="form-outline mb-4">
                          <label>Enter City</label>
                          <input
                            name="city"
                            value={basicaddress.city}
                            onChange={handleChange}
                            style={{ textTransform: "capitalize" }}
                            id="city"
                            placeholder="Enter City"
                            type="text"
                          />
                        </div>

                        {/* <div className="form-outline mb-2">
                          <input
                            type="file"
                            name="image"
                            id="profilePicture"
                            className="form-control"
                            style={{ padding: "15px" }}
                          />
                        </div>
                        <p style={{ color: "#E82E44" }}>
                          (Upload a Image and File Size up to 4,089 KB & File Type
                          JPG or JPEG or PNG)
                        </p>
                        <p className="comment-form-cookies-consent">
                          <input
                            id="wp-comment-cookies-consent"
                            name="wp-comment-cookies-consent"
                            type="checkbox"
                          />
                          <label for="wp-comment-cookies-consent">
                            I agree to the Privacy Policy and Terms & Conditions.
                          </label>
                        </p> */}
                        {/* <span
                    style={{
                      backgroundColor:"#ef5350",
                      display: err ? "flex" : 'none',
                      color: "white",
                      marginLeft: "10px",
                      padding:"10px",
                      marginBottom: "1rem",

                    }}
                  >
                    {err ? err : false}
                    </span> */}
                        <div
                          className="av-column-12"
                          style={{ paddingBottom: "1px" }}
                        >
                          {/* <a
                      >
                        {" "}
                        <button
                          type='button'
                          className="btn btn-primary btn-block mb-4"
                          style={{ float: "left" }}
                        >
                          Submit
                        </button>
                      </a> */}
                          {/* <a href='/register3'> <button
                      type="button"
                      className="btn btn-primary btn-block mb-4"
                      style={{float:"right"}}
                    >
                      Skip
                    </button></a> */}
                        </div>
                        {/* <!-- Register buttons --> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            onClick={handleBasicSubmit}
            sx={{ color: "#fff" }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {/* end basic details modal*/}

      {/* start personal details modal */}
      <Dialog
        open={personalopen}
        onClose={handlePersonalClose}
        scroll={perosnalscroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle>
          <div style={{ float: "right" }}>
            <IoMdClose style={{ color: "#e1254c" }} onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent
          dividers={perosnalscroll}
          sx={{ background: "#f5f6fa;" }}
        >
          <DialogContentText
            id="scroll-dialog-description"
            ref={personaldescriptionElementRef}
            tabIndex={-1}
          >
            <div className="av-container">
              <div className="av-columns-area">
                <div className="av-column-12 mx-auto">
                  <div id="comments" className="comments-area">
                    <div
                      id="respond"
                      className="comment-respond"
                      style={{ padding: "0px" }}
                    >
                      <form>
                        {/* <!-- Email input --> */}
                        <h3 id="reply-title" className="comment-reply-title">
                          Update Personal Details{" "}
                          <small>
                            <a
                              rel="nofollow"
                              id="cancel-comment-reply-link"
                              href="#"
                              style={{ display: "none" }}
                            >
                              Cancel reply
                            </a>
                          </small>
                        </h3>
                        <div className="form-outline mb-4">
                          <label>Height</label>
                          <input
                            name="height"
                            placeholder="Enter Height"
                            type="number"
                            value={personaladd?.height}
                            onChange={handlepersChange}
                            required
                            id="Height"
                          ></input>
                        </div>
                        <div className="form-outline mb-4">
                          <label>Weight</label>
                          <input
                            name="weight"
                            placeholder="Enter Weight"
                            type="number"
                            value={personaladd?.weight}
                            onChange={handlepersChange}
                            required
                            id="weight"
                          ></input>
                        </div>
                        <div className="form-outline mb-4">
                          <label>Complexion</label>
                          <select
                            name="complexion"
                            value={personaladd?.complexion}
                            onChange={handlepersChange}
                            required
                            id="complexion"
                          >
                            <option value="Complexion">
                              Choose Complexion
                            </option>
                            <option value="very fair">Very Fair</option>
                            <option value="fair">Fair</option>
                            <option value="wheatish">Wheatish</option>
                            <option value="wheatish brownn">
                              Wheatish Brownn
                            </option>
                            <option value="dark">Dark </option>
                          </select>
                        </div>
                        <div className="form-outline mb-4">
                          <label>Body Type</label>
                          <select
                            name="bodyType"
                            value={personaladd?.bodyType}
                            onChange={handlepersChange}
                            id="bodytype"
                          >
                            <option value="Body Type">Choose Body Type</option>
                            <option value="slim">Slim</option>

                            <option value="normal">Average</option>
                            <option value="fat">Fat</option>
                          </select>
                        </div>
                        <div className="form-outline mb-4">
                          <label>Physical Status</label>
                          <select
                            name="physicalStatus"
                            value={personaladd?.physicalStatus}
                            onChange={handlepersChange}
                            required
                            id="cars"
                          >
                            <option value="Physical Status">
                              Choose Physical Status
                            </option>
                            <option value="normal">Normal</option>
                            <option value="physically challenged">
                              Physically Challenged
                            </option>
                          </select>
                        </div>
                        <div className="form-outline mb-4">
                          <label>Eye wear</label>
                          <select
                            name="eyeWear"
                            value={personaladd?.eyeWear}
                            onChange={handlepersChange}
                            required
                            id="eyewear"
                          >
                            <option value="Eye Wear">Choose Eye Wear</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </div>
                        <div className="form-outline mb-4">
                          <label>Drinking Habit</label>
                          <select
                            name="drinkingHabits"
                            value={personaladd?.drinkingHabits}
                            onChange={handlepersChange}
                            id="drinkinghabits"
                          >
                            <option value="Drinking Habits">
                              Choose Drinking Habits
                            </option>
                            <option value="Never Drinks">Never Drink</option>
                            <option value="Drinks Socially">
                              Drinks Occasionally
                            </option>
                            <option value="Drinks Regularly">
                              Drinks Regularly
                            </option>
                            <option value="Not Specified">Not Specified</option>
                          </select>
                        </div>
                        <div className="form-outline mb-4">
                          <label>Smoking Habit</label>
                          <select
                            name="smokingHabits"
                            value={personaladd?.smokingHabits}
                            onChange={handlepersChange}
                            id="smokinghabits"
                          >
                            <option value="Smoking Habits">
                              Smoking Habits
                            </option>
                            <option value="Never Smokes">Never Smokes</option>
                            <option value="Smokes Occasionally">
                              Smoke Occasionally
                            </option>
                            <option value="Smokes Regularly">
                              Smoke Regularly
                            </option>
                            <option value="Not Specified">Not Specified</option>
                          </select>
                        </div>
                        <div className="form-outline mb-4">
                          <label>Eating Habit</label>
                          <select
                            name="eatingHabits"
                            value={personaladd?.eatingHabits}
                            onChange={handlepersChange}
                            id="eatinghabits"
                          >
                            <option value="Eating Habits">
                              Choose Eating Habits
                            </option>
                            <option value="Non-Vegetarian">
                              Non-Vegetarian
                            </option>
                            <option value="Vegetarian">Vegetarian</option>
                            <option value="Eggetarian">Eggetarian</option>
                            <option value="Not Specified">Not Specified</option>
                          </select>
                        </div>
                        <div className="form-outline mb-4">
                          <label>Blood Group</label>
                          <select
                            name="bloodGroup"
                            value={personaladd?.bloodGroup}
                            onChange={handlepersChange}
                            required
                            id="bloodGroup"
                          >
                            <option value="bg">Choose Blood Group</option>
                            <option value="A-">A-</option>
                            <option value="A+">A+</option>
                            <option value="B-">B-</option>
                            <option value="B+">B+</option>
                            <option value="O-">O-</option>
                            <option value="O+">O+</option>
                            <option value="AB-">AB-</option>
                            <option value="AB+">AB+</option>
                          </select>
                        </div>
                        <div className="form-outline mb-4">
                          <label>About Your Self</label>
                          <label>
                            <span className="wpcf7-form-control-wrap your-message">
                              <textarea
                                placeholder="About Myself"
                                value={personaladd?.aboutMySelf}
                                onChange={handlepersChange}
                                name="aboutMySelf"
                                required
                                cols="40"
                                rows="10"
                                className="wpcf7-form-control wpcf7-textarea"
                                aria-invalid="false"
                                maxLength={255}
                              ></textarea>
                            </span>
                          </label>
                        </div>
                        <div className="form-outline mb-4">
                          <label>Hobbies</label>
                          <label>
                            <span className="wpcf7-form-control-wrap your-message">
                              <textarea
                                placeholder="Hobbies"
                                value={personaladd?.hobbies}
                                onChange={handlepersChange}
                                name="hobbies"
                                cols="40"
                                rows="10"
                                className="wpcf7-form-control wpcf7-textarea"
                                aria-invalid="false"
                                maxLength={255}
                                style={{ textTransform: "capitalize" }}
                              ></textarea>
                            </span>
                          </label>
                        </div>

                        {/* <div className="av-column-12" style={{paddingBottom:"1px"}}>
                   <a 
                   > <button
                      type="submit"
                      className="btn btn-primary btn-block mb-4"
                      style={{float:"left"}}
                    >
                      Next
                    </button></a>
                    </div> */}
                        {/* <!-- Register buttons --> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            onClick={handlePersonalSubmit}
            sx={{ color: "#fff" }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {/* end personal details modal */}

      {/* start profession details modal */}
      <Dialog
        open={professionopen}
        onClose={handleProfessionClose}
        scroll={professionscroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle>
          <div style={{ float: "right" }}>
            <IoMdClose style={{ color: "#e1254c" }} onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent
          dividers={professionscroll}
          sx={{ background: "#f5f6fa;" }}
        >
          <DialogContentText
            id="scroll-dialog-description"
            ref={professiondescriptionElementRef}
            tabIndex={-1}
          >
            <div className="av-container">
              <div className="av-columns-area">
                <div className="av-column-12 mx-auto">
                  <div id="comments" className="comments-area">
                    <div
                      id="respond"
                      className="comment-respond"
                      style={{ padding: "0px" }}
                    >
                      <form>
                        {/* <!-- Email input --> */}
                        <h3 id="reply-title" className="comment-reply-title">
                          Update Education & Profession Details{" "}
                          <small>
                            <a
                              rel="nofollow"
                              id="cancel-comment-reply-link"
                              href="#"
                              style={{ display: "none" }}
                            >
                              Cancel reply
                            </a>
                          </small>
                        </h3>
                        <div className="form-outline mb-4">
                          <label>Marital Status</label>
                          <select
                            name="marriageStatus"
                            value={professionadd.maritalStatus}
                            onChange={handleprofChange}
                            id="maritalStatus
                            "
                          >
                            <option
                              name="maritalStatus
                              "
                              required
                              value="Choose marital status"
                            >
                              Choose Martial Status
                            </option>
                            <option value="never married">Never Married</option>
                            <option value="married">Married</option>
                            <option value="separated">Seperated</option>
                            <option value="divorced">Divorced</option>
                            <option value="widow/widower">Widow/widower</option>
                          </select>
                        </div>
                        <div className="form-outline mb-4">
                          <label>Profile Crteated By</label>
                          <select
                            name="profileCreatedBy"
                            value={professionadd.profileCreatedBy}
                            onChange={handleprofChange}
                            required
                            id="cars"
                          >
                            <option value="Choose Profile">
                              Profile Created By
                            </option>
                            <option value="self">Myself</option>
                            <option value="daughter">Daughter</option>
                            <option value="son">Son</option>
                            <option value="guardian">Guardian</option>
                            <option value="sibling">Sibling</option>
                            <option value="friend">Friend</option>
                            <option value="parent">Parent</option>
                            <option value="relative">Relative</option>
                          </select>
                        </div>
                        {/* <div className="form-outline mb-4">
                      <input
                        type="text"
                        name="referedBy"
                        
                        id="form2Example1"
                        className="form-control"
                        maxlength="10"
                        placeholder="Enter Your Whatsapp Number"
                      />
                    </div> */}
                        <div className="form-outline mb-4">
                          <label>Refered By </label>
                          <input
                            type="text"
                            name="referedBy"
                            value={professionadd.referedBy}
                            onChange={handleprofChange}
                            id="form2Example1"
                            className="form-control"
                            placeholder="Refered By"
                            style={{ textTransform: "capitalize" }}
                          />
                        </div>
                        <div className="form-outline mb-4">
                          <label>Enter Your Qualification </label>
                          <input
                            type="text"
                            name="educationalQualification"
                            value={professionadd.educationalQualification}
                            onChange={handleprofChange}
                            id="form2Example1"
                            className="form-control"
                            placeholder="Enter Your Qualification"
                            style={{ textTransform: "capitalize" }}
                          />
                        </div>
                        <div className="form-outline mb-4">
                          <label>Religion</label>
                          <select
                            name="religion"
                            value={professionadd.religion}
                            onChange={handleprofChange}
                            required
                            style={{ textTransform: "capitalize" }}
                            id="religion"
                          >
                            <option value="Choose Profile">
                              {" "}
                              Choose Religion
                            </option>
                            <option value="hindu">Hindu</option>
                            <option value="christian">Christian</option>
                            <option value="muslim">Muslim</option>
                            <option value="other">Others</option>
                          </select>
                        </div>
                        <div className="form-outline mb-4">
                          <label>Mother Tongue</label>
                          <select
                            name="motherTongue"
                            value={professionadd.motherTongue}
                            onChange={handleprofChange}
                            required
                            id="motherTongue"
                          >
                            <option value="Choose Profile">
                              Choose Mother Tongue
                            </option>
                            <option value="tamil">Tamil</option>
                            <option value="telugu">Telugu</option>
                            <option value="malayalam">Malayalam</option>
                            <option value="hindi">Hindi</option>
                            <option value="other">Other</option>
                          </select>
                        </div>
                        <div className="form-outline mb-4">
                          <label>Caste </label>
                          <select
                            name="caste"
                            required
                            onChange={handleCasteChange}
                            value={professionadd.caste}
                            style={{ textTransform: "capitalize" }}
                            id="caste"
                            className="form-control "
                          >
                            <option value="">Choose Caste</option>
                            {lowercaseCasteOptions.map((caste, index) => (
                              <option key={index} value={caste}>
                                {caste}
                              </option>
                            ))}
                          </select>
                        </div>

                        {caste &&
                        subCasteOptionsLowercase[caste] &&
                        subCasteOptionsLowercase[caste].length > 0 ? (
                          <div className="form-outline mb-4">
                            <label>Sub Caste</label>
                            <select
                              name="subCaste"
                              required
                              onChange={handleSubCasteChange}
                              value={professionadd.subCaste}
                              style={{ textTransform: "capitalize" }}
                              id="subCaste"
                              className="form-control "
                            >
                              <option value="">Choose Sub Caste</option>
                              {subCasteOptionsLowercase[caste].map(
                                (subCaste, index) => (
                                  <option key={index} value={subCaste}>
                                    {subCaste}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        ) : (
                          <div className="form-outline mb-4">
                            <label>Enter Your Sub Caste</label>
                            <input
                              placeholder="Sub Caste"
                              type="text"
                              name="subCaste"
                              style={{ textTransform: "capitalize" }}
                              required
                              onChange={handleSubCasteChange}
                              value={list.data.userDetails.subCaste}
                              id="subCaste"
                              className="form-control "
                            />
                          </div>
                        )}
                        <div className="form-outline mb-4">
                          <label>Profession</label>
                          <select
                            name="profession"
                            value={professionadd.profession}
                            onChange={handleprofChange}
                            required
                            id="profession"
                          >
                            <option value="Choose profession">
                              Choose Profession
                            </option>
                            <option value="employee">Employed</option>
                            <option value="bussiness">Bussiness</option>
                            <option value="student">Student</option>
                            <option value="not working">Not Working</option>
                            <option value="not mentioned">Not Mentioned</option>
                          </select>
                        </div>
                        <div className="form-outline mb-4">
                          <label>Enter Professional Designation</label>
                          <input
                            type="text"
                            name="professionDesignation"
                            required
                            value={professionadd.professionDesignation}
                            onChange={handleprofChange}
                            style={{ textTransform: "capitalize" }}
                            id="form2Example1"
                            className="form-control"
                            placeholder="Profession Designation"
                          />
                        </div>
                        <div className="form-outline mb-4">
                          <label>Enter Professional Description</label>
                          <label>
                            <span className="wpcf7-form-control-wrap your-message">
                              <textarea
                                placeholder="Profession in details"
                                name="professionDesc"
                                value={professionadd.professionDesc}
                                onChange={handleprofChange}
                                cols="40"
                                rows="10"
                                className="wpcf7-form-control wpcf7-textarea"
                                aria-invalid="false"
                                maxLength={255}
                                style={{ textTransform: "capitalize" }}
                              ></textarea>
                            </span>
                          </label>
                        </div>
                        <div className="form-outline mb-4">
                          <label>Profession Location</label>
                          <select
                            name="professionLocation"
                            value={professionadd.professionLocation}
                            onChange={handleprofChange}
                            id="professionLocation"
                          >
                            <option value="Choose Profile">
                              Choose Profession Location
                            </option>
                            <option value="bangalore">Bangalore</option>
                            <option value="chennai">Chennai</option>
                            <option value="coimbatore">Coimbatore</option>
                            <option value="mumbai">Mumbai</option>
                            <option value="hyderabad">Hyderabad</option>
                            <option value="other places">Other Places</option>
                          </select>
                        </div>
                        <div className="form-outline mb-4">
                          <label>Annual Income</label>
                          <select
                            name="annualIncome"
                            value={professionadd.annualIncome}
                            onChange={handleprofChange}
                            required
                            id="annualIncome"
                          >
                            <option value="Choose Profile">
                              Choose Annual Income
                            </option>
                            <option value="0-1 Lakhs">0-1 Lakhs</option>
                            <option value="1-2 Lakhs">1-2 Lakhs</option>
                            <option value="2-3 Lakhs">2-3 Lakhs</option>
                            <option value="3-4 Lakhs">3-4 Lakhs</option>
                            <option value="4-5 Lakhs">4-5 Lakhs</option>
                            <option value="5-6 Lakhs">5-6 Lakhs</option>
                            <option value="6-7 Lakhs">6-7 Lakhs</option>
                            <option value="7-8 Lakhs">7-8 Lakhs</option>
                            <option value="8-9 Lakhs">8-9 Lakhs</option>
                            <option value="9-10 Lakhs">9-10 Lakhs</option>
                            <option value="10 Lakhs above">
                              10 Lakhs above
                            </option>
                          </select>
                        </div>
                        {/* <div className="av-column-12" style={{paddingBottom:"1px"}}>
                   <a 
                   > <button
                      type="submit"
                      className="btn btn-primary btn-block mb-4"
                      style={{float:"left"}}
                    >
                      Next
                    </button></a>
                    <a href='/register4'> <button
                      type="button"
                      className="btn btn-primary btn-block mb-4"
                      style={{float:"right"}}
                    >
                      Skip
                    </button></a>
                    </div> */}
                        {/* <!-- Register buttons --> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            onClick={handleProfessionSubmit}
            sx={{ color: "#fff" }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {/* end profession details modal */}

      {/*start partner preference modal  */}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle>
          <div style={{ float: "right" }}>
            <IoMdClose style={{ color: "#e1254c" }} onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent
          dividers={scroll === "paper"}
          sx={{ background: "#f5f6fa;" }}
        >
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <div className="av-container">
              <div className="av-columns-area">
                <div className="av-column-12 mx-auto">
                  <div id="comments" className="comments-area">
                    <div
                      id="respond"
                      className="comment-respond"
                      style={{ padding: "0px" }}
                    >
                      <form>
                        {/* <!-- Email input --> */}
                        <h4 id="reply-title" className="comment-reply-title">
                          Update Partner Preference{" "}
                          <small>
                            <a
                              rel="nofollow"
                              id="cancel-comment-reply-link"
                              href="#"
                              style={{ display: "none" }}
                            >
                              Cancel reply
                            </a>
                          </small>
                        </h4>
                        <div className="form-outline mb-4">
                          <label>Age</label>
                          <input
                            type="number"
                            name="age"
                            value={prefaddress.age}
                            onChange={handleprefChange}
                            required
                            id="form2Example1"
                            className="form-control"
                            placeholder="Age"
                          />
                        </div>
                        <div className="form-outline mb-4">
                          <label>Height</label>
                          <input
                            type="number"
                            name="height"
                            value={prefaddress.height}
                            onChange={handleprefChange}
                            required
                            id="form2Example1"
                            className="form-control"
                            placeholder="Height"
                          />
                        </div>
                        <div className="form-outline mb-4">
                          <label>Dhosam</label>
                          <select
                            name="dhosam"
                            value={prefaddress.dhosam}
                            onChange={handleprefChange}
                            required
                            id="dhosam"
                          >
                            <option value="Choose dhoosam">Dhosam</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </div>
                        <div className="form-outline mb-4">
                          <label>Rahu Kethu</label>
                          <select
                            name="rahuKetu"
                            value={prefaddress.rahuKetu}
                            onChange={handleprefChange}
                            style={{ textTransform: "capitalize" }}
                            required
                            id="rahuKetu"
                          >
                            <option value="Choose Rahu/kethu">
                              Rahu/Kethu
                            </option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </div>
                        <div className="form-outline mb-4">
                          <label>Annual Income</label>
                          <select
                            name="annualIncome"
                            value={prefaddress.annualIncome}
                            onChange={handleprefChange}
                            required
                            id="annualIncome"
                          >
                            <option value="Choose Annual Income">
                              Annual Income
                            </option>
                            <option value="0-1 Lakhs">0-1 Lakhs</option>
                            <option value="1-2 Lakhs">1-2 Lakhs</option>
                            <option value="2-3 Lakhs">2-3 Lakhs</option>
                            <option value="3-4 Lakhs">3-4 Lakhs</option>
                            <option value="4-5 Lakhs">4-5 Lakhs</option>
                            <option value="5-6 Lakhs">5-6 Lakhs</option>
                            <option value="6-7 Lakhs">6-7 Lakhs</option>
                            <option value="7-8 Lakhs">7-8 Lakhs</option>
                            <option value="8-9 Lakhs">8-9 Lakhs</option>
                            <option value="9-10 Lakhs">9-10 Lakhs</option>
                            <option value="10 Lakhs above">
                              10 Lakhs above
                            </option>
                          </select>
                        </div>
                        <div className="form-outline mb-4">
                          <label>Foreign Intrest</label>
                          <select
                            name="foreignInterest"
                            value={prefaddress.foreignInterest}
                            onChange={handleprefChange}
                            required
                            id="foreignInterest"
                          >
                            <option value="Choose Foreign Intrest">
                              Foreign Intrest
                            </option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </div>
                        <div className="form-outline mb-4">
                          Martial Status
                          <select
                            name="maritalStatus"
                            value={prefaddress.maritalStatus}
                            onChange={handleprefChange}
                            required
                            id="maritalStatus"
                          >
                            <option value="Choose Martial Status">
                              Martial Status
                            </option>
                            <option value="never married">Never Married</option>
                            <option value="married">Married</option>
                            <option value="separated">Seperated</option>
                            <option value="divorced">Divorced</option>
                            <option value="widow/widower">Widow/widower</option>
                          </select>
                        </div>
                        {/* <div className="form-outline mb-4">
                      <select name="cars"  id="cars">
                        <option value="Choose Matching Star">Matching Star</option>
                        <option value="Male">kiruthigai</option>
                        <option value="Male">Anusham</option>
                        <option value="Female">Other</option>
                      </select>
                    </div> */}
                        {/* <div className="av-column-12" style={{paddingBottom:"1px"}}>
                   <a
                    > <button
                      type="submit"
                      className="btn btn-primary btn-block mb-4"
                      style={{float:"left"}}
                    >
                      Next
                    </button></a>
                    </div> */}
                        {/* <!-- Register buttons --> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button type="submit" onClick={handleSUbmit} sx={{ color: "#fff" }}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={del}
        onClose={handleDel}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle>
          <div style={{ float: "right" }}>
            <IoMdClose style={{ color: "#e1254c" }} onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent
          dividers={scroll === "paper"}
          sx={{ background: "#f5f6fa;" }}
        >
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <div className="av-container">
              <div className="av-columns-area">
                <div className="av-column-12 mx-auto">
                  <div id="comments" className="comments-area">
                    <div
                      id="respond"
                      className="comment-respond"
                      style={{ padding: "0px" }}
                    >
                      <form>
                        {/* <!-- Email input --> */}
                        <h4 id="reply-title" className="comment-reply-title">
                          If you delete your profile, it cannot be restored.{" "}
                        </h4>

                        <div className="form-outline mb-4">
                          Are Sure to Delete your Account
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button type="submit" onClick={handleDelete} sx={{ color: "#fff" }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* end partner preference model */}
    </div>
  ) : (
    <Loader />
  );
};

export default Profile;
