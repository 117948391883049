import React from "react";
// import { Link } from 'react-router-dom';

const Footer = () => {
  const d = new Date();
  const year = d.getFullYear();
  const Token = localStorage.getItem("Authtok");

  return (
    <div>
      {/* <!--===// Start: Footer
    =================================--> */}
      <footer id="footer-section" className="footer-section footer-one">
        {/* <div className="footer-above">
            <div className="av-container">
                <div className="av-columns-area">
                    <div className="av-column-4 av-sm-column-6">
                        <aside className="widget widget-contact">
                            <div className="contact-area">
                                <div className="contact-icon"><i className="fa fa-clock-o"></i></div>
                                <a href="#" className="contact-info">
                                    <span className="text">Mon-Fri 9am-6pm</span>
                                    <span className="title">Mon-Sat: 8am-5pm</span>
                                </a>
                            </div>
                        </aside>
                    </div>
                    <div className="av-column-4 av-sm-column-6">
                        <aside className="widget widget-contact">
                            <div className="contact-area">
                                <div className="contact-icon"><i className="fa fa-envelope-o"></i></div>
                                <a href="#" className="contact-info">
                                    <span className="text">Support Mail</span>
                                    <span className="title">info@example.com</span>
                                </a>
                            </div>
                        </aside>
                    </div>
                    <div className="av-column-4 av-sm-column-6">
                        <aside className="widget widget-contact">
                            <div className="contact-area">
                                <div className="contact-icon"><i className="fa fa-map-marker"></i></div>
                                <a href="#" className="contact-info">
                                    <span className="text">380 St Klida Road</span>
                                    <span className="title">Melbourne, Australia</span>
                                </a>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </div> */}
        <div className="footer-main">
          <div className="av-container">
            <div className="av-columns-area">
              <div className="av-column-6 pr-md-5">
                <aside className="widget widget_text">
                  <h4 className="widget-title" data-hover="About VVk Matrimony">
                    About VVK Matrimony
                  </h4>
                  <div className="textwidget">
                    <p>
                      We understand that finding a life partner is one of the
                      most important and personal decisions of one's life.
                    </p>
                    <div className="footer-badge">
                      <img
                        src="assets/images/footer/certificate01.png"
                        alt="badge"
                      />
                      <img
                        src="assets/images/footer/certificate02.png"
                        alt="badge"
                      />
                      <img
                        src="assets/images/footer/certificate03.png"
                        alt="badge"
                      />
                    </div>
                  </div>
                </aside>
              </div>
              <div className="av-column-6 pl-md-5">
                <div className="av-columns-area">
                  <div className="av-column-5 av-md-column-6">
                    <aside className="widget widget_categories">
                      <h4 className="widget-title" data-hover="Quick Links">
                        Quick Links
                      </h4>
                      <ul>
                        {Token ? (
                          <li className="cat-item">
                            <a href="/successstory">Success Stories</a>
                          </li>
                        ) : (
                          null
                          // <li className="cat-item">
                          //   <a href="/awards">Awards</a>
                          // </li>
                        )}
                        {Token ? (
                          <li className="cat-item">
                            <a href="/feedback">Feedback</a>
                          </li>
                        ) : null}
                       

                        <li className="cat-item">
                          <a href="/contact">Contact</a>
                        </li>
                        <li className="cat-item">
                          <a href="/privacypolicy">Privacy Policy</a>
                        </li>
                        <li className="cat-item">
                          <a href="/terms&condition">Terms & Conditions</a>
                        </li>
                        <li className="cat-item">
                          <a href="/disclaimer">Disclaimer</a>
                        </li>
                      </ul>
                    </aside>
                  </div>
                  <div className="av-column-7 av-md-column-6">
                    <aside className="widget widget-contact">
                      <h4 className="widget-title" data-hover="Get In Touch">
                        Get In Touch
                      </h4>
                      <div className="contact-area">
                        <div className="contact-icon">
                          <i className="fa fa-envelope-o"></i>
                        </div>
                        <a
                          href="mailto:vvkmatrimony12@gmail.com"
                          target="_blank"
                          className="contact-info"
                        >
                          <span className="title">Email</span>
                          <span className="text">vvkmatrimony12@gmail.com</span>
                        </a>
                      </div>
                      <div className="contact-area">
                        <div className="contact-icon">
                          <i className="fa fa-phone"></i>
                        </div>
                        <a
                          href="tel:+(91)9894234066"
                          target="_blank"
                          className="contact-info"
                        >
                          <span className="title">Phone</span>
                          <span className="text">+(91)98942 34066</span>
                        </a>
                      </div>
                      <div className="contact-area">
                        <div className="contact-icon">
                          <i className="fa fa-whatsapp"></i>
                        </div>
                        <a
                          href="https://wa.me/+919894234066"
                          target="_blank"
                          className="contact-info"
                        >
                          <span className="title">Whatsapp</span>
                          <span className="text">+(91)98942 34066</span>
                        </a>
                      </div>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-info-overwrap">
            <div className="icon">
              <a href="https://wa.me/+919894234066" target="_blank">
                <i className="fa fa-whatsapp" style={{ color: "#fff" }}></i>
              </a>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="av-container">
            <div className="av-columns-area">
              <div className="av-column-4 av-md-column-6 text-md-left text-center">
                <div className="widget-left">
                  <div className="logo">
                    <a href="/" className="site-title">
                      <h6>
                        VVK <span className="primary-color">Matrimony</span>
                      </h6>
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className="av-column-4 av-md-column-6 text-md-center text-center">
                <div className="widget-center">
                  <aside className="share-toolkit widget widget_social_widget">
                    <a href=";" className="toolkit-hover">
                      <i className="fa fa-share-alt"></i>
                    </a>
                    <ul>
                      <li>
                        <a href=";">
                          <i className="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href=";">
                          <i className="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href=";">
                          <i className="fa fa-linkedin"></i>
                        </a>
                      </li>
                      <li>
                        <a href=";">
                          <i className="fa fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href=";">
                          <i className="fa fa-google-plus"></i>
                        </a>
                      </li>
                    </ul>
                  </aside>
                </div>
              </div> */}
              <div className="av-column-4 av-md-column-6 text-av-right text-md-left text-center">
                <div className="widget-right">
                  <div className="copyright-text">
                    © {year}{" "}
                    <a href="/" target="_blank">
                      VVK Matrimony.
                    </a>{" "}
                    All Rights Reserved
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- End: Footer
    =================================--> */}
    </div>
  );
};

export default Footer;
