import React from "react";

const TermsandConditions = () => {
  return (
    <div>
      {/* <!--===// Start: Breadcrumb
        =================================--> */}
      <section id="breadcrumb-section" class="breadcrumb-area breadcrumb-left">
        <div class="av-container">
          <div class="av-columns-area">
            <div class="av-column-12">
              <div class="breadcrumb-content">
                <div class="breadcrumb-heading wow fadeInLeft">
                  <h2>Terms and Conditions</h2>
                </div>
                <ol class="breadcrumb-list wow fadeInRight">
                  <li>
                    <a href="/">
                      <i class="fa fa-home"></i> Home Page
                    </a>{" "}
                    &nbsp;-&nbsp;
                  </li>
                  <li class="active">Terms and Conditions</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: Breadcrumb
        =================================--> */}

      {/* <!--===// Start: terms and conditions
        =================================--> */}
      <section
        id="timeline-section"
        class="timeline-section av-py-default shapes-section"
        style={{ backgroundColor: "#fff" }}
      >
        <div class="av-container">
          <div class="av-column-12">
            <div class="heading-default text-center wow fadeInUp">
              <h3>
                Terms and <span class="primary-color">Conditions</span>
              </h3>
              <span class="separator">
                <span>
                  <span></span>
                </span>
              </span>
            </div>
          </div>
          <div class="av-columns-area">
            <div class="av-column-12 timeline">
              <div class="av-columns-area timeline-item">
                <div class="av-xs-column-12 av-md-column-12 order-3 order-md-1 timeline-content wow fadeInRight">
                  {" "}
                  <strong>Members Eligibility Criteria:</strong>
                  <p style={{ marginBottom: "60px" }}>
                    By registering on VVK matrimony, you certify that you are of
                    legal age to marry in accordance with the laws of your
                    country of residence. You also certify that you are either
                    an Indian national, a non-resident Indian, or a person of
                    Indian origin. You are solely responsible for ensuring the
                    accuracy and truthfulness of any information you provide on
                    the website, including personal information and any details
                    related to your search for a potential partner. You agree
                    not to use this website for any unlawful or fraudulent
                    activities or for any activities that may infringe the
                    rights of others. By using this website, you represent and
                    warrant that you have the legal capacity to enter into these
                    Terms and Conditions and that you are not prevented by any
                    applicable laws, orders, decrees, or injunctions from any
                    court, tribunal, or competent authority from entering into
                    matrimony.
                    <br />
                    <br />
                    You acknowledge and accept that VVK matrimony will not be
                    responsible for any loss or damage resulting from
                    unauthorized use of your account, whether with or without
                    your knowledge. Therefore, we strongly recommend that you do
                    not share your matrimonial account information with anyone.
                    VVK matrimony reserves the right to deactivate your
                    membership and/or your right to use the Service without any
                    refund of fees for violating our terms. The website may also
                    deactivate your account and deny you any and all current or
                    future services at any time, without notice, for any reason
                    whatsoever.
                  </p>
                </div>
                <div class="av-xs-column-12 av-md-column-12 order-3 order-md-1 timeline-content wow fadeInRight">
                  {" "}
                  <strong>User Conduct</strong>
                  <p style={{ marginBottom: "60px" }}>
                    VVK matrimony has no commitment to observe and monitor the
                    services. You agreed that you are solely responsible for the
                    content that you publish on the service. You agree not to
                    harass, intimidate, or defame other users of the website,
                    you agree not to use the website to solicit business or
                    promote any commercial activities. You agree not to use the
                    website to send unsolicited messages or spam. However,
                    vvkmatrimony.com reserves the right to review and removal of
                    any user to misconduct or violating the user conduct and
                    terms. We respect the privacy of our users and have a
                    separate privacy policy that governs our collection, use,
                    and disclosure of personal information. By using the
                    website, you agree to the terms of our privacy policy
                  </p>
                </div>
                <div class="av-xs-column-12 av-md-column-12 order-3 order-md-1 timeline-content wow fadeInRight">
                  {" "}
                  <strong>Advertisements and promotions:</strong>
                  <p style={{ marginBottom: "60px" }}>
                    By accessing vvkmatrimony.com, you acknowledge and agree
                    that the website may display sponsored content, including
                    but not limited to sponsored profiles, featured listings,
                    and highlighted matches. These sponsored features may be
                    displayed on various pages of the website, and
                    vvkmatrimony.com reserves the right to use third-party
                    partners to display such content. You understand that such
                    sponsored content may be presented to you based on your
                    search preferences, and you consent to receiving such
                    content. Please note that vvkmatrimony.com is not
                    responsible for the accuracy or quality of any sponsored
                    content, and you agree to conduct your own due diligence
                    before engaging with any such content.
                  </p>
                </div>
                <div class="av-xs-column-12 av-md-column-12 order-3 order-md-1 timeline-content wow fadeInRight">
                  {" "}
                  <strong>Disclaimers and Limitation of Liability </strong>
                  <p style={{ marginBottom: "60px" }}>
                    The information provided on our website is for general
                    informational purposes only. We do our best to ensure that
                    the information is accurate, reliable, and up-to-date, but
                    we cannot guarantee the completeness, timeliness, or
                    reliability of the information. Please note that the website
                    may contain errors or omissions, and we do not make any
                    warranty or representation regarding the accuracy of the
                    information provided.
                    <br />
                    <br />
                    We also cannot guarantee that the website will be free from
                    errors or interruptions, or that our servers are free from
                    viruses or other harmful components. You acknowledge that
                    your use of the website is at your own risk, and we will not
                    be liable for any direct, indirect, incidental, special, or
                    consequential damages arising from your use of the website
                    or from any information, content, or materials on the
                    website.
                    <br />
                    <br />
                    Therefore, we advise you to take appropriate measures to
                    protect yourself, such as installing reliable antivirus
                    software and keeping your operating system and web browser
                    up-to-date. You should also independently verify any
                    information you find on the website before making any
                    decisions based on it.
                  </p>
                </div>
                <div class="av-xs-column-12 av-md-column-12 order-3 order-md-1 timeline-content wow fadeInRight">
                  {" "}
                  <strong>Cancellation:</strong>
                  <p>
                    Once a purchase is made, it cannot be cancelled or refunded.
                    Please carefully review all details of the purchase before
                    making payment to ensure it meets your needs and
                    expectations. We apologize for any inconvenience this policy
                    may cause, but it allows us to maintain the quality and
                    affordability of our products/services. If you have any
                    questions or concerns regarding a purchase, please do not
                    hesitate to contact us for assistance. Thank you for using
                    vvkmatrimony.com if you have any questions or concerns about
                    these terms and conditions, please contact us at [Insert
                    contact information].
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div class='shape1'>
          <img src='assets/images/service/clipArt/shape1.png' alt='image' />
        </div>
        <div class='shape2'>
          <img src='assets/images/service/clipArt/shape2.png' alt='image' />
        </div>
        <div class='shape3'>
          <img src='assets/images/service/clipArt/shape3.png' alt='image' />
        </div>
        <div class='shape4'>
          <img src='assets/images/service/clipArt/shape4.png' alt='image' />
        </div>
        <div class='shapetimeline1'>
          <img src='assets/images/timeline/timeline1.png' alt='image' />
        </div>
        <div class='shapetimeline2'>
          <img src='assets/images/timeline/timeline2.png' alt='image' />
        </div>
        <div class='shapetimeline3'>
          <img src='assets/images/timeline/timeline3.png' alt='image' />
        </div> */}
      </section>
      {/* <!-- End: terms and conditions
        =================================--> */}
    </div>
  );
};

export default TermsandConditions;
