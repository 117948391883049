import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { regSearch } from "../Redux/Action";
import { useDispatch, useSelector } from "react-redux";
// import * as React from 'react';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
const casteOptions = [
  "24 Manai Telugu Chettiar",
  " Aaru Nattu Vellala",
  " Achirapakkam Chettiar",
  " Adi Dravidar",
  " Agamudayar / Arcot / Thuluva Vellala",
  "Agaram Vellan Chettiar",
  "Ahirwar",
  "Arunthathiyar",
  "Ayira Vysya",
  "Badaga",
  "Bairwa",
  "Balai",
  "Beri Chettiar",
  "Boyar",
  "Brahmin - Anaviln Desai",
  "Brahmin - Baidhiki/Vaidhiki",
  "Brahmin - Bardai",
  "Brahmin - Bhargav",
  "Brahmin - Gurukkal",
  "Brahmin - Iyengar",
  "Brahmin - lyer",
  "Brahmin - Khadayata",
  "Brahmin - Khedaval",
  "Brahmin - Mevada",
  "Brahmin - Others",
  "Brahmin - Rajgor",
  "Brahmin - Rarhi/Radhi",
  "Brahmin - Sarua",
  "Brahmin - Shri Gaud",
  "Brahmin - Tapodhan",
  "Brahmin - Valam",
  "Brahmin - Zalora",
  "Chattada Sri Vaishnava",
  "Cherakula Vellalar",
  "Chettiar",
  "Dasapalanjika / Kannada Saineegar",
  "Desikar",
  "Desikar Thanjavur",
  "Devandra Kula Vellalar",
  "Devanga Chettiar",
  "Devar/Thevar/Mukkulathor",
  "Dhanak",
  "Elur Chetty",
  "Gandla / Ganiga",
  "Gounder",
  "Gounder - Kongu Vellala Gounder",
  "Gounder - Nattu Gounder",
  "Gounder - Others -",
  "Gounder - Urali Gounder",
  "Gounder - Vanniya Kula Kshatriyar",
  "Gounder - Vettuva Gounder",
  "Gramani",
  "Gurukkal Brahmin",
  "Illaththu Pillai",
  "Intercaste",
  "Isai Vellalar",
  "Iyengar Brahmin",
  "Iyer Brahmin",
  "Julaha",
  "Kamma Naidu",
  "Kanakkan Padanna",
  "Kandara",
  "Karkathar",
  "Karuneegar",
  "Kasukara",
  "Kerala Mudali",
  "Khatik",
  "Kodikal Pillai",
  "Kongu Chettiar",
  "Kongu Nadar",
  "Kongu Vellala Gounder",
  "Kori/Koli",
  "Krishnavaka",
  "Kshatriya Raju",
  "Kulalar",
  "Kuravan",
  "Kuruhini Chetty",
  "Kurumbar",
  "Kuruva",
  "Manjapudur Chettiar",
  "Mannan/Velan / Vannan",
  "Maruthuvar",
  "Meenavar",
  "Meghwal",
  "Mudaliyar",
  "Mukkulathor",
  "Muthuraja / Mutharaiyar",
  "Nadar",
  "Naicker",
  "Naicker - Others",
  "Naicker - Vanniya Kula Kshatriyar",
  "Naidu",
  "Nanjil Mudali",
  "Nanjil Nattu Vellalar",
  "Nanjil Vellalar",
  "Nanjil pillai",
  "Nankudi Vellalar",
  "Nattu Gounder",
  "Nattukottai Chettiar",
  "Othuvaar",
  "Padmashali",
  "Pallan / Devandra Kula Vella",
  "Panan",
  "Pandaram",
  "Pandiya Vellalar",
  "Pannirandam Chettiar",
  "Paravan / Bharatar",
  "Parkavakulam / Udayar",
  "Parvatha Rajakulam",
  "Paswan / Dusadh",
  "Pattinavar",
  "Pattusali",
  "Pillai",
  "Poundra",
  "Pulaya/Cheruman",
  "Reddy",
  "Rohit / Chamar",
  "SC",
  "ST",
  "Sadhu Chetty",
  "Saiva Pillai Thanjavur",
  "Saiva Pillai Tirunelveli",
  "Saiva Vellan chettiar",
  "Saliyar",
  "Samagar",
  "Sambava",
  "Satnami",
  "Senai Thalaivar",
  "Senguntha Mudaliyar",
  "Sengunthar/Kaikolar",
  "Shilpkar",
  "Sonkar",
  "Sourashtra",
  "Sozhia Chetty",
  "Sozhiya Vellalar",
  "Telugupatti",
  "Thandan",
  "Thondai Mandala Vellalar",
  "Urali Gounder",
  "Vadambar",
  "Vadugan",
  "Valluvan",
  "Vaniya Chettiar",
  "Vannar",
  "Vannia Kula Kshatriyar",
  "Veera Saivam",
  "Veerakodi Vellala",
  "Vellalar",
  " Vellan Chettiars",
  "Vettuva Gounder",
  "Vishwakarma",
  "Vokkaliga",
  " Yadav",
  " Yadava Naidu",
];

const Search = () => {
  const [search, setSearch] = React.useState(null);
  const navigate = useNavigate();
  const [casteError, setCasteError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [stateError, setStateError] = useState("");
  const [disError, setDisError] = useState("");

  const [ageError, setAgeError] = useState("");
  const [ageToError, setAgeToError] = useState("");
  const handleChange = (e) => {
    if (e.target.name === "ageFrom" || e.target.name === "ageTo") {
      const integerValue = parseInt(e.target.value);
      if (isNaN(integerValue) || integerValue < 0) {
        e.target.value = "";
        return;
      }
    }

    if (e.target.name === "caste") {
      if (!/^[A-Za-z\s]+$/.test(e.target.value)) {
        setCasteError("Please enter only letters (A-Z, a-z)");
      } else {
        setCasteError("");
      }
    }
    if (e.target.name === "country") {
      if (!/^[A-Za-z\s]+$/.test(e.target.value)) {
        setCountryError("Please enter only letters (A-Z, a-z)");
      } else {
        setCountryError("");
      }
    }
    if (e.target.name === "state") {
      if (!/^[A-Za-z\s]+$/.test(e.target.value)) {
        setStateError("Please enter only letters (A-Z, a-z)");
      } else {
        setStateError("");
      }
    }
    if (e.target.name === "district") {
      if (!/^[A-Za-z\s]+$/.test(e.target.value)) {
        setDisError("Please enter only letters (A-Z, a-z)");
      } else {
        setDisError("");
      }
    }

    if (e.target.name === "ageTo") {
      const enteredAge = parseInt(e.target.value);
      if (enteredAge < 18) {
        setAgeError("Age must be 18 or older");
      } else {
        setAgeError("");
      }
    }
    if (e.target.name === "ageFrom") {
      const enteredAge = parseInt(e.target.value);
      if (enteredAge < 18) {
        setAgeToError("Age must be 18 or older");
      } else {
        setAgeToError("");
      }
    }
    setSearch({ ...search, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/searchprofile", {
      state: {
        searchQuery: search,
      },
    });
    // dispatch(regSearch(search)).then((res) => {
    // navigate("/searchprofile");
    // });
  };

  return (
    <div>
      {/* <!--===// Start: Breadcrumb
        =================================--> */}
      <section id="breadcrumb-section" class="breadcrumb-area breadcrumb-left">
        <div class="av-container">
          <div class="av-columns-area">
            <div class="av-column-12">
              <div class="breadcrumb-content">
                <div class="breadcrumb-heading wow fadeInLeft">
                  <h2>Regular Search</h2>
                </div>
                <ol class="breadcrumb-list wow fadeInRight">
                  <li>
                    <a href="/">
                      <i class="fa fa-home"></i> Home Page
                    </a>{" "}
                    &nbsp;-&nbsp;
                  </li>
                  <li class="active">Search</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: Breadcrumb
        =================================--> */}

      <section
        id="post-section"
        className="post-section av-py-default"
        style={{
          backgroundColor: "#fff",
          // backgroundImage: "url('/assets/images/register.jpeg')",
        }}
      >
        <div className="av-container">
          <div className="av-columns-area">
            <div className="av-column-10 mx-auto">
              <div id="comments" className="comments-area">
                <div id="respond" className="comment-respond">
                  <form onSubmit={handleSubmit}>
                    {/* <!-- Email input --> */}
                    <h3 id="reply-title" className="comment-reply-title">
                      Regular Search{" "}
                      <small>
                        <a
                          rel="nofollow"
                          id="cancel-comment-reply-link"
                          href="#"
                          style={{ display: "none" }}
                        >
                          Cancel reply
                        </a>
                      </small>
                    </h3>
                    <div className="form-outline mb-4">
                      <select
                        name="religion"
                        id="religion"
                        required
                        onChange={handleChange}
                      >
                        <option value="Choose Profile">Select Religion</option>
                        <option value="hindu">Hindu</option>
                        <option value="christian">Christian</option>
                        <option value="muslim">Muslim</option>
                        <option value="others">Others</option>
                      </select>
                    </div>
                    <div className="form-outline mb-4">
                      <select
                        name="motherTongue"
                        id="motherTongue"
                        onChange={handleChange}
                      >
                        <option value="Choose Profile">
                          Select Mother Tongue
                        </option>
                        <option value="tamil">Tamil</option>
                        <option value="telugu">Telugu</option>
                        <option value="malayalam">Malayalam</option>
                        <option value="hindi">Hindi</option>
                        <option value="others">Others</option>
                      </select>
                    </div>
                    <div className="form-outline mb-4">
                      <label>Enter Your Qualification </label>
                      <input
                        type="text"
                        name="educationalQualification"
                        onChange={handleChange}
                        id="form2Example1"
                        className="form-control"
                        placeholder="Enter Your Qualification"
                      />
                    </div>
                    {/* <!-- Password input --> */}
                    <div className="form-outline mb-4">
                      <select
                        name="maritalStatus"
                        id="cars"
                        onChange={handleChange}
                      >
                        <option name="marriageStatus" value="Choose Gender">
                          Select Martial Status
                        </option>
                        <option value="never married">Never Married</option>
                        <option value="married">Married</option>
                        <option value="seperated">Seperated</option>
                        <option value="divorced">Divorced</option>
                        <option value="widow/widower">Widow/widower</option>
                      </select>
                    </div>
                    <div className="form-outline mb-4">
                      <select
                        name="caste"
                        onChange={handleChange}
                        id="caste"
                        className="form-control "
                      >
                        <option value="">Select Caste</option>
                        {casteOptions.map((caste, index) => (
                          <option key={index} value={caste}>
                            {caste}
                          </option>
                        ))}
                      </select>
                    </div>{" "}
                    <div className="form-outline mb-4">
                      <select
                        name="rahuKetu"
                        required
                        onChange={handleChange}
                        id="rahuKetu"
                      >
                        <option value="Choose Rahu/kethu">
                          Select Rahu/Kethu
                        </option>
                        <option value="raghu">Raghu Dhosam</option>
                        <option value="kethu">Kethu Dhosam</option>
                        <option value="raghuKethu">Ragu-Kethu Dhosam</option>
                        <option value="raKePari">
                          Ragu-Kethu Dhosam Parikaram Seiyapattathu
                        </option>
                        <option value="sevvai">Sevvai Dhosam</option>
                        <option value="sevvaiPari">
                          Sevvai Dhosam Parikaram Seiyapattathu
                        </option>
                        <option value="noDhosam">Dhasam Illai</option>
                        <option value="sutha">Suththa Jathagam</option>
                      </select>
                    </div>
                    {/* <p className="comment-form-cookies-consent">
                        <input
                          id="wp-comment-cookies-consent"
                          name="wp-comment-cookies-consent"
                          type="checkbox"
                        />
                        <label for="wp-comment-cookies-consent">
                          Willing to marry from other communities also .
                        </label>
                      </p> */}
                    {/*  */}
                    <div
                      className="form-outline"
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        gap: "50px",
                      }}
                    >
                      <div
                        className="form-outline"
                        style={{
                          display: "flex",
                          gap: "20px",
                        }}
                      >
                        <div className="form-outline mb-4">
                          <label>Enter Age From </label>
                          <input
                            type="number"
                            name="ageFrom"
                            id="ageFrom"
                            placeholder="Enter Age From"
                            onChange={handleChange}
                            className={`form-control  ${
                              ageError ? "is-invalid" : ""
                            }`}
                            maxLength="2"
                          />
                          {ageError && (
                            <div
                              className="invalid-feedback"
                              style={{ color: "red" }}
                            >
                              {ageError}
                            </div>
                          )}
                        </div>{" "}
                        <div className="form-outline mb-4">
                          <label>Enter Age To </label>
                          <input
                            type="number"
                            name="ageTo"
                            placeholder="Enter Age To"
                            onChange={handleChange}
                            className={`form-control  ${
                              ageToError ? "is-invalid" : ""
                            }`}
                            maxLength="2" // Fixed typo: "maxlength" should be "maxLength"
                          />
                          {ageToError && (
                            <div
                              className="invalid-feedback"
                              style={{ color: "red" }}
                            >
                              {ageToError}
                            </div>
                          )}
                        </div>
                      </div>
                      {/*  */}
                      {/* <div className="form-outline">
                        <FormControl sx={{ m: 1, minWidth: 150 }}>
                          <InputLabel id="demo-simple-select-helper-label">
                            HeightFrom
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            name="heightFrom"
                            // value={age}
                            label="HeightFrom"
                            onChange={handleChange}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value="5.1">5.1</MenuItem>
                            <MenuItem value="5.2">5.2</MenuItem>
                            <MenuItem value="5.3">5.3</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: 150 }}>
                          <InputLabel id="demo-simple-select-helper-label">
                            HeightTo
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            name="heightTo"
                            // value={age}
                            label="HeightTo"
                            onChange={handleChange}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value="5.1">5.1</MenuItem>
                            <MenuItem value="5.2">5.2</MenuItem>
                            <MenuItem value="5.3">5.3</MenuItem>
                          </Select>
                        </FormControl>
                      </div> */}
                    </div>
                    <br />
                    <div className="form-outline">
                      <label>Enter District</label>
                      <input
                        type="text"
                        name="district"
                        onChange={handleChange}
                        id="city"
                        placeholder="Enter City Name"
                        pattern="[A-Za-z\s]+"
                        title="Please enter only letters (A-Z, a-z)"
                        className={`form-control  ${
                          disError ? "is-invalid" : ""
                        }`}
                      />
                      {disError && (
                        <div
                          className="invalid-feedback"
                          style={{ color: "red" }}
                        >
                          {disError}
                        </div>
                      )}
                    </div>
                    <br />
                    <div className="form-outline ">
                      <label>Enter State</label>
                      <input
                        type="text"
                        name="state"
                        onChange={handleChange}
                        id="state"
                        placeholder="Enter State Name"
                        pattern="[A-Za-z\s]+"
                        title="Please enter only letters (A-Z, a-z)"
                        className={`form-control  ${
                          stateError ? "is-invalid" : ""
                        }`}
                      />
                      {stateError && (
                        <div
                          className="invalid-feedback"
                          style={{ color: "red" }}
                        >
                          {stateError}
                        </div>
                      )}
                    </div>
                    <br />
                    <div className="form-outline ">
                      <label>Enter Country</label>
                      <input
                        type="text"
                        name="country"
                        onChange={handleChange}
                        id="country"
                        placeholder="Enter Country Name"
                        pattern="[A-Za-z\s]+"
                        title="Please enter only letters (A-Z, a-z)"
                        className={`form-control  ${
                          countryError ? "is-invalid" : ""
                        }`}
                      />
                      {countryError && (
                        <div
                          className="invalid-feedback"
                          style={{ color: "red" }}
                        >
                          {countryError}
                        </div>
                      )}
                    </div>
                    <br />
                    <div
                      className="av-column-12"
                      style={{ paddingBottom: "1px" }}
                    >
                      <a
                      //  href='/register3'
                      >
                        {" "}
                        <button
                          type="submit"
                          className="btn btn-primary btn-block mb-4"
                          style={{ float: "left" }}
                        >
                          Search
                        </button>
                      </a>
                      {/* <a href='/register3'> <button
                      type="button"
                      className="btn btn-primary btn-block mb-4"
                      style={{float:"right"}}
                    >
                      Skip
                    </button></a> */}
                    </div>
                    {/* <!-- Register buttons --> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Search;
