import React from "react";
import {Link} from "react-router-dom"
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { premiumprofile, AddIntrest } from "../Redux/Action";
import { Skeleton } from "../Common/Skeleton";
import Swal from "sweetalert2";

import UserMeta from "./UserMeta";

const PremiumMatches = () => {
  const dispatch = useDispatch();
  const Token = localStorage.getItem("Authtok");
  const [filters, setFilters] = useState({
    maritalStatus: [],
    motherTongue: [],
    profileCreated: [],
    religion: [],
    caste: [],
    subCaste: [],
  });
  const [filteredUsers, setFilteredUsers] = useState(null);

  const permiumMatch = useSelector(
    ({ premiumprofile }) => premiumprofile.payload
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(permiumMatch(filters)).then((res) => {
      // setFilters(res.data.data)
      setFilteredUsers(res.data.data);
    });
  };

  const handleChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: [
        ...filters[e.target.name],
        e.target.value.toLowerCase(),
      ],
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    Promise.allSettled([dispatch(premiumprofile())]);
  }, []);

  return (
    <div>
      {/* <!--===// Start: Groom
        =================================--> */}
          <section id="breadcrumb-section" class="breadcrumb-area breadcrumb-left">
        <div class="av-container">
          <div class="av-columns-area">
            <div class="av-column-12">
              <div class="breadcrumb-content">
                <div class="breadcrumb-heading wow fadeInLeft">
                  <h2> Premium Matches</h2>
                </div>
                <ol class="breadcrumb-list wow fadeInRight">
                  <li>
                    <a href="/">
                      <i class="fa fa-home"></i> Home Page
                    </a>{" "}
                    &nbsp;-&nbsp;
                  </li>
                  <li class="active"> Premium Matches</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="post-section"
        class="post-section av-py-default"
        style={{ backgroundColor: "#fff" }}
      >
        <div class="av-container">
          <div class="av-columns-area">
            <div class="av-column-12">
              <div class="heading-default text-center wow fadeInUp">
                <h3>
                  Premium <span class="primary-color">Matches</span>
                </h3>
                <span class="separator">
                  <span>
                    <span></span>
                  </span>
                </span>
              </div>
            </div>
          </div>

            {permiumMatch === "Data Not Found!" ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",fontSize:"30px",fontWeight:"600"
                }}
              >
                No Profile Found
              </div>
            ) : (
              <div class="av-column-12">
                <div class="av-container">
                  {/* <div class="av-columns-area">
                    <div class="av-column-12">
                        <div class="heading-default text-center wow fadeInUp">
                            <h3>Featured <span class="primary-color">Bride</span></h3>
                            <span class="separator"><span><span></span></span></span>
                            <p>Being happy never goes out of style.</p>
                        </div>
                    </div>
                </div> */}
                  <div class="av-columns-area">
                    {Array.isArray(permiumMatch) &&
                    permiumMatch.length !== 0 ? (
                      permiumMatch.map((x) => (
                        <div class="av-column-4 av-sm-column-6">
                          <div class="team-member">
                            <div class="team-thumb" tabIndex="0">
                              <a
                                href={
                                  Token ? "/profiledetails/" + x.id : "/login"
                                }
                              >
                                {" "}
                                <p
                                  style={{
                                    margin: "0px",
                                    padding: "10px",
                                    color: "#252525",
                                  }}
                                >
                                  {x.profileId} |{" "}
                                  <a href=";">
                                    <span style={{ color: "#00a03a" }}>
                                      {x.age}Yrs
                                    </span>
                                  </a>
                                </p>
                                <Link
                                to={
                                  Token ? "/profiledetails/" + x.id : "/login"
                                }
                              >  <img
                                  src={process.env.REACT_APP_URL + x.images}
                                  alt="team_item"
                                  title=""
                                  style={{
                                    aspectRatio: "1/1",
                                    objectFit: "cover",
                                  }}
                                /></Link>
                              </a>
                              {/* <div class="team-view"><a href="#"><i class="fa fa-eye"></i></a></div> */}
                              <div
                                class="team-thumber"
                                style={{ cursor: "pointer" }}
                              >
                                <aside class="widget widget_social_widget">
                                  <a
                                    onClick={() =>
                                      dispatch(
                                        AddIntrest({ interestProfileId: x.id })
                                      ).then((res) => {
                                        Swal.fire(res.data.data);
                                      })
                                    }
                                  >
                                    <div
                                      class="cards-title"
                                      style={{ color: "#fff" }}
                                    >
                                      Send Interest
                                    </div>
                                  </a>
                                </aside>
                              </div>
                            </div>
                            <div class="team-info">
                              <h5 style={{ textTransform: "capitalize" }}>
                                {x.userName.length > 10
                                  ? x.userName.substring(0, 10) + "..."
                                  : x.userName}
                              </h5>
                              <a
                                href={
                                  Token ? "/profiledetails/" + x.id : "/login"
                                }
                              >
                                <p>View Full Profile</p>
                              </a>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      // <h3 style={{ textAlign: "center" }}>No Profile Match</h3>
                      <Skeleton />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
     
      </section>
      {/* <!-- End: Groom
        =================================--> */}
    </div>
  );
};

export default PremiumMatches;
