import { apiCalls } from "../Axios/Service";
// import { useParams, useNavigate } from "react-router-dom";
import { navigate } from "react-router-dom";
import {
  profile,
  personal,
  userlog,
  profdet,
  horoscopeauto,
  proof,
  family,
  pdfview,
  partner,
  viewprofile,
  contact,
  viewfullprofile,
  banprof,
  recommend,
  list,
  prepof,
  horoscopeUpdate,
  normalprof,
  natchathiraPro,
  recentprof,
  banner,
  searchprofile,
  searchlocation,
  searchmartailstatus,
  searchforeginintrest,
  successtestimony,
  homerecentprof,
  logout,
  payNow,
  membership,
  Hidematch,
  reportAcc,
  feedbackacc,
  completion,
  addIntrestUser,
  AddFavourite,
  AddSuccess,
  AddContactProfile,
  ListFavourite,
  ListHidematch,
  SingleUserprofile,
  profileUpdate,
  personalityUpdate,
  professionUpdate,
  familydetUpdate,
  PartnerPreferenceUpdate,
  Membership,
  membershipsum,
  forgotuser,
  verifyotp,
  imageupdate,
  metauser,
  getprefpartner,
  getfamdet,
  getpersonal,
  getprofdetails,
  verifymail,
  otpverify,
  getad,
  regularSearch,
  // filter,
  amsam,
  visitprofile,
  rasi,
  horomaches,
  birthmatch,
  birthchart,
  accountDelete,
  userdoc,
} from "../Redux/ActionType";

// const Token = localStorage.getItem("AuthTok");

// profileuser
export function profileuser(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/profile/addProfile", data, null, {
        "content-type": "multipart/form-data",
      })
        .then((res) => {
          dispatch({
            type: profile.login.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: profile.login.error,
            data: err,
          });
          reject(err);
        })
    );
}

// update image
export function Updateimage(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/profile/updateImage", data, null, {
        "content-type": "multipart/form-data",
      })
        .then((res) => {
          dispatch({
            type: imageupdate.image.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: imageupdate.image.error,
            data: err,
          });
          reject(err);
        })
    );
}

// report
export function Reportaccount(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/others/createReport", data, null, {
        "content-type": "multipart/form-data",
      })
        .then((res) => {
          dispatch({
            type: reportAcc.report.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: reportAcc.report.error,
            data: err,
          });
          reject(err);
        })
    );
}
// addvisitprofile
export function Addvisitprofile(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/profile/addUserFollowers", data)
        .then((res) => {
          dispatch({
            type: visitprofile.addprofile.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: visitprofile.addprofile.error,
            data: err,
          });
          reject(err);
        })
    );
}
//viewvisitprofile
export function Viwevisitprofile() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("get", "/user/profile/viewUserFollowers")
        .then((res) => {
          dispatch({
            type: visitprofile.viewprofile.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: visitprofile.viewprofile.error,
            data: err,
          });
          reject(err);
        })
    );
}
//viewinterestedprofile
export function Viewinterestedprofile() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("get", "/user/bannerProfiles/interestReceivedProfile")
        .then((res) => {
          dispatch({
            type: list.interestrecive.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: list.interestrecive.error,
            data: err,
          });
          reject(err);
        })
    );
}
//viewinterestprofile
export function Viewinterestprofile() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("get", "/user/bannerProfiles/interestSendProfile")
        .then((res) => {
          dispatch({
            type: list.interest.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: list.interest.error,
            data: err,
          });
          reject(err);
        })
    );
}
//viewlikeprofile
export function Viewlikeprofile() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("get", "/user/bannerProfiles/profileWhoShortlists")
        .then((res) => {
          dispatch({
            type: list.like.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: list.like.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function FavProfile() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("get", "/user/profile/viewWhislistDetails")
        .then((res) => {
          dispatch({
            type: list.fav.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: list.fav.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function Subcription() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("get", "/user/profile/userSubscription")
        .then((res) => {
          dispatch({
            type: membership.subcription.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: membership.subcription.error,
            data: err,
          });
          reject(err);
        })
    );
}
//viewvisitlist
export function Viwevisitlist() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("get", "/user/bannerProfiles/profileWhoVisits")
        .then((res) => {
          dispatch({
            type: list.visit.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: list.visit.error,
            data: err,
          });
          reject(err);
        })
    );
}
//viewvisitprofile
export function Viewcontactprofiles() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("get", "/user/profile/viewContactUsers")
        .then((res) => {
          dispatch({
            type: contact.list.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: contact.list.error,
            data: err,
          });
          reject(err);
        })
    );
}
//payment
export function payNows(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/profile/membershipUpdate", data)
        .then((res) => {
          dispatch({
            type: payNow.pay.success,
            data: res?.data?.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: payNow.pay.error,
            data: err,
          });
          reject(err);
        })
    );
}
// feedback
export function Feedbackaccount(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/others/createFeedback", data, null, {
        "content-type": "multipart/form-data",
      })
        .then((res) => {
          dispatch({
            type: feedbackacc.feedback.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: feedbackacc.feedback.error,
            data: err,
          });
          reject(err);
        })
    );
}

// personal details
export function personaldetails(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/profile/personalityDetails", data)
        .then((res) => {
          dispatch({
            type: personal.login.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: personal.login.error,
            data: err,
          });
          reject(err);
        })
    );
}

// get personal details
export function getpersonaldetails() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("get", "/user/profile/viewPersonalityDetails")
        .then((res) => {
          dispatch({
            type: getpersonal.pesonaldetail.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: getpersonal.pesonaldetail.error,
            data: err,
          });
          reject(err);
        })
    );
}

// login user
export function loginuser(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/auth/login", data)
        .then((res) => {
          dispatch({
            type: userlog.login.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: userlog.login.error,
            data: err,
          });
          reject(err);
        })
    );
}

// otp verify
export function Otpverify(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/auth/mobileOTPVerify", data)
        .then((res) => {
          if (res && res.data) {
            dispatch({
              type: verifyotp.otp.success,
              data: res.data,
            });
            resolve(res);
          } else {
            throw new Error("Invalid API response");
          }
        })
        .catch((err) => {
          dispatch({
            type: verifyotp.otp.error,
            data: err,
          });
          reject(err);
        })
    );
}
// mobile number otp verify
export function Otpverifynumber(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/auth/phone", data)
        .then((res) => {
          dispatch({
            type: otpverify.otpnumber.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: otpverify.otpnumber.error,
            data: err,
          });
          reject(err);
        })
    );
}

// mail verification
export function Mailverify(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/auth/verifyEmailCode", data)
        .then((res) => {
          dispatch({
            type: verifymail.mail.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: verifymail.mail.error,
            data: err,
          });
          reject(err);
        })
    );
}

// forgot password
export function Forgotpassword(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/auth/forgotPassword", data)
        .then((res) => {
          dispatch({
            type: forgotuser.forgot.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: forgotuser.forgot.error,
            data: err,
          });
          reject(err);
        })
    );
}

// Profession details
export function profession(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/profile/userDetails", data)
        .then((res) => {
          dispatch({
            type: profdet.login.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: profdet.login.error,
            data: err,
          });
          reject(err);
        })
    );
}

// get Profession details
export function getprofession() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("get", "/user/profile/viewUserDetails")
        .then((res) => {
          dispatch({
            type: getprofdetails.detailprof.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: getprofdetails.detailprof.error,
            data: err,
          });
          reject(err);
        })
    );
}

// horoscope details auto
export function horoscopeA(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/profile/horoscopeDetails", data)
        .then((res) => {
          dispatch({
            type: horoscopeauto.login.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: horoscopeauto.login.error,
            data: err,
          });
          reject(err);
        })
    );
}

// idproof details
export function idproofdet(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/profile/userDocsDetails", data, undefined, {
        "Content-Type": "multipart/form-data",
      })
        .then((res) => {
          dispatch({
            type: proof.login.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: proof.login.error,
            data: err,
          });
          reject(err);
        })
    );
}

// family details
export function familydet(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/profile/familyDetails", data)
        .then((res) => {
          dispatch({
            type: family.login.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: family.login.error,
            data: err,
          });
          reject(err);
        })
    );
}

// get family details
export function getfamilydet() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("get", "/user/profile/viewFamilyDetails")
        .then((res) => {
          dispatch({
            type: getfamdet.famdet.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: getfamdet.famdet.error,
            data: err,
          });
          reject(err);
        })
    );
}

// partner preference
export function partnerperf(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/profile/partnerDetails", data)
        .then((res) => {
          dispatch({
            type: partner.login.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: partner.login.error,
            data: err,
          });
          reject(err);
        })
    );
}

// get partner preference
export function getCompletion() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("get", "/user/others/completionScore")
        .then((res) => {
          dispatch({
            type: completion.get.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          if (err?.data?.error?.message === "UnAuthorized") {
            dispatch({
              type: completion.get.error,
              data: {
                status: "unauthorized",
                data: err,
              },
            });
          } else {
            dispatch({
              type: completion.get.error,
              data: {
                status: "failed",
                data: err,
              },
            });
          }

          reject(err);
        })
    );
}

//completion
export function getpartnerperf() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("get", "/user/profile/viewPartnerDetails")
        .then((res) => {
          dispatch({
            type: getprefpartner.getpartner.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: getprefpartner.getpartner.error,
            data: err,
          });
          reject(err);
        })
    );
}

// View profile list
export function profilelist(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("get", "/user/profile/viewProfile", data)
        .then((res) => {
          dispatch({
            type: viewprofile.login.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          if (err.data.error.message === "UnAuthorized") {
            dispatch({
              type: viewprofile.login.error,
              data: {
                status: "unauthorized",
                data: err,
              },
            });
          } else {
            dispatch({
              type: viewprofile.login.error,
              data: {
                status: "failed",
                data: err,
              },
            });
          }

          reject(err);
        })
    );
}

export function horolist(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("get", "/user/profile/viewHoroscopeDetails", data)
        .then((res) => {
          dispatch({
            type: viewprofile.horo.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          if (err.data.error.message === "UnAuthorized") {
            dispatch({
              type: viewprofile.horo.error,
              data: {
                status: "unauthorized",
                data: err,
              },
            });
          } else {
            dispatch({
              type: viewprofile.login.error,
              data: {
                status: "failed",
                data: err,
              },
            });
          }

          reject(err);
        })
    );
}

// View full profile list
export function Allprofilelist(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("get", "/user/profile/viewFullProfile")
        .then((res) => {
          dispatch({
            type: viewfullprofile.get.success,
            data: {
              status: "ok",
              data: res.data.data,
            },
          });
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          if (err && err.message === "timeout of 5000ms exceeded") {
            dispatch({
              type: viewfullprofile.get.error,
              data: {
                status: "timeout",
                data: err,
              },
            });
          } else if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.message === "UnAuthorized"
          ) {
            dispatch({
              type: viewfullprofile.get.error,
              data: {
                status: "unauthorized",
                data: err,
              },
            });
          } else {
            dispatch({
              type: viewfullprofile.get.error,
              data: {
                status: "failed",
                data: err,
              },
            });
          }

          reject(err);
        })
    );
}

// View single prfile details
export function SingleProfile(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/profile/viewSingleFullProfile", data)
        .then((res) => {
          dispatch({
            type: SingleUserprofile.Singleuser.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: SingleUserprofile.Singleuser.error,
            data: err,
          });
          reject(err);
        })
    );
}

// recommend Profile
export function recommendprofilee() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("get", "/user/bannerProfiles/recommendedUsers")
        .then((res) => {
          dispatch({
            type: recommend.recommendprofile.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: recommend.recommendprofile.error,
            data: err,
          });
          reject(err);
        })
    );
}

// Banner Profile
export function Banner() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("get", "/user/profile/banner")
        .then((res) => {
          dispatch({
            type: banner.banner.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: banner.banner.error,
            data: err,
          });
          reject(err);
        })
    );
}

// premium Profile
export function premiumprofile(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("get", "/user/bannerProfiles/premiumUsers", data)
        .then((res) => {
          dispatch({
            type: prepof.premiumprofile.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: prepof.premiumprofile.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function nakshatraprofile() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("get", "/user/search/natchathiraPorutham")
        .then((res) => {
          dispatch({
            type: natchathiraPro.natchathiraprofile.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: natchathiraPro.natchathiraprofile.error,
            data: err,
          });
          reject(err);
        })
    );
}

// normal Profile
export function normalprofile() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("get", "/user/bannerProfiles/normalUsers")
        .then((res) => {
          dispatch({
            type: normalprof.normalprofile.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: normalprof.normalprofile.error,
            data: err,
          });
          reject(err);
        })
    );
}

// hide profile

// List hide profile
export function ListHideProfile() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("get", "/user/bannerProfiles/viewHideProfile")
        .then((res) => {
          dispatch({
            type: ListHidematch.Listhide.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: ListHidematch.Listhide.error,
            data: err,
          });
          reject(err);
        })
    );
}

// recent Profile
export function recentprofile() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("get", "/user/bannerProfiles/recentProfiles")
        .then((res) => {
          dispatch({
            type: recentprof.recentprofile.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          if (err && err.message === "timeout of 5000ms exceeded") {
            dispatch({
              type: recentprof.recentprofile.error,
              data: {
                status: "timeout",
                data: err,
              },
            });
          } else if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.message === "UnAuthorized"
          ) {
            dispatch({
              type: recentprof.recentprofile.error,
              data: {
                status: "unauthorized",
                data: err,
              },
            });
          } else {
            dispatch({
              type: recentprof.recentprofile.error,
              data: {
                status: "failed",
                data: err,
              },
            });
          }

          reject(err);
        })
    );
}

// Home recent Profile
export function Homerecentprofile() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("get", "/user/bannerProfiles/homeRecentProfiles")
        .then((res) => {
          dispatch({
            type: homerecentprof.homerecent.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: homerecentprof.homerecent.error,
            data: err,
          });
          reject(err);
        })
    );
}

// searchby horoscope
export function searchoro(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/search/searchByHoro", data)
        .then((res) => {
          dispatch({
            type: searchprofile.searchoro.success,
            data: res.data.data,
          });
          resolve(true);
        })
        .catch((err) => {
          dispatch({
            type: searchprofile.searchoro.error,
            data: err,
          });
          reject(err);
        })
    );
}

// searchby location
export function searchloc(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/search/search", data)
        .then((res) => {
          dispatch({
            type: searchlocation.searchs.success,
            data: res.data.data,
          });
          resolve(true);
        })
        .catch((err) => {
          dispatch({
            type: searchlocation.searchs.error,
            data: err,
          });
          reject(err);
        })
    );
}

// searchby martial status
export function searchmar(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/search/search", data)
        .then((res) => {
          dispatch({
            type: searchmartailstatus.searchm.success,
            data: res.data.data,
          });
          resolve(true);
        })
        .catch((err) => {
          dispatch({
            type: searchmartailstatus.searchm.error,
            data: err,
          });
          reject(err);
        })
    );
}

// searchby foreign intrest
export function searchfor(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/search/searchByForeignInterest", data)
        .then((res) => {
          dispatch({
            type: searchforeginintrest.searchf.success,
            data: res.data.data,
          });
          resolve(true);
        })
        .catch((err) => {
          dispatch({
            type: searchforeginintrest.searchf.error,
            data: err,
          });
          reject(err);
        })
    );
}
// pdf view
export function PDFview(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/restApi/pdfDownload", data)
        .then((res) => {
          dispatch({
            type: pdfview.view.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: pdfview.view.error,
            data: err,
          });
          reject(err);
        })
    );
}

// success story
export function successstory() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("get", "/user/others/viewSuccessstory")
        .then((res) => {
          dispatch({
            type: successtestimony.story.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: successtestimony.story.error,
            data: err,
          });
          reject(err);
        })
    );
}

// logout
// export function logoutUser() {
//   return (dispatch) =>
//     new Promise((resolve, reject) =>
//       apiCalls("post", "/user/auth/logout")

//         .then((res) => {
//           console.log(res);
//           dispatch({

//             type: logout.logoutUser.success,
//             data: res?.data?.data,
//           });
//           resolve(res);
//         })
//         .catch((err) => {
//           dispatch({
//             type: logout.logoutUser.error,
//             data: err,
//           });
//           reject(err);
//         })
//     );
// }

// memmbership plans
export function MembershipPlans() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("get", "/user/profile/viewMembership")
        .then((res) => {
          dispatch({
            type: membership.plans.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: membership.plans.error,
            data: err,
          });
          reject(err);
        })
    );
}

// logout
export function logoutUser(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/auth/logout", data)
        .then((res) => {
          dispatch({
            type: logout.logoutUser.success,
            data: res.data.data,
          });
          resolve(true);
        })
        .catch((err) => {
          dispatch({
            type: logout.logoutUser.error,
            data: err,
          });
          reject(err);
        })
    );
}

// Add Intrest
export function AddIntrest(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/profile/sendInterest", data)
        .then((res) => {
          dispatch({
            type: addIntrestUser.AddIn.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: addIntrestUser.AddIn.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function Hideprofile(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/bannerProfiles/hideProfile", data)
        .then((res) => {
          dispatch({
            type: Hidematch.hide.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: Hidematch.hide.error,
            data: err,
          });
          reject(err);
        })
    );
}

// Add favourite
export function Likedprofile(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/profile/whislistDetails", data)
        .then((res) => {
          dispatch({
            type: AddFavourite.Addfav.success,
            data: res.data.data,
          });
          resolve(res.data.data);
        })
        .catch((err) => {
          dispatch({
            type: AddFavourite.Addfav.error,
            data: err,
          });
          reject(err);
        })
    );
}

// ListShortlist
export function ListShortlist() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("get", "/user/profile/viewWhislistDetails")
        .then((res) => {
          dispatch({
            type: ListFavourite.Listfav.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: ListFavourite.Listfav.error,
            data: err,
          });
          reject(err);
        })
    );
}

// AddContactProfile
export function ContactProfile(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/profile/addUsersToContact", data)
        .then((res) => {
          dispatch({
            type: AddContactProfile.Addcontact.success,
            data: res.data.data,
          });
          resolve(res.data.data);
        })
        .catch((err) => {
          dispatch({
            type: AddContactProfile.Addcontact.error,
            data: err,
          });
          reject(err);
        })
    );
}

// Add Success story
export function AddSuccessStory(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/others/addSuccessstory", data, null, {
        "content-type": "multipart/form-data",
      })
        .then((res) => {
          dispatch({
            type: AddSuccess.Story.success,
            data: res.data.data,
          });

          resolve(res.data.data);
        })
        .catch((err) => {
          dispatch({
            type: AddSuccess.Story.error,
            data: err,
          });
          reject(err);
        })
    );
}

// buy membership
export function Buymembership(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/profile/buyNow", data)
        .then((res) => {
          dispatch({
            type: Membership.planMembership.success,
            data: res.data.data,
          });
          resolve(res.data.data);
          // console.log("membership", res.data.data);
        })
        .catch((err) => {
          dispatch({
            type: Membership.planMembership.error,
            data: err,
          });
          reject(err);
        })
    );
}

// membership summary
export function Membershipsummary(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/profile/membershipSummary", data)
        .then((res) => {
          dispatch({
            type: membershipsum.summary.success,
            data: res.data.data,
          });
          resolve(true);
        })
        .catch((err) => {
          dispatch({
            type: membershipsum.summary.error,
            data: err,
          });
          reject(err);
        })
    );
}

// user meta
export function Usermeta() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("get", "/user/bannerProfiles/viewUserMeta")
        .then((res) => {
          dispatch({
            type: metauser.user.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: metauser.user.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function Usermetadetails() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("get", "user/bannerProfiles/userMeta")
        .then((res) => {
          dispatch({
            type: metauser.details.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: metauser.details.error,
            data: err,
          });
          reject(err);
        })
    );
}
// advertisement
export function advertisement() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("get", "/user/others/viewAds")
        .then((res) => {
          dispatch({
            type: getad.ad.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: getad.ad.error,
            data: err,
          });
          reject(err);
        })
    );
}

// regular search
export function regSearch(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/search/regularSearch", data)
        .then((res) => {
          dispatch({
            type: regularSearch.regular.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: regularSearch.regular.error,
            data: err,
          });
          reject(err);
        })
    );
}

// filter profile
export function filterProfile(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/search/filter", data)
        .then((res) => {
          dispatch({
            type: normalprof.normalprofile.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: normalprof.normalprofile.error,
            data: err,
          });
          reject(err);
        })
    );
}

// astrology rasi
export function amsamhoro(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/profile/addAmsamChart", data)
        .then((res) => {
          dispatch({
            type: amsam.horoamsam.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: amsam.horoamsam.error,
            data: err,
          });
          reject(err);
        })
    );
}

// astrology Amsam
export function rasihoro(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/profile/addRasiChart", data)
        .then((res) => {
          dispatch({
            type: rasi.hororasi.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: rasi.hororasi.error,
            data: err,
          });
          reject(err);
        })
    );
}

// start Update Userdetails

// update profileuser
export function Updateprofileuser(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls(
        "post",
        "/user/profile/updateProfile",
        data
        // "content-type": "multipart/form-data",
      )
        .then((res) => {
          dispatch({
            type: profileUpdate.Profile.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: profileUpdate.Profile.error,
            data: err,
          });
          reject(err);
        })
    );
}

//update personal details
export function Updatepersonaldetails(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/profile/updatePersonalityDetails", data)
        .then((res) => {
          dispatch({
            type: personalityUpdate.Personality.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: personalityUpdate.Personality.error,
            data: err,
          });
          reject(err);
        })
    );
}

// update Profession details
export function Updateprofession(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/profile/updateUserDetails", data)
        .then((res) => {
          dispatch({
            type: professionUpdate.Profession.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: professionUpdate.Profession.error,
            data: err,
          });
          reject(err);
        })
    );
}

// update family details
export function Updatefamilydet(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/profile/updateFamilyDetails", data)
        .then((res) => {
          dispatch({
            type: familydetUpdate.familydet.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: familydetUpdate.familydet.error,
            data: err,
          });
          reject(err);
        })
    );
}
// update horoscope details
export function Updatehoroscopedet(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/profile/updateHoroscopeDetails", data)
        .then((res) => {
          dispatch({
            type: horoscopeUpdate.horoscopedet.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: horoscopeUpdate.horoscopedet.error,
            data: err,
          });
          reject(err);
        })
    );
}

// Update partner preference
export function Updatepartnerperf(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("post", "/user/profile/updatePartnerDetails", data)
        .then((res) => {
          dispatch({
            type: PartnerPreferenceUpdate.PartnerPre.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: PartnerPreferenceUpdate.PartnerPre.error,
            data: err,
          });
          reject(err);
        })
    );
}

// end updates userdetails
// Delete account
export function DeleteAccount() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      apiCalls("get", "user/profile/selfDelete")
        .then((res) => {
          dispatch({
            type: accountDelete.Delete.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: accountDelete.Delete.error,
            data: err,
          });
          reject(err);
        })
    );
}
// horoscope matches
export function HoroscopeMatches(data) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      const params = new URLSearchParams(data).toString();
      apiCalls("get", `/user/restApi/matching?${params}`)
        .then((res) => {
          dispatch({
            type: horomaches.horomatches.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: horomaches.horomatches.error,
            data: err,
          });
          reject(err);
        });
    });
}
// horoscope matches
export function BirthMatches(data) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      const params = new URLSearchParams(data).toString();
      apiCalls("get", `/user/restApi/getBirthDetails?${params}`)
        .then((res) => {
          dispatch({
            type: birthmatch.birthmatches.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: birthmatch.birthmatches.error,
            data: err,
          });
          reject(err);
        });
    });
}

export function BirthChart(data) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      const params = new URLSearchParams(data).toString();
      apiCalls("get", `/user/restApi/getChart?${params}`)
        .then((res) => {
          dispatch({
            type: birthchart.birthcharts.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: birthchart.birthcharts.error,
            data: err,
          });
          reject(err);
        });
    });
}
export function Document() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      apiCalls("get", `/user/profile/viewUserDocsDetails`)
        .then((res) => {
          dispatch({
            type: userdoc.docs.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: userdoc.docs.error,
            data: err,
          });
          reject(err);
        });
    });
}
