import React from "react";
import { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { MdVerifiedUser, MdLocalOffer, MdMessage } from "react-icons/md";
import {
  recommendprofilee,
  recentprofile,
  successstory,
  Homerecentprofile,
  MembershipPlans,
} from "../Redux/Action";
import { useDispatch, useSelector } from "react-redux";
// import Skeleton from '@mui/material/Skeleton';
import { Skeleton } from "../Common/Skeleton";
import { RiCustomerService2Fill } from "react-icons/ri";
import Loader from "../Common/Loader";
import ContactInfo from "../Home/ContactInfo";
import Pricing from "../Home/Pricing";
import SuccessStory from "../Home/SuccessStory";
import Carousel from "../Home/Carousel";
import HomeProfile from "../Home/HomeProfile";
import ScrollTop from "./ScrollTop";

const Home = () => {
  // const [show, setShow] = useState(false);
  const [load, setLoad] = useState(true);
  const Token = localStorage.getItem("Authtok");

  // const [diamond, setDiamond] = useState('');
  // const [gold, setGold] = useState('');
  // const [platinum, setPlatinum] = useState('');
  // const dispatch = useDispatch();
  // const Token = localStorage.getItem("Authtok")

  // const profile = useSelector(({ recentprofile }) => recentprofile.payload);
  // const recprofile = useSelector(({ recommendprofilee }) => recommendprofilee.payload);
  // const successTestimonial = useSelector(({ successstory }) => successstory.payload);
  // const homeprofile = useSelector(({ Homerecentprofile }) => Homerecentprofile.payload)
  // const plan = useSelector(({ MembershipPlans }) => MembershipPlans.payload);

  useEffect(() => {
    
    setLoad(false);
  }, []);

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <ScrollTop />
      {/* carousel start */}
      <Carousel />
      {/* end carousel */}

      {/* <!--===// Start: HomeProfile
        =================================--> */}
      <HomeProfile />
      {/* <!--===// end: HomeProfile
        =================================--> */}

      {/* <!--===// Start: Contact Info
        =================================--> */}
      <ContactInfo />
      {/* <!-- End: Contact Info
        =================================--> */}

      {/* <!--===// Start: Pricing
        =================================--> */}
      {Token ? <Pricing /> : null}
      {/* <!-- End: Pricing
        =================================--> */}

      {/* <!--===// Start: Testimonial
        =================================--> */}
      <SuccessStory />
      {/* <!-- End: Testimonial
        =================================--> */}
    </div>
  );
};

export default Home;
