import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser } from "../Redux/Action";
import logo from "../Images/logo2.png";
import Loader from "./Loader";
const Header = () => {
  const Token = localStorage.getItem("Authtok");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function logout() {
    console.log("Logout function called");
    dispatch(logoutUser());

    navigate("/login");
    window.location.reload();
  }

  // const logout = () => {
  //     dispatch(logoutUser());
  //     localStorage.removeItem("AuthTok");
  //      navigate("/");
  // };

  return (
    <div>
      {/* <!--===// Start: Preloader
    =================================--> */}
      {/* <Loader /> */}
      {/* <!-- End: Preloader
    =================================--> */}
      {/* <!--===// Start: Header
    =================================--> */}
      <header id="header-section" className="header header-one">
        <div className="navigator-wrapper">
          {/* <!--===// Start: Mobile Toggle
            =================================--> */}
          <div className="theme-mobile-nav sticky-nav ">
            <div className="av-container">
              <div className="av-columns-area">
                <div className="av-column-12">
                  <div className="theme-mobile-menu">
                    <div className="mobile-logo">
                      <div className="logo">
                        <a href="/index" className="site-title">
                          <img
                            src={logo}
                            alt="VVK Materimony"
                            style={{ width: "150px", height: "50px" }}
                          />
                        </a>
                      </div>
                    </div>
                    <div className="menu-toggle-wrap">
                      <div className="mobile-menu-right">
                        <ul className="header-wrap-right"></ul>
                      </div>
                      <div className="hamburger hamburger-menu">
                        <button
                          type="button"
                          className="toggle-lines menu-toggle"
                        >
                          <span className="top-bun"></span>
                          <span className="meat"></span>
                          <span className="bottom-bun"></span>
                        </button>
                      </div>
                      {/* <div className="headtop-mobi">
                        <button type="button" className="header-above-toggle">
                          <span></span>
                        </button>
                      </div> */}
                    </div>
                    <div id="mobile-m" className="mobile-menu">
                      <button
                        type="button"
                        className="header-close-menu close-style"
                      ></button>
                    </div>
                    {/* <div id="mob-h-top" className="mobi-head-top"><div className="header-widget">
                                    <div className="av-container">
                                        <div className="av-columns-area">
                                            <div className="av-column-5">
                                                <div className="widget-left text-av-left text-center">
                                                    <div className="team-thumber">
                                                        <aside className="widget widget_social_widget">
                                                            <a href="#" className="toolkit-hover"><i className="fa fa-share-alt"></i></a>
                                                            <ul>
                                                                <li><a href=";"><i className="fa fa-facebook"></i></a></li>
                                                                <li><a href=";"><i className="fa fa-twitter"></i></a></li>
                                                                <li><a href=";"><i className="fa fa-linkedin"></i></a></li>
                                                                <li><a href=";"><i className="fa fa-instagram"></i></a></li>
                                                                <li><a href=";"><i className="fa fa-google-plus"></i></a></li>
                                                            </ul>
                                                        </aside>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="av-column-7">
                                                <div className="widget-right text-av-right text-center">                                
                                                    <aside className="widget widget-contact">
                                                        <div className="contact-area">
                                                            <div className="contact-icon">
                                                               <i className="fa fa-support"></i>
                                                            </div>
                                                            <a href=";" className="contact-info">
                                                                <span className="title">Live Chat</span>
                                                            </a>
                                                        </div>
                                                    </aside>
                                                    <aside className="widget widget-contact">
                                                        <div className="contact-area">
                                                            <div className="contact-icon">
                                                                <i className="fa fa-envelope"></i>
                                                            </div>
                                                            <Link to="mailto:info@example.com" className="contact-info">
                                                                <span className="title">info@example.com</span>
                                                            </Link>
                                                        </div>
                                                    </aside>
                                                    <aside className="widget widget-contact">
                                                        <div className="contact-area">
                                                            <div className="contact-icon">
                                                                <i className="fa fa-whatsapp"></i>
                                                            </div>
                                                            <a href="tel:+01-9876543210" className="contact-info">
                                                                <span className="title">+01-9876543210</span>
                                                            </a>
                                                        </div>
                                                    </aside>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div></div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--===// End: Mobile Toggle
            =================================-->         */}

          {/* <!--===// Start: Navigation
            =================================--> */}
          <div className="nav-area d-none d-av-block">
            <div className="navbar-area sticky-nav">
              <div className="av-container">
                <div className="av-columns-area">
                  <div className="av-column-2 my-auto">
                    <div className="logo">
                      <Link to="/" className="site-title">
                        <img
                          src={logo}
                          alt="VVK Materimony"
                          style={{ width: "200px", height: "100px" }}
                        />
                      </Link>
                      {/* <!--p className="site-description">Multipurpose</p--> */}
                    </div>
                  </div>
                  <div className="av-column-10 my-auto">
                    <div className="theme-menu">
                      <nav className="menubar">
                        <ul id="menu-primary-menu" className="menu-wrap">
                          <li className="menu-item me-1">
                            <a href="/">Home </a>
                            {/* <span className="mobile-toggler d-av-none"><button type="button" className="fa fa-chevron-right" aria-label="Mobile Toggler"></button></span> */}
                          </li>
                          <li
                            className="menu-item me-1 itms"
                            style={{ padding: "0px 15px" }}
                          >
                            <div style={{ display: "flex" }}>
                              {" "}
                              <div
                                style={{ cursor: "pointer", fontWeight: "500" }}
                              >
                                Matches
                              </div>
                              <span className="mobile-toggler d-av-none">
                                <button
                                  type="button"
                                  className="fa fa-chevron-right"
                                  aria-label="Mobile Toggler"
                                ></button>
                              </span>
                            </div>
                            <ul className="dropdown-menu">
                              <li className="menu-item">
                                <a href={Token ? "/allprofile" : "/login"}>
                                  All Matches
                                </a>
                              </li>
                              <li className="menu-item">
                                <a href={Token ? "/newmatches" : "/login"}>
                                  New Matches
                                </a>
                              </li>
                              <li className="menu-item">
                                <a href={Token ? "/premiummatches" : "/login"}>
                                  Premium Matches
                                </a>
                              </li>{" "}
                              <li className="menu-item">
                                <a
                                  href={Token ? "/nakshatramatches" : "/login"}
                                >
                                  Nakshatram Matches
                                </a>
                              </li>
                              {
                                // <li className="menu-item"><a href={Token ?"/horoscopematches": "/login"}>Horoscope Matches</a></li>
                              }
                              <li className="menu-item">
                                <a
                                  href={
                                    Token ? "/shortlistedprofile" : "/login"
                                  }
                                >
                                  Shortlisted by You
                                </a>
                              </li>
                              <li className="menu-item">
                                <a href={Token ? "/ignoredmatches" : "/login"}>
                                  Ignored Profile
                                </a>
                              </li>
                              <li className="menu-item">
                                <a href={Token ? "/visitedprofile" : "/login"}>
                                  Visited Profile
                                </a>
                              </li>
                              <li className="menu-item">
                                <a href={Token ? "/contactprofile" : "/login"}>
                                  Contact Profile
                                </a>
                              </li>
                              <li className="menu-item">
                                <a href={Token ? "/interest" : "/login"}>
                                  Interested Profile
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="menu-item ">
                            <a href={Token ? "/premium" : "/login"}>
                              Membership Plans
                            </a>
                          </li>
                          <li className="menu-item">
                            <a href={Token ? "/search" : "/login"}>Search</a>
                            {/* <ul className="dropdown-menu">
                                                    <li className="menu-item"><Link to="/">Regular Search</Link></li>
                                                    <li className="menu-item"><Link to="/">Horoscope Search</Link></li>
                                                    <li className="menu-item"><Link to="/">Loaction Seachs</Link></li>
                                                </ul> */}
                          </li>
                          {Token ? (
                            <li className="menu-item">
                              <a href="/myprofile">My Profile</a>
                              {/* <span className="mobile-toggler d-av-none"><button type="button" className="fa fa-chevron-right" aria-label="Mobile Toggler"></button></span> */}
                            </li>
                          ) : (
                            false
                          )}
                          <li className="menu-item">
                            <a href="/ourservices">About us</a>
                          </li>
                          {Token ? (
                            <li className="menu-item">
                              <a href="/login">Logout</a>
                            </li>
                          ) : (
                            <li className="menu-item">
                              <a href="/login">Login</a>
                            </li>
                          )}
                        </ul>
                      </nav>
                      {/* <div className="menu-right">
                        <ul className="header-wrap-right">
                          <li className="search-button">
                            <button
                              id="view-search-btn"
                              className="header-search-toggle"
                            >
                              <i className="fa fa-search"></i>
                            </button>
                            <div className="view-search-btn header-search-popup">
                              <div className="search-overlay-layer"></div>
                              <div className="header-search-pop">
                                <form
                                  role="search"
                                  method="get"
                                  className="search-form"
                                  action="#"
                                  aria-label="Site Search"
                                >
                                  <span className="screen-reader-text">
                                    Search for:
                                  </span>
                                  <input
                                    type="search"
                                    className="search-field header-search-field"
                                    placeholder="Type To Search"
                                    name="s"
                                    id="popfocus"
                                    autoFocus={true}
                                  />
                                  <button
                                    type="submit"
                                    className="search-submit"
                                  >
                                    <i className="fa fa-search"></i>
                                  </button>
                                </form>
                                <button
                                  type="button"
                                  className="close-style header-search-close"
                                ></button>
                              </div>
                            </div>
                          </li>
                          <li className="about-toggle-list">
                            <div className="hamburger hamburger-about">
                              <button
                                type="button"
                                className="toggle-lines about-toggle"
                              >
                                <span className="top-bun"></span>
                                <span className="meat"></span>
                                <span className="bottom-bun"></span>
                              </button>
                              <div className="author-popup">
                                <div className="author-overlay-layer"></div>
                                <div className="author-div">
                                  <div className="author-anim">
                                    <button
                                      type="button"
                                      className="author-close close-style"
                                    ></button>
                                    <div className="author-content">
                                      Lorem ipsum is simply dummy text here...
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <li className="av-button-area">
                        <Link
                          to="/quickregister"
                          className="av-btn av-btn-primary av-btn-bubble"
                        >
                          Quick Register <i className="fa fa-user"></i>{" "}
                          <span className="bubble_effect">
                            <span className="circle top-left"></span>{" "}
                            <span className="circle top-left"></span>{" "}
                            <span className="circle top-left"></span>{" "}
                            <span className="button effect-button"></span>{" "}
                            <span className="circle bottom-right"></span>{" "}
                            <span className="circle bottom-right"></span>{" "}
                            <span className="circle bottom-right"></span>
                          </span>
                        </Link>
                      </li> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- End: Header */}
    </div>
  );
};

export default Header;
