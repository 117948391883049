import React from 'react'

const AboveFooterDetails = () => {
  return (
    <div>
                {/* <!--===// Start: Detail Info
        =================================--> */}
        {/* <section id="detailinfo-section" class="detailinfo-section av-py-default">
            <div class="av-container">
                <div class="av-columns-area">
                    <div class="av-column-6">
                        <div class="detailinfo secondary">
                            <aside class="widget widget-contact">
                                <div class="contact-area">
                                    <div class="contact-icon"><i class="fa fa-users"></i></div>
                                    <div class="contact-info">
                                        <span class="text">Jobseeker</span>
                                        <span class="title">Looking For Job?</span>
                                    </div>
                                    <a href="javascript:void(0);" class="av-btn av-btn-primary av-btn-bubble">Apply Now <i class="fa fa-arrow-right"></i> <span class="bubble_effect"><span class="circle top-left"></span> <span class="circle top-left"></span> <span class="circle top-left"></span> <span class="button effect-button"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span></span></a>
                                </div>
                            </aside>
                        </div>
                    </div>
                    <div class="av-column-6">
                        <div class="detailinfo primary">
                            <aside class="widget widget-contact">
                                <div class="contact-area">
                                    <div class="contact-icon"><i class="fa fa-file-text"></i></div>
                                    <div class="contact-info">
                                        <span class="text">Recruiter</span>
                                        <span class="title">Are You Recruiting?</span>
                                    </div>
                                    <a href="javascript:void(0);" class="av-btn av-btn-secondary av-btn-bubble">Apply Now <i class="fa fa-arrow-right"></i> <span class="bubble_effect"><span class="circle top-left"></span> <span class="circle top-left"></span> <span class="circle top-left"></span> <span class="button effect-button"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span></span></a>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        {/* <!-- End: Detail Info
        =================================--> */}
    </div>
  )
}

export default AboveFooterDetails