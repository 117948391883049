import React from 'react'
// import { FaFacebookF } from 'react-icons/fa';
// import { ImGoogle } from 'react-icons/im';
// import { FaTwitter } from 'react-icons/fa';
// import { BsGithub } from 'react-icons/bs';


const QuickRegister = () => {
  return (
    <div>
        {/* <!--===// Start: login Form
        =================================--> */}
       <section id="post-section" class="post-section av-py-default" style={{backgroundColor:"#fff",backgroundImage:"url('/assets/images/register.jpeg')"}}>
            <div class="av-container">
                <div class="av-columns-area">
                    <div class="av-column-10 mx-auto">
                        <div id="comments" class="comments-area">
                            <div id="respond" class="comment-respond">
                                {/* <h3 id="reply-title" class="comment-reply-title">Login Your Account <small><a rel="nofollow" id="cancel-comment-reply-link" href="#" style={{display:"none"}}>Cancel reply</a></small></h3>
                                <form action="#" method="post" id="commentform" class="comment-form" novalidate>
                                    <p class="comment-notes">
                                        <span id="email-notes">Your email address will not be published.</span> Required fields are marked <span class="required">*</span>
                                    </p>
                                    <p class="comment-form-author">
                                        <input id="author" name="author" type="text" size="30" maxlength="245" required='required' placeholder="Your Name" />
                                    </p>
                                    <p class="comment-form-email">
                                        <input id="email" name="email" type="email" size="30" maxlength="100" aria-describedby="email-notes" required='required' placeholder="Your Email" /></p>
                                    <p class="comment-form-subject">
                                        <input id="subject" name="subject" type="text" size="30" maxlength="200" placeholder="Your Subject" />
                                    </p>
                                    <p class="comment-form-comment">
                                        <textarea name="comment" id="comment" cols="45" rows="8" class="comment-textarea" placeholder="Write a comment&hellip;" required="required"></textarea>
                                    </p>
                                  
                                    <p class="form-submit">
                                        <input name="submit" type="submit" id="comment-submit" class="xl-btn" value="SUBMIT" /> 
                                        <input type='hidden' name='comment_post_ID' id='comment_post_ID' />
                                        <input type='hidden' name='comment_parent' id='comment_parent' />
                                    </p>
                                </form> */}
                                <form>
  {/* <!-- Email input --> */}
  <h3 id="reply-title" class="comment-reply-title">Quick Register to Your Account <small><a rel="nofollow" id="cancel-comment-reply-link" href="#" style={{display:"none"}}>Cancel reply</a></small></h3>
  <div class="form-outline mb-4">
    {/* <label class="form-label" for="form2Example1">Enter Phone Number </label><br/><br/> */}
    <input type="email" id="form2Example1" class="form-control" placeholder="Enter Your Phone Number"/>
  </div>

  {/* <!-- Password input --> */}
  {/* <div class="form-outline mb-4">
    <label class="form-label" for="form2Example2">Password</label><br/>
    <input type="password" id="form2Example2" class="form-control" placeholder="Enter your password"/>
  </div> */}

  {/* <!-- 2 column grid layout for inline styling --> */}
  {/* <div class="row mb-4" style={{display:"flex", justifyContent:"space-between"}}>
    <div class="col d-flex justify-content-center">
      <div class="form-check" style={{display:"none"}}>
        <label class="form-check-label" for="form2Example31"> Remember me </label>
        <input class="form-check-input" type="checkbox" value="" id="form2Example31" checked />
      </div>
    </div>

    <div class="col">
      <a href="/forgotpassword">Forgot password?</a>
    </div>
  </div> */}

  {/* <!-- Submit button --> */}
  <a href='/otp'><button type="button" class="btn btn-primary btn-block mb-4">Next</button></a>

  {/* <!-- Register buttons --> */}

</form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- End: login Form
        =================================--> */}
    </div>
  )
}

export default QuickRegister