import React from "react";
import { useLocation } from "react-router-dom";

import Table from "react-bootstrap/Table";

const Birthdetails = () => {
  const location = useLocation();
  const birthdetail = location.state?.birthdetail;

  return (
    <div>
      {/* start-partner preference */}
      <section
        id="post-section"
        className="post-section av-py-default"
        style={{ backgroundColor: "#fff", paddingTop: "0px" }}
      >
        <h4
          style={{
            margin: "10px 0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Birth Details
        </h4>
        <div className="av-container">
          <div className="av-columns-area">
            <div className="av-column-12">
              <div className="sidebar">
                <aside
                  id="widget-latest-posts"
                  className="widget widget_latest_posts"
                >
                  <div className="widget-content">
                    <div className="post-content">
                      <h5
                        className="post-title"
                        style={{ fontSize: "20px", textAlign: "center" }}
                      >
                        <a>Nakshatra Details</a>
                      </h5>

                      <Table striped bordered hover text-center>
                        <tbody>
                          <tr>
                            <td style={{ textAlign: "center" }}>Nakshatra</td>
                            <td style={{ textAlign: "center" }}>
                              {birthdetail.data.nakshatra.name}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "center" }}>
                              Nakshatra Pada{" "}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {birthdetail.data.nakshatra.pada}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "center" }}>
                              Nakshatra Lord{" "}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {birthdetail.data.nakshatra.lord.vedic_name}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "center" }}>
                              Chandra Rasi{" "}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {birthdetail.data.chandra_rasi.name}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "center" }}>
                              Chandra Rasi Lord{" "}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {birthdetail.data.chandra_rasi.lord.vedic_name}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "center" }}>
                              Soorya Rasi{" "}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {birthdetail.data.soorya_rasi.name}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "center" }}>
                              Soorya Rasi Lord{" "}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {birthdetail.data.soorya_rasi.lord.vedic_name}
                            </td>
                          </tr>{" "}
                          <tr>
                            <td style={{ textAlign: "center" }}>Zodiac </td>
                            <td style={{ textAlign: "center" }}>
                              {birthdetail.data.zodiac.name}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      <h5
                        className="post-title"
                        style={{ fontSize: "20px", textAlign: "center" }}
                      >
                        <a>Additional Info</a>
                      </h5>

                      <Table striped bordered hover>
                        <tbody>
                          <tr>
                            <td style={{ textAlign: "center" }}>Deity </td>
                            <td style={{ textAlign: "center" }}>
                              {birthdetail.data.additional_info.deity}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "center" }}>Ganam </td>
                            <td style={{ textAlign: "center" }}>
                              {birthdetail.data.additional_info.ganam}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "center" }}>Symbol </td>
                            <td style={{ textAlign: "center" }}>
                              {birthdetail.data.additional_info.symbol}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "center" }}>AnimalSign</td>
                            <td style={{ textAlign: "center" }}>
                              {birthdetail.data.additional_info.animal_sign}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "center" }}>Nadi</td>
                            <td style={{ textAlign: "center" }}>
                              {birthdetail.data.additional_info.nadi}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "center" }}>Color </td>
                            <td style={{ textAlign: "center" }}>
                              {birthdetail.data.additional_info.color}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "center" }}>
                              BestDirection{" "}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {birthdetail.data.additional_info.best_direction}
                            </td>
                          </tr>{" "}
                          <tr>
                            <td style={{ textAlign: "center" }}>Syllables </td>
                            <td style={{ textAlign: "center" }}>
                              {birthdetail.data.additional_info.syllables}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "center" }}>BirthStone</td>
                            <td style={{ textAlign: "center" }}>
                              {birthdetail.data.additional_info.birth_stone}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "center" }}>Gender </td>
                            <td style={{ textAlign: "center" }}>
                              {birthdetail.data.additional_info.gender}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "center" }}>Planet </td>
                            <td style={{ textAlign: "center" }}>
                              {birthdetail.data.additional_info.planet}
                            </td>
                          </tr>{" "}
                          <tr>
                            <td style={{ textAlign: "center" }}>EnemyYoni </td>
                            <td style={{ textAlign: "center" }}>
                              {birthdetail.data.additional_info.enemy_yoni}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end-partner preference */}
    </div>
  );
};

export default Birthdetails;
