import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddSuccessStory } from "../Redux/Action";
import { useDispatch } from "react-redux";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Swal from "sweetalert2";
import AboveFooterDetails from "./AboveFooterDetails";

const SuccessStory = () => {
  const [success, setSuccess] = React.useState(null);
  const [image, setImage] = useState(null);
  const [response, setResponse] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [phoneGError, setPhoneGError] = useState("");
  const [phoneBError, setPhoneBError] = useState("");
  const [brideName, setBrideName] = useState("");
  const [groomName, setGroomName] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;

    if (name === "bridePhoneNumber" || name === "groomPhoneNumber") {
      updatedValue = "+91" + value;
    }
    if (name === "bridePhoneNumber") {
      if (!/^[0-9]{10}$/.test(value)) {
        setPhoneGError("Please enter a valid 10-digit phone number");
      } else {
        setPhoneGError("");
      }
    }
    if (name === "groomPhoneNumber") {
      if (!/^[0-9]{10}$/.test(value)) {
        setPhoneBError("Please enter a valid 10-digit phone number");
      } else {
        setPhoneBError("");
      }
    }
    if (name === "brideName") {
      if (!/^[A-Za-z\s]+$/.test(value)) {
        setBrideName("Please enter only letters (A-Z, a-z)");
      } else {
        setBrideName("");
      }
    }
    if (name === "groomName") {
      if (!/^[A-Za-z\s]+$/.test(value)) {
        setGroomName("Please enter only letters (A-Z, a-z)");
      } else {
        setGroomName("");
      }
    }
    setSuccess({ ...success, [name]: updatedValue });
  };

  const handleImage = (e) => {
    setImage(e.target.files[0]);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("image", image);
    Object.entries(success).map(([key, value]) => formData.append(key, value));
    dispatch(AddSuccessStory(formData)).then((res) => {
      setResponse(res);
      Swal.fire(res);
      navigate("/");
    });
  };

  return (
    <div style={{ backgroundColor: "#fff" }}>
      {/* <!--===// Start: Breadcrumb
=================================--> */}
      <section id="breadcrumb-section" class="breadcrumb-area breadcrumb-left">
        <div class="av-container">
          <div class="av-columns-area">
            <div class="av-column-12">
              <div class="breadcrumb-content">
                <div class="breadcrumb-heading wow fadeInLeft">
                  <h2>Success Story</h2>
                </div>
                <ol class="breadcrumb-list wow fadeInRight">
                  <li>
                    <a href="/">
                      <i class="fa fa-home"></i> Home Page
                    </a>{" "}
                    &nbsp;-&nbsp;
                  </li>
                  <li class="active">Success Story</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: Breadcrumb
=================================--> */}
      {/* start feedback form */}
      <section
        id="post-section"
        className="post-section av-py-default"
        style={{
          backgroundColor: "#fff",
          // backgroundImage: "url('/assets/images/register.jpeg')",
        }}
      >
        <div className="av-container">
          <div className="av-columns-area">
            <div className="av-column-10 mx-auto">
              <div id="comments" className="comments-area">
                <div id="respond" className="comment-respond">
                  <form onSubmit={handleSubmit}>
                    {/* <!-- Email input --> */}
                    <h5 id="reply-title" className="comment-reply-title">
                      Share your success story & Inspire others to find their
                      best match!{" "}
                      <small>
                        <a
                          rel="nofollow"
                          id="cancel-comment-reply-link"
                          href=";"
                          style={{ display: "none" }}
                        >
                          Cancel reply
                        </a>
                      </small>
                    </h5>
                    <div className="form-outline mb-4">
                      <label>Bride Name</label>
                      <span style={{ color: "red" }}>*</span>
                      <input
                        type="text"
                        name="brideName"
                        id="form2Example1"
                        required
                        placeholder="Bride Name (Female)"
                        onChange={handleChange}
                        className={`form-control  ${
                          brideName ? "is-invalid" : ""
                        }`}
                      />
                      {brideName && (
                        <div
                          className="invalid-feedback"
                          style={{ color: "red" }}
                        >
                          {brideName}
                        </div>
                      )}
                    </div>
                  
                    <div className="form-outline mb-4">
                      <label>Groom Name</label>
                      <span style={{ color: "red" }}>*</span>
                      <input
                        type="text"
                        name="groomName"
                        id="form2Example1"
                        placeholder="Groom Name (Male)"
                        onChange={handleChange}
                        className={`form-control  ${
                          groomName ? "is-invalid" : ""
                        }`}
                      />
                      {groomName && (
                        <div
                          className="invalid-feedback"
                          style={{ color: "red" }}
                        >
                          {groomName}
                        </div>
                      )}
                    </div>
                    
                    <div className="form-outline mb-4">
                      <label>Bride Phone Number</label>
                      <span style={{ color: "red" }}>*</span>
                      <input
                        type="text"
                        name="bridePhoneNumber"
                        id="form2Example1"
                        maxlength="10"
                        placeholder="Bride Mobile Number"
                        onChange={handleChange}
                        className={`form-control  ${
                          phoneGError ? "is-invalid" : ""
                        }`}
                        maxLength="10"
                      />
                      {phoneGError && (
                        <div
                          className="invalid-feedback"
                          style={{ color: "red" }}
                        >
                          {phoneGError}
                        </div>
                      )}
                    </div>
                   
                    <div className="form-outline mb-4">
                      <label>Groom Phone Number</label>
                      <span style={{ color: "red" }}>*</span>
                      <input
                        type="text"
                        name="groomPhoneNumber"
                        id="form2Example1"
                        maxlength="10"
                        placeholder="Groom Mobile Number"
                        onChange={handleChange}
                        className={`form-control  ${
                          phoneBError ? "is-invalid" : ""
                        }`}
                        maxLength="10"
                      />
                      {phoneBError && (
                        <div
                          className="invalid-feedback"
                          style={{ color: "red" }}
                        >
                          {phoneBError}
                        </div>
                      )}
                    </div>
                    
                    <div className="form-outline mb-2">
                      <label>Please Upload Image</label>{" "}
                      <span style={{ color: "red" }}>*</span>
                      <input
                        type="file"
                        name="image"
                        id="profilePicture"
                        accept=".jpg, .jpeg, .png"
                        className="form-control"
                        onChange={handleImage}
                        style={{ padding: "15px" }}
                      />
                      <p style={{ color: "#E82E44" }}>
                        (Upload a Marrige Image and File Size up to 4,089 KB &
                        File Type JPG or JPEG or PNG)
                      </p>
                    </div>
                    <div className="form-outline mb-4">
                      <label>Success Story</label>
                      <span style={{ color: "red" }}>*</span>
                      <label>
                        <span className="wpcf7-form-control-wrap your-message">
                          <textarea
                            placeholder="Success Story"
                            name="content"
                            cols="40"
                            rows="10"
                            className="wpcf7-form-control wpcf7-textarea"
                            aria-invalid="false"
                            onChange={handleChange}
                            maxlength={255}
                          ></textarea>
                        </span>
                      </label>
                    </div>
                    <Stack sx={{ width: "100%" }} spacing={2}>
                      {response ? (
                        <Alert severity="error">{response}</Alert>
                      ) : (
                        false
                      )}
                    </Stack>
                    <div
                      className="av-column-12"
                      style={{ paddingBottom: "1px" }}
                    >
                      {" "}
                      <button
                        type="submit"
                        className="btn btn-primary btn-block mb-4"
                        style={{ float: "left" }}
                      >
                        Post
                      </button>
                    </div>
                    {/* <!-- Register buttons --> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end feedback form  */}

      {/* <!--===// Start: Contact Form
        =================================--> */}
      {/* <section id="contactform-section" class="contactform-section av-pb-default" style={{ backgroundColor: "#fff" }}>
            <div class="contactform-cards" style={{marginTop:"0"}}>
                <div class="av-container">
                    <div class="av-columns-area">
                        <div class="av-column-6">
                            <div class="contactform-cards-item" style={{backgroundImage: "url('assets/images/contact/card01.jpg')"}}>
                                <div class="cards-area">
                                    <div class="cards-icon"><i class="fa fa-wechat"></i></div>
                                    <div class="cards-title" style={{fontSize:"25px"}}>WhattsApp Chat</div>
                                    <div class="cards-btn"><a href="#" class="av-btn av-btn-primary av-btn-bubble">Start Chat <i class="fa fa-arrow-right"></i> <span class="bubble_effect"><span class="circle top-left"></span> <span class="circle top-left"></span> <span class="circle top-left"></span> <span class="button effect-button"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span></span></a></div>
                                </div>
                            </div>
                        </div>
                        <div class="av-column-6">
                            <div class="contactform-cards-item" style={{backgroundImage: "url('assets/images/contact/card02.jpg')"}}>
                                <div class="cards-area">
                                <div class="cards-icon"><i class="fa fa-wechat"></i></div>
                                    <div class="cards-title" style={{fontSize:"25px"}}>WhattsApp Chat</div>
                                    <div class="cards-btn"> 
                                        <aside class="share-toolkit widget widget_social_widget">
                                            <a href="#" class="toolkit-hover"><i class="fa fa-share-alt"></i></a>
                                            <ul>
                                                <li><a href="javascript:void(0);"><i class="fa fa-facebook"></i></a></li>
                                                <li><a href="javascript:void(0);"><i class="fa fa-twitter"></i></a></li>
                                                <li><a href="javascript:void(0);"><i class="fa fa-linkedin"></i></a></li>
                                                <li><a href="javascript:void(0);"><i class="fa fa-instagram"></i></a></li>
                                            </ul>
                                        </aside>
                                    </div>
                                    <div class="cards-btn"><a href="#" class="av-btn av-btn-primary av-btn-bubble">Start Chat <i class="fa fa-arrow-right"></i> <span class="bubble_effect"><span class="circle top-left"></span> <span class="circle top-left"></span> <span class="circle top-left"></span> <span class="button effect-button"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span></span></a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
      <AboveFooterDetails />
      {/* <!-- End: Contact Form
        =================================--> */}
    </div>
  );
};

export default SuccessStory;
