import React from "react";

const Contact = () => {
  return (
    <div>
      <div id="content" class="gradiant-content">
        {/* <!--===// Start: Breadcrumb
=================================--> */}
        <section
          id="breadcrumb-section"
          class="breadcrumb-area breadcrumb-left"
        >
          <div class="av-container">
            <div class="av-columns-area">
              <div class="av-column-12">
                <div class="breadcrumb-content">
                  <div class="breadcrumb-heading wow fadeInLeft">
                    <h2>Contact Page</h2>
                  </div>
                  <ol class="breadcrumb-list wow fadeInRight">
                    <li>
                      <a href="/">
                        <i class="fa fa-home"></i> Home Page
                      </a>{" "}
                      &nbsp;-&nbsp;
                    </li>
                    <li class="active">Contact</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End: Breadcrumb
=================================--> */}

        {/* <!--===// Start: Contact Info
=================================--> */}
        {
          //     <section id="contactinfo-section" class="contactinfo-section av-py-default">
          //     <div class="av-container">
          //         <div class="av-columns-area">
          //             <div class="av-column-12">
          //                 <div class="heading-default text-center wow fadeInUp">
          //                     <h3>Frequently Asked <span class="primary-color">Question?</span></h3>
          //                     <span class="separator"><span><span></span></span></span>
          //                     <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          //                 </div>
          //             </div>
          //         </div>
          //         <div class="av-columns-area contactinfo-row">
          //             <div class="av-column-3 av-sm-column-6 px-2">
          //                 <div class="contactinfo-item">
          //                     <i class="fa fa-wechat"></i>
          //                     <h5>Quick Answers</h5>
          //                     <p>Find Brief answer to your short question here</p>
          //                     <a href=";">Read More</a>
          //                 </div>
          //             </div>
          //             <div class="av-column-3 av-sm-column-6 px-2">
          //                 <div class="contactinfo-item">
          //                     <i class="fa fa-whatsapp"></i>
          //                     <h5>Let's Talk</h5>
          //                     <p>Find Brief answer to your short question here</p>
          //                     <a href=";">Call Us</a>
          //                 </div>
          //             </div>
          //             <div class="av-column-3 av-sm-column-6 px-2">
          //                 <div class="contactinfo-item">
          //                     <i class="fa fa-facebook"></i>
          //                     <h5>We are Social</h5>
          //                     <p>Find Brief answer to your short question here</p>
          //                     <a href=";">Join our Community</a>
          //                 </div>
          //             </div>
          //             <div class="av-column-3 av-sm-column-6 px-2">
          //                 <div class="contactinfo-item">
          //                     <i class="fa fa-headphones"></i>
          //                     <h5>Support</h5>
          //                     <p>Find Brief answer to your short question here</p>
          //                     <a href=";">Help & Support</a>
          //                 </div>
          //             </div>
          //         </div>
          //     </div>
          // </section>
        }
        {/* <!-- End: Contact Info
=================================--> */}

        {/* <!--===// Start: Contact Map Info
=================================--> */}
        <section
          id="contactmap-section"
          class="contactmap-section av-py-default"
        >
          <div class="av-container">
            <div class="av-columns-area">
              <div class="av-md-column-6 ml-md-0 mx-auto">
                <div class="contactmapinfo">
                  <aside class="widget widget-contact">
                    <div class="contact-area">
                      <div class="contact-icon">
                        <i class="fa fa-phone"></i>
                      </div>
                      <div class="contact-info">
                        <a
                          href="tel:+(91)9894234066"
                          target="_blank"
                          className="contact-info"
                        >
                          <span className="title">Phone</span>
                          <span className="text">+(91)98942 34066</span>
                        </a>
                      </div>
                    </div>
                  </aside>
                  <aside class="widget widget-contact">
                    <div class="contact-area">
                      <div class="contact-icon">
                        <i class="fa fa-envelope"></i>
                      </div>
                      <div class="contact-info">
                        <a
                          href="mailto:vvkmatrimony12@gmail.com"
                          target="_blank"
                          className="contact-info"
                        >
                          <span className="title">Email</span>
                          <span className="text">vvkmatrimony12@gmail.com</span>
                        </a>
                      </div>
                    </div>
                  </aside>
                  <aside class="widget widget-contact">
                    <div class="contact-area">
                      <div class="contact-icon">
                        <i class="fa fa-map-marker"></i>
                      </div>
                      <div class="contact-info">
                        <span class="title">Address</span>
                        <span class="text">
                          364/A, Swamimalai main Road,
                          <br />
                          Baburajapuram,
                          <br />
                          Swamimalai,
                          <br /> Thanjavur-612302
                          <br />
                          Tamil nadu.
                        </span>
                      </div>
                    </div>
                  </aside>
                  {
                    // <aside class="widget widget-contact">
                    //     <div class="contact-area">
                    //         <div class="contact-icon"><i class="fa fa-clock-o"></i></div>
                    //         <div class="contact-info">
                    //             <span class="title">Our Opening Hours</span>
                    //             <div class="text">
                    //                 <dl class="av-grid-dl">
                    //                     <dt>Monday-Friday</dt>
                    //                     <dd>8.00am - 6.00pm</dd>
                    //                     <dt>Saturday</dt>
                    //                     <dd>9.00am - 2.00pm</dd>
                    //                 </dl>
                    //             </div>
                    //         </div>
                    //     </div>
                    // </aside>
                  }
                </div>
              </div>
              <div class="map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1438544.2714565487!2d10.197676761709376!3d51.14314480954461!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1489634550185"
                  width="100"
                  height="472"
                  frameborder="0"
                  scrolling="no"
                  style={{ border: "0" }}
                  title="map  for location"
                ></iframe>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End: Contact Map Info
=================================--> */}

        {/* <!--===// Start: Contact Office
=================================--> */}
        {
          //     <section id="contactoffice-section" class="contactoffice-section av-py-default">
          //     <div class="av-container">
          //         <div class="av-columns-area">
          //             <div class="av-column-12">
          //                 <div class="heading-default text-center wow fadeInUp">
          //                     <h3>Our <span class="primary-color">Offices</span></h3>
          //                     <span class="separator"><span><span></span></span></span>
          //                     <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          //                 </div>
          //             </div>
          //         </div>
          //         <div class="av-columns-area">
          //             <div class="av-column-12">
          //                 <div class="contactoffice-carousel owl-carousel owl-theme">
          //                     <div class="contactoffice-item">
          //                         <img src="assets/images/contact/unitedStates.png" alt="flag"/>
          //                         <h5>United States</h5>
          //                         <p>4140 Parker Rd, Allentown New California, 98380</p>
          //                         <a href="tel:+01-2345-6789">+01-2345-6789</a>
          //                     </div>
          //                     <div class="contactoffice-item">
          //                         <img src="assets/images/contact/germany.png" alt="flag"/>
          //                         <h5>Munich, Germany</h5>
          //                         <p>32 Dora Creek, Tutable Creek Germany, 48965</p>
          //                         <a href="tel:+01-2345-6789">+01-2345-6789</a>
          //                     </div>
          //                     <div class="contactoffice-item">
          //                         <img src="assets/images/contact/spain.png" alt="flag"/>
          //                         <h5>Madrid Spain</h5>
          //                         <p>8502 Presten Rd, Inglewood Mine 95545</p>
          //                         <a href="tel:+01-2345-6789">+01-2345-6789</a>
          //                     </div>
          //                     <div class="contactoffice-item">
          //                         <img src="assets/images/contact/russia.png" alt="flag"/>
          //                         <h5>Russia, France</h5>
          //                         <p>1240 Park, Avenues Mall Street Russia, 62633</p>
          //                         <a href="tel:+01-2345-6789">+01-2345-6789</a>
          //                     </div>
          //                     <div class="contactoffice-item">
          //                         <img src="assets/images/contact/unitedStates.png" alt="flag"/>
          //                         <h5>United States</h5>
          //                         <p>4140 Parker Rd, Allentown New California, 98380</p>
          //                         <a href="tel:+01-2345-6789">+01-2345-6789</a>
          //                     </div>
          //                 </div>
          //             </div>
          //         </div>
          //     </div>
          // </section>
        }
        {/* <!-- End: Contact Office
=================================--> */}

        {/* <!--===// Start: Contact Form
=================================--> */}
        {/* <section id="contactform-section" class="contactform-section av-pb-default">
    <div class="contactformbg-heading av-py-default">
        <div class="av-container">
            <div class="av-columns-area">
                <div class="av-column-12">
                    <div class="heading-default text-center wow fadeInUp">
                        <h3>Have A <span class="primary-color">Question?</span></h3>
                        <span class="separator"><span><span></span></span></span>
                        <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="contactform-content">
        <div class="av-container">
            <div class="av-columns-area">
                <div class="av-column-12">
                    <div class="contactform">
                        <div role="form" class="wpcf7" id="wpcf7-f229-o1" lang="en" dir="ltr">
                            <div class="screen-reader-response" role="alert" aria-live="polite"></div>
                            <form action="#" method="post" class="wpcf7-form init" novalidate>
                                <div style={{display:"none"}}>
                                    <input type="hidden" name="_wpcf7" value="229"/>
                                    <input type="hidden" name="_wpcf7_version" value="5.2.2"/>
                                    <input type="hidden" name="_wpcf7_locale" value="en"/>
                                    <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f229-o1"/>
                                    <input type="hidden" name="_wpcf7_container_post" value="0"/>
                                    <input type="hidden" name="_wpcf7_posted_data_hash"/>
                                </div>
                                <p>
                                    <label><span class="wpcf7-form-control-wrap your-name"><input type="text" placeholder="Your Name" name="your-name" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false"/></span></label>
                                </p>
                                <p>
                                    <label><span class="wpcf7-form-control-wrap your-email"><input type="email" placeholder="Your Email" name="your-email" size="40" class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email" aria-required="true" aria-invalid="false"/></span></label>
                                </p>
                                <p>
                                    <label><span class="wpcf7-form-control-wrap your-email"><input type="number" placeholder="Phone Number" name="your-number" size="40" class="wpcf7-form-control wpcf7-text wpcf7-number wpcf7-validates-as-required wpcf7-validates-as-number" aria-required="true" aria-invalid="false"/></span></label>
                                </p>
                                <p>
                                    <label><span class="wpcf7-form-control-wrap your-subject"><input type="text" placeholder="Subject" name="your-subject" size="40" class="wpcf7-form-control wpcf7-text" aria-invalid="false"/></span></label>
                                </p>
                                <p>
                                    <label><span class="wpcf7-form-control-wrap your-message"><textarea placeholder="Message" name="your-message" cols="40" rows="10" class="wpcf7-form-control wpcf7-textarea" aria-invalid="false"></textarea></span></label>
                                </p>
                                <p>
                                    <button type="submit" class="av-btn av-btn-primary av-btn-bubble wpcf7-submit">Send Now <i class="fa fa-arrow-right"></i> <span class="bubble_effect"><span class="circle top-left"></span> <span class="circle top-left"></span> <span class="circle top-left"></span> <span class="button effect-button"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span></span></button>
                                    <span class="ajax-loader"></span>
                                </p>
                                <div class="wpcf7-response-output" role="alert" aria-hidden="true"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <div class="contactform-cards">
        <div class="av-container">
            <div class="av-columns-area">
                <div class="av-column-6">
                    <div class="contactform-cards-item" style={{backgroundImage: "url('assets/images/contact/card01.jpg')"}}>
                        <div class="cards-area">
                            <div class="cards-icon"><i class="fa fa-wechat"></i></div>
                            <div class="cards-title">Live Chat</div>
                            <div class="cards-btn"><a href="#" class="av-btn av-btn-primary av-btn-bubble">Start Chat <i class="fa fa-arrow-right"></i> <span class="bubble_effect"><span class="circle top-left"></span> <span class="circle top-left"></span> <span class="circle top-left"></span> <span class="button effect-button"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span></span></a></div>
                        </div>
                    </div>
                </div>
                <div class="av-column-6">
                    <div class="contactform-cards-item" style={{backgroundImage: "url('assets/images/contact/card02.jpg')"}}>
                        <div class="cards-area">
                            <div class="cards-title">Live Chat</div>
                            <div class="cards-btn">
                                <aside class="share-toolkit widget widget_social_widget">
                                    <a href="#" class="toolkit-hover"><i class="fa fa-share-alt"></i></a>
                                    <ul>
                                        <li><a href=";"><i class="fa fa-facebook"></i></a></li>
                                        <li><a href=";"><i class="fa fa-twitter"></i></a></li>
                                        <li><a href=";"><i class="fa fa-linkedin"></i></a></li>
                                        <li><a href=";"><i class="fa fa-instagram"></i></a></li>
                                    </ul>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</section> */}
        {/* <!-- End: Contact Form
=================================--> */}
      </div>
    </div>
  );
};

export default Contact;
