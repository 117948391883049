import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { partnerperf } from "../Redux/Action";
import { useDispatch, useSelector } from "react-redux";
import { getCompletion } from "../Redux/Action";
import Alert from "@mui/material/Alert";
import Swal from "sweetalert2";
import Stack from "@mui/material/Stack";

const Register7 = () => {
  const [partpref, setPartpref] = useState("");
  const [err, setErr] = useState("");
  const [load, setLoad] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(partnerperf(partpref))
      .then((res) => {
        navigate("/");
        window.location.reload();
      })
      .catch((e) => {
        setErr(e.response.data.error.message);
      });
  };
  const [ageError, setAgeError] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "age" && value < 0) {
      return;
    }
    if (name === "age") {
      const enteredAge = parseInt(value);
      if (enteredAge < 18) {
        setAgeError("Age must be 18 or older");
      } else {
        setAgeError("");
      }
    }
    setPartpref({ ...partpref, [name]: value });
  };
  const completion = useSelector(({ getCompletion }) => getCompletion.payload);
  // console.log(completion, "completion");

  useEffect(() => {
    dispatch(getCompletion()).then((res) => {
      if (
        res.data.data.partnerPreference ===
          "Partner preference details missing" ||
        res.data.data.Score === 0
      ) {
        setLoad(false);
      } else {
        navigate("/myprofile");
        window.location.reload();
      }
    });
  }, []);
  return (
    <div>
      {/* <!--===// Start: registeration Form
        =================================--> */}
      <section
        id="post-section"
        className="post-section av-py-default"
        style={{
          backgroundColor: "#fff",
          backgroundImage: "url('/assets/images/register.jpeg')",
        }}
      >
        <div className="av-container">
          <div className="av-columns-area">
            <div className="av-column-12 mx-auto">
              <div id="comments" className="comments-area">
                <div id="respond" className="comment-respond">
                  <form onSubmit={handleSubmit}>
                    {/* <!-- Email input --> */}
                    <h3 id="reply-title" className="comment-reply-title">
                      Partner Preference{" "}
                      <small>
                        <a
                          rel="nofollow"
                          id="cancel-comment-reply-link"
                          href="#"
                          style={{ display: "none" }}
                        >
                          Cancel reply
                        </a>
                      </small>
                    </h3>
                    <div className="form-outline mb-4">
                      <label>
                        Enter Your Partner Age{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="number"
                        name="age"
                        onChange={handleChange}
                        required
                        id="form2Eagexample1"
                        placeholder="Age"
                        className={`form-control mt-3 ${
                          ageError ? "is-invalid" : ""
                        }`}
                        maxLength="2"
                      />
                      {ageError && (
                        <div className="invalid-feedback" style={{color:"red"}}>{ageError}</div>
                      )}
                    </div>
                    <div className="form-outline mb-4">
                      <label>
                        Enter Your Partner Height{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        name="height"
                        placeholder="Enter Height"
                        type="number"
                        onChange={handleChange}
                        required
                        id="Height"
                        maxLength={3}
                        minLength={2}
                      ></input>
                    </div>
                    <div className="form-outline mb-4">
                      <label>
                        Choose Your Partner Dhosam{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        name="dhosam"
                        required
                        onChange={handleChange}
                        id="dhosam"
                      >
                        <option value="Choose dhoosam">Select Dhosam</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                    <div className="form-outline mb-4">
                      <label>
                        Choose Your Partner Rahu/Kethu{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        name="rahuKetu"
                        required
                        onChange={handleChange}
                        id="rahuKetu"
                      >
                        <option value="Choose Rahu/kethu">
                          Select Rahu/Kethu
                        </option>
                        <option value="raghu">Raghu Dhosam</option>
                        <option value="kethu">Kethu Dhosam</option>
                        <option value="raghuKethu">Ragu-Kethu Dhosam</option>
                        <option value="raKePari">
                          Ragu-Kethu Dhosam Parikaram Seiyapattathu
                        </option>
                        <option value="sevvai">Sevvai Dhosam</option>
                        <option value="sevvaiPari">
                          Sevvai Dhosam Parikaram Seiyapattathu
                        </option>
                        <option value="noDhosam">Dhasam Illai</option>
                        <option value="sutha">Suththa Jathagam</option>
                      </select>
                    </div>
                    <div className="form-outline mb-4">
                      <label>
                        Choose Your Partner Annual Income{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        name="annualIncome"
                        required
                        onChange={handleChange}
                        id="annualIncome"
                      >
                        <option value="Choose Profile">
                          Select Annual Income
                        </option>
                        <option value="0-1 Lakhs">0-1 Lakhs</option>
                        <option value="1-2 Lakhs">1-2 Lakhs</option>
                        <option value="2-3 Lakhs">2-3 Lakhs</option>
                        <option value="3-4 Lakhs">3-4 Lakhs</option>
                        <option value="4-5 Lakhs">4-5 Lakhs</option>
                        <option value="5-6 Lakhs">5-6 Lakhs</option>
                        <option value="6-7 Lakhs">6-7 Lakhs</option>
                        <option value="7-8 Lakhs">7-8 Lakhs</option>
                        <option value="8-9 Lakhs">8-9 Lakhs</option>
                        <option value="9-10 Lakhs">9-10 Lakhs</option>
                        <option value="above 10 lakhs">Above 10 Lakhs</option>
                      </select>
                    </div>
                    <div className="form-outline mb-4">
                      <label>
                        Choose Your Partner Foreign Intrest{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        name="foreignInterest"
                        required
                        onChange={handleChange}
                        id="foreignInterest"
                      >
                        <option value="Choose Foreign Intrest">
                          Select Foreign Intrest
                        </option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                    <div className="form-outline mb-4">
                      <label>
                        Choose Your Partner Martial Status{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        name="maritalStatus"
                        required
                        onChange={handleChange}
                        id="maritalStatus"
                      >
                        <option value="Choose Martial Status">
                          Select Martial Status
                        </option>
                        <option value="never married">Never Married</option>
                        <option value="married">Married</option>
                        <option value="separated">Separated</option>
                        <option value="divorced">Divorced</option>
                        <option value="widow/widower">Widow/widower</option>
                      </select>
                    </div>
                    <div className="form-outline mb-4">
                      <label>
                        Specify Your Partner Criteria
                        <span style={{ color: "red" }}>*</span>
                        <span className="wpcf7-form-control-wrap your-message">
                          <textarea
                            placeholder="Type your partner prefernces for upcoming matches"
                            name="weLookingFor"
                            required
                            onChange={handleChange}
                            cols="40"
                            rows="10"
                            className="wpcf7-form-control wpcf7-textarea"
                            aria-invalid="false"
                            maxLength={255}
                          ></textarea>
                        </span>
                      </label>
                    </div>
                    <Stack sx={{ width: "100%" }} spacing={2}>
                      {err ? (
                        <Alert autoHideDuration={6000} severity="error">
                          {err}
                        </Alert>
                      ) : (
                        false
                      )}
                    </Stack>
                    <div
                      className="av-column-12"
                      style={{ paddingBottom: "1px" }}
                    >
                      <a
                      // href='/'
                      >
                        {" "}
                        <button
                          type="submit"
                          className="btn btn-primary btn-block mb-4"
                          style={{ float: "left" }}
                        >
                          Submit
                        </button>
                      </a>
                    </div>
                    {/* <!-- Register buttons --> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: registration Form
        =================================--> */}
    </div>
  );
};

export default Register7;
