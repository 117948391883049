import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ForgotPassword from "../Authentication/ForgotPassword";
import Otp from "../Authentication/Otp";
import QuickRegister from "../Authentication/QuickRegister";
import Register from "../Authentication/Register";
import Register2 from "../Authentication/Register2";
import Register3 from "../Authentication/Register3";
import Register30 from "../Authentication/Register30";
import Register31 from "../Authentication/Register31";
import Register32 from "../Authentication/Register32";
import Register4 from "../Authentication/Register4";
import Register5 from "../Authentication/Register5";
import Register6 from "../Authentication/Register6";
import Register7 from "../Authentication/Register7";
import VerifyPassword from "../Authentication/VerifyPassword";
import Blog from "../Blogs/Blog";
import BlogDetails from "../Blogs/BlogDetails";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import About from "../Component/About";
import Awards from "../Component/Awards";
import Contact from "../Component/Contact";
import Error404 from "../Component/Error404";
import Faq from "../Component/Faq";
import Home from "../Component/Home";
import HoroscopeMatches from "../Matches/HoroscopeMatches";
import IgnoredMatches from "../Matches/IgnoredMatches";
import Index from "../Component/Index";
import NewMatches from "../Matches/NewMatches";
import Premium from "../Component/Premium";
import PremiumMatches from "../Matches/PremiumMatches";
import PrivacyPolicy from "../Information/PrivacyPolicy";
import TermsandConditions from "../Information/TermsandConditions";
import Profile from "../MyProfile/Profile";
import ProfileDetails from "../MyProfile/ProfileDetails";
import Login from "../Authentication/Login";
import SomethingWentWrong from "../Component/SomethingWentWrong";
import Search from "../Component/Search";
import SuccessStory from "../Component/SuccessStory";
import Report from "../Component/Report";
import OrderSummary from "../Component/OrderSummary";
import SearchProfile from "../Component/SearchProfile";
import PaymentStatus from "../Component/PaymentStatus";
import FeedBack from "../Component/FeedBack";
import AllProfile from "../Matches/AllProfile";
import ShortListed from "../Matches/ShortListed";
import UpdateFamilyDetails from "../UpdateForms/UpdateFamilyDetails";
import SuccessProfile from "../Component/SuccessProfile";
import OurServices from "../Component/OurServices";
import VisitedProfile from "../Matches/VisitedProfile";
import ContactProfile from "../Matches/ContactProfile";
import Disclaimer from "../Information/Disclaimer";
import Birthdetails from "../MyProfile/BirthDetails";
import Birthchart from "../MyProfile/BirthChart";
import PDFView from "../MyProfile/PDFView";
import LikeList from "../Matches/LikeList";
import VisitList from "../Matches/VisitList";
import Interest from "../Matches/Interest";
import InterestRecive from "../Matches/InterestRecive";
import SubcriptionHistory from "../Component/SubcriptionHistory";
import NakshartaMatches from "../Matches/NakshatraMatches";


const Mainroutes = () => {
  const Token = localStorage.getItem("Authtok");

  return (
    <div>
      <Router>
        <Header />
        <Routes>
          {/* Public Routes */}
          {Token ? (
            <>
              <Route exact path={"/personaldetail"} element={<Register2 />} />
              <Route exact path={"/edu&prof"} element={<Register3 />} />
              <Route exact path={"/horoscopedetail"} element={<Register4 />} />
              <Route exact path={"/idproof"} element={<Register5 />} />
              <Route exact path={"/familydetail"} element={<Register6 />} />
              <Route exact path={"/partnerdetail"} element={<Register7 />} />
            </>
          ) : (
            <Route exact path={"*"} element={<Home />} />
          )}
          <Route exact path={"/signup"} element={<Register />} />
          <Route exact path={"/"} element={<Home />} />
          <Route exact path={"/index"} element={<Index />} />
          <Route exact path={"/about"} element={<About />} />
          <Route exact path={"/contact"} element={<Contact />} />
          <Route exact path={"/blog"} element={<Blog />} />
          <Route exact path={"/blogdetails"} element={<BlogDetails />} />
          <Route exact path={"/disclaimer"} element={<Disclaimer />} />
          <Route
            exact
            path={"/terms&condition"}
            element={<TermsandConditions />}
          />
          <Route
            exact
            path={"/subscriptionhistory"}
            element={<SubcriptionHistory />}
          />
          <Route exact path={"/privacypolicy"} element={<PrivacyPolicy />} />
          <Route exact path={"/login"} element={<Login />} />
          <Route exact path={"/quickregister"} element={<QuickRegister />} />
          <Route exact path={"/otp"} element={<Otp />} />

          <Route exact path={"/forgotpassword"} element={<ForgotPassword />} />
          <Route
            exact
            path={"/verifypassword/:id"}
            element={<VerifyPassword />}
          />
          <Route exact path={"/newmatches"} element={<NewMatches />} />
          <Route exact path={"/contactprofile"} element={<ContactProfile />} />
          <Route exact path={"/allprofile"} element={<AllProfile />} />
          <Route exact path={"/premiummatches"} element={<PremiumMatches />} />
          <Route exact path={"/nakshatramatches"} element={<NakshartaMatches />} />

          <Route exact path={"/likelist"} element={<LikeList />} />
          <Route exact path={"/visitlist"} element={<VisitList />} />
          <Route exact path={"/interest"} element={<Interest />} />
          <Route exact path={"/interestrec"} element={<InterestRecive />} />
          <Route
            exact
            path={"/horoscopematches"}
            element={<HoroscopeMatches />}
          />
          <Route exact path={"/ignoredmatches"} element={<IgnoredMatches />} />
          <Route exact path={"/visitedprofile"} element={<VisitedProfile />} />
          <Route exact path={"/awards"} element={<Awards />} />
          <Route exact path={"/myprofile"} element={<Profile />} />
          <Route
            exact
            path={"/profiledetails/:id"}
            element={<ProfileDetails />}
          />
          <Route exact path={"/pdfview"} element={<PDFView />} />
          <Route exact path={"/birthdetail"} element={<Birthdetails />} />
          <Route exact path={"/birthchart"} element={<Birthchart />} />
          <Route exact path={"/premium"} element={<Premium />} />
          <Route exact path={"/faq"} element={<Faq />} />
          <Route exact path={"/rasi"} element={<Register30 />} />
          <Route exact path={"/amsa"} element={<Register31 />} />
          <Route exact path={"/horoscopeauto"} element={<Register32 />} />
          <Route exact path={"/search"} element={<Search />} />
          <Route exact path={"/searchprofile"} element={<SearchProfile />} />
          <Route exact path={"/report/:id"} element={<Report />} />
          <Route exact path={"/feedback"} element={<FeedBack />} />
          <Route exact path={"/ordersummary"} element={<OrderSummary />} />
          <Route exact path={"/paymentstatus"} element={<PaymentStatus />} />
          <Route exact path={"/successstory"} element={<SuccessStory />} />
          <Route exact path={"/shortlistedprofile"} element={<ShortListed />} />
          <Route
            exact
            path={"/updatefamdetails"}
            element={<UpdateFamilyDetails />}
          />
          <Route
            exact
            path={"/profileofsuccesstory"}
            element={<SuccessProfile />}
          />
          <Route exact path={"/ourservices"} element={<OurServices />} />

          <Route path={"*"} element={<Error404 />} />
          <Route exact path={"*"} element={<SomethingWentWrong />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
};

export default Mainroutes;
