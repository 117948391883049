import React from 'react'
// import { Link } from "react-router-dom"
import error from "../error/Forgot password-bro.svg"

const SomethingWentWrong = () => {

    function err(){
        window.location.reload();
    }
  return (
    <div>
    {/* <!--===// Start: 404 Page
    =================================--> */}
    <section id="section404" class="section404 shapes-section" style={{backgroundColor:"#fff"}}>
        <div class="av-container">
            <div class="av-columns-area wow fadeInUp">
                <div class="av-column-6 text-center mx-auto">
                    <div class="card404">
                        <img src={error} alt="Somthing Went Wrong" style={{backgroundImage: "url('/assets/images/Forgot password-bro.svg')"}}/>
                        {/* <h1>4<span class="card404icon"><i class="fa fa-wrench"></i></span>4</h1> */}
                        <h4>Somthing Went Wrong</h4>
                        <p>Lorem ipsum is simply dummy text of the printing and tepesetting industry. Lorem ipsum has been industry.</p>
                        <div class="card404-btn mt-3">
                            {/* <a href=";" class="av-btn av-btn-primary av-btn-bubble">Back <i class="fa fa-arrow-right"></i> <span class="bubble_effect"><span class="circle top-left"></span> <span class="circle top-left"></span> <span class="circle top-left"></span> <span class="button effect-button"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span></span></a> */}
                            <a onClick={err} class="av-btn av-btn-secondary av-btn-bubble">Retry <i class="fa fa-arrow-right"></i> <span class="bubble_effect"><span class="circle top-left"></span> <span class="circle top-left"></span> <span class="circle top-left"></span> <span class="button effect-button"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="shape24"><img src="assets/images/page404/img01.png" alt="page404 img01"/></div>
        <div class="shape25"><img src="assets/images/page404/img02.png" alt="page404 img01"/></div>
        <div class="shape26"><img src="assets/images/page404/img03.png" alt="page404 img02"/></div>
        <div class="shape27"><img src="assets/images/page404/img04.png" alt="page404 img02"/></div>
        <div class="shape28"><img src="assets/images/page404/img05.png" alt="page404 img03"/></div>
        <div class="shape29"><img src="assets/images/page404/img06.png" alt="page404 img03"/></div>
        <div class="shape30"><img src="assets/images/page404/img07.png" alt="page404 img04"/></div>
        <div class="shape31"><img src="assets/images/page404/img08.png" alt="page404 img04"/></div>
        <div class="shape32"><img src="assets/images/page404/img09.png" alt="page404 img05"/></div>
        <div class="shape33"><img src="assets/images/page404/img10.png" alt="page404 img05"/></div>
        <div class="shape34"><img src="assets/images/page404/img11.png" alt="page404 img06"/></div>
        <div class="shape35"><img src="assets/images/page404/img12.png" alt="page404 img06"/></div>
        <div class="shape36"><img src="assets/images/page404/img13.png" alt="page404 img07"/></div>
    </section>
    {/* <!-- End: 404 Page
    =================================--> */}
</div>
  )
}

export default SomethingWentWrong