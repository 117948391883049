import React from "react";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { MdVerifiedUser, MdLocalOffer, MdMessage } from "react-icons/md";
import {
  recommendprofilee,
  recentprofile,
  Homerecentprofile,
  AddIntrest,
} from "../Redux/Action";
import { useDispatch, useSelector } from "react-redux";
// import Skeleton from '@mui/material/Skeleton';
import { Skeleton } from "../Common/Skeleton";
import Swal from "sweetalert2";

const HomeProfile = () => {
  const [load, setLoad] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();
  const [add, setAdd] = useState("");
  const [err, setErr] = useState("");
  const [profileId, setProfileId] = useState({
    interestProfileId: "",
  });
  const dispatch = useDispatch();
  const Token = localStorage.getItem("Authtok");
  const profile = useSelector(({ recentprofile }) => recentprofile.payload);
  const recprofile = useSelector(
    ({ recommendprofilee }) => recommendprofilee.payload
  );
  const homeprofile = useSelector(
    ({ Homerecentprofile }) => Homerecentprofile.payload
  );
  if (profile) {
    // console.log(profile);
    if (profile.status === "unauthorized") {
      navigate("/login");
    } else if (profile.status === "failed") {
      navigate("/error");
    } else if (profile.status === "timeout") {
      navigate("/login");
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    Promise.allSettled([dispatch(Homerecentprofile())]).then((res) =>
      setLoad(false)
    );
  }, []);
  useEffect(() => {
    if (Token) {
      window.scrollTo(0, 0);
      Promise.allSettled([
        dispatch(recentprofile()),
        dispatch(recommendprofilee()),
      ]).then((res) => setLoad(false));
    }
  }, [Token]);

  const handleClick1 = (id) => {
    dispatch(AddIntrest({ interestProfileId: id }))
      .then((res) => {
        Swal.fire(res.data.data);
        setErr(res.data.data);
      })
      .catch((e) => {
        setErr(e.response.data.error.message);
        console.log("err", e.response.data.error.message);
      });
  };

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      {/* <!--===// Start: groom
        =================================--> */}
      {Token ? (
        <section
          id="team-section"
          className="team-section av-py-default team-home shapes-section"
          style={{ backgroundColor: "#fff", paddingBottom: "0" }}
        >
          {homeprofile === "Data Not Found!" ? null : (
            <div className="av-container">
              <div className="av-columns-area">
                <div className="av-column-12">
                  <div className="heading-default text-center wow fadeInUp">
                    <h3>
                      New <span className="primary-color">Profiles</span>
                    </h3>
                    <span className="separator">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    {/* <p>
                                        Profile with a fine prospect of happiness behind her.
                                    </p> */}
                  </div>
                </div>
              </div>

              <div className="av-columns-area">
                {Array.isArray(homeprofile) && homeprofile.length !== 0 ? (
                  homeprofile.map((x) => (
                    <div className="av-column-3 av-sm-column-6" key={x.id}>
                      <div className="team-member">
                        <div className="team-thumb" tabIndex="0">
                          <p
                            style={{
                              margin: "0px",
                              padding: "10px",
                              color: "#252525",
                            }}
                          >
                            {x.profileId} |&nbsp;
                            <span style={{ color: "#00a03a" }}>{x.age}Yrs</span>
                          </p>

                          <Link
                            to={Token ? "/profiledetails/" + x.id : "/login"}
                          >
                            <img
                              src={process.env.REACT_APP_URL + x.images}
                              alt="team_item"
                              title=""
                              style={{ aspectRatio: "1/1", objectFit: "cover" }}
                            />
                          </Link>

                          <div
                            className="team-thumber"
                            style={{ cursor: "pointer" }}
                          >
                            {Token ? (
                              <aside className="widget widget_social_widget">
                                <Link>
                                  <div
                                    onClick={() => handleClick1(x.id)}
                                    className="cards-title"
                                    style={{ color: "#fff" }}
                                  >
                                    Send Interest
                                  </div>
                                </Link>
                              </aside>
                            ) : (
                              <aside className="widget widget_social_widget">
                                <Link href="/login">
                                  <div
                                    className="cards-title"
                                    style={{ color: "#fff" }}
                                  >
                                    Send Interest
                                  </div>
                                </Link>
                              </aside>
                            )}
                          </div>
                        </div>

                        <div className="team-info">
                          <Link
                            to={Token ? "/profiledetails/" + x.id : "/login"}
                          >
                            <h5 style={{ textTransform: "capitalize" }}>
                              {x.userName.length > 10
                                ? x.userName.substring(0, 10) + "..."
                                : x.userName}
                            </h5>
                            <p>View Profile</p>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  // <h3 style={{ textAlign: "center" }}>No Profile Match</h3>
                  // <Skeleton variant="rectangular" width={1120} height={200} />
                  <Skeleton />
                )}
              </div>
            </div>
          )}
        </section>
      ) : (
        <section
          id="team-section"
          className="team-section av-py-default team-home shapes-section"
          style={{ backgroundColor: "#fff", paddingBottom: "0" }}
        >
          {profile === "Data Not Found!" ? null : (
            <div className="av-container">
              <div className="av-columns-area">
                <div className="av-column-12">
                  <div className="heading-default text-center wow fadeInUp">
                    <h3>
                      New <span className="primary-color">Matches</span>
                    </h3>
                    <span className="separator">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    {/* <p>Profile with a fine prospect of happiness behind her.</p> */}
                  </div>
                </div>
              </div>

              <div className="av-columns-area">
                {Array.isArray(profile) && profile.length !== 0 ? (
                  profile.map((x) => (
                    <div className="av-column-3 av-sm-column-6">
                      <div className="team-member">
                        <div className="team-thumb" tabIndex="0">
                          <p
                            style={{
                              margin: "0px",
                              padding: "10px",
                              color: "#252525",
                            }}
                          >
                            {x.profileId} |&nbsp;
                            <span style={{ color: "#00a03a" }}>{x.age}Yrs</span>
                          </p>
                          <a
                            href={Token ? "/profiledetails/" + x.id : "/login"}
                          >
                            <img
                              src={process.env.REACT_APP_URL + x.images}
                              alt="team_item"
                              title=""
                              style={{ aspectRatio: "1/1", objectFit: "cover" }}
                            />
                          </a>
                          <div
                            className="team-thumber"
                            style={{ cursor: "pointer" }}
                          >
                            {Token ? (
                              <aside className="widget widget_social_widget">
                                <a
                                  onClick={() =>
                                    dispatch(
                                      AddIntrest({ interestProfileId: x.id })
                                    ).then((res) => {
                                      Swal.fire(res.data.data);
                                      setAdd(res.data.data);
                                    })
                                  }
                                >
                                  <div
                                    className="cards-title"
                                    style={{ color: "#fff" }}
                                  >
                                    Send Interest
                                  </div>
                                </a>
                              </aside>
                            ) : (
                              <aside className="widget widget_social_widget">
                                <a href="/login">
                                  <div
                                    className="cards-title"
                                    style={{ color: "#fff" }}
                                  >
                                    Send Interest
                                  </div>
                                </a>
                              </aside>
                            )}
                          </div>
                        </div>
                        <div className="team-info">
                          <a
                            href={Token ? "/profiledetails/" + x.id : "/login"}
                          >
                            <h5 style={{ textTransform: "capitalize" }}>
                              {x.userName.length > 10
                                ? x.userName.substring(0, 10) + "..."
                                : x.userName}
                            </h5>
                            <p>View Profile</p>
                          </a>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  // <h3 style={{ textAlign: "center" }}>No Profile Match</h3>
                  // <Skeleton variant="rectangular" width={1120} height={200} />
                  <Skeleton />
                )}
              </div>
            </div>
          )}
        </section>
      )}
      {/* <!-- End: groom
        =================================--> */}

      {/* <Stack sx={{ width: "100%" }} spacing={2}>
        {err ? <Alert severity="error">{err}</Alert> : false}
      </Stack> */}

      {/* <!--===// Start: bride
        =================================--> */}
      {Token ? (
        <section
          id="team-section"
          className="team-section av-py-default team-home shapes-section"
          style={{ backgroundColor: "#fff" }}
        >
          {recprofile === "Data Not Found!" ||
          recentprofile[0] === "Data not found" ? null : (
            <div className="av-container">
              <div className="av-columns-area">
                <div className="av-column-12">
                  <div className="heading-default text-center wow fadeInUp">
                    <h3>
                      Recommended{" "}
                      <span className="primary-color">Profiles</span>
                    </h3>
                    <span className="separator">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    {/* <p>Being happy never goes out of style.</p> */}
                  </div>
                </div>
              </div>

              <div className="av-columns-area">
                {Array.isArray(recprofile) && recprofile.length !== 0 ? (
                  recprofile.map((x) =>
                    x === "Data not found" ? null : (
                      <div className="av-column-3 av-sm-column-6">
                        <div className="team-member">
                          <div className="team-thumb" tabIndex="0">
                            <p
                              style={{
                                margin: "0px",
                                padding: "10px",
                                color: "#252525",
                              }}
                            >
                              {x.profileId} |&nbsp;
                              <span style={{ color: "#00a03a" }}>
                                {x.age}Yrs
                              </span>
                            </p>
                            <a
                              href={
                                Token ? "/profiledetails/" + x.id : "/login"
                              }
                            >
                              <img
                                src={process.env.REACT_APP_URL + x.images}
                                alt="team_item"
                                title=""
                                style={{
                                  aspectRatio: "1/1",
                                  objectFit: "cover",
                                }}
                              />
                            </a>
                            {/* <div className="team-view"><a href="#"><i className="fa fa-eye"></i></a></div> */}
                            <div
                              className="team-thumber"
                              style={{ cursor: "pointer" }}
                            >
                              {Token ? (
                                <aside className="widget widget_social_widget">
                                  <a
                                    onClick={() =>
                                      dispatch(
                                        AddIntrest({ interestProfileId: id })
                                      ).then((res) => {
                                        Swal.fire(res.data.data);
                                        setErr(res.data.data);
                                      })
                                    }
                                  >
                                    <div
                                      className="cards-title"
                                      style={{ color: "#fff" }}
                                    >
                                      Send Interest
                                    </div>
                                  </a>
                                </aside>
                              ) : (
                                <aside className="widget widget_social_widget">
                                  <a href="/login">
                                    <div
                                      className="cards-title"
                                      style={{ color: "#fff" }}
                                    >
                                      Send Interest
                                    </div>
                                  </a>
                                </aside>
                              )}
                            </div>
                          </div>
                          <div className="team-info">
                            <a
                              href={
                                Token ? "/profiledetails/" + x.id : "/login"
                              }
                            >
                              {" "}
                              <h5 style={{ textTransform: "capitalize" }}>
                                {x.userName.length > 10
                                  ? x.userName.substring(0, 10) + "..."
                                  : x.userName}
                              </h5>
                              <p>View Profile</p>
                            </a>
                          </div>
                        </div>
                      </div>
                    )
                  )
                ) : (
                  // <h3 style={{ textAlign: "center" }}>No Profile Match</h3>
                  // <Skeleton variant="rectangular" width={1120} height={200} sx={{textAlign:"center"}} />
                  <Skeleton />
                )}
              </div>
            </div>
          )}
        </section>
      ) : (
        false
      )}
      {/* <!-- End: bride
        =================================--> */}
    </div>
  );
};

export default HomeProfile;
