import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Subcription } from "../Redux/Action";
import moment from "moment";
import Loader from "../Common/Loader";

const SubcriptionHistory = () => {
  // const [viewprofile, setViewprofile] = useState("");
  const [load, setLoad] = useState(true);
  const dispatch = useDispatch();

  const subcription = useSelector(({ Subcription }) => Subcription.payload);
  useEffect(() => {
    dispatch(Subcription()).then((res) => setLoad(false));
  }, []);

  return !subcription ? (
    <Loader />
  ) : (
    <>
      <div>
        <section
          id="post-section"
          className="post-section av-py-defaults"
          style={{ backgroundColor: "#fff", paddingTop: "0px" }}
        >
          <div className="heading-default text-center wow fadeInUp ">
            <h3 style={{ padding: "30px 0px 0px 0px" }}>
              Your <span className="primary-color"> Membership Plans</span>
            </h3>
            <span className="separator">
              <span>
                <span></span>
              </span>
            </span>
          </div>

          <div className="av-container">
            {subcription === "Subscriptions not found" ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "30px",
                  fontWeight: "600",marginTop:"50px",padding:"30px 0px"
                }}
              >
                Subscriptions not found
              </div>
            ) : (
              <div className="av-columns-area">
                {Array.isArray(subcription) && subcription.length !== 0 ? (
                  subcription.map((x) => (
                    <div className="av-column-6">
                      <article className="post-items">
                        <div className="post-content">
                          <h5 className="post-title"></h5>

                          <ul className="av-list-check">
                            <li>
                              Membership Plan Name :{" "}
                              <span style={{ fontWeight: "600" }}>
                                {x.membershipName}
                              </span>
                            </li>
                            <li>
                              Membership Plan Price :{" "}
                              <span style={{ fontWeight: "600" }}>
                                {x.amount}
                              </span>
                            </li>
                            <li>
                              Membership Duration From :{" "}
                              <span style={{ fontWeight: "600" }}>
                                {moment(x.durationFrom).format("DD-MM-YYYY")}
                              </span>
                            </li>

                            <li>
                              Membership Duration To :{" "}
                              <span style={{ fontWeight: "600" }}>
                                {moment(x.durationTo).format("DD-MM-YYYY")}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </article>
                    </div>
                  ))
                ) : (
                  <h3
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "80px 0px",
                    }}
                  >
                    Subcription not found
                  </h3>
                )}
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default SubcriptionHistory;
