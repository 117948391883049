import React from "react";
import { useState ,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompletion } from "../Redux/Action";  
import { useNavigate } from "react-router-dom";
import { idproofdet } from "../Redux/Action";
import Alert from "@mui/material/Alert";
import Swal from 'sweetalert2'
import Stack from "@mui/material/Stack";

const Register5 = () => {
  const navigate = useNavigate();
  const [err, setErr] = useState("");
  const dispatch = useDispatch();
  const [load, setLoad] = useState(true);
  // const [cert, setCert] = useState("")
  // const [horo, setHoro] = useState("")

  const [proofFiles, setProofFiles] = useState({
    proofId: null,
    certificate: null,
    horo: null,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
  
    Object.entries(proofFiles).forEach(([key, value]) => {
      formData.append("document", value);
      dispatch(idproofdet(formData))
        .then((res) => {
          setErr(res.data.data);
          navigate("/familydetail");
        })
        .catch((e) => {
          if (e.res && e.res.Error === "UnAuthorized") {
            setErr("Please login...!");
          }
        });
    });
  };

  const handleChange = (e) => {
    setProofFiles({ ...proofFiles, [e.target.name]: e.target.files[0] });
  };

  // const handleChange1= (e) => {
  //   setIdproof(e.target.files[0]);
  //   console.log("handlechange", idproof);
  //   // setIdproof(e.target.files[0]);
  // }
  // const handleChange2= (e) => {
  //   setCert(e.target.files[0]);
  //   console.log("handlechange", cert);
  //   // setIdproof(e.target.files[0]);
  // }
  // const handleChange3= (e) => {
  //   setHoro(e.target.files[0]);
  //   console.log("handlechange", horo);
  //   // setIdproof(e.target.files[0]);
  // }
  const completion = useSelector(({ getCompletion }) => getCompletion.payload);
  // console.log(completion, "completion");

  useEffect(() => {
    dispatch(getCompletion()).then((res) => {
      if (
        res.data.data.userDocuments === "User document details missing" ||
        res.data.data.Score === 0
      ) {
        setLoad(false);
      } else {
        navigate("/myprofile");
        window.location.reload();
      }
    });
  }, []);
  return (
    <div>
      {/* <!--===// Start: registeration Form
        =================================--> */}
      <section
        id="post-section"
        className="post-section av-py-default"
        style={{
          backgroundColor: "#fff",
          backgroundImage: "url('/assets/images/register.jpeg')",
        }}
      >
        <div className="av-container">
          <div className="av-columns-area">
            <div className="av-column-10 mx-auto">
              <div id="comments" className="comments-area">
                <div id="respond" className="comment-respond">
                  <form onSubmit={handleSubmit}>
                    {/* <!-- Email input --> */}
                    {/* <h3 id="reply-title" className="comment-reply-title">
                      Lets Create Your Profile{" "}
                      <small>
                        <a
                          rel="nofollow"
                          id="cancel-comment-reply-link"
                          href="#"
                          style={{ display: "none" }}
                        >
                          Cancel reply
                        </a>
                      </small>
                    </h3> */}
                    <label className="form-label" for="form2Example1">
                      <h5 style={{ paddingBottom: "5px" }}>
                        ID Proof - Aadhar Card
                      </h5>
                    </label>

                    <p>( File Type Should be PDF)</p>
                    <div
                      className="form-outline mb-4"
                      style={{ border: "solid 1px" }}
                    >
                      <input
                        type="file"
                        name="proofId"
                        accept=".pdf"
                        onChange={handleChange}
                        required
                        id="form2Example1"
                        className="form-control"
                        placeholder="Username"
                        style={{ padding: "10px" }}
                      />
                    </div>
                    <label className="form-label" for="form2Example1">
                      <h5 style={{ paddingBottom: "5px" }}>
                        Education Details
                      </h5>
                    </label>
                    <p>( File Type Should be PDF)</p>
                    <div
                      className="form-outline mb-4"
                      style={{ border: "solid 1px" }}
                    >
                      <input
                        type="file"
                        name="certificate"
                        accept=".pdf"
                        onChange={handleChange}
                        required
                        id="form2Example1"
                        className="form-control"
                        placeholder="Username"
                        style={{ padding: "10px" }}
                      />
                    </div>
                    <label className="form-label" for="form2Example1">
                      <h5 style={{ paddingBottom: "5px" }}>Horoscope</h5>
                    </label>
                    <p>( File Type Should be PDF)</p>
                    <div
                      className="form-outline mb-4"
                      style={{ border: "solid 1px" }}
                    >
                      <input
                        type="file"
                        name="horo"
                        accept=".pdf"
                        onChange={handleChange}
                        required
                        id="form2Example1"
                        className="form-control"
                        placeholder="Username"
                        style={{ padding: "10px" }}
                      />
                    </div>
                    <Stack sx={{ width: "100%" }} spacing={2}>
                      {err ? (
                        <Alert autoHideDuration={6000} severity="error">
                          {err}
                        </Alert>
                      ) : (
                        false
                      )}
                    </Stack>
                    <div
                      className="av-column-12"
                      style={{ paddingBottom: "1px" }}
                    >
                     
                        {" "}
                        <button
                          type="submit"
                          className="btn btn-primary btn-block mb-4"
                          style={{ float: "left" }}
                        >
                          Next
                        </button>
                    
                      <a href='/familydetail'> <button
                      type="button"
                      className="btn btn-primary btn-block mb-4"
                      style={{float:"right"}}
                    >
                      Skip
                    </button></a>
                    </div>
                    {/* <!-- Register buttons --> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: registration Form
        =================================--> */}
    </div>
  );
};

export default Register5;
