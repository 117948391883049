import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { personaldetails, getCompletion } from "../Redux/Action";
import { profession } from "../Redux/Action";
import Alert from "@mui/material/Alert";
import Swal from "sweetalert2";
import Stack from "@mui/material/Stack";
const casteOptions = [
  "24 Manai Telugu Chettiar",
  " Aaru Nattu Vellala",
  " Achirapakkam Chettiar",
  " Adi Dravidar",
  " Agamudayar / Arcot / Thuluva Vellala",
  "Agaram Vellan Chettiar",
  "Ahirwar",
  "Arunthathiyar",
  "Ayira Vysya",
  "Badaga",
  "Bairwa",
  "Balai",
  "Beri Chettiar",
  "Boyar",
  "Brahmin - Anaviln Desai",
  "Brahmin - Baidhiki/Vaidhiki",
  "Brahmin - Bardai",
  "Brahmin - Bhargav",
  "Brahmin - Gurukkal",
  "Brahmin - Iyengar",
  "Brahmin - lyer",
  "Brahmin - Khadayata",
  "Brahmin - Khedaval",
  "Brahmin - Mevada",
  "Brahmin - Others",
  "Brahmin - Rajgor",
  "Brahmin - Rarhi/Radhi",
  "Brahmin - Sarua",
  "Brahmin - Shri Gaud",
  "Brahmin - Tapodhan",
  "Brahmin - Valam",
  "Brahmin - Zalora",
  "Chattada Sri Vaishnava",
  "Cherakula Vellalar",
  "Chettiar",
  "Dasapalanjika / Kannada Saineegar",
  "Desikar",
  "Desikar Thanjavur",
  "Devandra Kula Vellalar",
  "Devanga Chettiar",
  "Devar/Thevar/Mukkulathor",
  "Dhanak",
  "Elur Chetty",
  "Gandla / Ganiga",
  "Gounder",
  "Gounder - Kongu Vellala Gounder",
  "Gounder - Nattu Gounder",
  "Gounder - Others -",
  "Gounder - Urali Gounder",
  "Gounder - Vanniya Kula Kshatriyar",
  "Gounder - Vettuva Gounder",
  "Gramani",
  "Gurukkal Brahmin",
  "Illaththu Pillai",
  "Intercaste",
  "Isai Vellalar",
  "Iyengar Brahmin",
  "Iyer Brahmin",
  "Julaha",
  "Kamma Naidu",
  "Kanakkan Padanna",
  "Kandara",
  "Karkathar",
  "Karuneegar",
  "Kasukara",
  "Kerala Mudali",
  "Khatik",
  "Kodikal Pillai",
  "Kongu Chettiar",
  "Kongu Nadar",
  "Kongu Vellala Gounder",
  "Kori/Koli",
  "Krishnavaka",
  "Kshatriya Raju",
  "Kulalar",
  "Kuravan",
  "Kuruhini Chetty",
  "Kurumbar",
  "Kuruva",
  "Manjapudur Chettiar",
  "Mannan/Velan / Vannan",
  "Maruthuvar",
  "Meenavar",
  "Meghwal",
  "Mudaliyar",
  "Mukkulathor",
  "Muthuraja / Mutharaiyar",
  "Nadar",
  "Naicker",
  "Naicker - Others",
  "Naicker - Vanniya Kula Kshatriyar",
  "Naidu",
  "Nanjil Mudali",
  "Nanjil Nattu Vellalar",
  "Nanjil Vellalar",
  "Nanjil pillai",
  "Nankudi Vellalar",
  "Nattu Gounder",
  "Nattukottai Chettiar",
  "Othuvaar",
  "Padmashali",
  "Pallan / Devandra Kula Vella",
  "Panan",
  "Pandaram",
  "Pandiya Vellalar",
  "Pannirandam Chettiar",
  "Paravan / Bharatar",
  "Parkavakulam / Udayar",
  "Parvatha Rajakulam",
  "Paswan / Dusadh",
  "Pattinavar",
  "Pattusali",
  "Pillai",
  "Poundra",
  "Pulaya/Cheruman",
  "Reddy",
  "Rohit / Chamar",
  "SC",
  "ST",
  "Sadhu Chetty",
  "Saiva Pillai Thanjavur",
  "Saiva Pillai Tirunelveli",
  "Saiva Vellan chettiar",
  "Saliyar",
  "Samagar",
  "Sambava",
  "Satnami",
  "Senai Thalaivar",
  "Senguntha Mudaliyar",
  "Sengunthar/Kaikolar",
  "Shilpkar",
  "Sonkar",
  "Sourashtra",
  "Sozhia Chetty",
  "Sozhiya Vellalar",
  "Telugupatti",
  "Thandan",
  "Thondai Mandala Vellalar",
  "Urali Gounder",
  "Vadambar",
  "Vadugan",
  "Valluvan",
  "Vaniya Chettiar",
  "Vannar",
  "Vannia Kula Kshatriyar",
  "Veera Saivam",
  "Veerakodi Vellala",
  "Vellalar",
  " Vellan Chettiars",
  "Vettuva Gounder",
  "Vishwakarma",
  "Vokkaliga",
  " Yadav",
  " Yadava Naidu",
];

const subCasteOptions = {
  "Brahmin - Iyengar": [
    "Thenkalai",
    "Vadakalai",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
  ],
  "Brahmin - lyer": [
    "Ashtasahasram",
    "Brahacharanam",
    "Vadama",
    "Vathima",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
  ],
  "Brahmin - Others": [
    "Brahmin - Anavil",
    "Brahmin - Anaviln Desai",
    "Brahmin - Audichya",
    "Brahmin - Khedaval",
    "Bardai",
    "Bhargav",
    "Brahmin - Gaur",
    "Khadayata",
    "Mevada",
    "Modh",
    "Nagar",
    "Rajgor",
    "Brahmin - Saraswat",
    "Brahmin - Shri Gaud",
    "Shri Mali",
    "Tapodhan",
    "Valam",
    "Zalora",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
    "Barendra",
    "Panda",
    "Rarhi/Radhi",
    "Danua",
    "Halua",
    "Jhadua",
    "Sarua",
  ],
  Chettiar: [
    "24 Manai Telugu Chettiar",
    "24 Manai Telugu Chettiar 16 Veedu",
    "24 Manai Telugu Chettiar 8 Veedu",
    "Achirapakkam Chettiar",
    "Agaram Vellan Chettiar",
    "Ayira Vysya",
    "Beri Chettiar",
    "Devanga Chettiar",
    "Elur",
    "Gandla / Ganiga",
    "Kasukara",
    "Arya Vysya",
    "Kongu Chettiar",
    "Kuruhini Chetty",
    "Manjapudur Chettiar",
    "Nattukottai Chettiar",
    "Padma Saliar",
    "Pannirandam Chettiar",
    "Parvatha Rajakulam",
    "Pattinavar",
    "Pattusali",
    "Sadhu Chetty",
    "Senai Thalaivar",
    "Sozhia Chetty",
    "Telugupatti",
    "Vadambar",
    "Vaniya Chettiar",
    "Vellan Chettiars",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
  ],
  "Devar/Thevar/Mukkulathor": [
    "Agamudayar",
    "Ambalakarar",
    "Appanad Kondayamkottai Maravar",
    "Easanattu Kallar",
    "Kallar",
    "Maniyakarar",
    "Maravar",
    "Piramalai Kallar",
    "Rajakula Agamudayar",
    "Sembanad Maravar",
    "Servai",
    "Thanjavur Kallar",
    "Vallambar",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
  ],
  Gounder: [
    "Gounder - Kongu Vellala Gounder",
    "Gounder - Urali Gounder",
    "Gounder - Vanniya Kula Kshatriyar",
    "Gounder - Vettuva Gounder",
    "Nattu Gounder",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
  ],
  "Iyengar Brahmin": [
    "Thenkalai",
    "Vadakalai",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
  ],
  "Iyer Brahmin": [
    "Ashtasahasram",
    "Brahacharanam",
    "Vadama",
    "Vathima",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
  ],
  "Kongu Vellala Gounder": [
    "Aadai",
    "Aadhi",
    "Aanthai",
    "Aavan",
    "Alagan",
    "Andai",
    "Andhuvan",
    "Cheran",
    "Devendran",
    "Eenjan",
    "Ennai",
    "Kaadai",
    "Kaari",
    "Kanakkan",
    "Kannan",
    "Kannandhai",
    "Keeran",
    "Koorai",
    "Koventhar",
    "Kuzhlaayan",
    "Maadai",
    "Maniyan",
    "Medhi",
    "Muthan",
    "Muzhlukkadhan",
    "Nattu Gounder",
    "Odhaalar",
    "Paandian",
    "Padaithalaiyan",
    "Panaiyan",
    "Panangadai",
    "Pannai",
    "Pannan",
    "Pavalan",
    "Payiran",
    "Perunkudi",
    "Pillan",
    "Podiyan",
    "Ponnan",
    "Poochadhai Poodhiyan",
    "Poosan",
    "Perizhanthan",
    "Sathandhai",
    "Sedan",
    "Sellan",
    "Sempoothan",
    "Sengannan",
    "Sengunni",
    "Seralan",
    "Sevadi",
    "Thodai",
    "Thooran",
    "Vannakkan",
    "Veliyan",
    "Vellamban",
    "Venduvan",
    "Viliyan",
    "Villi",
  ],
  Mudaliyar: [
    "Agamudayar/Arcot/Thuluva Vellala",
    "Isai Vellalar",
    "Kerala Mudali",
    "Kongu Vellala Gounder",
    "Saiva Pillai Tirunelveli",
    "Sengunthar/Kaikolar",
    "Sozhiya Vellalar",
    "Thondai Mandala Vellala",
    "Veerakodi Vellala",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
  ],
  Mukkulathor: [
    "Mukkulathor",
    "Agamudayar",
    "Ambalakarar",
    "Appanad Kondayamkottai Maravar",
    "Easanattu Kallar",
    "Kallar",
    "Maniyakarar",
    "Maravar",
    "Piramalai Kallar",
    "Rajakula Agamudayar",
    "Sembanad Maravar",
    "Servai",
    "Thanjavur Kallar",
    "Vallambar",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
  ],
  Nadar: [
    "Gramani/Sanar",
    "Kongu Nadar",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
  ],
  Naidu: [
    "Balija/Balija Naidu",
    "Ediga/Goud",
    "Gajula",
    "Gavara",
    "Kamma",
    "Kapu",
    "Munnuru Kapu",
    "Mutharaja",
    "Naidu",
    "Perika",
    "Raja Kambalathu Naicker",
    "Reddy",
    "Shetty Balija",
    "Surya Balija",
    "Telaga",
    "Turupu Kapu",
    "Vada Balija",
    "Vadugan",
    "Velama",
    "Yadava",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
  ],
  Pillai: [
    "Aaru Nattu Vellala",
    "Agamudayar/Arcot/Thuluva Vellala",
    "Cherakula Vellalar",
    "Desikar",
    "Desikar Thanjavur",
    "Illaththu Pillai",
    "Isai Vellalar",
    "Karkathar",
    "Kodikal Pillai",
    "Nanjil Mudali",
    "Nanjil pillai",
    "Nankudi Vellalar",
    "Othuvaar",
    "Pandiya Vellalar",
    "Saiva Pillai Thanjavur",
    "Saiva Pillai Tirunelveli",
    "Sengunthar/Kaikolar",
    "Sozhiya Vellalar",
    "Thondai Mandala Vellala",
    "Veerakodi Vellala",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
  ],
  Reddy: [
    "Ayodhi",
    "Chowdary",
    "Desuru",
    "Ganjam",
    "Gone Kapu",
    "Gudati",
    "Kapu",
    "Motati",
    "Palle",
    "Panta",
    "Pedakanti",
    "Poknati",
    "Reddiyar",
    "Vanni",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
    "Bhoomanchi Reddy",
    "Gandla",
    "Palnati",
    "Sajjana",
    "Velanati",
  ],
  Vellalar: [
    "Aaru Nattu Vellala",
    "Agamudayar/Arcot/Thuluva Vellala",
    "Cherakula Vellalar",
    "Desikar",
    "Desikar Thanjavur",
    "Illaththu Pillai",
    "Isai Vellalar",
    "Karkathar",
    "Kodikal Pillai",
    "Nanjil Mudali",
    "Nanjil pillai",
    "Nankudi Vellalar",
    "Othuvaar",
    "Pandiya Vellalar",
    "Saiva Pillai Thanjavur",
    "Saiva Pillai Tirunelveli",
    "Sengunthar/Kaikolar",
    "Sozhiya Vellalar",
    "Thondai Mandala Vellala",
    "Veerakodi Vellala",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
  ],
  Vishwakarma: [
    "Black Smith",
    "Carpentry (Vadrangi, Vadla)",
    "Goldsmiths",
    "Sculptor (Shilpi)",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
  ],
  Yadav: [
    "Doddi",
    "Erragolla",
    "Karna",
    "Kuruma",
    "Pakanati",
    "Puja",
    "Yadava Golla",
    "Ala Golla",
    "Others",
    "Don't know sub-caste",
    "Don't wish to specify",
    "Aheer/Ahir",
    "Dadhor",
    "Das",
    "Gadri/Gadariya",
    "Gawli",
    "Goala/Gola/Golla",
    "Gop/Gopal/Gopala",
    "Goriya",
    "Gouda",
    "Gwala",
    "Gwalvanshi",
    "Jadav",
    "Konar",
    "Krishnauth",
    "Kurubas/Gollas",
    "Mandal",
    "Manjrauth",
    "Nandavanshi",
    "Raut",
    "Suryavanshi",
    "Thethwar",
    "Yadav",
    "Yaduvanshi",
  ],
};
const Register3 = () => {
  const [professiondet, setProfessiondet] = useState("");
  const navigate = useNavigate();
  const [load, setLoad] = useState(true);
  const [err, setErr] = useState("");
  const dispatch = useDispatch();

  const handelSubmit = (e) => {
    e.preventDefault();
    dispatch(profession(professiondet))
      .then((res) => {
        navigate("/idproof");
      })
      .catch((err) => {
        setErr(err.response.data.error.message);
        console.log("err", err.response.data.error.message);
      });
  };

  const [caste, setCaste] = useState("");
  const [subCaste, setSubCaste] = useState("");

  const handleCasteChange = (e) => {
    const selectedCaste = e.target.value;
    setCaste(selectedCaste);
    setSubCaste(""); // Reset sub-caste when caste changes
    setProfessiondet({ ...professiondet, caste: selectedCaste, subCaste: "" });
  };

  const handleSubCasteChange = (e) => {
    const selectedSubCaste = e.target.value;
    setSubCaste(selectedSubCaste);
    setProfessiondet({ ...professiondet, subCaste: selectedSubCaste });
  };

  const handleChange = (e) => {
    setProfessiondet({ ...professiondet, [e.target.name]: e.target.value });
  };
  const completion = useSelector(({ getCompletion }) => getCompletion.payload);
  // console.log(completion, "completion");

  useEffect(() => {
    dispatch(getCompletion()).then((res) => {
      if (
        res.data.data.userDetails === "User details missing" ||
        res.data.data.Score === 0
      ) {
        setLoad(false);
      } else {
        navigate("/myprofile");
        window.location.reload();
      }
    });
  }, []);
  return (
    <div>
      <div>
        {/* <!--===// Start: registeration Form
        =================================--> */}
        <section
          id="post-section"
          className="post-section av-py-default"
          style={{
            backgroundColor: "#fff",
            backgroundImage: "url('/assets/images/register.jpeg')",
          }}
        >
          <div className="av-container">
            <div className="av-columns-area">
              <div className="av-column-12 mx-auto">
                <div id="comments" className="comments-area">
                  <div id="respond" className="comment-respond">
                    <form onSubmit={handelSubmit}>
                      {/* <!-- Email input --> */}
                      <h3 id="reply-title" className="comment-reply-title">
                        Education & Profession Details{" "}
                        <small>
                          <a
                            rel="nofollow"
                            id="cancel-comment-reply-link"
                            href="#"
                            style={{ display: "none" }}
                          >
                            Cancel reply
                          </a>
                        </small>
                      </h3>
                      <div className="form-outline mb-4">
                        <label>
                          Choose Your Marital Status{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          name="marriageStatus"
                          onChange={handleChange}
                          id="marriageStatus"
                        >
                          <option
                            name="marriageStatus"
                            required
                            value="Choose Gender"
                          >
                            Select Martial Status
                          </option>
                          <option value="never married">Never Married</option>
                          <option value="married">Married</option>
                          <option value="seperated">Seperated</option>
                          <option value="divorced">Divorced</option>
                          <option value="widow/widower">Widow/widower</option>
                        </select>
                      </div>
                      <div className="form-outline mb-4">
                        <label>
                          Enter Your Profile Created By{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          name="profileCreatedBy"
                          required
                          onChange={handleChange}
                          id="profileCreatedBy"
                        >
                          <option value="Choose Profile">
                            Select Profile Created By
                          </option>
                          <option value="self">Myself</option>

                          <option value="guardian">Guardian</option>
                          <option value="sibling">Sibling</option>
                          <option value="friend">Friend</option>
                          <option value="parent">Parent</option>
                        </select>
                      </div>
                      {/* <div className="form-outline mb-4">
                      <input
                        type="text"
                        name="referedBy"
                        onChange={handleChange}
                        id="form2Example1"
                        className="form-control"
                        maxlength="10"
                        placeholder="Enter Your Whatsapp Number"
                      />
                    </div> */}
                      <div className="form-outline mb-4">
                        <label>Enter Who Refered By </label>
                        <input
                          type="text"
                          name="referedBy"
                          onChange={handleChange}
                          id="referedBy"
                          className="form-control"
                          placeholder="Refered By"
                        />
                      </div>
                      <div className="form-outline mb-4">
                        <label>
                          Enter Your Qualification{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          name="educationalQualification"
                          onChange={handleChange}
                          id="form2Example1"
                          className="form-control"
                          placeholder="Enter Your Qualification"
                        />
                      </div>
                      <div className="form-outline mb-4">
                        <label>Choose Your Religion</label>
                        <span style={{ color: "red" }}>*</span>
                        <select
                          name="religion"
                          required
                          onChange={handleChange}
                          id="religion"
                        >
                          <option value="Choose Profile">
                            Select Religion
                          </option>
                          <option value="Hindu">Hindu</option>
                          <option value="Christian">Christian</option>
                          <option value="Muslim">Muslim</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                      <div className="form-outline mb-4">
                        <label>
                          Choose Your Mother Tongue{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          name="motherTongue"
                          required
                          onChange={handleChange}
                          id="motherTongue"
                        >
                          <option value="Choose Profile">
                            Select Mother Tongue
                          </option>
                          <option value="Tamil">Tamil</option>
                          <option value="Telugu">Telugu</option>
                          <option value="Malayalam">Malayalam</option>
                          <option value="Hindi">Hindi</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                      <div className="form-outline mb-4">
                        <label>
                          Enter Your Caste{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          name="caste"
                          required
                          onChange={handleCasteChange}
                          value={caste}
                          id="caste"
                          className="form-control mt-3"
                        >
                          <option value="">Select Caste</option>
                          {casteOptions.map((caste, index) => (
                            <option key={index} value={caste}>
                              {caste}
                            </option>
                          ))}
                        </select>
                      </div>

                      {caste &&
                      subCasteOptions[caste] &&
                      subCasteOptions[caste].length > 0 ? (
                        <div className="form-outline mb-4">
                          <label>
                            Enter Your Sub Caste{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            name="subCaste"
                            required
                            onChange={handleSubCasteChange}
                            value={subCaste}
                            id="subCaste"
                            className="form-control mt-3"
                          >
                            <option value="">Select Sub Caste</option>
                            {subCasteOptions[caste].map((subCaste, index) => (
                              <option key={index} value={subCaste}>
                                {subCaste}
                              </option>
                            ))}
                          </select>
                        </div>
                      ) : (
                        <div className="form-outline mb-4">
                          <label>
                            {" "}
                            Enter Your Sub Caste{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            placeholder="Sub Caste"
                            type="text"
                            name="subCaste"
                            required
                            onChange={handleSubCasteChange}
                            value={subCaste}
                            id="subCaste"
                            className="form-control mt-3"
                          />
                        </div>
                      )}
                      <div className="form-outline mb-4">
                        <label>
                          Choose Your Profession{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          name="profession"
                          required
                          onChange={handleChange}
                          id="profession"
                        >
                          <option value="Choose Profile">
                            Select Profession
                          </option>
                          <option value="employee">Employed</option>
                          <option value="business">Business</option>
                          <option value="student">Student</option>
                          <option value="not working">Not Working</option>
                          <option value="not mentioned">Not Mentioned</option>
                        </select>
                      </div>
                      <div className="form-outline mb-4">
                        <label>
                          Enter Your Profession Designation{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          name="professionDesignation"
                          required
                          onChange={handleChange}
                          id="professionDesignation"
                          placeholder="Profession Designation"
                          className="form-control mt-3 "
                        />
                      </div>
                      <div className="form-outline mb-4">
                        <label>
                          <span className="wpcf7-form-control-wrap your-message">
                            <label>Enter Your Profession in details</label>
                            <textarea
                              placeholder="Profession in details"
                              onChange={handleChange}
                              name="professionDesc"
                              cols="40"
                              rows="10"
                              className="wpcf7-form-control wpcf7-textarea"
                              aria-invalid="false"
                              maxLength={255}
                            ></textarea>
                          </span>
                        </label>
                      </div>
                      <div className="form-outline mb-4">
                        <label>Choose Your Profession Location</label>
                        <select
                          name="professionLocation"
                          onChange={handleChange}
                          id="professionLocation"
                        >
                          <option value="Choose Profile">
                            Select Profession Location
                          </option>
                          <option value="Bangalore">Bangalore</option>
                          <option value="Chennai">Chennai</option>
                          <option value="Coimbatore">Coimbatore</option>
                          <option value="Mumbai">Mumbai</option>
                          <option value="Hyderabad">Hyderabad</option>
                          <option value="Other Places">Other Places</option>
                        </select>
                      </div>
                      <div className="form-outline mb-4">
                        <label>
                          Choose Your Annual Income{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          name="annualIncome"
                          required
                          onChange={handleChange}
                          id="annualIncome"
                        >
                          <option value="Choose Profile">
                            Select Annual Income
                          </option>
                          <option value="0-1 Lakhs">0-1 Lakhs</option>
                          <option value="1-2 Lakhs">1-2 Lakhs</option>
                          <option value="2-3 Lakhs">2-3 Lakhs</option>
                          <option value="3-4 Lakhs">3-4 Lakhs</option>
                          <option value="4-5 Lakhs">4-5 Lakhs</option>
                          <option value="5-6 Lakhs">5-6 Lakhs</option>
                          <option value="6-7 Lakhs">6-7 Lakhs</option>
                          <option value="7-8 Lakhs">7-8 Lakhs</option>
                          <option value="8-9 Lakhs">8-9 Lakhs</option>
                          <option value="9-10 Lakhs">9-10 Lakhs</option>
                          <option value="10 Lakhs above">10 Lakhs above</option>
                        </select>
                      </div>
                      <Stack sx={{ width: "100%" }} spacing={2}>
                        {err ? (
                          <Alert autoHideDuration={6000} severity="error">
                            {err}
                          </Alert>
                        ) : (
                          false
                        )}
                      </Stack>
                      <div
                        className="av-column-12"
                        style={{ paddingBottom: "1px" }}
                      >
                        <a
                        //  href='/register4'
                        >
                          {" "}
                          <button
                            type="submit"
                            className="btn btn-primary btn-block mb-4"
                            style={{ float: "left" }}
                          >
                            Next
                          </button>
                        </a>
                        <a href="/idproof">
                          {" "}
                          <button
                            type="button"
                            className="btn btn-primary btn-block mb-4"
                            style={{ float: "right" }}
                          >
                            Skip
                          </button>
                        </a>
                      </div>
                      {/* <!-- Register buttons --> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End: registration Form
        =================================--> */}
      </div>
    </div>
  );
};

export default Register3;
