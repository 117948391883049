import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCompletion } from "../Redux/Action";

const Register4 = () => {
  const [horoscope, setHoroscope] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [load, setLoad] = useState(true);

  // const handleSubmit= (e) => {
  //   e.preventDefault();
  //   dispatch((horoscope))
  // }

  // const handleChange= (e) =>{
  //   setHoroscope({...horoscope, [e.target.name]: e.target.value})
  // }
  const completion = useSelector(({ getCompletion }) => getCompletion.payload);
  // console.log(completion, "completion");

  useEffect(() => {
    dispatch(getCompletion()).then((res) => {
      if (
        res.data.data.horoscopeDetails === "Horoscope details missing" ||
        res.data.data.Score === 0
      ) {
        setLoad(false);
      } else {
        navigate("/myprofile");
        window.location.reload();
      }
    });
  }, []);
  return (
    <div>
      {/* <!--===// Start: registeration Form
        =================================--> */}
      <section
        id="post-section"
        className="post-section av-py-default"
        style={{
          backgroundColor: "#fff",
          backgroundImage: "url('/assets/images/register.jpeg')",
        }}
      >
        <div className="av-container">
          <div className="av-columns-area">
            <div className="av-column-12 mx-auto">
              <div id="comments" className="comments-area">
                <div id="respond" className="comment-respond">
                  <form>
                    {/* <!-- Email input --> */}
                    <h3 id="reply-title" className="comment-reply-title">
                      Horoscope Details{" "}
                      <small>
                        <a
                          rel="nofollow"
                          id="cancel-comment-reply-link"
                          href="#"
                          style={{ display: "none" }}
                        >
                          Cancel reply
                        </a>
                      </small>
                    </h3>
                    <fieldset
                      className="comment-form-cookies-consent"
                      style={{ border: "solid 1px #000" }}
                    >
                      {/* <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" /> */}
                      Generate Horoscope Manually <a href="/rasi">Click here</a>
                      {/* <label for="wp-comment-cookies-consent" style={{fontSize:"20px"}}>Generate Horoscope Manually &nbsp;<a data-animation="fadeInUp" data-delay="800ms" href="/register31" className="av-btn av-btn-primary av-btn-bubble" style={{borderStyle:"none"}}>&nbsp;Click here<i className="fa fa-arrow-right"></i> <span className="bubble_effect"><span className="circle top-left"></span> <span className="circle top-left"></span> <span className="circle top-left"></span> <span className="button effect-button"></span> <span className="circle bottom-right"></span> <span className="circle bottom-right"></span> <span className="circle bottom-right"></span></span></a></label> */}
                    </fieldset>
                    <h3 style={{ textAlign: "center" }}>(Or)</h3>
                    <fieldset
                      className="comment-form-cookies-consent"
                      style={{ border: "solid 1px #000" }}
                    >
                      {/* <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" /> */}
                      Generate Horoscope Automatically{" "}
                      <a href="/horoscopeauto">Click here</a>
                      {/* <label for="wp-comment-cookies-consent" style={{fontSize:"20px"}}>Generate Horoscope Automatically &nbsp;<a data-animation="fadeInUp" data-delay="800ms" href="/register32" className="av-btn av-btn-primary av-btn-bubble" style={{borderStyle:"none"}}>&nbsp;Click here<i className="fa fa-arrow-right"></i> <span className="bubble_effect"><span className="circle top-left"></span> <span className="circle top-left"></span> <span className="circle top-left"></span> <span className="button effect-button"></span> <span className="circle bottom-right"></span> <span className="circle bottom-right"></span> <span className="circle bottom-right"></span></span></a></label> */}
                    </fieldset>
                    {/* <!-- Register buttons --> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: registration Form
        =================================--> */}
    </div>
  );
};

export default Register4;
