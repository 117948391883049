import React from "react";
// import { AiFillEdit } from "react-icons/ai";
import { BsFillTelephoneFill } from "react-icons/bs";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsFillEyeSlashFill, BsHeart } from "react-icons/bs";
import { IoIosContact } from "react-icons/io";
import {
  Allprofilelist,
  Hideprofile,
  Likedprofile,
  ContactProfile,
  SingleProfile,
  HoroscopeMatches,
  Addvisitprofile,
  FavProfile,
  // BirthMatches,
} from "../Redux/Action";
import Alert from "@mui/material/Alert";
import Swal from "sweetalert2";
import Loader from "../Common/Loader";
import { emphasize, styled } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
import { FiFlag } from "react-icons/fi";
// import { BiBlock } from "react-icons/bi";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import dots from "./dotted_shape.png";
import moment from "moment";
import profile from "../Images/profile.jpg";
import DialogActions from "@mui/material/DialogActions";
import { AiOutlineStar } from "react-icons/ai";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// import { profile } from "../Redux/ActionType";
// import "./WaterMark.css"

const ProfileDetails = () => {
  // const [viewprofile, setViewprofile] = useState("");
  const [load, setLoad] = useState(true);
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const list = useSelector(({ SingleProfile }) => SingleProfile.payload);

  const [open, setOpen] = React.useState(false);

  // const hide = (hideid) => {
  //   dispatch (Allprofilelist({hideProfile:proId}))
  // }

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  if (list) {
    // console.log(list);
    if (list?.status === "unauthorized") {
      navigate("/login");
    } else if (list?.status === "failed") {
      navigate("/error");
    } else if (list?.status === "timeout") {
      navigate("/error");
    }
  }

  useEffect(() => {
    dispatch(SingleProfile({ id })).then((res) => {
      setLoad(false);
      if (res.data?.data === "Membership not exists") {
        Swal.fire({
          icon: "error",
          title: "Please Buy Membership",
          text: "You are not able to view another person's profile!",
          willClose: () => {
            window.location.replace("/");
          },
        });
        setLoad(false);
      } else if (res.data?.data === "Membership expired") {
        Swal.fire({
          icon: "error",
          title: "Membership Expired",
          text: "You are not able to view another person's profile!",
          willClose: () => {
            window.location.replace("/");
          },
        });
        setLoad(false);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(Addvisitprofile({ followersId: id })).then((res) => {
      setLoad(false);
    });
  }, []);
  const [fav, setFav] = useState([]);
  useEffect(() => {
    dispatch(FavProfile()).then((res) => {
      setFav(res.data?.data);
      setLoad(false);
    });
  }, []);
  const handleLikeClick = (profileId) => {
    let likeStatus;

    if (Array.isArray(fav)) {
      fav.forEach((item) => {
        if (profileId === item.id) {
          likeStatus = "unlike";
        } else if (likeStatus !== "unlike") {
          likeStatus = "like";
        }
      });
    } else {
      likeStatus = "like"; // Default likeStatus if fav is not an array
    }

    dispatch(
      Likedprofile({
        profileId: profileId,
        fav: likeStatus,
      })
    ).then((res) => {
      dispatch(FavProfile()).then((res) => {
        setFav(res.data?.data);
        setLoad(false);
      });
    });
  };

  const getNakshatraNumber = (nakshatra) => {
    switch (nakshatra) {
      case "Ashwini":
        return 1;
      case "Barani":
        return 2;
      case "Krithikai":
        return 3;
      case "Rohini":
        return 4;
      case "Mirugasiridam":
        return 5;
      case "Thiruvadhirai":
        return 6;
      case "Punarpoosam":
        return 7;
      case "Poosam":
        return 8;
      case "Ayilyam":
        return 9;
      case "Magam":
        return 10;
      case "Pooram":
        return 11;
      case "Uthiram":
        return 12;
      case "Astham":
        return 13;
      case "Chithirai":
        return 14;
      case "Swathi":
        return 15;
      case "Visagam":
        return 16;
      case "Anusham":
        return 17;
      case "Kettai":
        return 18;
      case "Mulam":
        return 19;
      case "Puradam":
        return 20;
      case "Uthiradam":
        return 21;
      case "Thiruvonam":
        return 22;
      case "Avittam":
        return 23;
      case "Sadayam":
        return 24;
      case "Purattadhi":
        return 25;
      case "Uttrttadhi":
        return 26;
      case "Revathi":
        return 27;
      default:
        return null;
    }
  };

  const [profilehoro, setprofilehoro] = useState("");
  const [nakshapro, setNakshapro] = useState("");
  useEffect(() => {
    dispatch(Allprofilelist())
      .then((res) => setLoad(false))
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const profiledetail = useSelector(
    ({ Allprofilelist }) => Allprofilelist?.payload
  );

  useEffect(() => {
    if (
      profiledetail &&
      profiledetail.data &&
      profiledetail.data?.horoscopeDetails
    ) {
      setprofilehoro(profiledetail.data?.horoscopeDetails);
      setNakshapro(profiledetail?.data?.horoscopeDetails?.zodiacStar);
    }
  }, [profiledetail]);

  const nakshatraNumber = getNakshatraNumber(nakshapro);

  const [horoscope, setHoroscope] = useState("");
  const [nakshalist, setNakshalist] = useState("");
  useEffect(() => {
    if (
      list &&
      list?.data &&
      list?.data?.horoscopeDetails === "Horoscope details not completed"
    ) {
      setLoad(false);
    } else if (list && list?.data && list?.data?.horoscopeDetails) {
      setHoroscope(list?.data?.horoscopeDetails);
      setNakshalist(list?.data?.horoscopeDetails.zodiacStar);
    }
  }, [list]);

  const nakshatraList = getNakshatraNumber(nakshapro);

  const [matchmsg, setMatchmsg] = useState("");

  useEffect(() => {
    if (
      (list?.data?.horoscopeDetails || profilehoro) ===
      "Horoscope details not completed"
    ) {
      setLoad(false);
    } else {
      if (
        profilehoro &&
        profilehoro.data &&
        profilehoro.data?.horoscopeDetails &&
        profilehoro.data?.horoscopeDetails.gender === "MALE"
      ) {
        dispatch(
          HoroscopeMatches({
            girl_nakshatra: nakshatraList,
            girl_nakshatra_pada: horoscope.padham,
            boy_nakshatra: nakshatraNumber,
            boy_nakshatra_pada: profilehoro.padham,
          })
        ).then((res) => setMatchmsg(res.data?.data?.data?.message.description));
      } else if (list && list?.data && list?.data?.horoscopeDetails) {
        dispatch(
          HoroscopeMatches({
            girl_nakshatra: nakshatraNumber,
            girl_nakshatra_pada: profilehoro.padham,
            boy_nakshatra: nakshatraList,
            boy_nakshatra_pada: horoscope.padham,
          })
        ).then((res) =>
          setMatchmsg(res.data?.data?.data?.message?.description)
        );
      }
    }
  }, [horoscope.padham, profilehoro.padham]);

  const handleReportClick = () => {
    // Assuming list?.data?.profile?.id is a valid ID
    const reportUrl = `/report/${list?.data?.profile?.id}`;

    // Redirect to the report page
    navigate(reportUrl);
  };

  const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
      theme.palette.mode === "light"
        ? theme.palette.grey[100]
        : theme.palette.grey[800];
    return {
      backgroundColor,
      height: theme.spacing(3),
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightRegular,
      "&:hover, &:focus": {
        backgroundColor: emphasize(backgroundColor, 0.06),
      },
      "&:active": {
        boxShadow: theme.shadows[1],
        backgroundColor: emphasize(backgroundColor, 0.12),
      },
    };
  });

  const style = {
    watermark: {
      position: "absolute",
      bottom: "0",
      right: "5",
      background: "rgb(0, 0, 0)",
      background: "rgba(0, 0, 0, 0.5)",
      color: "#f1f1f1",
      left: "15%",
      width: "0%",
      height: "100%",
      opacity: "0.5",
      padding: "20px",
    },
  };

  return !list ? (
    <Loader />
  ) : (
    <>
      <div>
        {/* <!--===// Start: About Section
        =================================--> */}
        <section
          id="about-section"
          className="about-section av-py-default shapes-section"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="av-container">
            <div className="av-columns-area">
              <div className="av-column-6 mb-4 mb-av-0">
                <div className="about-content">
                  <img
                    src={
                      process.env.REACT_APP_URL + list?.data?.profile?.images
                    }
                    alt="about_item"
                    style={{
                      width: "250px",
                      height: "230px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="about-content-summery">
                                <div className="about-summery">Call Us: +91 99999-99999<i></i><i></i><i></i><i></i></div>
                            </div> 
                    <div className="watermark" style={style.watermark}>
                  <p>VVK&nbsp;MATRIMONY</p>
                  </div> */}
                </div>
              </div>
              <div className="av-column-6">
                <div className="about-panel">
                  <div className="heading-title">
                    {/* <h6>We Are Consultio</h6> */}
                    <h5 style={{ textTransform: "capitalize" }}>
                      {list?.data?.profile?.userName} &nbsp;&nbsp;
                      {/* <Link to={"tel:" + list?.profile?.phone}>
                        <BsFillTelephoneFill style={{ color: "#d81956" }} />
                      </Link> */}
                    </h5>

                    <p
                      style={{
                        margin: "0px",
                        padding: "10px",
                        color: "#252525",
                        paddingLeft: "0px",
                      }}
                    >
                      {list?.data?.profile?.profileId}
                      {list?.data?.userDetails?.profileCreatedBy ===
                      null ? null : (
                        <span>
                          {" "}
                          &nbsp;|&nbsp; Profile Created by{" "}
                          {list?.data?.userDetails?.profileCreatedBy}
                        </span>
                      )}
                    </p>
                  </div>

                  <ul className="av-list-check">
                    <li>{list?.data?.profile?.email}</li>
                    <li>
                      Date of Birth{" "}
                      <span style={{ fontWeight: "600" }}>
                        {moment(list?.data?.profile?.dob).format("DD-MM-YYYY")}
                      </span>{" "}
                    </li>
                    <li>
                      Age{" "}
                      <span style={{ fontWeight: "600" }}>
                        {list?.data?.profile?.age} Yrs
                      </span>{" "}
                    </li>
                    {
                      // {list?.data?.userDetails != "User details not completed" ? (
                      //   <li style={{ textTransform: "capitalize" }}>
                      //     Studied {list?.data?.userDetails.educationalQualification}
                      //   </li>
                      // ) : null}
                    }
                    <li>
                      Lives in{" "}
                      <span style={{ textTransform: "capitalize" }}>
                        {list?.data?.profile?.city}
                      </span>
                      ,{" "}
                      <span style={{ textTransform: "capitalize" }}>
                        {list?.data?.profile?.state}
                      </span>
                      ,
                      <span style={{ textTransform: "capitalize" }}>
                        {" "}
                        {list?.data?.profile?.country}
                      </span>
                    </li>
                    {list?.data?.profile?.membershipPlan?.planName ? (
                      <li>
                        Membership Plan -{" "}
                        <span style={{ fontWeight: "600" }}>
                          {list?.data?.profile?.membershipPlan?.planName}
                        </span>
                      </li>
                    ) : null}
                    {list?.data?.profile?.profileType === null ? null : (
                      <li>
                        Profile Type -{" "}
                        <span
                          style={{
                            fontWeight: "600",
                            textTransform: "capitalize",
                          }}
                        >
                          {list?.data?.profile?.profileType}
                        </span>
                      </li>
                    )}

                    {matchmsg ? (
                      <span>
                        <Alert severity="success" style={{ width: "80%" }}>
                          {" "}
                          {matchmsg}
                        </Alert>
                      </span>
                    ) : null}

                    {
                      //   <li>
                      //   Mother Tongue is <span style={{ textTransform: "capitalize" }}>{list?.data?.userDetails.motherTongue}</span>
                      // </li>
                    }
                    {
                      // <li>{list?.data?.userDetails.caste} - {list?.data?.userDetails.subCaste} (caste- subcaste)</li>
                      // <li>Star is {list?.data?.horoscopeDetails.zodiacStar}, Raasi is {list?.data?.horoscopeDetails.zodiacSign}</li>
                    }
                  </ul>

                  {
                    //  <div className="about-wrapper">
                    //   <div className="skills-wrapper">
                    //     <div className="skill-panel">
                    //       <div className="skill-heading">Your Profile Completeness</div>
                    //       <div className="skillbar" data-percent="0%">
                    //         <div className="skill-bar-percent">
                    //           <span className="count-bar">0</span>%
                    //         </div>
                    //         <div className="skillbar-bar"></div>
                    //       </div>
                    //     </div>
                    //   </div>
                    // </div>
                  }
                </div>
              </div>
              <div
                id="info-section"
                className="info-section info-section-one"
                style={{ marginLeft: "-30px" }}
              >
                <div className="av-container">
                  <div
                    className="av-columns-area info-wrapper wow fadeInUp"
                    style={{ padding: "20px  20px" }}
                  >
                    <div className="av-column-4 av-sm-column-4">
                      <aside className="widget widget-contact">
                        <div
                          className="contact-area"
                          style={{ cursor: "pointer" }}
                        >
                          <a
                            onClick={() => {
                              dispatch(
                                Hideprofile({
                                  hideProfile: list?.data?.profile?.id,
                                })
                              ).then((res) => {
                                Swal.fire(res.data?.data);
                              });
                            }}
                            className="contact-info"
                          >
                            <span className="title">Hide the Profile</span>
                            <BsFillEyeSlashFill />
                          </a>
                        </div>
                      </aside>
                    </div>
                    <div className="av-column-4 av-sm-column-4">
                      <aside className="widget widget-contact">
                        <div
                          className="contact-area"
                          style={{ cursor: "pointer" }}
                        >
                          <a
                            onClick={() =>
                              handleLikeClick(list?.data?.profile?.id)
                            }
                            className="contact-info"
                          >
                            {Array.isArray(fav) &&
                            fav.some(
                              (item) => item.id === list?.data?.profile?.id
                            ) ? (
                              <span>Remove from favorite</span>
                            ) : (
                              <span>Add to favorite</span>
                            )}
                            <BsHeart />
                          </a>
                        </div>
                      </aside>
                    </div>
                    <div className="av-column-4 av-sm-column-5">
                      <aside className="widget widget-contact">
                        <div
                          className="contact-area"
                          style={{ cursor: "pointer" }}
                        >
                          <a
                            onClick={() =>
                              dispatch(
                                ContactProfile({
                                  contactProfileId: list?.data?.profile?.id,
                                })
                              ).then((res) => {
                                Swal.fire(res);
                              })
                            }
                            className="contact-info"
                          >
                            <span className="title">Add to Contact</span>
                            <IoIosContact />
                          </a>
                        </div>
                      </aside>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End: About Section
        =================================--> */}

        {/* <!--===// Start: profile details
        =================================--> */}
        <section
          id="post-section"
          className="post-section av-py-defaults"
          style={{ backgroundColor: "#fff", paddingTop: "0px" }}
        >
          <div className="av-container">
            <div className="av-columns-area">
              <div className="av-column-12">
                <article className="post-items">
                  <figure className="post-image post-image-absolute">
                    <div className="featured-image">
                      <a href=";" className="post-hover">
                        {/* <img src="assets/images/blog/blogFullSize/img01.jpg" alt="post_item"/> */}
                      </a>
                    </div>
                  </figure>
                  {list?.data?.personalityDetails ===
                  "Personality details not completed" ? null : (
                    <div className="post-content">
                      {/* <span className="post-date"> <a href=";"><span>08</span>July 2021</a> </span> */}
                      {list?.data?.profile?.gender === "MALE" ? (
                        <h5 className="post-title">
                          <a href=";" rel="bookmark">
                            About Him
                          </a>
                        </h5>
                      ) : (
                        <h5 className="post-title">
                          <a href=";" rel="bookmark">
                            About Her
                          </a>
                        </h5>
                      )}
                      {list?.data?.personalityDetails === null ? null : (
                        <div className="post-footer">
                          <p>{list?.data?.personalityDetails?.aboutMySelf}</p>

                          {/* <a href=";" className="more-link">Read More</a>
                                  <span className="post-count">01</span> */}
                          <ul className="av-list-check">
                            {list?.data?.personalityDetails?.weight ? (
                              <li>
                                Weight -{list?.data?.personalityDetails?.weight}
                                kg
                              </li>
                            ) : null}
                            {list?.data?.personalityDetails?.height ? (
                              <li>
                                Height -{list?.data?.personalityDetails?.height}
                                inch
                              </li>
                            ) : null}

                            {list?.data?.personalityDetails?.bloodGroup ? (
                              <li>
                                Blood Group -{" "}
                                {list?.data?.personalityDetails?.bloodGroup}
                              </li>
                            ) : null}

                            {list?.data?.personalityDetails?.physicalStatus ? (
                              <li>
                                Physical Status -{" "}
                                <span style={{ textTransform: "capitalize" }}>
                                  {
                                    list?.data?.personalityDetails
                                      ?.physicalStatus
                                  }
                                </span>
                              </li>
                            ) : null}
                            {list?.data?.personalityDetails?.eatingHabits ? (
                              <li>
                                Eating Habits -{" "}
                                <span style={{ textTransform: "capitalize" }}>
                                  {list?.data?.personalityDetails?.eatingHabits}
                                </span>
                              </li>
                            ) : null}
                            {list?.data?.personalityDetails?.drinkingHabits ? (
                              <li>
                                Drinking Habits -{" "}
                                <span style={{ textTransform: "capitalize" }}>
                                  {
                                    list?.data?.personalityDetails
                                      ?.drinkingHabits
                                  }
                                </span>
                              </li>
                            ) : null}
                            {list?.data?.personalityDetails?.smokingHabits ? (
                              <li>
                                Smoking Habits -{" "}
                                <span style={{ textTransform: "capitalize" }}>
                                  {
                                    list?.data?.personalityDetails
                                      ?.smokingHabits
                                  }
                                </span>
                              </li>
                            ) : null}
                            {list?.data?.personalityDetails?.hobbies ? (
                              <li>
                                Hobbies -{" "}
                                <span style={{ textTransform: "capitalize" }}>
                                  {list?.data?.personalityDetails?.hobbies
                                    .split(" ")
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                    )
                                    .join(" ")}
                                </span>
                              </li>
                            ) : null}
                            {list?.data?.personalityDetails?.smokingHabits ? (
                              <li>
                                Smoking Habits -{" "}
                                <span style={{ textTransform: "capitalize" }}>
                                  {
                                    list?.data?.personalityDetails
                                      ?.smokingHabits
                                  }
                                </span>
                              </li>
                            ) : null}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                </article>
              </div>
              <div className="av-column-12">
                <article className="post-items">
                  <figure className="post-image post-image-absolute">
                    <div className="featured-image">
                      <a href=";" className="post-hover">
                        {/* <img src="assets/images/blog/blogFullSize/img02.jpg" alt="post_item"/> */}
                      </a>
                    </div>
                  </figure>
                  {list?.data?.familyDetails ===
                  "Family details not completed" ? null : (
                    <div className="post-content">
                      {/* <span className="post-date"> <a href=";"><span>08</span>July 2021</a> </span> */}
                      {list?.data?.profile?.gender === "MALE" ? (
                        <h5 className="post-title">
                          <a href=";" rel="bookmark">
                            His family Details
                          </a>
                        </h5>
                      ) : (
                        <h5 className="post-title">
                          <a href=";" rel="bookmark">
                            Her family Details
                          </a>
                        </h5>
                      )}
                      <div className="post-footer">
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> */}
                        {/* <a href=";" className="more-link">Read More</a>
                                    <span className="post-count">02</span> */}
                        <ul className="av-list-check">
                          {list?.data?.familyDetails && (
                            <li style={{ textTransform: "capitalize" }}>
                              Father's Name -{" "}
                              {list?.data?.familyDetails.fatherName}(
                              {list?.data?.familyDetails.fatherAlive === "yes"
                                ? "Alive"
                                : "No More"}
                              )
                            </li>
                          )}
                          {list?.data?.familyDetails && (
                            <li style={{ textTransform: "capitalize" }}>
                              Mother's Name -{" "}
                              {list?.data?.familyDetails.motherName}(
                              {list?.data?.familyDetails.motherAlive === "yes"
                                ? "Alive"
                                : "No More"}
                              )
                            </li>
                          )}

                          {list?.data?.familyDetails &&
                          list?.data?.familyDetails.fatherAlive === "yes" ? (
                            <li style={{ textTransform: "capitalize" }}>
                              Father's Occupation -{" "}
                              {list?.data?.familyDetails.fatherOccupation}
                            </li>
                          ) : null}
                          {list?.data?.familyDetails &&
                          list?.data?.familyDetails.motherAlive === "yes" ? (
                            <li style={{ textTransform: "capitalize" }}>
                              Mother's Occupation -{" "}
                              {list?.data?.familyDetails.motherOccupation}
                            </li>
                          ) : null}

                          {list?.data?.familyDetails && (
                            <li style={{ textTransform: "capitalize" }}>
                              Family Type -{" "}
                              {list?.data?.familyDetails.familyType}
                            </li>
                          )}
                          {list?.data?.familyDetails &&
                            list?.data?.familyDetails.financialStatus && (
                              <li style={{ textTransform: "capitalize" }}>
                                Financial Status -{" "}
                                {list?.data?.familyDetails.financialStatus}
                              </li>
                            )}
                          {list?.data?.familyDetails &&
                            list?.data?.familyDetails.siblingDetails && (
                              <li style={{ textTransform: "capitalize" }}>
                                No. of Siblings -{" "}
                                {list?.data?.familyDetails.siblingDetails}
                              </li>
                            )}
                          {
                            //   <a href="/premium">
                            //   <li>Request Gothram</li>
                            // </a>
                          }
                        </ul>
                      </div>
                    </div>
                  )}
                </article>
              </div>
              <div className="av-column-12">
                <article className="post-items">
                  <figure className="post-image post-image-absolute">
                    <div className="featured-image">
                      <a href=";" className="post-hover">
                        {/* <img src="assets/images/blog/blogFullSize/img03.jpg" alt="post_item"/> */}
                      </a>
                    </div>
                  </figure>
                  {list?.data?.userDetails ===
                  "User details not completed" ? null : (
                    <div className="post-content">
                      {/* <span className="post-date"> <a href=";"><span>10</span>July 2021</a> </span> */}
                      {list?.data?.profile?.gender === "MALE" ? (
                        <h5 className="post-title">
                          <a href=";" rel="bookmark">
                            His professional details & Religious details
                          </a>
                        </h5>
                      ) : (
                        <h5 className="post-title">
                          <a href=";" rel="bookmark">
                            Her professional details & Religious details
                          </a>
                        </h5>
                      )}
                      <div className="post-footer">
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> */}
                        {/* <a href=";" className="more-link">Read More</a>
                                  <span className="post-count">03</span> */}
                        <ul className="av-list-check">
                          {list?.data?.userDetails && (
                            <li>
                              Marital Status -{" "}
                              <span style={{ textTransform: "capitalize" }}>
                                {list?.data?.userDetails.maritalStatus}
                              </span>{" "}
                            </li>
                          )}
                          {list?.data?.userDetails && (
                            <li>
                              Caste -{" "}
                              <span style={{ textTransform: "capitalize" }}>
                                {list?.data?.userDetails.caste}{" "}
                              </span>
                            </li>
                          )}
                          {list?.data?.userDetails && (
                            <li>
                              Religion -{" "}
                              <span style={{ textTransform: "capitalize" }}>
                                {list?.data?.userDetails.religion}{" "}
                              </span>
                            </li>
                          )}
                          {list?.data?.userDetails && (
                            <li>
                              Caste -{" "}
                              <span style={{ textTransform: "capitalize" }}>
                                {list?.data?.userDetails.caste}
                              </span>{" "}
                            </li>
                          )}
                          {list?.data?.userDetails && (
                            <li>
                              Sub-Caste -{" "}
                              <span style={{ textTransform: "capitalize" }}>
                                {list?.data?.userDetails.subCaste}{" "}
                              </span>
                            </li>
                          )}
                          {list?.data?.userDetails && (
                            <li>
                              Educational Qualification -{" "}
                              <span style={{ textTransform: "capitalize" }}>
                                {" "}
                                {
                                  list?.data?.userDetails
                                    .educationalQualification
                                }
                              </span>{" "}
                            </li>
                          )}
                          {list?.data?.userDetails && (
                            <li>
                              Working in -{" "}
                              <span style={{ textTransform: "capitalize" }}>
                                {list?.data?.userDetails.professionDesignation}/
                                {list?.data?.userDetails.profession}
                              </span>
                            </li>
                          )}
                          {list?.data?.userDetails && (
                            <li>
                              Working Location -{" "}
                              <span style={{ textTransform: "capitalize" }}>
                                {list?.data?.userDetails.professionLocation}
                              </span>
                            </li>
                          )}
                          {list?.data?.userDetails && (
                            <li>
                              Earns ₹ {list?.data?.userDetails.annualIncome}
                              &nbsp; Annually
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  )}
                </article>
              </div>
              <div className="av-column-12">
                <article className="post-items">
                  <figure className="post-image post-image-absolute">
                    <div className="featured-image">
                      <a href=";" className="post-hover">
                        {/* <img src="assets/images/blog/blogFullSize/img03.jpg" alt="post_item"/> */}
                      </a>
                    </div>
                  </figure>
                  {list?.data?.horoscopeDetails ===
                  "Horoscope details not completed" ? null : (
                    <div className="post-content">
                      {/* <span className="post-date"> <a href=";"><span>10</span>July 2021</a> </span> */}
                      {list?.data?.profile?.gender === "MALE" ? (
                        <h5 className="post-title">
                          <a href=";" rel="bookmark">
                            His Horoscope details
                          </a>
                        </h5>
                      ) : (
                        <h5 className="post-title">
                          <a href=";" rel="bookmark">
                            Her Horoscope details
                          </a>
                        </h5>
                      )}
                      <div className="post-footer">
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> */}
                        {/* <a href=";" className="more-link">Read More</a>
                                <span className="post-count">03</span> */}
                        <ul className="av-list-check">
                          {list?.data?.horoscopeDetails && (
                            <li>
                              Time of Birth -{" "}
                              <span style={{ textTransform: "capitalize" }}>
                                {list?.data?.horoscopeDetails.timeOfBirth}
                              </span>{" "}
                            </li>
                          )}
                          {list?.data?.horoscopeDetails && (
                            <li>
                              Date of Birth -{" "}
                              <span style={{ textTransform: "capitalize" }}>
                                {list?.data?.horoscopeDetails.dateOfBirth}{" "}
                              </span>
                            </li>
                          )}
                          {list?.data?.horoscopeDetails && (
                            <li>
                              Place of Birth -{" "}
                              <span style={{ textTransform: "capitalize" }}>
                                {list?.data?.horoscopeDetails.placeOfBirth}{" "}
                              </span>
                            </li>
                          )}
                          {list?.data?.horoscopeDetails && (
                            <li>
                              Zodiac Sign -{" "}
                              <span style={{ textTransform: "capitalize" }}>
                                {list?.data?.horoscopeDetails.zodiacSign}
                              </span>{" "}
                            </li>
                          )}
                          {list?.data?.horoscopeDetails && (
                            <li>
                              Zodiac Star-{" "}
                              <span style={{ textTransform: "capitalize" }}>
                                {list?.data?.horoscopeDetails.zodiacStar}{" "}
                              </span>
                            </li>
                          )}
                          {list?.data?.horoscopeDetails && (
                            <li>
                              Gothram -{" "}
                              <span style={{ textTransform: "capitalize" }}>
                                {" "}
                                {list?.data?.horoscopeDetails.gothram}
                              </span>{" "}
                            </li>
                          )}
                          {list?.data?.horoscopeDetails && (
                            <li>
                              Padham -{" "}
                              <span style={{ textTransform: "capitalize" }}>
                                {list?.data?.horoscopeDetails.padham}
                              </span>
                            </li>
                          )}
                          {list?.data?.horoscopeDetails && (
                            <li>
                              Select Lagnam -{" "}
                              <span style={{ textTransform: "capitalize" }}>
                                {list?.data?.horoscopeDetails.selectLagnam}
                              </span>
                            </li>
                          )}
                          {list?.data?.horoscopeDetails && (
                            <li>
                              Madhulam {list?.data?.horoscopeDetails.madhulam}
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  )}
                </article>
              </div>
              {
                // <div className="av-column-12">
                //   <article className="post-items">
                //     <figure className="post-image post-image-absolute">
                //       <div className="featured-image">
                //         <a href=";" className="post-hover">
                //           {/* <img src="assets/images/blog/blogFullSize/img04.jpg" alt="post_item"/> */}
                //         </a>
                //       </div>
                //     </figure>
                //     {list?.data?.partnerDetails ===
                //     "Partner details not completed" ? null : (
                //       <div className="post-content">
                //         {/* <span className="post-date"> <a href=";"><span>11</span>July 2021</a> </span> */}
                //         {list?.data?.profile?.gender === "MALE" ? (
                //           <h5 className="post-title">
                //             <a href=";" rel="bookmark">
                //               About his loved ones
                //             </a>
                //           </h5>
                //         ) : (
                //           <h5 className="post-title">
                //             <a href=";" rel="bookmark">
                //               About her loved ones
                //             </a>
                //           </h5>
                //         )}
                //         <div className="post-footer">
                //           {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> */}
                //           {/* <a href=";" className="more-link">Read More</a>
                //                       <span className="post-count">04</span> */}
                //           <ul className="av-list-check">
                //             <li>
                //               {list?.data?.familyDetails.familyType},{" "}
                //               {list?.data?.familyDetails.financialStatus} &{" "}
                //               {list?.data?.familyDetails.familyValues}
                //             </li>
                //             <Link to="/premium">
                //               <li>Request Family Details</li>
                //             </Link>
                //           </ul>
                //         </div>
                //       </div>
                //     )}
                //   </article>
                // </div>
              }
              <div
                className="av-column-12"
                style={{ display: "flex", justifyContent: "end" }}
              >
                <div className="av-column-4">
                  <p>
                    <StyledBreadcrumb label="Had an unpleasant experience with this profile?" />
                  </p>
                </div>
                <div className="av-column-6">
                  <p>
                    <Breadcrumbs aria-label="breadcrumb">
                      {/* <StyledBreadcrumb
                label="Had an unpleasant experience with this profile?"
              /> */}
                      <StyledBreadcrumb
                        component="a"
                        onClick={handleReportClick}
                        icon={<FiFlag />}
                        label="Report"
                      />
                      {
                        //  <StyledBreadcrumb
                        //   component="a"
                        //   onClick={handleClickOpen}
                        //   icon={<BiBlock />}
                        //   label="Block"
                        // />
                      }
                    </Breadcrumbs>
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Are you sure you want to block this member?"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          <div className="textwidget">
                            <div
                              className="textwidget_img"
                              style={{ textAlign: "-webkit-center" }}
                            >
                              <img
                                src={
                                  process.env.REACT_APP_URL +
                                  list?.data?.profile?.images
                                }
                                alt=""
                                className="rounded-circle"
                                style={{ height: "100px" }}
                              />
                              <div className="circles-spin">
                                <div className="circle-one"></div>
                              </div>
                            </div>
                            <h6 style={{ textAlign: "center" }}>
                              <span style={{ textTransform: "capitalize" }}>
                                {list?.data?.profile?.userName}
                              </span>{" "}
                              {list?.data?.profile?.profileId}
                            </h6>
                          </div>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose} sx={{ color: "#fff" }}>
                          Yes
                        </Button>
                        <Button
                          onClick={handleClose}
                          sx={{ color: "#fff" }}
                          autoFocus
                        >
                          Cancel
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End: profile details
        =================================--> */}

        {/* start-partner preference */}
        <section
          id="post-section"
          className="post-section av-py-default"
          style={{ backgroundColor: "#fff", paddingTop: "0px" }}
        >
          <div className="av-container">
            <div className="av-columns-area">
              <div className="av-column-12">
                <div className="sidebar">
                  <aside id="widget-text" className="widget widget_text">
                    {list?.data?.profile?.gender === "MALE" ? (
                      <h6 className="widget-title">
                        See how well your profile matches his preferences{" "}
                        <span></span>
                      </h6>
                    ) : (
                      <h6 className="widget-title">
                        See how well your profile matches her preferences{" "}
                        <span></span>
                      </h6>
                    )}
                    <div className="textwidget">
                      <div className="textwidget_img">
                        <img
                          src={
                            process.env.REACT_APP_URL +
                            list?.data?.profile?.images
                          }
                          alt=""
                          className="rounded-circle"
                          style={{
                            height: "135px",
                            width: "135px",
                            objectFit: "cover",
                          }}
                        />
                        <div className="circles-spin">
                          <div className="circle-one"></div>
                        </div>
                      </div>
                      <h6>
                        Well Hi There, I'm{" "}
                        <span style={{ textTransform: "capitalize" }}>
                          {list?.data?.profile?.userName}
                        </span>
                      </h6>
                      <p>The best thing to hold onto in life is each other.</p>
                    </div>
                  </aside>
                  {list?.data?.partnerDetails === "" ? (
                    <aside
                      id="widget-latest-posts"
                      className="widget widget_latest_posts"
                    >
                      <div className="widget-content">
                        <article className="latest_posts post-items">
                          <div className="post-content">
                            <span className="count-link"></span>
                            <h5
                              className="post-title"
                              style={{ fontSize: "20px" }}
                            >
                              <a>Basic Partner Preferences</a>
                            </h5>
                            <div className="post-meta">
                              <div className="post-list">
                                <ul className="post-categories">
                                  <li>
                                    <a
                                      style={{
                                        fontSize: "17px",
                                        color: "#00000099",
                                        fontWeight: "700",
                                        background: "none",
                                      }}
                                    >
                                      Age
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <span className="post-date">
                                {/* <i className="fa fa-clock-o"></i>{" "} */} -
                                &nbsp;
                                <time
                                  dateTime="2020-07-02"
                                  className="meta-info"
                                  style={{ fontSize: "20px" }}
                                >
                                  {list?.data?.partnerDetails.age}
                                </time>
                              </span>
                            </div>
                            <div className="post-meta">
                              <div className="post-list">
                                <ul className="post-categories">
                                  <li>
                                    <a
                                      style={{
                                        fontSize: "17px",
                                        color: "#00000099",
                                        fontWeight: "700",
                                        background: "none",
                                      }}
                                    >
                                      Annual Income
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <span className="post-date">
                                {/* <i className="fa fa-clock-o"></i>{" "} */} -
                                &nbsp;
                                <time
                                  dateTime="2020-07-02"
                                  className="meta-info"
                                  style={{ fontSize: "20px" }}
                                >
                                  {list?.data?.partnerDetails.annualIncome}
                                </time>
                              </span>
                            </div>

                            <div className="post-meta">
                              <div className="post-list">
                                <ul className="post-categories">
                                  <li>
                                    <a href="#"></a>
                                    <a
                                      href="#"
                                      style={{
                                        fontSize: "17px",
                                        color: "#00000099",
                                        fontWeight: "700",
                                        background: "none",
                                      }}
                                    >
                                      Martial Status
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <span className="post-date">
                                {/* <i className="fa fa-clock-o"></i>{" "} */} -
                                &nbsp;
                                <time
                                  dateTime="2020-07-02"
                                  className="meta-info"
                                  style={{ fontSize: "20px" }}
                                >
                                  {list?.data?.partnerDetails.maritalStatus}
                                </time>
                              </span>
                            </div>
                            <div className="post-meta">
                              <div className="post-list">
                                <ul className="post-categories">
                                  <li>
                                    <a
                                      href="#"
                                      style={{
                                        fontSize: "17px",
                                        color: "#00000099",
                                        fontWeight: "700",
                                        background: "none",
                                      }}
                                    >
                                      Foreign Intrest
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <span className="post-date">
                                {/* <i className="fa fa-clock-o"></i>{" "} */} -
                                &nbsp;
                                <time
                                  dateTime="2020-07-02"
                                  className="meta-info"
                                  style={{ fontSize: "20px" }}
                                >
                                  {list?.data?.partnerDetails.foreignInterest}
                                </time>
                              </span>
                            </div>
                            <div className="post-meta">
                              <div className="post-list">
                                <ul className="post-categories">
                                  <li>
                                    <a
                                      href="#"
                                      style={{
                                        fontSize: "17px",
                                        color: "#00000099",
                                        fontWeight: "700",
                                        background: "none",
                                      }}
                                    >
                                      Height
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <span className="post-date">
                                {/* <i className="fa fa-clock-o"></i>{" "} */} -
                                &nbsp;
                                <time
                                  dateTime="2020-07-02"
                                  className="meta-info"
                                  style={{ fontSize: "20px" }}
                                >
                                  {list?.data?.partnerDetails.height}
                                </time>
                              </span>
                            </div>
                            <div className="post-meta">
                              <div className="post-list">
                                <ul className="post-categories">
                                  <li>
                                    <a
                                      href="#"
                                      style={{
                                        fontSize: "17px",
                                        color: "#00000099",
                                        fontWeight: "700",
                                        background: "none",
                                      }}
                                    >
                                      Rahu Ketu
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <span className="post-date">
                                {/* <i className="fa fa-clock-o"></i>{" "} */} -
                                &nbsp;
                                <time
                                  dateTime="2020-07-02"
                                  className="meta-info"
                                  style={{ fontSize: "20px" }}
                                >
                                  {list?.data?.partnerDetails.rahuKetu}
                                </time>
                              </span>
                            </div>
                            <div className="post-meta">
                              <div className="post-list">
                                <ul className="post-categories">
                                  <li>
                                    <a
                                      href="#"
                                      style={{
                                        fontSize: "17px",
                                        color: "#00000099",
                                        fontWeight: "700",
                                        background: "none",
                                      }}
                                    >
                                      Dhosam
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <span className="post-date">
                                {/* <i className="fa fa-clock-o"></i>{" "} */} -
                                &nbsp;
                                <time
                                  dateTime="2020-07-02"
                                  className="meta-info"
                                  style={{ fontSize: "20px" }}
                                >
                                  {list?.data?.partnerDetails.dhosam}
                                </time>
                              </span>
                            </div>
                          </div>
                        </article>
                      </div>
                    </aside>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end-partner preference */}
      </div>
    </>
  );
};

export default ProfileDetails;
