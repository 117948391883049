import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Feedbackaccount } from "../Redux/Action";
import Swal from "sweetalert2";

const FeedBack = () => {
  const dispatch = useDispatch();
  const [feedback, setFeedback] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(Feedbackaccount(feedback)).then((res) => {
      Swal.fire(res.data.data);
    });
  };

  const handleChange = (e) => {
    setFeedback({ ...feedback, [e.target.name]: e.target.value });
  };
  return (
    <div>
      {/* <!--===// Start: Breadcrumb
        =================================--> */}
      <section id="breadcrumb-section" class="breadcrumb-area breadcrumb-left">
        <div class="av-container">
          <div class="av-columns-area">
            <div class="av-column-12">
              <div class="breadcrumb-content">
                <div class="breadcrumb-heading wow fadeInLeft">
                  <h2>Feedback</h2>
                </div>
                <ol class="breadcrumb-list wow fadeInRight">
                  <li>
                    <a href="/">
                      <i class="fa fa-home"></i> Home Page
                    </a>{" "}
                    &nbsp;-&nbsp;
                  </li>
                  <li class="active">Feedback</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: Breadcrumb
        =================================--> */}

      {/* start feedback form */}
      <section
        id="post-section"
        className="post-section av-py-default"
        style={{
          backgroundColor: "#fff",
          // backgroundImage: "url('/assets/images/register.jpeg')",
        }}
      >
        <div className="av-container">
          <div className="av-columns-area">
            <div className="av-column-10 mx-auto">
              <div id="comments" className="comments-area">
                <div id="respond" className="comment-respond">
                  <form onSubmit={handleSubmit}>
                    {/* <!-- Email input --> */}
                    <h5 id="reply-title" className="comment-reply-title">
                      Please feel free to post your questions, comments and
                      suggestions. We are eager to assist you and serve you
                      better.{" "}
                      <small>
                        <a
                          rel="nofollow"
                          id="cancel-comment-reply-link"
                          href="#"
                          style={{ display: "none" }}
                        >
                          Cancel reply
                        </a>
                      </small>
                    </h5>
                    {/* <div className="form-outline mb-4">
                      <input
                        type="text"
                        name="name"
                        onChange={handleChange}
                        id="form2Example1"
                        className="form-control"
                        placeholder="Enter your name"
                      />
                    </div> */}
                    <br />
                    <div className="form-outline mb-4">
                      <select
                        name="category"
                        onChange={handleChange}
                        id="form2Example1"
                      >
                        <option value="Category">Category</option>
                        <option value="Problems related to the website">
                          Problems related to the Website{" "}
                        </option>
                        <option value="Problems related to profiles">
                          Problems related to Profiles
                        </option>
                        <option value="Compliments and suggestions">
                          Compliments and Suggestions{" "}
                        </option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                    {/* <div className="form-outline mb-4">
                      <input
                        type="text"
                        name="matrimonyId"
                        onChange={handleChange}
                        id="form2Example1"
                        className="form-control"
                        placeholder="Enter your matrimony ID "
                      />
                    </div> */}
                    <div className="form-outline mb-4">
                      <label>
                        <span className="wpcf7-form-control-wrap your-message">
                          <textarea
                            placeholder="Enter your suggestions / feedback"
                            name="suggestions"
                            onChange={handleChange}
                            cols="20"
                            rows="10"
                            className="wpcf7-form-control wpcf7-textarea"
                            aria-invalid="false"
                            maxLength={255}
                          ></textarea>
                        </span>
                      </label>
                    </div>
                    <div
                      className="av-column-12"
                      style={{ paddingBottom: "1px" }}
                    >
                      <a
                      //  href='/register3'
                      >
                        {" "}
                        <button
                          type="submit"
                          className="btn btn-primary btn-block mb-4"
                          style={{ float: "left" }}
                        >
                          Submit
                        </button>
                      </a>
                      {/* <a href='/register3'> <button
                      type="button"
                      className="btn btn-primary btn-block mb-4"
                      style={{float:"right"}}
                    >
                      Skip
                    </button></a> */}
                    </div>
                    {/* <!-- Register buttons --> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end feedback form  */}

      {/* <!--===// Start: Contact Form
        =================================--> */}
      {/* <section
        id="contactform-section"
        class="contactform-section av-pb-default"
        style={{ backgroundColor: "#fff" }}
      >
        <div class="contactform-cards" style={{ marginTop: "0" }}>
          <div class="av-container">
            <div class="av-columns-area">
              <div class="av-column-6">
                <div
                  class="contactform-cards-item"
                  style={{
                    backgroundImage: "url('assets/images/contact/card01.jpg')",
                  }}
                >
                  <div class="cards-area">
                    <div class="cards-icon">
                      <i class="fa fa-wechat"></i>
                    </div>
                    <div class="cards-title" style={{ fontSize: "25px" }}>
                      WhattsApp Chat
                    </div>
                    <div class="cards-btn">
                      <a href="#" class="av-btn av-btn-primary av-btn-bubble">
                        Start Chat <i class="fa fa-arrow-right"></i>{" "}
                        <span class="bubble_effect">
                          <span class="circle top-left"></span>{" "}
                          <span class="circle top-left"></span>{" "}
                          <span class="circle top-left"></span>{" "}
                          <span class="button effect-button"></span>{" "}
                          <span class="circle bottom-right"></span>{" "}
                          <span class="circle bottom-right"></span>{" "}
                          <span class="circle bottom-right"></span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="av-column-6">
                <div
                  class="contactform-cards-item"
                  style={{
                    backgroundImage: "url('assets/images/contact/card02.jpg')",
                  }}
                >
                  <div class="cards-area">
                    <div class="cards-icon">
                      <i class="fa fa-wechat"></i>
                    </div>
                    <div class="cards-title" style={{ fontSize: "25px" }}>
                      WhattsApp Chat
                    </div>
                    {/* <div class="cards-btn"> 
                                        <aside class="share-toolkit widget widget_social_widget">
                                            <a href="#" class="toolkit-hover"><i class="fa fa-share-alt"></i></a>
                                            <ul>
                                                <li><a href="javascript:void(0);"><i class="fa fa-facebook"></i></a></li>
                                                <li><a href="javascript:void(0);"><i class="fa fa-twitter"></i></a></li>
                                                <li><a href="javascript:void(0);"><i class="fa fa-linkedin"></i></a></li>
                                                <li><a href="javascript:void(0);"><i class="fa fa-instagram"></i></a></li>
                                            </ul>
                                        </aside>
                                    </div> 
                    <div class="cards-btn">
                      <a href="#" class="av-btn av-btn-primary av-btn-bubble">
                        Start Chat <i class="fa fa-arrow-right"></i>{" "}
                        <span class="bubble_effect">
                          <span class="circle top-left"></span>{" "}
                          <span class="circle top-left"></span>{" "}
                          <span class="circle top-left"></span>{" "}
                          <span class="button effect-button"></span>{" "}
                          <span class="circle bottom-right"></span>{" "}
                          <span class="circle bottom-right"></span>{" "}
                          <span class="circle bottom-right"></span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- End: Contact Form
        =================================--> */}
    </div>
  );
};

export default FeedBack;
