import React from "react";
import UserMeta from "./UserMeta";

const HoroscopeMatches = () => {
  return (
    <div>
      {/* <!--===// Start: Bride
        =================================--> */}
      <section
        id="post-section"
        class="post-section av-py-default"
        style={{ backgroundColor: "#fff" }}
      >
        <div class="av-container">
          <div class="av-columns-area">
            <div class="av-column-12">
              <div class="heading-default text-center wow fadeInUp">
                <h3>
                  Horoscope <span class="primary-color">Matches</span>
                </h3>
                <span class="separator">
                  <span>
                    <span></span>
                  </span>
                </span>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p>
              </div>
            </div>
          </div>
          {/* user meta start */}
          <UserMeta />
          {/* user meta end */}
          <div class="av-columns-area">
            <div class="av-column-3">
              <div class="sidebar">
                <aside id="widget-text" class="widget widget_text">
                  <h6 class="widget-title">
                    Sort by <span></span>
                  </h6>
                  <div class="tagcloud">
                    <form action="/action_page.php">
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Relevance"
                      />
                      <label for="vehicle1">Relevance</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle3"
                        name="vehicle3"
                        value="Last Login"
                      />
                      <label for="vehicle3">Last Login</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle3"
                        name="vehicle3"
                        value="Date Created"
                      />
                      <label for="vehicle3">Date Created</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle3"
                        name="vehicle3"
                        value="Premium Menber"
                      />
                      <label for="vehicle3">Premium Menber</label>
                      <br />
                    </form>
                  </div>
                </aside>
                <aside id="widget-text" class="widget widget_text">
                  <h6 class="widget-title">
                    Filter by <span></span>
                  </h6>
                  {/* <div class="textwidget">
                    <div class="textwidget_img">
                      <img
                        src="assets/images/sidebar/about_me.png"
                        alt=""
                        class="rounded-circle"
                      />
                      <div class="circles-spin">
                        <div class="circle-one"></div>
                      </div>
                    </div>
                    <h6>Well Hi There, I'm Zolie Maron Duo</h6>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by
                    </p>
                  </div> */}
                </aside>
                <aside id="tag_cloud-2" class="widget widget_tag_cloud">
                  <h6 class="widget-title">
                    Profile Type <span></span>
                  </h6>
                  <div class="tagcloud">
                    <h5>Shows Profile Created</h5>
                    <form action="/action_page.php">
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Within a Week"
                      />
                      <label for="vehicle1">Within a Week</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle3"
                        name="vehicle3"
                        value="Within a Month"
                      />
                      <label for="vehicle3">Within a Month</label>
                      <br />
                    </form>
                  </div>
                  <div class="tagcloud" style={{ paddingTop: "0px" }}>
                    <h5>Profiles</h5>
                    <form action="/action_page.php">
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="With Photo"
                      />
                      <label for="vehicle1">With Photo</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle3"
                        name="vehicle3"
                        value="With Horoscope"
                      />
                      <label for="vehicle3">With Horoscope</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle3"
                        name="vehicle3"
                        value="Currently Online"
                      />
                      <label for="vehicle3">Currently Online</label>
                      <br />
                    </form>
                  </div>
                  <div class="tagcloud" style={{ paddingTop: "0px" }}>
                    <h5>Profile Created By</h5>
                    <form action="/action_page.php">
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Self"
                      />
                      <label for="vehicle1">Self</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle3"
                        name="vehicle3"
                        value="Parents"
                      />
                      <label for="vehicle3">Parents</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle3"
                        name="vehicle3"
                        value="Siblings"
                      />
                      <label for="vehicle3">Siblings</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle3"
                        name="vehicle3"
                        value="Relative"
                      />
                      <label for="vehicle3">Relative</label>
                      <br />
                      <br />
                      <input type="submit" value="Submit" />
                    </form>
                  </div>
                </aside>

                <aside id="tag_cloud-2" class="widget widget_tag_cloud">
                  <h6 class="widget-title">
                    Personal Details <span></span>
                  </h6>
                  <div class="tagcloud">
                    <h5>Physical Status</h5>
                    <form action="/action_page.php">
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Normal"
                      />
                      <label for="vehicle1">Normal</label>
                      <br />
                    </form>
                  </div>
                  <div class="tagcloud" style={{ paddingTop: "0px" }}>
                    <h5>Body Type</h5>
                    <form action="/action_page.php">
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Slim"
                      />
                      <label for="vehicle1">Slim</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="NormAverageal"
                      />
                      <label for="vehicle1">Average</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Athletic"
                      />
                      <label for="vehicle1">Athletic</label>
                      <br />
                      <br />
                      <input type="submit" value="Submit" />
                    </form>
                  </div>
                </aside>
                <aside id="tag_cloud-2" class="widget widget_tag_cloud">
                  <h6 class="widget-title">
                    Religious Details <span></span>
                  </h6>
                  <div class="tagcloud">
                    <h5>Religion</h5>
                    <form action="/action_page.php">
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Hindu"
                      />
                      <label for="vehicle1">Hindu</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle2"
                        name="vehicle2"
                        value="Inter-Religion"
                      />
                      <label for="vehicle2">Inter-Religion</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle3"
                        name="vehicle3"
                        value="Christian"
                      />
                      <label for="vehicle3">Christian</label>
                      <br />
                    </form>
                  </div>
                  <div class="tagcloud" style={{ paddingTop: "0px" }}>
                    <h5>Caste</h5>
                    <form action="/action_page.php">
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Caste no bar"
                      />
                      <label for="vehicle1">Caste no bar</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle2"
                        name="vehicle2"
                        value="Adi Dravidar"
                      />
                      <label for="vehicle2">Adi Dravidar</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle3"
                        name="vehicle3"
                        value="Agri"
                      />
                      <label for="vehicle3">Agri</label>
                      <br />
                      <br />
                      <input type="submit" value="Submit" />
                    </form>
                  </div>
                </aside>
                {/* <aside id="widget-text-2" class="widget widget_text">
                  <h6 class="widget-title">
                    Need Help? <span></span>
                  </h6>
                  <div class="textwidget">
                    <p class="text-left">
                      sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque laudantium, totam rem
                      aperiam, eaque ipsa quae ab illo inventore veritatis et
                      quasi architecto beatae vitae dicta sunt explicabo.
                    </p>
                    <h4 class="primary-color text-left">
                      <a href="tel:+0123456879">+0123456879</a>
                    </h4>
                  </div>
                </aside> */}
                <aside id="tag_cloud-2" class="widget widget_tag_cloud">
                  <h6 class="widget-title">
                    Lifestyle Details <span></span>
                  </h6>
                  <div class="tagcloud">
                    <h5>Eating Habits</h5>
                    <form action="/action_page.php">
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Non-Vegetarian"
                      />
                      <label for="vehicle1">Non-Vegetarian</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle2"
                        name="vehicle2"
                        value="Vegetarian"
                      />
                      <label for="vehicle2">Vegetarian</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle3"
                        name="vehicle3"
                        value="Eggetarian"
                      />
                      <label for="vehicle3">Eggetarian</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle3"
                        name="vehicle3"
                        value="Not Specified"
                      />
                      <label for="vehicle3">Not Specified</label>
                      <br />
                      <br />
                    </form>
                  </div>
                  <div class="tagcloud" style={{ paddingTop: "0px" }}>
                    <h5>Drinking Habits</h5>
                    <form action="/action_page.php">
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Never Drink"
                      />
                      <label for="vehicle1">Never Drink</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle2"
                        name="vehicle2"
                        value="Drink Socially"
                      />
                      <label for="vehicle2">Drink Socially</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle3"
                        name="vehicle3"
                        value="Drink Regularly"
                      />
                      <label for="vehicle3">Drink Regularly</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle3"
                        name="vehicle3"
                        value="Not Specified"
                      />
                      <label for="vehicle3">Not Specified</label>
                      <br />
                      <br />
                    </form>
                  </div>
                  <div class="tagcloud" style={{ paddingTop: "0px" }}>
                    <h5>Smoking Habits</h5>
                    <form action="/action_page.php">
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Never Smokes"
                      />
                      <label for="vehicle1">Never Smokes</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle2"
                        name="vehicle2"
                        value="Smoke Occasionally"
                      />
                      <label for="vehicle2">Smoke Occasionally</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle3"
                        name="vehicle3"
                        value="Smoke Regularly"
                      />
                      <label for="vehicle3">Smoke Regularly</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle3"
                        name="vehicle3"
                        value="Not Specified"
                      />
                      <label for="vehicle3">Not Specified</label>
                      <br />
                      <br />
                      <input type="submit" value="Submit" />
                    </form>
                  </div>
                </aside>
                <aside id="tag_cloud-2" class="widget widget_tag_cloud">
                  <h6 class="widget-title">
                    Basic Details <span></span>
                  </h6>
                  <div class="tagcloud">
                    <h5>Mother Tongue</h5>
                    <form action="/action_page.php">
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Tamil"
                      />
                      <label for="vehicle1">Tamil</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle2"
                        name="vehicle2"
                        value="Hindi"
                      />
                      <label for="vehicle2">Hindi</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle3"
                        name="vehicle3"
                        value="Telugu"
                      />
                      <label for="vehicle3">Telugu</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle3"
                        name="vehicle3"
                        value="Malayalam"
                      />
                      <label for="vehicle3">Malayalam</label>
                      <br />
                      <br />
                    </form>
                  </div>
                  <div class="tagcloud" style={{ paddingTop: "0px" }}>
                    <h5>Martial Status</h5>
                    <form action="/action_page.php">
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Never Married"
                      />
                      <label for="vehicle1">Never Married</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle2"
                        name="vehicle2"
                        value="Awaiting Divorce"
                      />
                      <label for="vehicle2">Awaiting Divorce</label>
                      <br />
                      <input
                        type="checkbox"
                        id="vehicle3"
                        name="vehicle3"
                        value="Divorce"
                      />
                      <label for="vehicle3">Divorced</label>
                      <br />
                      <br />
                      <input type="submit" value="Submit" />
                    </form>
                  </div>
                </aside>
              </div>
            </div>
            {/* profile list */}
            <div class="av-column-9">
              <div class="av-container">
                {/* <div class="av-columns-area">
                    <div class="av-column-12">
                        <div class="heading-default text-center wow fadeInUp">
                            <h3>Featured <span class="primary-color">Bride</span></h3>
                            <span class="separator"><span><span></span></span></span>
                            <p>Being happy never goes out of style.</p>
                        </div>
                    </div>
                </div> */}
                <div class="av-columns-area">
                  <div class="av-column-4 av-sm-column-6">
                    <div class="team-member">
                      <div class="team-thumb" tabIndex="0">
                        <p
                          style={{
                            margin: "0px",
                            padding: "10px",
                            color: "#252525",
                          }}
                        >
                          9w837893 |{" "}
                          <a href=";">
                            <span style={{ color: "#00a03a" }}>Chat now</span>
                          </a>
                        </p>
                        <img
                          src="assets/images/team/team03.jpg"
                          alt="team_item"
                          title=""
                        />
                        {/* <div class="team-view"><a href="#"><i class="fa fa-eye"></i></a></div> */}
                        <div class="team-thumber">
                          <aside class="widget widget_social_widget">
                            {/* <ul>
                              <li>
                                <a href="#">
                                  <i class="fa fa-facebook"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i class="fa fa-twitter"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i class="fa fa-linkedin"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i class="fa fa-instagram"></i>
                                </a>
                              </li>
                            </ul> */}
                            <a href=";">
                              <div
                                class="cards-title"
                                style={{ color: "#fff" }}
                              >
                                Send Interest
                              </div>
                            </a>
                          </aside>
                        </div>
                      </div>
                      <div class="team-info">
                        <h5>
                          <a href=";">Steven Lucy</a>
                        </h5>
                        <a href=";">
                          <p>View Full Profile</p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="av-column-4 av-sm-column-6">
                    <div class="team-member">
                      <div class="team-thumb" tabIndex="0">
                        <p
                          style={{
                            margin: "0px",
                            padding: "10px",
                            color: "#252525",
                          }}
                        >
                          9w837893 |{" "}
                          <a href="#">
                            <span style={{ color: "#00a03a" }}>Chat now</span>
                          </a>
                        </p>
                        <img
                          src="assets/images/team/team03.jpg"
                          alt="team_item"
                          title=""
                        />
                        {/* <div class="team-view"><a href="#"><i class="fa fa-eye"></i></a></div> */}
                        <div class="team-thumber">
                          <aside class="widget widget_social_widget">
                            <a href=";">
                              <div
                                class="cards-title"
                                style={{ color: "#fff" }}
                              >
                                Send Interest
                              </div>
                            </a>
                          </aside>
                        </div>
                      </div>
                      <div class="team-info">
                        <h5>
                          <a href=";">Glenn Maxwell</a>
                        </h5>
                        <a href=";">
                          <p>View Full Profile</p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="av-column-4 av-sm-column-6">
                    <div class="team-member">
                      <div class="team-thumb" tabIndex="0">
                        <p
                          style={{
                            margin: "0px",
                            padding: "10px",
                            color: "#252525",
                          }}
                        >
                          9w837893 |{" "}
                          <a href="#">
                            <span style={{ color: "#00a03a" }}>Chat now</span>
                          </a>
                        </p>
                        <img
                          src="assets/images/team/team03.jpg"
                          alt="team_item"
                          title=""
                        />
                        {/* <div class="team-view"><a href="#"><i class="fa fa-eye"></i></a></div> */}
                        <div class="team-thumber">
                          <aside class="widget widget_social_widget">
                            <a href=";">
                              <div
                                class="cards-title"
                                style={{ color: "#fff" }}
                              >
                                Send Interest
                              </div>
                            </a>
                          </aside>
                        </div>
                      </div>
                      <div class="team-info">
                        <h5>
                          <a href=";">Aoron Finch</a>
                        </h5>
                        <a href=";">
                          <p>View Full Profile</p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="av-column-4 av-sm-column-6">
                    <div class="team-member">
                      <div class="team-thumb" tabIndex="0">
                        <p
                          style={{
                            margin: "0px",
                            padding: "10px",
                            color: "#252525",
                          }}
                        >
                          9w837893 |{" "}
                          <a href="#">
                            <span style={{ color: "#00a03a" }}>Chat now</span>
                          </a>
                        </p>
                        <img
                          src="assets/images/team/team03.jpg"
                          alt="team_item"
                          title=""
                        />
                        {/* <div class="team-view"><a href="#"><i class="fa fa-eye"></i></a></div> */}
                        <div class="team-thumber">
                          <aside class="widget widget_social_widget">
                            <a href=";">
                              <div
                                class="cards-title"
                                style={{ color: "#fff" }}
                              >
                                Send Interest
                              </div>
                            </a>
                          </aside>
                        </div>
                      </div>
                      <div class="team-info">
                        <h5>
                          <a href=";">Tim David</a>
                        </h5>
                        <a href=";">
                          <p>View Full Profile</p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="av-column-4 av-sm-column-6">
                    <div class="team-member">
                      <div class="team-thumb" tabIndex="0">
                        <p
                          style={{
                            margin: "0px",
                            padding: "10px",
                            color: "#252525",
                          }}
                        >
                          9w837893 |{" "}
                          <a href="#">
                            <span style={{ color: "#00a03a" }}>Chat now</span>
                          </a>
                        </p>
                        <img
                          src="assets/images/team/team03.jpg"
                          alt="team_item"
                          title=""
                        />
                        {/* <div class="team-view"><a href="#"><i class="fa fa-eye"></i></a></div> */}
                        <div class="team-thumber">
                          <aside class="widget widget_social_widget">
                            <a href=";">
                              <div
                                class="cards-title"
                                style={{ color: "#fff" }}
                              >
                                Send Interest
                              </div>
                            </a>
                          </aside>
                        </div>
                      </div>
                      <div class="team-info">
                        <h5>
                          <a href=";">Glenn Maxwell</a>
                        </h5>
                        <a href=";">
                          <p>View Full Profile</p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="av-column-4 av-sm-column-6">
                    <div class="team-member">
                      <div class="team-thumb" tabIndex="0">
                        <p
                          style={{
                            margin: "0px",
                            padding: "10px",
                            color: "#252525",
                          }}
                        >
                          9w837893 |{" "}
                          <a href="#">
                            <span style={{ color: "#00a03a" }}>Chat now</span>
                          </a>
                        </p>
                        <img
                          src="assets/images/team/team03.jpg"
                          alt="team_item"
                          title=""
                        />
                        {/* <div class="team-view"><a href="#"><i class="fa fa-eye"></i></a></div> */}
                        <div class="team-thumber">
                          <aside class="widget widget_social_widget">
                            <a href=";">
                              <div
                                class="cards-title"
                                style={{ color: "#fff" }}
                              >
                                Send Interest
                              </div>
                            </a>
                          </aside>
                        </div>
                      </div>
                      <div class="team-info">
                        <h5>
                          <a href=";">Aoron Finch</a>
                        </h5>
                        <a href=";">
                          <p>View Full Profile</p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="av-column-12 text-center mt-5">
                    <div class="av-post-pagination">
                      {/* <!-- Pagination --> */}
                      <nav
                        class="navigation pagination"
                        role="navigation"
                        aria-label="Posts"
                      >
                        <h2 class="screen-reader-text">Posts navigation</h2>
                        <div class="nav-links">
                          <a class="prev page-numbers" href=";">
                            <i class="fa fa-arrow-left"></i>
                          </a>
                          <span
                            aria-current="page"
                            class="page-numbers current"
                          >
                            1
                          </span>
                          <a class="page-numbers" href=";">
                            2
                          </a>
                          <a class="page-numbers" href=";">
                            3
                          </a>
                          <a class="next page-numbers" href=";">
                            <i class="fa fa-arrow-right"></i>
                          </a>
                        </div>
                      </nav>
                      {/* <!-- Pagination --> */}
                    </div>
                  </div>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: Bride
        =================================--> */}
    </div>
  );
};

export default HoroscopeMatches;
