import React from "react";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Reportaccount } from "../Redux/Action";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import ScrollTop from "./ScrollTop";

const Report = () => {
  const dispatch = useDispatch();
  const [report, setReport] = useState("");
  const [image, setImage] = useState();
  const { id } = useParams();
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("image", image);
    formData.append("reportedPerson", id);

    Object.entries(report).forEach(([key, value]) => {
      formData.append(key, value);
    });

    dispatch(Reportaccount(formData))
      .then((res) => {
        Swal.fire(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleChange = (e) => {
    setReport({ ...report, [e.target.name]: e.target.value });
  };
  const handleImage = (e) => {
    setImage(e.target.files[0]);
  };
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <ScrollTop />
      {/* <!--===// Start: Breadcrumb
        =================================--> */}
      <section id="breadcrumb-section" class="breadcrumb-area breadcrumb-left">
        <div class="av-container">
          <div class="av-columns-area">
            <div class="av-column-12">
              <div class="breadcrumb-content">
                <div class="breadcrumb-heading wow fadeInLeft">
                  <h2>Report Voilation</h2>
                </div>
                <ol class="breadcrumb-list wow fadeInRight">
                  <li>
                    <Link to="/">
                      <i class="fa fa-home"></i> Home Page
                    </Link>{" "}
                    &nbsp;-&nbsp;
                  </li>
                  <li class="active">Report</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: Breadcrumb
        =================================--> */}

      {/* start report voilation */}
      <section id="post-section" class="post-section av-py-default">
        <div class="av-container">
          <div class="av-columns-area">
            <div class="av-column-10 mx-auto">
              <div class="heading-default text-center wow fadeInUp">
                <h3>
                  Report <span class="primary-color">Voilation</span>
                </h3>
                <span class="separator">
                  <span>
                    <span></span>
                  </span>
                </span>
                <p>
                  We work with cyber police to take into custody people who
                  misuse our services.
                </p>
              </div>
            </div>
          </div>
          <div class="av-columns-area">
            <div class="av-column-12 mx-auto">
              <article class="post-items">
                <div class="post-content">
                  <h5 class="post-title">Report Voilation</h5>
                  <div class="post-footer">
                    <p>
                      We need your support and co-operation to stop violation.
                      You can alert us by sending an e-mail to{" "}
                      <a
                        href="mailto:vvkmatrimony12@gmail.com"
                        target="_blank"
                        className="contact-info"
                        style={{ color: "red" }}
                      >
                        <span className="text">vvkmatrimony12@gmail.com</span>
                      </a>
                      and we will take necessary action. Also, while reporting
                      such complaints, please provide all evidence including any
                      e-mail (Full header of the e-mail) you may have received.
                    </p>
                    <h4 style={{ fontSize: "20px" }}>
                      Note: We will not disclose your identity to the miscreant
                    </h4>
                    {/* <h4 style={{fontSize:"20px"}}>Some examples of violation:</h4> */}
                    <ul class="av-list-check">
                      <li>
                        If a member sends you obscene or inappropriate e-mails.
                      </li>
                      <li>
                        If you suspect a member's profile to be obscene or
                        fraudulent.
                      </li>
                      <li>If a member is sending you harassing e-mails.</li>
                      <li>If you suspect a member's photograph is not real.</li>
                      <li>
                        If you notice any other business / individual trying to
                        solicit you with ads or other material.
                      </li>
                    </ul>
                  </div>
                </div>
              </article>
              <div className="av-container">
                <div className="av-columns-area">
                  <div className="av-column-10 mx-auto">
                    <div id="comments" className="comments-area">
                      <div id="respond" className="comment-respond">
                        <form onSubmit={handleSubmit}>
                          {/* <!-- Email input --> */}
                          <h3 id="reply-title" className="comment-reply-title">
                            Report the status{" "}
                            <small>
                              <a
                                rel="nofollow"
                                id="cancel-comment-reply-link"
                                href="#"
                                style={{ display: "none" }}
                              >
                                Cancel reply
                              </a>
                            </small>
                          </h3>
                          <div className="form-outline mb-4">
                            <label>Choose Abuse Category</label>
                            <select
                              name="abuseCategory"
                              onChange={handleChange}
                              id="religion"
                            >
                              <option value="Choose Profile">
                                Abuse Category
                              </option>
                              <option value="Members's profile is abscene/fraud">
                                Members's profile is abscene/fraud
                              </option>
                              <option value="Member's details are inappropriate">
                                Member's details are inappropriate
                              </option>
                              <option value="Member has duplicate profile">
                                Member has duplicate profile
                              </option>
                              <option value="Wrong phone number / Not reachable">
                                Wrong phone number / Not reachable
                              </option>
                              <option value="Member's photo is not real">
                                Member's photo is not real
                              </option>
                              <option value="Member sends unsolicited and illicit emails / ads">
                                Member sends unsolicited and illicit emails /
                                ads
                              </option>
                              <option value="Member's marriage is fixed">
                                Member's marriage is fixed
                              </option>
                              <option value="Member is engaged">
                                Member is engaged
                              </option>
                              <option value="Member has got married">
                                Member has got married
                              </option>
                              <option value="Other reasons">
                                Other reasons
                              </option>
                            </select>
                          </div>
                          {/* <div className="form-outline mb-4">
                      <input
                        type="text"
                        name="userName"
                        id="form2Example1"
                        className="form-control"
                        placeholder="Enter your subject"
                      />
                    </div> */}
                         
                          <div className="form-outline mb-4">
                            <label>Complaint Details</label>
                            <label>
                              <span className="wpcf7-form-control-wrap your-message">
                                <textarea
                                  placeholder="Complaint Details"
                                  name="complaintDetails"
                                  onChange={handleChange}
                                  cols="40"
                                  rows="10"
                                  className="wpcf7-form-control wpcf7-textarea"
                                  aria-invalid="false"
                                  maxLength={255}
                                ></textarea>
                              </span>
                            </label>
                          </div>

                          <div className="form-outline mb-2">
                            <label>Upload Your Evidence</label>
                            <input
                              type="file"
                              name="image"
                              accept=".jpeg, .png,.jpg"
                              id="profilePicture"
                              onChange={handleImage}
                              className="form-control"
                              style={{ padding: "15px" }}
                            />
                          </div>

                          <div
                            className="av-column-12"
                            style={{ paddingBottom: "1px" }}
                          >
                            <a
                            //  href='/register3'
                            >
                              {" "}
                              <button
                                type="submit  "
                                className="btn btn-primary btn-block mb-4"
                                style={{ float: "left" }}
                              >
                                Submit
                              </button>
                            </a>
                            {/* <a href='/register3'> <button
                      type="button"
                      className="btn btn-primary btn-block mb-4"
                      style={{float:"right"}}
                    >
                      Skip
                    </button></a> */}
                          </div>
                          {/* <!-- Register buttons --> */}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end report voilation */}
    </div>
  );
};

export default Report;
