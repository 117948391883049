import React, { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* <!--===// Start: Breadcrumb
        =================================--> */}
      <section id="breadcrumb-section" class="breadcrumb-area breadcrumb-left">
        <div class="av-container">
          <div class="av-columns-area">
            <div class="av-column-12">
              <div class="breadcrumb-content">
                <div class="breadcrumb-heading wow fadeInLeft">
                  <h2>Privacy Policy</h2>
                </div>
                <ol class="breadcrumb-list wow fadeInRight">
                  <li>
                    <a href="/">
                      <i class="fa fa-home"></i> Home Page
                    </a>{" "}
                    &nbsp;-&nbsp;
                  </li>
                  <li class="active">Privacy Policy</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: Breadcrumb
        =================================--> */}

      {/* <!--===// Start: terms and conditions
        =================================--> */}
      <section
        id="timeline-section"
        class="timeline-section av-py-default shapes-section"
        style={{ backgroundColor: "#fff" }}
      >
        <div class="av-container">
          <div class="av-column-12">
            <div class="heading-default text-center wow fadeInUp">
              <h3>
                Privacy <span class="primary-color">Policy</span>
              </h3>
              <span class="separator">
                <span>
                  <span></span>
                </span>
              </span>
            </div>
          </div>
          <div class="av-columns-area">
            <div class="av-column-12 timeline">
              <div class="av-columns-area timeline-item">
                <div class="av-xs-column-12 av-md-column-12 order-3 order-md-1 timeline-content wow fadeInRight">
                  {" "}
                  <strong>Free Users and Paid Users</strong>
                  <p style={{ marginBottom: "60px" }}>
                    All visitors to our site can browse the site, search the
                    profiles and features of our site has to offer without
                    providing any personal information or paying to membership.
                    However, membership Paid users enjoy certain features which
                    are mentioned elsewhere in the site.
                  </p>
                </div>
                <div class="av-xs-column-12 av-md-column-12 order-3 order-md-1 timeline-content wow fadeInRight">
                  {" "}
                  <strong>Handling Your Information</strong>
                  <p style={{ marginBottom: "60px" }}>
                    We gather information from members and guests who apply for
                    the matrimonial services that our site offers. It includes,
                    email address, name, a user-specified password, mailing
                    address, zip code, telephone number, horoscope details and
                    etc.
                    <br />
                    <br />
                    We use third party payment gateways for various modes of
                    online transactions and Cookies are used to store the login
                    information. We do not control the use of cookies by third
                    parties. <br />
                    <br />
                    VVKMATRIMONY use also your personal information for
                    verification, analysis of data, for deliver personalized
                    experience to our website/app. Your IP address is also used
                    to help identify you and to gather broad demographic
                    information.
                  </p>
                </div>
                <div class="av-xs-column-12 av-md-column-12 order-3 order-md-1 timeline-content wow fadeInRight">
                  {" "}
                  <strong>Information Sharing:</strong>
                  <p style={{ marginBottom: "60px" }}>
                    The information collected from our members will not be
                    shared with any individual or organization without the
                    members' approval. We may change this Privacy Policy from
                    time to time based on client feedback or change of policy in
                    our company to without any notice to you. However, changes
                    will be updated in the Privacy Policy page.
                  </p>
                </div>
                <div class="av-xs-column-12 av-md-column-12 order-3 order-md-1 timeline-content wow fadeInRight">
                  {" "}
                  <strong>Changes in Privacy Policy:</strong>
                  <p style={{ marginBottom: "60px" }}>
                    We may change this Privacy Policy from time to time based on
                    client feedback or change of policy in our company to
                    without any notice to you. However, changes will be updated
                    in the Privacy Policy page.
                  </p>
                </div>

                <div class="av-xs-column-12 av-md-column-12 order-3 order-md-1 timeline-content wow fadeInRight">
                  {" "}
                  <strong>Refund Policy</strong>
                  <p>
                    Once a purchase is made, it cannot be cancelled or refunded.
                    Please carefully review all details of the purchase before
                    making payment to ensure it meets your needs and
                    expectations. We apologize for any inconvenience this policy
                    may cause, but it allows us to maintain the quality and
                    affordability of our products/services. If you have any
                    questions or concerns regarding a purchase, please do not
                    hesitate to contact us for assistance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div class='shape1'>
          <img src='assets/images/service/clipArt/shape1.png' alt='image' />
        </div>
        <div class='shape2'>
          <img src='assets/images/service/clipArt/shape2.png' alt='image' />
        </div>
        <div class='shape3'>
          <img src='assets/images/service/clipArt/shape3.png' alt='image' />
        </div>
        <div class='shape4'>
          <img src='assets/images/service/clipArt/shape4.png' alt='image' />
        </div>
        <div class='shapetimeline1'>
          <img src='assets/images/timeline/timeline1.png' alt='image' />
        </div>
        <div class='shapetimeline2'>
          <img src='assets/images/timeline/timeline2.png' alt='image' />
        </div>
        <div class='shapetimeline3'>
          <img src='assets/images/timeline/timeline3.png' alt='image' />
        </div> */}
      </section>
      {/* <!-- End: terms and conditions
        =================================--> */}
    </div>
  );
};

export default PrivacyPolicy;
