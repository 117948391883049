import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdVerifiedUser, MdLocalOffer, MdMessage } from "react-icons/md";
import { MembershipPlans, Buymembership } from "../Redux/Action";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { RiCustomerService2Fill } from "react-icons/ri";
import Loader from "../Common/Loader";

const Pricing = () => {
  const [plans, setPlans] = useState(null);
  const [load, setLoad] = useState(true);

  const dispatch = useDispatch();
  const Token = localStorage.getItem("Authtok");
  const plan = useSelector(({ MembershipPlans }) => MembershipPlans.payload);
  const goldMenbership = (e) => {
    dispatch(Buymembership({ id: plan[0].id })).then((res) => {
      if (res === "Membership already exists") {
        Swal.fire(res);
      } else {
        const url = res?.url;
        if (url) {
          window.location.href = url;
        }
      }
    });
    // navigate("/ordersummary");
  };

  const platinumMenbership = (id) => {
    dispatch(Buymembership({ id: plan[1].id })).then((res) => {
      if (res === "Membership already exists") {
        Swal.fire(res);
      } else {
        const url = res?.url;
        if (url) {
          window.location.href = url;
        }
      }
    });
    // navigate("/ordersummary");
  };

  const diamondMenbership = (e) => {
    dispatch(Buymembership({ id: plan[2].id })).then((res) => {
      if (res === "Membership already exists") {
        Swal.fire(res);
      } else {
        const url = res?.url;
        if (url) {
          window.location.href = url;
        }
      }
    });
    // navigate("/ordersummary");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (Token) {
      Promise.allSettled([dispatch(MembershipPlans())]).then((resultArray) => {
        const data = resultArray[0].value.data.data;

        setPlans(data);
        setLoad(false);
      });
    }
  }, []);

  return (
    <div>
      <section
        id="pricing-section"
        className="pricing-section pricing-two av-py-default shapes-section"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="av-container">
          {plans ? (
            <div className="av-columns-area">
              <div className="av-column-12">
                <div className="heading-default text-center wow fadeInUp">
                  <h3>
                    Our <span className="primary-color">Pricing</span>
                  </h3>
                  <span className="separator">
                    <span>
                      <span></span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          ) : null}
          <div className="av-filter-wrapper-2">
            <div className="av-columns-area wow fadeInUp">
              <div className="av-column-12">
                <div
                  id="av-filter-init-2"
                  className="av-columns-area av-filter-init pricing-row"
                >
                  <div className="av-column-4 av-sm-column-6 av-filter-item all monthly">
                    <div className="pricing-item">
                      <div className="pricing-heading">
                        <h5>Golden User</h5>
                      </div>
                      <div className="pricing-rate">
                        <p className="pricing-name">Yearly Package</p>
                        <span className="pricing">
                          {plans?.[0]?.amount
                            ? `${parseFloat(plans[0].amount).toLocaleString(
                                "en-IN",
                                {
                                  maximumFractionDigits: 0,
                                }
                              )} INR`
                            : "0 INR"}{" "}
                          {/* You can include "0 INR" as the default value */}
                        </span>
                      </div>

                      <div className="pricing-list">
                        <ul>
                          <li>
                            Access 500 profiles per year <br />
                            (changable)
                          </li>
                          <li>Send interest requests</li>
                          <li>Marriage compatability feature (porutham)</li>
                          <li>Can see and download horoscope details</li>
                        </ul>
                      </div>
                      {Token ? (
                        <div className="pricing-btn">
                          <a
                            onClick={goldMenbership}
                            target="_blank"
                            className="av-btn av-btn-secondary av-btn-bubble"
                          >
                            Purchase Plan <i className="fa fa-arrow-right"></i>{" "}
                            <span className="bubble_effect">
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="button effect-button"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>
                            </span>
                          </a>
                        </div>
                      ) : (
                        <div className="pricing-btn">
                          <a
                            href="/login"
                            className="av-btn av-btn-secondary av-btn-bubble"
                          >
                            Purchase Plan<i className="fa fa-arrow-right"></i>{" "}
                            <span className="bubble_effect">
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="button effect-button"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>
                            </span>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="av-column-4 av-sm-column-6 av-filter-item all lifetime">
                    <div className="pricing-item">
                      <div className="pricing-heading">
                        <h5>Plantinum User</h5>
                      </div>
                      <div className="pricing-rate">
                        <p className="pricing-name">Yearly Package</p>
                        <span className="pricing">
                          {plans?.[1]?.amount
                            ? `${parseFloat(plans[1].amount).toLocaleString(
                                "en-IN",
                                {
                                  maximumFractionDigits: 0,
                                }
                              )} INR`
                            : "0 INR"}{" "}
                          {/* You can include "0 INR" as the default value */}
                        </span>
                      </div>

                      <div className="pricing-list">
                        <ul>
                          <li>Access unlimited profiles for a year</li>
                          <li>Show your profile in Home Page for 3 months</li>
                          <li>Display in suggestion profiles</li>
                          <li>
                            Profile optimization for increase the chances of
                            finding profile by 3 times
                          </li>
                        </ul>
                      </div>
                      {Token ? (
                        <div className="pricing-btn">
                          <a
                            onClick={platinumMenbership}
                            target="_blank"
                            className="av-btn av-btn-secondary av-btn-bubble"
                          >
                            Purchase Plan<i className="fa fa-arrow-right"></i>{" "}
                            <span className="bubble_effect">
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="button effect-button"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>
                            </span>
                          </a>
                        </div>
                      ) : (
                        <div className="pricing-btn">
                          <a
                            href="/login"
                            className="av-btn av-btn-secondary av-btn-bubble"
                          >
                            Purchase Plan<i className="fa fa-arrow-right"></i>{" "}
                            <span className="bubble_effect">
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="button effect-button"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>
                            </span>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="av-column-4 av-sm-column-6 av-filter-item all yearly">
                    <div className="pricing-item recommended">
                      <div className="pricing-heading">
                        <h5>Diamond User</h5>
                      </div>
                      <div className="pricing-rate">
                        <p className="pricing-name">Yearly Package</p>
                        <span className="pricing">
                          {plans?.[2]?.amount
                            ? `${parseFloat(plans[2].amount).toLocaleString(
                                "en-IN",
                                {
                                  maximumFractionDigits: 0,
                                }
                              )} INR`
                            : "0 INR"}{" "}
                          {/* You can include "0 INR" as the default value */}
                        </span>
                      </div>

                      <div className="pricing-list">
                        <ul>
                          <li>
                            Access 750 profiles per year <br />
                            (changable)
                          </li>
                          <li>Can see and download horoscope details</li>
                          <li>
                            Send interest requests <br />
                            (porutham)
                          </li>
                          <li>Receiving hand picked profiles via Email</li>
                        </ul>
                      </div>
                      {Token ? (
                        <div className="pricing-btn">
                          <a
                            onClick={diamondMenbership}
                            target="-blank"
                            className="av-btn av-btn-secondary av-btn-bubble"
                          >
                            Purchase Plan<i className="fa fa-arrow-right"></i>{" "}
                            <span className="bubble_effect">
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="button effect-button"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>
                            </span>
                          </a>
                        </div>
                      ) : (
                        <div className="pricing-btn">
                          <a
                            href="/login"
                            className="av-btn av-btn-secondary av-btn-bubble"
                          >
                            Purchase Plan<i className="fa fa-arrow-right"></i>{" "}
                            <span className="bubble_effect">
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="circle top-left"></span>{" "}
                              <span className="button effect-button"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>{" "}
                              <span className="circle bottom-right"></span>
                            </span>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!--===// Start: Step Section
        =================================--> */}
      <section id="step-section" className="step-section av-py-default">
        <div className="av-container">
          <div className="av-columns-area">
            <div className="av-column-12">
              <div className="heading-default text-center wow fadeInUp">
                <h3>
                  Benefits of{" "}
                  <span className="primary-color">Premium Membership</span>
                </h3>
                <span className="separator">
                  <span>
                    <span></span>
                  </span>
                </span>
                {/* <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> */}
              </div>
            </div>
          </div>
          <div className="av-columns-area">
            <div className="av-column-3 av-sm-column-6">
              <div className="stepbox">
                <div className="stepbox-outer">
                  <div className="step-effect"></div>
                  <div className="stepbox-icon">
                    {/* <span className="stepbox-number">1</span> */}
                    {/* <i className="fa fa-paint-brush"></i> */}
                    <MdVerifiedUser style={{ verticalAlign: "middle" }} />
                  </div>
                </div>
                <div className="stepbox-content">
                  <h5 className="stepbox-title">
                    <a href="">View Verified Contact Details</a>
                  </h5>
                  {/* <div className="stepbox-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</div> */}
                </div>
              </div>
            </div>
            <div className="av-column-3 av-sm-column-6">
              <div className="stepbox">
                <div className="stepbox-outer">
                  <div className="step-effect"></div>
                  <div className="stepbox-icon">
                    {/* <span className="stepbox-number">2</span> */}
                    {/* <i className="fa fa-paint-brush"></i> */}
                    <MdLocalOffer style={{ verticalAlign: "middle" }} />
                  </div>
                </div>
                <div className="stepbox-content">
                  <h5 className="stepbox-title">
                    <a href="#">Special Offers From Partner</a>
                  </h5>
                  {/* <div className="stepbox-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</div> */}
                </div>
              </div>
            </div>
            <div className="av-column-3 av-sm-column-6">
              <div className="stepbox">
                <div className="stepbox-outer">
                  <div className="step-effect"></div>
                  <div className="stepbox-icon">
                    {/* <span className="stepbox-number">3</span> */}
                    {/* <i className="fa fa-paint-brush"></i> */}
                    <MdMessage style={{ verticalAlign: "middle" }} />
                  </div>
                </div>
                <div className="stepbox-content">
                  <h5 className="stepbox-title">
                    <a href="#">Write Direct Message to Member</a>
                  </h5>
                  {/* <div className="stepbox-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</div> */}
                </div>
              </div>
            </div>
            <div className="av-column-3 av-sm-column-6">
              <div className="stepbox">
                <div className="stepbox-outer">
                  <div className="step-effect"></div>
                  <div className="stepbox-icon">
                    {/* <span className="stepbox-number">4</span> */}
                    {/* <i className="fa fa-paint-brush"></i> */}
                    <RiCustomerService2Fill
                      style={{ verticalAlign: "middle" }}
                    />
                  </div>
                </div>
                <div className="stepbox-content">
                  <h5 className="stepbox-title">
                    <a href="#">Quick Response Service </a>
                  </h5>
                  {/* <div className="stepbox-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: Step Section
        =================================--> */}
    </div>
  );
};

export default Pricing;
