import React from 'react';
// import ReactDOM from 'react-dom';
import { createStore, compose, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import Reducer from './Redux/Reducers/Index';
import { setHeaderToken } from './Axios/Service';
import Mainroutes from './Routes/Mainroutes';
import { createRoot } from 'react-dom/client';

// CREATE STORE
const store = createStore(Reducer, compose(applyMiddleware(thunk)));

// TOKEN AUTHORIZATION
const token = localStorage.getItem('Authtok');
if (token) {
  setHeaderToken(token);
}

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <Mainroutes />
  </Provider>
);
