import React from "react";
import { Link } from "react-router-dom";
import { regSearch, AddIntrest } from "../Redux/Action";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Skeleton } from "../Common/Skeleton";
import { useLocation } from "react-router-dom";

const SearchProfile = () => {
  const Token = localStorage.getItem("Authtok");
  const dispatch = useDispatch();
  const [err, setErr] = useState("");

  const location = useLocation();
  const searchQuery = location?.state?.searchQuery;

  const [searchProfile, setSearchProfile] = useState(" ");
  const handleClick1 = (id) => {
    dispatch(AddIntrest({ interestProfileId: id }))
      .then((res) => {
        Swal.fire(res.data.data);
        setErr(res.data.data);
      })
      .catch((e) => {
        setErr(e.response.data.error.message);
        console.log("err", e.response.data.error.message);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(regSearch(searchQuery)).then((res) => {
      setSearchProfile(res.data.data);
    });
  }, []);
  // console.log(searchProfile);
  return (
    <div>
      {/* <!--===// Start: Breadcrumb
        =================================--> */}
      <section id="breadcrumb-section" class="breadcrumb-area breadcrumb-left">
        <div class="av-container">
          <div class="av-columns-area">
            <div class="av-column-12">
              <div class="breadcrumb-content">
                <div class="breadcrumb-heading wow fadeInLeft">
                  <h2>SearchProfile</h2>
                </div>
                <ol class="breadcrumb-list wow fadeInRight">
                  <li>
                    <a href="/">
                      <i class="fa fa-home"></i> Home Page
                    </a>{" "}
                    &nbsp;-&nbsp;
                  </li>
                  <li class="active">SearchProfile</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: Breadcrumb
        =================================--> */}

      {/* <!--===// Start: bride
        =================================--> */}
      <section
        id="team-section"
        className="team-section av-py-default team-home shapes-section"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="av-container">
          <div className="av-columns-area">
            <div className="av-column-12">
              <div className="heading-default text-center wow fadeInUp">
                <h3>
                  Search <span className="primary-color">Profile</span>
                </h3>
                <span className="separator">
                  <span>
                    <span></span>
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div class="av-column-12">
          {searchProfile === "Data not found" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",fontSize:"30px",fontWeight:"600"
              }}
            >
              No Profile Found
            </div>
          ) : ( 
             <div className="av-columns-areas" style={{display:"flex"}}>
              {Array.isArray(searchProfile) && searchProfile.length !== 0 ? (
                searchProfile.map((x) => (
                  <div className="av-column-3 av-sm-column-6">
                    <div className="team-member">
                      <div className="team-thumb" tabIndex="0">
                        <p
                          style={{
                            margin: "0px",
                            padding: "10px",
                            color: "#252525",
                          }}
                        >
                          {x.profileId} |&nbsp;
                          <span style={{ color: "#00a03a" }}>{x.age}Yrs</span>
                        </p>
                        <img
                          src={process.env.REACT_APP_URL + x.images}
                          alt="team_item"
                          title=""
                          style={{ aspectRatio: "1/1", objectFit: "cover" }}
                        />
                        {/* <div className="team-view"><a href="#"><i className="fa fa-eye"></i></a></div> */}
                        <div className="team-thumber">
                          <aside className="widget widget_social_widget">
                            {Token ? (
                              <aside className="widget widget_social_widget">
                                <Link>
                                  <div
                                    onClick={() => handleClick1(x.id)}
                                    className="cards-title"
                                    style={{ color: "#fff" }}
                                  >
                                    Send Interest
                                  </div>
                                </Link>
                              </aside>
                            ) : (
                              <aside className="widget widget_social_widget">
                                <Link href="/login">
                                  <div
                                    className="cards-title"
                                    style={{ color: "#fff" }}
                                  >
                                    Send Interest
                                  </div>
                                </Link>
                              </aside>
                            )}
                          </aside>
                        </div>
                      </div>
                      <div className="team-info">
                        <Link to={`/profiledetails/${x.id}`}>
                          {" "}
                          <h5 style={{ textTransform: "capitalize" }}>
                            {x.userName && x.userName.length > 10
                              ? x.userName.substring(0, 10) + "..."
                              : x.userName}
                          </h5>
                          <p>View Profile</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  {" "}
                  <h3>No Profile Match</h3>
                </div>
              )}
            </div>)}
          </div>
        </div>
       
      </section>
    </div>
  );
};

export default SearchProfile;
