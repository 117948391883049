import React, { useEffect } from "react";
import { successstory } from "../Redux/Action";
import { useDispatch, useSelector } from "react-redux";

const SuccessProfile = () => {
  const dispatch = useDispatch();
  const successTestimonial = useSelector(
    ({ successstory }) => successstory.payload
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    Promise.allSettled([dispatch(successstory())]);
  }, []);
  return (
    <div style={{ backgroundColor: "#fff" }}>
      {/* <!--===// Start: Breadcrumb
        =================================--> */}
      <section id="breadcrumb-section" class="breadcrumb-area breadcrumb-left">
        <div class="av-container">
          <div class="av-columns-area">
            <div class="av-column-12">
              <div class="breadcrumb-content">
                <div class="breadcrumb-heading wow fadeInLeft">
                  <h2>Profile of Success Story</h2>
                </div>
                <ol class="breadcrumb-list wow fadeInRight">
                  <li>
                    <a href="/">
                      <i class="fa fa-home"></i> Home Page
                    </a>{" "}
                    &nbsp;-&nbsp;
                  </li>
                  <li class="active">Profile of Success Story</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: Breadcrumb
        =================================--> */}

      {/* start stories */}
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 mt-3 ">
            {Array.isArray(successTestimonial) &&
            successTestimonial.length !== 0
              ? successTestimonial.map((x) => (
                  <div class="card">
                    <div class="card-horizontal">
                      <div class="img-square-wrapper">
                        <img
                          class=""
                          src={process.env.REACT_APP_URL + x.image}
                          alt="VVK matrimonail Bride and Groom photo"
                          style={{ aspectRatio: "1/1", objectFit: "cover" }}
                        />
                      </div>
                      <div class="card-body">
                        <h4
                          class="card-title"
                          style={{ textTransform: "capitalize" }}
                        >
                          {x.brideName} & <span>{x.groomName}</span>
                        </h4>
                        <p class="card-text">{x.content}</p>
                        <a
                          href="/successstory"
                          class="mt-auto btn btn-primary  "
                        >
                          Post your's
                        </a>
                      </div>
                    </div>
                  </div>
                ))
              : false}
          </div>
        </div>
      </div>
      {/* end stories */}
    </div>
  );
};

export default SuccessProfile;
