import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ListShortlist, AddIntrest } from "../Redux/Action";
import { Skeleton } from "../Common/Skeleton";
import Swal from "sweetalert2";

// import UserMeta from './UserMeta';

const ShortListed = () => {
  const dispatch = useDispatch();
  const Token = localStorage.getItem("Authtok");
  const Listfav = useSelector(({ ListShortlist }) => ListShortlist.payload);
  useEffect(() => {
    Promise.allSettled([dispatch(ListShortlist())]);
  }, []);
  return (
    <div>
      {/* <!--===// Start: Breadcrumb
        =================================--> */}
      <section id="breadcrumb-section" class="breadcrumb-area breadcrumb-left">
        <div class="av-container">
          <div class="av-columns-area">
            <div class="av-column-12">
              <div class="breadcrumb-content">
                <div class="breadcrumb-heading wow fadeInLeft">
                  <h2> Shortlisted Profiles</h2>
                </div>
                <ol class="breadcrumb-list wow fadeInRight">
                  <li>
                    <a href="/">
                      <i class="fa fa-home"></i> Home Page
                    </a>{" "}
                    &nbsp;-&nbsp;
                  </li>
                  <li class="active"> Shortlisted Profiles</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: Breadcrumb
        =================================--> */}
      <section
        id="post-section"
        class="post-section av-py-default"
        style={{ backgroundColor: "#fff" }}
      >
        <div class="av-container">
          <div class="av-columns-area">
            <div class="av-column-12">
              <div class="heading-default text-center wow fadeInUp">
                <h3>
                  Shortlisted <span class="primary-color">Profiles</span>
                </h3>
                <span class="separator">
                  <span>
                    <span></span>
                  </span>
                </span>
                {/* <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p> */}
              </div>
            </div>
          </div>

          {/* user meta start */}
          {/* <UserMeta /> */}
          {/* user meta end */}
          {Listfav === "User Not Found!" ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",fontSize:"30px",fontWeight:"600"
                }}
              >
                No Profile Found
              </div>
            ) : (
               <div class="av-columns-area">
            {/* profile list */}
            <div class="av-column-12">
              <div class="av-container">
                {/* <div class="av-columns-area">
                    <div class="av-column-12">
                        <div class="heading-default text-center wow fadeInUp">
                            <h3>Featured <span class="primary-color">Bride</span></h3>
                            <span class="separator"><span><span></span></span></span>
                            <p>Being happy never goes out of style.</p>
                        </div>
                    </div>
                </div> */}
                <div class="av-columns-area">
                  {Array.isArray(Listfav) && Listfav.length !== 0 ? (
                    Listfav.map((x) => (
                      <div class="av-column-4 av-sm-column-6">
                        <div class="team-member">
                          <div class="team-thumb" tabIndex="0">
                            <p
                              style={{
                                margin: "0px",
                                padding: "10px",
                                color: "#252525",
                              }}
                            >
                              {x.profileId} |{" "}
                              <a href="#">
                                <span style={{ color: "#00a03a" }}>
                                  {x.age}Yrs
                                </span>
                              </a>
                            </p>
                            <Link
                              to={Token ? "/profiledetails/" + x.id : "/login"}
                            >
                              <img
                                src={process.env.REACT_APP_URL + x.images}
                                alt="team_item"
                                title=""
                                style={{
                                  aspectRatio: "1/1",
                                  objectFit: "cover",
                                }}
                              />
                            </Link>
                            {/* <div class="team-view"><a href="#"><i class="fa fa-eye"></i></a></div> */}
                            <div
                              class="team-thumber"
                              style={{ cursor: "pointer" }}
                            >
                              <aside class="widget widget_social_widget">
                                <a
                                  onClick={() =>
                                    dispatch(
                                      AddIntrest({ interestProfileId: x.id })
                                    ).then((res) => {
                                      Swal.fire(res.data.data);
                                    })
                                  }
                                >
                                  <div
                                    class="cards-title"
                                    style={{ color: "#fff" }}
                                  >
                                    Send Interest
                                  </div>
                                </a>
                              </aside>
                            </div>
                          </div>
                          <div class="team-info">
                            <h5 style={{ textTransform: "capitalize" }}>
                              {x.userName.length > 10
                                ? x.userName.substring(0, 10) + "..."
                                : x.userName}
                            </h5>
                            <a
                              href={
                                Token ? "/profiledetails/" + x.id : "/login"
                              }
                            >
                              <p>View Full Profile</p>
                            </a>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    // <h3 style={{ textAlign: "center" }}>No Profile Match</h3>
                    <Skeleton />
                  )}
                </div>
              </div>
            </div>
          </div>)}
        </div>
      </section>
    </div>
  );
};

export default ShortListed;
