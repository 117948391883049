import React, { useState, useEffect } from "react";
import { Usermeta, Usermetadetails, Banner } from "../Redux/Action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Carousel = () => {
  const Token = localStorage.getItem("Authtok");
  const dispatch = useDispatch();
  const [load, setLoad] = useState(true);
  const [like, setLike] = useState("");

  useEffect(() => {
    if (Token) {
      dispatch(Usermetadetails()).then((res) => setLoad(false));
    }
  }, [Token]);

  useEffect(() => {
    if (Token) {
      Promise.allSettled([
        dispatch(Usermeta()).then((res) => {
          setLike(res.data.data);
        }),
      ]);
    }
  }, [Token, dispatch]);

  // console.log(like);

  // const handleSubmit = (e) => {
  //     dispatch(Otpverifynumber())
  // }
  useEffect(() => {
    window.scrollTo(0, 0);

    Promise.allSettled([dispatch(Banner())]);
  }, []);
  return (
    <div>
      {/* carousel start */}
      {Token ? (
        <section id="slider-section" className="slider-wrapper">
          <div className="main-slider owl-carousel owl-theme">
            <div className="itemss">
              <img
                src="assets/images/slider/home-banner-ri.webp"
                data-img-url="assets/images/slider/home-banner-ri.webp"
                alt="slideitem"
                className="imgss"
              />
              <div className="theme-slider">
                <div className="theme-table">
                  <div className="theme-table-cell">
                    <div className="av-container">
                      <div className="theme-content text-left">
                        <h3 data-animation="fadeInUp" data-delay="150ms">
                          Trusted Matrimony & Matchmaking Service
                        </h3>
                        <h1 data-animation="fadeInLeft" data-delay="200ms">
                          Welcome to{" "}
                          <span className="primary-color">VVK Matrimony</span>
                        </h1>
                        {/* <Link data-animation="fadeInUp" data-delay="800ms" to="/login" className="av-btn av-btn-primary av-btn-bubble">Login <i className="fa fa-arrow-right"></i> <span className="bubble_effect"><span className="circle top-left"></span> <span className="circle top-left"></span> <span className="circle top-left"></span> <span className="button effect-button"></span> <span className="circle bottom-right"></span> <span className="circle bottom-right"></span> <span className="circle bottom-right"></span></span></Link> */}
                        {/* <Link data-animation="fadeInUp" data-delay="800ms" to="/login" className="av-btn av-btn-secondary av-btn-bubble">Login Your Profile <i className="fa fa-arrow-right"></i> <span className="bubble_effect"><span className="circle top-left"></span> <span className="circle top-left"></span> <span className="circle top-left"></span> <span className="button effect-button"></span> <span className="circle bottom-right"></span> <span className="circle bottom-right"></span> <span className="circle bottom-right"></span></span></Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section id="slider-section" className="slider-wrapper">
          <div className="main-slider owl-carousel owl-theme">
            <div className="itemss">
              <img
                src="assets/images/slider/home-banner-ri.webp"
                data-img-url="assets/images/slider/home-banner-ri.webp"
                alt="slideitem"
                className="imgss"
              />
              <div className="theme-slider">
                <div className="theme-table">
                  <div className="theme-table-cell">
                    <div className="av-container">
                      <div className="theme-content text-left">
                        <h3 data-animation="fadeInUp" data-delay="150ms">
                          Trusted Matrimony & Matchmaking Service
                        </h3>
                        <h1 data-animation="fadeInLeft" data-delay="200ms">
                          Welcome to{" "}
                          <span className="primary-color">VVK Matrimony</span>
                        </h1>
                        {/* <Link data-animation="fadeInUp" data-delay="800ms" to="/login" className="av-btn av-btn-primary av-btn-bubble">Login <i className="fa fa-arrow-right"></i> <span className="bubble_effect"><span className="circle top-left"></span> <span className="circle top-left"></span> <span className="circle top-left"></span> <span className="button effect-button"></span> <span className="circle bottom-right"></span> <span className="circle bottom-right"></span> <span className="circle bottom-right"></span></span></Link> */}
                        <a
                          data-animation="fadeInUp"
                          data-delay="800ms"
                          href="/login"
                          className="av-btn av-btn-secondary av-btn-bubble"
                        >
                          Login Your Profile{" "}
                          <i className="fa fa-arrow-right"></i>{" "}
                          <span className="bubble_effect">
                            <span className="circle top-left"></span>{" "}
                            <span className="circle top-left"></span>{" "}
                            <span className="circle top-left"></span>{" "}
                            <span className="button effect-button"></span>{" "}
                            <span className="circle bottom-right"></span>{" "}
                            <span className="circle bottom-right"></span>{" "}
                            <span className="circle bottom-right"></span>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {/* end carousel */}

      {/* <!-- start: Info
        =================================--> */}
      {Token && like ? (
        <div
          id="info-section"
          className="info-section info-section-one"
          style={{ marginBottom: "0" }}
        >
          <div className="av-container">
            <div
              className="av-columns-area info-wrapper wow fadeInUp"
              style={{
                display: "block",
                padding: "32px 50px 60px",
                borderRadius: "20px",
              }}
            >
              <div className="av-columns-area funfact-wrapper">
                {" "}
                <div className="av-column-4 av-sm-column-6">
                  <a href="/likelist" style={{ textDecoration: "none" }}>
                    {" "}
                    <div className="funfact-item">
                      <i className="fa fa-umbrella"></i>
                      <h3>
                        <span className="counter">{like?.profileLikes}</span> +
                      </h3>
                      <h5>Profile Likes</h5>
                    </div>
                  </a>
                </div>
                <div className="av-column-4 av-sm-column-6">
                  <a href="/visitlist" style={{ textDecoration: "none" }}>
                    {" "}
                    <div className="funfact-item">
                      <i className="fa fa-hand-peace-o"></i>
                      <h3>
                        <span className="counter">{like?.profileVisits}</span> +
                      </h3>
                      <h5>Profile Visits</h5>
                    </div>
                  </a>
                </div>
                <div className="av-column-4 av-sm-column-6">
                  <a href="/interestrec" style={{ textDecoration: "none" }}>
                    {" "}
                    <div className="funfact-item">
                      <i className="fa fa-smile-o"></i>
                      <h3>
                        <span className="counter">
                          {like?.interestReceived}
                        </span>
                        +
                      </h3>
                      <h5>Interest Received</h5>
                    </div>{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {/* <!-- End: Info
        =================================--> */}
    </div>
  );
};

export default Carousel;
