import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddIntrest, recentprofile } from "../Redux/Action";
import { filterProfile } from "../Redux/Action";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Skeleton } from "../Common/Skeleton";
import dummy from "../Images/dummy.jpeg";
import UserMeta from "./UserMeta";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// import Card from "react-bootstrap/Card";

const AllProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [load, setLoad] = useState(true);
  const Token = localStorage.getItem("Authtok");
  const [filters, setFilters] = useState({
    maritalStatus: [],
    motherTongue: [],
    profileCreated: [],
    religion: [],
  });
  const [filteredUsers, setFilteredUsers] = useState(null);

  const Allmatches = useSelector(({ recentprofile }) => recentprofile.payload);

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(filterProfile(filters)).then((res) => {
      setFilteredUsers(res.data.data);
      window.scrollTo(0, 0);
    });
  };

  const handleChange = (e) => {
    const checkboxName = e.target.name;
    const checkboxValue = e.target.value.toLowerCase();

    if (e.target.checked) {
      setFilters({
        ...filters,
        [checkboxName]: [...filters[checkboxName], checkboxValue],
      });
    } else {
      setFilters({
        ...filters,
        [checkboxName]: filters[checkboxName].filter(
          (value) => value !== checkboxValue
        ),
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    Promise.allSettled([dispatch(recentprofile())]);
  }, []);

  return (
    <div>
      {/* <!--===// Start: Bride
        =================================--> */}
      <section
        id="post-section"
        className="post-section av-py-default"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="av-container">
          <div className="av-columns-area">
            <div className="av-column-12">
              <div className="heading-default text-center wow fadeInUp">
                <h3>
                  All <span className="primary-color">Matches</span>
                </h3>
                <span className="separator">
                  <span>
                    <span></span>
                  </span>
                </span>
                {
                  //  <p>
                  //   Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  //   qui officia deserunt mollit anim id est laborum.
                  // </p>
                }
              </div>
            </div>
          </div>
          {/* user meta start */}
          <UserMeta />
          {/* user meta end */}
          <div className="av-columns-area">
            <div className="av-column-3 av-sm-column-12">
              <div className="sidebar">
                <aside id="tag_cloud-2" className="widget widget_tag_cloud">
                  <h6 className="widget-title">
                    Profile Type <span></span>
                  </h6>
                  <div className="tagcloud" style={{ paddingTop: "0px" }}>
                    <form action="/action_page.php" onSubmit={handleSubmit}>
                      <h5>Profile Created By</h5>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox />}
                          id="self"
                          onChange={handleChange}
                          name="profileCreated"
                          label="Self"
                          value="self"
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          id="parent"
                          onChange={handleChange}
                          name="profileCreated"
                          label="Parent"
                          value="parent"
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          id="guardian"
                          onChange={handleChange}
                          name="profileCreated"
                          label="Guardian"
                          value="guardian"
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          id="sibiling"
                          name="profileCreated"
                          onChange={handleChange}
                          label="Sibiling"
                          value="sibiling"
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          id="friend"
                          name="profileCreated"
                          onChange={handleChange}
                          label="Friend"
                          value="friend"
                        />
                      </FormGroup>
                      <br />
                      {/* <input type="submit" value="Submit"/> */}

                      {/* <h6 className="widget-title">
                    Religious Details <span></span>
                  </h6> */}
                      <div className="tagcloud">
                        <h5>Religion</h5>
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox />}
                            name="religion"
                            onChange={handleChange}
                            label="Hindu"
                            value="hindu"
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            name="religion"
                            onChange={handleChange}
                            label="Muslim"
                            value="muslim"
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            name="religion"
                            onChange={handleChange}
                            label="Christian"
                            value="christian"
                          />
                        </FormGroup>
                      </div>

                      <br />
                      <div className="tagcloud">
                        <h5>Mother Tongue</h5>
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox />}
                            onChange={handleChange}
                            name="motherTongue"
                            value="tamil"
                            label="Tamil"
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            onChange={handleChange}
                            name="motherTongue"
                            value="hindi"
                            label="Hindi"
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            onChange={handleChange}
                            name="motherTongue"
                            value="telugu"
                            label="Telugu"
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            onChange={handleChange}
                            name="motherTongue"
                            value="malayalam"
                            label="Malayalam"
                          />
                        </FormGroup>
                        <br />
                      </div>
                      <div className="tagcloud" style={{ paddingTop: "0px" }}>
                        <h5>Martial Status</h5>
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox />}
                            onChange={handleChange}
                            name="maritalStatus"
                            value="never married"
                            label="Never Married"
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            onChange={handleChange}
                            name="maritalStatus"
                            value="awaiting divorce"
                            label="Awaiting Divorce"
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            onChange={handleChange}
                            name="maritalStatus"
                            value="divorce"
                            label="Divorce"
                          />
                        </FormGroup>
                        <br />
                        <button
                          type="submit"
                          className="btn btn-primary btn-block mb-4"
                          style={{ float: "left" }}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </aside>
              </div>
            </div>

            {/* profile list */}

            <div className="av-column-9 av-sm-column-12">
              <div className="av-container">
                {filteredUsers !== null ? (
                  <div className="av-columns-area">
                    {Array.isArray(filteredUsers) &&
                    filteredUsers.length !== 0 ? (
                      filteredUsers.map((x) => (
                        <div
                          className="av-column-4 av-sm-column-6"
                          key={x.user.profileId}
                        >
                          <div className="team-member">
                            <div className="team-thumb" tabIndex="0">
                              <p
                                style={{
                                  margin: "0px",
                                  padding: "10px",
                                  color: "#252525",
                                }}
                              >
                                {x.user.profileId} |{" "}
                                <span style={{ color: "#00a03a" }}>
                                  {x.user.age}Yrs
                                </span>
                              </p>
                              <Link
                                to={
                                  Token ? "/profiledetails/" + x.id : "/login"
                                }
                              >
                                {x.user.imageVerified === "0" ? (
                                  <img
                                    src={dummy}
                                    alt="team_item"
                                    title=""
                                    style={{
                                      aspectRatio: "1/1",
                                      objectFit: "cover",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={
                                      process.env.REACT_APP_URL + x.user.images
                                    }
                                    alt="team_item"
                                    title=""
                                    style={{
                                      aspectRatio: "1/1",
                                      objectFit: "cover",
                                    }}
                                  />
                                )}
                              </Link>
                              <div
                                className="team-thumber"
                                style={{ cursor: "pointer" }}
                              >
                                <aside className="widget widget_social_widget">
                                  <a
                                    onClick={() =>
                                      dispatch(
                                        AddIntrest({
                                          interestProfileId: x.id,
                                        })
                                      ).then((res) => {
                                        Swal.fire(res.data.data);
                                      })
                                    }
                                  >
                                    <div
                                      className="cards-title"
                                      style={{ color: "#fff" }}
                                    >
                                      Send Interest
                                    </div>
                                  </a>
                                </aside>
                              </div>
                            </div>
                            <div className="team-info">
                              <h5 style={{ textTransform: "capitalize" }}>
                                {x.user.userName.length > 10
                                  ? x.user.userName.substring(0, 10) + "..."
                                  : x.user.userName}
                              </h5>
                              <Link
                                to={
                                  Token
                                    ? "/profiledetails/" + x.userId
                                    : "/login"
                                }
                              >
                                <p>View Full Profile</p>
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "30px",
                          marginTop: "50px",marginLeft:"50px"
                        }}
                      >
                        No Profile Found
                      </div>
                    )}
                  </div>
                ) : null}

                {filteredUsers ? null : (
                  <div className="av-columns-area">
                    {Array.isArray(Allmatches) && Allmatches.length !== 0
                      ? Allmatches.map((x) => (
                          <div
                            className="av-column-4 av-sm-column-6"
                            key={x.profileId}
                            data={x}
                          >
                            <div className="team-member">
                              <div className="team-thumb" tabIndex="0">
                                <p
                                  style={{
                                    margin: "0px",
                                    padding: "10px",
                                    color: "#252525",
                                  }}
                                >
                                  {x.profileId} |{" "}
                                  <span style={{ color: "#00a03a" }}>
                                    {x.age}Yrs
                                  </span>
                                </p>
                                <Link
                                  to={
                                    Token ? "/profiledetails/" + x.id : "/login"
                                  }
                                >
                                  <img
                                    src={process.env.REACT_APP_URL + x.images}
                                    alt="team_item"
                                    title=""
                                    style={{
                                      aspectRatio: "1/1",
                                      objectFit: "cover",
                                    }}
                                  />
                                </Link>
                                {/* <div className="team-view"><a href="#"><i className="fa fa-eye"></i></a></div> */}
                                <div
                                  className="team-thumber"
                                  style={{ cursor: "pointer" }}
                                >
                                  <aside className="widget widget_social_widget">
                                    <a
                                      onClick={() =>
                                        dispatch(
                                          AddIntrest({
                                            interestProfileId: x.id,
                                          })
                                        ).then((res) => {
                                          Swal.fire(res.data.data);
                                        })
                                      }
                                    >
                                      <div
                                        className="cards-title"
                                        style={{ color: "#fff" }}
                                      >
                                        Send Interest
                                      </div>
                                    </a>
                                  </aside>
                                </div>
                              </div>
                              <div className="team-info">
                                <h5 style={{ textTransform: "capitalize" }}>
                                  {x.userName && x.userName.length > 10
                                    ? x.userName.substring(0, 10) + "..."
                                    : x.userName}
                                </h5>
                                <Link
                                  to={
                                    Token ? "/profiledetails/" + x.id : "/login"
                                  }
                                >
                                  <p>View Full Profile</p>
                                </Link>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: Bride
        =================================--> */}
    </div>
  );
};

export default AllProfile;
