import React from "react";

const BlogDetails = () => {
  return (
    <div>
      <div id="content" class="gradiant-content">
        {/* <!--===// Start: Breadcrumb
=================================--> */}
        <section
          id="breadcrumb-section"
          class="breadcrumb-area breadcrumb-left"
        >
          <div class="av-container">
            <div class="av-columns-area">
              <div class="av-column-12">
                <div class="breadcrumb-content">
                  <div class="breadcrumb-heading wow fadeInLeft">
                    <h2>Blog Details</h2>
                  </div>
                  <ol class="breadcrumb-list wow fadeInRight">
                    <li>
                      <a href="/">
                        <i class="fa fa-home"></i> Home Page
                      </a>{" "}
                      &nbsp;-&nbsp;
                    </li>
                    <li class="active">Blog Details</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End: Breadcrumb
=================================--> */}

        {/* <!--===// Start: Blog
=================================--> */}
        <section id="post-section" class="post-section av-py-default">
          <div class="av-container">
            <div class="av-columns-area">
              <div class="av-column-10 mx-auto">
                <div class="heading-default text-center wow fadeInUp">
                  <h3>
                    Our <span class="primary-color">Blogs</span>
                  </h3>
                  <span class="separator">
                    <span>
                      <span></span>
                    </span>
                  </span>
                  <p>
                    Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </p>
                </div>
              </div>
            </div>
            <div class="av-columns-area">
              <div class="av-column-10 mx-auto">
                <article class="post-items">
                  <figure class="post-image post-image-absolute">
                    <div class="featured-image">
                      <a href=";" class="post-hover">
                        <img
                          src="assets/images/blog/blogFullSize/img01.jpg"
                          alt="post_item"
                        />
                      </a>
                    </div>
                  </figure>
                  <div class="post-content">
                    <span class="post-date">
                      {" "}
                      <a href=";">
                        <span>08</span>July 2021
                      </a>{" "}
                    </span>
                    <h5 class="post-title">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit
                    </h5>
                    <div class="post-footer">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </p>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </p>
                      <blockquote>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.
                        </p>
                        <h5>-Robert Karlos</h5>
                      </blockquote>
                      <h4>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit
                      </h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </p>
                      <ul class="av-list-check">
                        <li>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit
                        </li>
                        <li>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit
                        </li>
                        <li>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit
                        </li>
                      </ul>
                      <span class="post-count">01</span>
                    </div>
                  </div>
                </article>
                <article class="author-details">
                  <div class="section-header">
                    <h2>About the Author</h2>
                  </div>
                  <div class="media">
                    <div class="auth-mata">
                      <img
                        alt="avatar"
                        src="assets/images/blog/postImg/avatar-1.png"
                        class="avatar avatar-200 photo"
                      />
                    </div>
                    <div class="media-body author-meta-det">
                      <h4>
                        <a
                          href="https://example.com"
                          title="Visit admin’s website"
                          rel="author external"
                        >
                          John Kanon
                        </a>
                      </h4>
                      <a href=";" class="av-btn av-btn-primary av-btn-bubble">
                        View All Post <i class="fa fa-arrow-right"></i>{" "}
                        <span class="bubble_effect">
                          <span class="circle top-left"></span>{" "}
                          <span class="circle top-left"></span>{" "}
                          <span class="circle top-left"></span>{" "}
                          <span class="button effect-button"></span>{" "}
                          <span class="circle bottom-right"></span>{" "}
                          <span class="circle bottom-right"></span>{" "}
                          <span class="circle bottom-right"></span>
                        </span>
                      </a>
                    </div>
                  </div>
                </article>
                <div id="comments" class="comments-area">
                  <div class="comments-title">
                    <h3>3 Comments</h3>
                  </div>
                  <div class="single-comments-title">
                    <h4>
                      {" "}
                      One thought on &ldquo;
                      <span>
                        Complete solutions for global international
                        organizations
                      </span>
                      &rdquo;
                    </h4>
                  </div>
                  <ol class="comment-list">
                    <li
                      id="comment-11"
                      class="comment even thread-even depth-1 parent"
                    >
                      <article id="div-comment-11" class="comment-body">
                        <footer class="comment-meta">
                          <div class="comment-author vcard">
                            {" "}
                            <img
                              alt="avatar"
                              src="assets/images/blog/postImg/avatar-1.png"
                              class="avatar avatar-32 photo"
                              height="32"
                              width="32"
                              loading="lazy"
                            />{" "}
                            <b class="fn">
                              <a
                                href=";"
                                rel="external nofollow ugc"
                                class="url"
                              >
                                Bill Gates
                              </a>
                            </b>{" "}
                            <span class="says">says:</span>
                          </div>
                          <div class="comment-metadata">
                            {" "}
                            <a href=";">
                              <time dateTime="2021-06-17T07:20:57+00:00">
                                June 17, 2021 at 7:20 am
                              </time>
                            </a>
                          </div>
                          <em class="comment-awaiting-moderation">
                            Your comment is awaiting moderation.
                          </em>
                        </footer>
                        <div class="comment-content">
                          <p>lorem ipsum is simply dummy text here.</p>
                        </div>
                        <div class="reply">
                          <a
                            rel="nofollow"
                            class="comment-reply-link"
                            href=";"
                            data-commentid="11"
                            data-postid="140"
                            data-belowelement="div-comment-11"
                            data-respondelement="respond"
                            data-replyto="Reply to Bill Gates"
                            aria-label="Reply to Bill Gates"
                          >
                            Reply
                          </a>
                        </div>
                      </article>
                      <ol class="children">
                        <li
                          id="comment-12"
                          class="comment odd alt depth-2 parent"
                        >
                          <article id="div-comment-12" class="comment-body">
                            <footer class="comment-meta">
                              <div class="comment-author vcard">
                                {" "}
                                <img
                                  alt="avatar"
                                  src="assets/images/blog/postImg/avatar-1.png"
                                  class="avatar avatar-32 photo"
                                  height="32"
                                  width="32"
                                  loading="lazy"
                                />{" "}
                                <b class="fn">
                                  <a
                                    href=";"
                                    rel="external nofollow ugc"
                                    class="url"
                                  >
                                    Bill Gates
                                  </a>
                                </b>{" "}
                                <span class="says">says:</span>
                              </div>
                              <div class="comment-metadata">
                                {" "}
                                <a href=";">
                                  <time dateTime="2021-06-17T07:34:08+00:00">
                                    June 17, 2021 at 7:34 am
                                  </time>
                                </a>
                              </div>
                              <em class="comment-awaiting-moderation">
                                Your comment is awaiting moderation.
                              </em>
                            </footer>
                            <div class="comment-content">
                              <p>lorem ipsum is simply dummy text here.</p>
                            </div>
                            <div class="reply">
                              <a
                                rel="nofollow"
                                class="comment-reply-link"
                                href=";"
                                data-commentid="12"
                                data-postid="140"
                                data-belowelement="div-comment-12"
                                data-respondelement="respond"
                                data-replyto="Reply to Bill Gates"
                                aria-label="Reply to Bill Gates"
                              >
                                Reply
                              </a>
                            </div>
                          </article>
                          <ol class="children">
                            <li id="comment-13" class="comment even depth-3">
                              <article id="div-comment-13" class="comment-body">
                                <footer class="comment-meta">
                                  <div class="comment-author vcard">
                                    {" "}
                                    <img
                                      alt="avatar"
                                      src="assets/images/blog/postImg/avatar-1.png"
                                      class="avatar avatar-32 photo"
                                      height="32"
                                      width="32"
                                      loading="lazy"
                                    />{" "}
                                    <b class="fn">
                                      <a
                                        href=";"
                                        rel="external nofollow ugc"
                                        class="url"
                                      >
                                        Bill Gates
                                      </a>
                                    </b>{" "}
                                    <span class="says">says:</span>
                                  </div>
                                  <div class="comment-metadata">
                                    {" "}
                                    <a href=";">
                                      <time dateTime="2021-06-17T07:34:53+00:00">
                                        June 17, 2021 at 7:34 am
                                      </time>
                                    </a>
                                  </div>
                                  <em class="comment-awaiting-moderation">
                                    Your comment is awaiting moderation.
                                  </em>
                                </footer>
                                <div class="comment-content">
                                  <p>lorem ipsum is simply dummy text here.</p>
                                </div>
                                <div class="reply">
                                  <a
                                    rel="nofollow"
                                    class="comment-reply-link"
                                    href=";"
                                    data-commentid="13"
                                    data-postid="140"
                                    data-belowelement="div-comment-13"
                                    data-respondelement="respond"
                                    data-replyto="Reply to Bill Gates"
                                    aria-label="Reply to Bill Gates"
                                  >
                                    Reply
                                  </a>
                                </div>
                              </article>
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                  </ol>
                  <div id="respond" class="comment-respond">
                    <h3 id="reply-title" class="comment-reply-title">
                      Leave a Reply{" "}
                      <small>
                        <a
                          rel="nofollow"
                          id="cancel-comment-reply-link"
                          href=";"
                          style={{ display: "none" }}
                        >
                          Cancel reply
                        </a>
                      </small>
                    </h3>
                    <form
                      action="#"
                      method="post"
                      id="commentform"
                      class="comment-form"
                      novalidate
                    >
                      <p class="comment-notes">
                        <span id="email-notes">
                          Your email address will not be published.
                        </span>{" "}
                        Required fields are marked{" "}
                        <span class="required">*</span>
                      </p>
                      <p class="comment-form-author">
                        <input
                          id="author"
                          name="author"
                          type="text"
                          value=""
                          size="30"
                          maxlength="245"
                          required="required"
                          placeholder="Your Name"
                        />
                      </p>
                      <p class="comment-form-email">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          value=""
                          size="30"
                          maxlength="100"
                          aria-describedby="email-notes"
                          required="required"
                          placeholder="Your Email"
                        />
                      </p>
                      <p class="comment-form-subject">
                        <input
                          id="subject"
                          name="subject"
                          type="text"
                          value=""
                          size="30"
                          maxlength="200"
                          placeholder="Your Subject"
                        />
                      </p>
                      <p class="comment-form-comment">
                        <textarea
                          name="comment"
                          id="comment"
                          cols="45"
                          rows="8"
                          class="comment-textarea"
                          placeholder="Write A comment&hellip;"
                          required="required"
                        ></textarea>
                      </p>
                      <p class="comment-form-cookies-consent">
                        <input
                          id="wp-comment-cookies-consent"
                          name="wp-comment-cookies-consent"
                          type="checkbox"
                          value="yes"
                          checked="checked"
                        />{" "}
                        <label for="wp-comment-cookies-consent">
                          Save my name, email, and website in this browser for
                          the next time I comment.
                        </label>
                      </p>
                      <p class="form-submit">
                        <input
                          name="submit"
                          type="submit"
                          id="comment-submit"
                          class="xl-btn"
                          value="Post Comment"
                        />{" "}
                        <input
                          type="hidden"
                          name="comment_post_ID"
                          value="140"
                          id="comment_post_ID"
                        />{" "}
                        <input
                          type="hidden"
                          name="comment_parent"
                          id="comment_parent"
                          value="0"
                        />
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End: Blog
=================================--> */}
      </div>
    </div>
  );
};

export default BlogDetails;
