import React from 'react'

const Faq = () => {
  return (
    <div>
               {/* <!--===// Start: FAQ
        =================================--> */}
        <section id="faq-section" class="faq-section av-py-default" style={{backgroundColor:"#fff"}}>
            <div class="av-container">
                <div class="av-columns-area">
                    <div class="av-column-12">
                        <div class="heading-default text-center wow fadeInUp">
                            <h3>Frequently Asked <span class="primary-color">Question?</span></h3>
                            <span class="separator"><span><span></span></span></span>
                            <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        </div>
                    </div>
                </div>
                <div class="av-columns-area">
                    <div class="av-column-6">
                        {/* <div class="av-tab-filter filters-faq">
                            <a href=";" class="av-btn av-btn-primary av-btn-bubble active" data-filter="*">All <span class="bubble_effect"><span class="circle top-left"></span> <span class="circle top-left"></span> <span class="circle top-left"></span> <span class="button effect-button"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span></span></a>
                            <a href=";" class="av-btn av-btn-primary av-btn-bubble" data-filter=".technical">Technical <span class="bubble_effect"><span class="circle top-left"></span> <span class="circle top-left"></span> <span class="circle top-left"></span> <span class="button effect-button"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span></span></a>
                            <a href=";" class="av-btn av-btn-primary av-btn-bubble" data-filter=".general">General <span class="bubble_effect"><span class="circle top-left"></span> <span class="circle top-left"></span> <span class="circle top-left"></span> <span class="button effect-button"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span></span></a>
                            <a href=";" class="av-btn av-btn-primary av-btn-bubble" data-filter=".pre-sales">Pre-Sales <span class="bubble_effect"><span class="circle top-left"></span> <span class="circle top-left"></span> <span class="circle top-left"></span> <span class="button effect-button"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span></span></a>
                            <a href=";" class="av-btn av-btn-primary av-btn-bubble" data-filter=".common">Common <span class="bubble_effect"><span class="circle top-left"></span> <span class="circle top-left"></span> <span class="circle top-left"></span> <span class="button effect-button"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span></span></a>
                            <a href=";" class="av-btn av-btn-primary av-btn-bubble" data-filter=".affiliates">Affiliates <span class="bubble_effect"><span class="circle top-left"></span> <span class="circle top-left"></span> <span class="circle top-left"></span> <span class="button effect-button"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span> <span class="circle bottom-right"></span></span></a>
                        </div> */}
                        <div class="av-columns-area faq-type">                  
                            <div class="av-column-6 faq-item technical">
                                <div class="accordion accordion-default">
                                    <div class="accordion-title"><button type="button" class="accordion-button"><span>Filter</span> <i class="fa fa-plus"></i></button></div>
                                    <div class="accordion-content" style={{paddingTop:"15px"}}>
                                    <form action="/action_page.php">
                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Self"/>
                                        <label for="vehicle1">Self</label><br/>
                                        <input type="checkbox" id="vehicle3" name="vehicle3" value="Parents"/>
                                        <label for="vehicle3">Parents</label><br/>
                                        <input type="checkbox" id="vehicle3" name="vehicle3" value="Siblings"/>
                                        <label for="vehicle3">Siblings</label><br/>
                                        <input type="checkbox" id="vehicle3" name="vehicle3" value="Relative"/>
                                        <label for="vehicle3">Relative</label><br/><br/>
                                        <input type="submit" value="Submit"/>
                                    </form>
                                </div>
                            </div>
                        </div>
                            <div class="av-column-6 faq-item general">
                                <div class="accordion accordion-default">
                                    <div class="accordion-title"><button type="button" class="accordion-button"><span>How do I conduct keyword research?</span> <i class="fa fa-plus"></i></button></div>
                                    <div class="accordion-content">It is a long established fact that a reader will distracted by the readable content of a page when.</div>
                                </div>
                            </div>
                            <div class="av-column-6 faq-item pre-sales">
                                <div class="accordion accordion-default">
                                    <div class="accordion-title"><button type="button" class="accordion-button"><span>Filter</span> <i class="fa fa-plus"></i></button></div>
                                    <div class="accordion-content">
                                    <form action="/action_page.php">
                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Self"/>
                                        <label for="vehicle1">Self</label><br/>
                                        <input type="checkbox" id="vehicle3" name="vehicle3" value="Parents"/>
                                        <label for="vehicle3">Parents</label><br/>
                                        <input type="checkbox" id="vehicle3" name="vehicle3" value="Siblings"/>
                                        <label for="vehicle3">Siblings</label><br/>
                                        <input type="checkbox" id="vehicle3" name="vehicle3" value="Relative"/>
                                        <label for="vehicle3">Relative</label><br/><br/>
                                        <input type="submit" value="Submit"/>
                                    </form>
                                 </div>
                                </div>
                            </div>
                            <div class="av-column-6 faq-item common">
                                <div class="accordion accordion-default">
                                    <div class="accordion-title"><button type="button" class="accordion-button"><span>Which SEO techniques are popular?</span> <i class="fa fa-plus"></i></button></div>
                                    <div class="accordion-content">It is a long established fact that a reader will distracted by the readable content of a page when.</div>
                                </div>
                            </div>
                            <div class="av-column-6 faq-item affiliates">
                                <div class="accordion accordion-default">
                                    <div class="accordion-title"><button type="button" class="accordion-button"><span>Filter</span> <i class="fa fa-plus"></i></button></div>
                                    <div class="accordion-content">
                                    <form action="/action_page.php">
                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Self"/>
                                        <label for="vehicle1">Self</label><br/>
                                        <input type="checkbox" id="vehicle3" name="vehicle3" value="Parents"/>
                                        <label for="vehicle3">Parents</label><br/>
                                        <input type="checkbox" id="vehicle3" name="vehicle3" value="Siblings"/>
                                        <label for="vehicle3">Siblings</label><br/>
                                        <input type="checkbox" id="vehicle3" name="vehicle3" value="Relative"/>
                                        <label for="vehicle3">Relative</label><br/><br/>
                                        <input type="submit" value="Submit"/>
                                    </form>
                                    </div>
                                </div>
                            </div>
                            <div class="av-column-6 faq-item common">
                                <div class="accordion accordion-default">
                                    <div class="accordion-title"><button type="button" class="accordion-button"><span>Which SEO techniques are popular?</span> <i class="fa fa-plus"></i></button></div>
                                    <div class="accordion-content">It is a long established fact that a reader will distracted by the readable content of a page when.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- End: FAQ
        =================================--> */}
    </div>
  )
}

export default Faq