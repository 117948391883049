import React from "react";

const About = () => {
  return (
    <div>
      {/* <!--===// Start: Breadcrumb
        =================================--> */}
      <section id="breadcrumb-section" class="breadcrumb-area breadcrumb-left">
        <div class="av-container">
          <div class="av-columns-area">
            <div class="av-column-12">
              <div class="breadcrumb-content">
                <div class="breadcrumb-heading wow fadeInLeft">
                  <h2>About</h2>
                </div>
                <ol class="breadcrumb-list wow fadeInRight">
                  <li>
                    <a href="/">
                      <i class="fa fa-home"></i> Home Page
                    </a>{" "}
                    &nbsp;-&nbsp;
                  </li>
                  <li class="active">About</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: Breadcrumb
        =================================--> */}

      {/* <!--===// Start: About Section
        =================================--> */}
      <section
        id="about-section"
        class="about-section av-py-default shapes-section"
        style={{ backgroundColor: "#fff" }}
      >
        <div class="av-container">
          <div class="av-columns-area">
            <div class="av-column-6 mb-4 mb-av-0">
              <div class="about-content">
                <div class="shapedotted">
                  <img src="assets/images/about/dotted_shape.png" alt="image" />
                </div>
                <img src="assets/images/about/about_1st.png" alt="about_item" />
                <div class="about-content-summery">
                  <div class="about-summery">
                    Call Us: +9198942 34066<i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="av-column-6">
              <div class="about-panel">
                <div class="heading-title">
                  {/* <h6>We Are Consultio</h6> */}
                  <h3>We Provide Trusted Matrimonial & Matchmaking Services</h3>
                </div>
                <p>
                  Welcome to vvkmatrimony12.com, the leading online platform for
                  matchmaking and marriage services. Our mission is to help our
                  members find their life partners with ease and convenience,
                  using our advanced technology and personalized services. At
                  vvkmatrimony.com, we understand that finding a life partner is
                  one of the most important and personal decisions of one's
                  life. That's why we provide a safe, secure, and user-friendly
                  online platform for our members to connect, interact, and
                  explore potential matches from all over the world. Our
                  platform is designed to offer a seamless and hassle-free
                  experience, with features like advanced search, chat, and
                  privacy settings, to make the matchmaking process more
                  efficient and effective. Our team comprises of experienced
                  professionals from the matrimonial and technology sectors, who
                  are dedicated to delivering the best possible services to our
                  members. We constantly strive to improve and innovate our
                  services to meet the evolving needs of our members and provide
                  the best possible user experience. We take pride in our
                  commitment to data privacy and security, and we ensure that
                  our platform is fully compliant with all relevant regulations
                  and best practices. Our members can be assured that their
                  personal information and communications are kept confidential
                  and secure. Thank you for choosing vvkmatrimony.com as your
                  partner in the search for a life partner. We are committed to
                  helping you find your soulmate and building a lifetime of
                  happiness and love.
                </p>
                <div class="about-wrapper">
                  <div class="skills-wrapper">
                    <div class="skill-panel">
                      <div class="skill-heading">Customer Support</div>
                      <div class="skillbar" data-percent="90%">
                        <div class="skill-bar-percent">
                          <span class="count -bar">90</span>%
                        </div>
                        <div class="skillbar-bar"></div>
                      </div>
                    </div>
                  </div>
                  <blockquote class="av-blockquote">
                    <h5>
                      We thank you for visiting us and we wish you good luck in
                      finding your life partner!
                    </h5>
                  </blockquote>
                  {/* <div class="about-footer">
                                    <aside class="widget widget-contact">
                                        <div class="contact-area">
                                            <div class="contact-icon">
                                                <img src="assets/images/about/about_author.png" alt="about_author"/>
                                            </div>
                                            <div class="contact-info">
                                                <h6 class="title">Dainel Primera</h6>
                                                <p class="text">Founder & CEO</p>
                                            </div>
                                        </div>
                                    </aside>
                                    <div class="about-sign"><img src="assets/images/about/singnature.png" alt="singnature"/></div>
                                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: About Section
        =================================--> */}

      {/* <!--===// Start: Timeline Section
        =================================--> */}
      <section
        id="timeline-section"
        class="timeline-section av-py-default shapes-section"
        style={{ backgroundColor: "#fff", paddingBottom: "0px" }}
      >
        <div class="av-container">
          <div class="av-columns-area">
            <div class="av-column-12">
              <div class="heading-default text-center wow fadeInUp">
                <h3>
                  Story Of <span class="primary-color">Journey</span>
                </h3>
                <span class="separator">
                  <span>
                    <span></span>
                  </span>
                </span>
                <p>
                  Apart from Arya Vysya matrimonial services, we aim at creating
                  an in-depth portal for other services, as well. This will
                  include:-
                </p>
              </div>
            </div>
          </div>
          <div class="av-columns-area">
            <div class="av-column-12 timeline">
              <div class="av-columns-area timeline-item">
                <div class="av-xs-column-10 av-md-column-5 order-3 order-md-1 timeline-content wow fadeInRight">
                  {" "}
                  <strong>A Business History</strong>
                  <p>
                    Commenced in the year 2005, Vysyamala was offering its
                    matrimonial services in the name of Vysyamatrimony.in. Later
                    in the year 2008, we revamped our website and renamed it to
                    Vysyamala with an extended array of Arya Vysya matrimonial
                    services.
                  </p>
                </div>
                <div class="av-xs-column-2 av-sm-column-1 px-md-3 order-2 timeline-circle text-md-center">
                  <span class="timeline-shaped">
                    <span class="timeline-shape"></span>
                  </span>
                </div>
                <div class="av-xs-column-10 av-md-column-5 order-1 order-md-3 pb-3 timeline-date">
                  <time>2017</time>
                </div>
              </div>
              <div class="av-columns-area timeline-item">
                <div class="av-xs-column-10 av-md-column-5 order-3 order-md-1 timeline-content wow fadeInLeft">
                  {" "}
                  <strong>Something Big Project</strong>
                  <p>
                    Our official facebook page is aimed at collating various
                    events happening in our community. Visit and like our page
                    to get continuous updates. Vysyamala on facebook
                  </p>
                </div>
                <div class="av-xs-column-2 av-sm-column-1 px-md-3 order-2 timeline-circle text-md-center">
                  <span class="timeline-shaped">
                    <span class="timeline-shape"></span>
                  </span>
                </div>
                <div class="av-xs-column-10 av-md-column-5 order-1 order-md-3 pb-3 timeline-date">
                  <time>2018</time>
                </div>
              </div>
              <div class="av-columns-area timeline-item">
                <div class="av-xs-column-10 av-md-column-5 order-3 order-md-1 timeline-content wow fadeInRight">
                  {" "}
                  <strong>Infrastucture Design</strong>
                  <p>
                    Write to us if you know: You can also share information
                    related to Arya Vysya poojas that are scheduled to happen in
                    your city to vvkmatrimony12@gmail.com
                  </p>
                </div>
                <div class="av-xs-column-2 av-sm-column-1 px-md-3 order-2 timeline-circle text-md-center">
                  <span class="timeline-shaped">
                    <span class="timeline-shape"></span>
                  </span>
                </div>
                <div class="av-xs-column-10 av-md-column-5 order-1 order-md-3 pb-3 timeline-date">
                  <time>2019</time>
                </div>
              </div>
              <div class="av-columns-area timeline-item">
                <div class="av-xs-column-10 av-md-column-5 order-3 order-md-1 timeline-content wow fadeInLeft">
                  {" "}
                  <strong>Award Winning Creation</strong>
                  <p>
                    We just don't stop with matching you with your partner; we
                    also treasure the cultural heritage of Arya Vysyas. You can
                    know the information related to the origin, Gothras,
                    traditions about Arya Vysyas. Also, you can discover special
                    recipes on our site!.
                  </p>
                </div>
                <div class="av-xs-column-2 av-sm-column-1 px-md-3 order-2 timeline-circle text-md-center">
                  <span class="timeline-shaped">
                    <span class="timeline-shape"></span>
                  </span>
                </div>
                <div class="av-xs-column-10 av-md-column-5 order-1 order-md-3 pb-3 timeline-date">
                  <time>2020</time>
                </div>
              </div>
              <div class="av-columns-area timeline-item">
                <div class="av-xs-column-10 av-md-column-5 order-3 order-md-1 timeline-content wow fadeInRight">
                  {" "}
                  <strong>Client Projects Done</strong>
                  <p>
                    Profiles of the brides and grooms published on this website
                    are verified thru a phone call. We understand your privacy
                    requirements and we assure safety for your contact details
                    to avoid unnecessary communication.
                  </p>
                </div>
                <div class="av-xs-column-2 av-sm-column-1 px-md-3 order-2 timeline-circle text-md-center">
                  <span class="timeline-shaped">
                    <span class="timeline-shape"></span>
                  </span>
                </div>
                <div class="av-xs-column-10 av-md-column-5 order-1 order-md-3 pb-3 timeline-date">
                  <time>2021</time>
                </div>
              </div>
              <div class="av-columns-area timeline-item">
                <div class="av-xs-column-10 av-md-column-5 order-3 order-md-1 timeline-content wow fadeInLeft">
                  {" "}
                  <strong>Develop Other Projects</strong>
                  <p>
                    Vysyamala.com is an online matrimonial service exclusively
                    for Arya Vysya Community. Vysyamala aims to serve as a
                    one-stop platform for prospective Arya Vysya Brides and
                    Grooms to meet and communicate with each other. The site is
                    built with careful attention to the needs and sensibilities
                    of people of Arya Vysyas.
                  </p>
                </div>
                <div class="av-xs-column-2 av-sm-column-1 px-md-3 order-2 timeline-circle text-md-center">
                  <span class="timeline-shaped">
                    <span class="timeline-shape"></span>
                  </span>
                </div>
                <div class="av-xs-column-10 av-md-column-5 order-1 order-md-3 pb-3 timeline-date">
                  <time>2022</time>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div class="shapetimeline3"><img src="assets/images/timeline/timeline3.png" alt="image"/></div> */}
      </section>
      {/* <!-- End: Timeline Section
        =================================--> */}

      {/* <!--===// Start: Team
        =================================--> */}
      <section
        id="team-section"
        class="team-section av-py-default team-home shapes-section"
        style={{ backgroundColor: "#fff" }}
      >
        <div class="av-container">
          <div class="av-columns-area">
            <div class="av-column-12">
              <div class="heading-default text-center wow fadeInUp">
                <h3>
                  Our <span class="primary-color">Team</span>
                </h3>
                <span class="separator">
                  <span>
                    <span></span>
                  </span>
                </span>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p>
              </div>
            </div>
          </div>
          <div class="av-columns-area">
            <div class="av-column-3 av-sm-column-6">
              <div class="team-member">
                <div class="team-thumb" tabIndex="0">
                  <img
                    src="assets/images/team/team01.jpg"
                    alt="team_item"
                    title=""
                  />
                  <div class="team-view">
                    <a href="#">
                      <i class="fa fa-eye"></i>
                    </a>
                  </div>
                  <div class="team-thumber">
                    <aside class="widget widget_social_widget">
                      <ul>
                        <li>
                          <a href="#">
                            <i class="fa fa-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i class="fa fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i class="fa fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i class="fa fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </aside>
                  </div>
                </div>
                <div class="team-info">
                  <h5>
                    <a href=";">Steven Lucy</a>
                  </h5>
                  <p>Executive</p>
                </div>
              </div>
            </div>
            <div class="av-column-3 av-sm-column-6">
              <div class="team-member">
                <div class="team-thumb" tabIndex="0">
                  <img
                    src="assets/images/team/team02.jpg"
                    alt="team_item"
                    title=""
                  />
                  <div class="team-view">
                    <a href="#">
                      <i class="fa fa-eye"></i>
                    </a>
                  </div>
                  <div class="team-thumber">
                    <aside class="widget widget_social_widget">
                      <ul>
                        <li>
                          <a href="#">
                            <i class="fa fa-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i class="fa fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i class="fa fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i class="fa fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </aside>
                  </div>
                </div>
                <div class="team-info">
                  <h5>
                    <a href=";">Glenn Maxwell</a>
                  </h5>
                  <p>Project Manager</p>
                </div>
              </div>
            </div>
            <div class="av-column-3 av-sm-column-6">
              <div class="team-member">
                <div class="team-thumb" tabIndex="0">
                  <img
                    src="assets/images/team/team03.jpg"
                    alt="team_item"
                    title=""
                  />
                  <div class="team-view">
                    <a href="#">
                      <i class="fa fa-eye"></i>
                    </a>
                  </div>
                  <div class="team-thumber">
                    <aside class="widget widget_social_widget">
                      <ul>
                        <li>
                          <a href="#">
                            <i class="fa fa-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i class="fa fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i class="fa fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i class="fa fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </aside>
                  </div>
                </div>
                <div class="team-info">
                  <h5>
                    <a href=";">Aoron Finch</a>
                  </h5>
                  <p>Manager and director</p>
                </div>
              </div>
            </div>
            <div class="av-column-3 av-sm-column-6">
              <div class="team-member">
                <div class="team-thumb" tabIndex="0">
                  <img
                    src="assets/images/team/team04.jpg"
                    alt="team_item"
                    title=""
                  />
                  <div class="team-view">
                    <a href="#">
                      <i class="fa fa-eye"></i>
                    </a>
                  </div>
                  <div class="team-thumber">
                    <aside class="widget widget_social_widget">
                      <ul>
                        <li>
                          <a href="#">
                            <i class="fa fa-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i class="fa fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i class="fa fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i class="fa fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </aside>
                  </div>
                </div>
                <div class="team-info">
                  <h5>
                    <a href=";">Christiana Ena</a>
                  </h5>
                  <p>Executive Officer</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: Team
        =================================--> */}

      {/* <!--===// Start: Pricing
        =================================--> */}
      <section
        id="pricing-section"
        class="pricing-section pricing-two av-py-default shapes-section"
        style={{ backgroundColor: "#fff", paddingTop: "0" }}
      >
        <div class="av-container">
          <div class="av-columns-area">
            <div class="av-column-12">
              <div class="heading-default text-center wow fadeInUp">
                <h3>
                  Our <span class="primary-color">Pricing</span>
                </h3>
                <span class="separator">
                  <span>
                    <span></span>
                  </span>
                </span>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
            </div>
          </div>
          <div class="av-filter-wrapper-2">
            <div class="av-columns-area wow fadeInUp">
              <div class="av-column-12">
                <div class="av-tab-filter filters-theme">
                  <a
                    data-filter="*"
                    class="av-btn av-btn-primary av-btn-bubble active"
                  >
                    All{" "}
                    <span class="bubble_effect">
                      <span class="circle top-left"></span>{" "}
                      <span class="circle top-left"></span>{" "}
                      <span class="circle top-left"></span>{" "}
                      <span class="button effect-button"></span>{" "}
                      <span class="circle bottom-right"></span>{" "}
                      <span class="circle bottom-right"></span>{" "}
                      <span class="circle bottom-right"></span>
                    </span>
                  </a>
                  <a
                    data-filter=".monthly"
                    class="av-btn av-btn-primary av-btn-bubble"
                  >
                    Monthly{" "}
                    <span class="bubble_effect">
                      <span class="circle top-left"></span>{" "}
                      <span class="circle top-left"></span>{" "}
                      <span class="circle top-left"></span>{" "}
                      <span class="button effect-button"></span>{" "}
                      <span class="circle bottom-right"></span>{" "}
                      <span class="circle bottom-right"></span>{" "}
                      <span class="circle bottom-right"></span>
                    </span>
                  </a>
                  <a
                    data-filter=".yearly"
                    class="av-btn av-btn-primary av-btn-bubble"
                  >
                    Yearly{" "}
                    <span class="bubble_effect">
                      <span class="circle top-left"></span>{" "}
                      <span class="circle top-left"></span>{" "}
                      <span class="circle top-left"></span>{" "}
                      <span class="button effect-button"></span>{" "}
                      <span class="circle bottom-right"></span>{" "}
                      <span class="circle bottom-right"></span>{" "}
                      <span class="circle bottom-right"></span>
                    </span>
                  </a>
                  <a
                    data-filter=".lifetime"
                    class="av-btn av-btn-primary av-btn-bubble"
                  >
                    Lifetime{" "}
                    <span class="bubble_effect">
                      <span class="circle top-left"></span>{" "}
                      <span class="circle top-left"></span>{" "}
                      <span class="circle top-left"></span>{" "}
                      <span class="button effect-button"></span>{" "}
                      <span class="circle bottom-right"></span>{" "}
                      <span class="circle bottom-right"></span>{" "}
                      <span class="circle bottom-right"></span>
                    </span>
                  </a>
                </div>
                <div
                  id="av-filter-init-2"
                  class="av-columns-area av-filter-init pricing-row"
                >
                  <div class="av-column-4 av-sm-column-6 av-filter-item all monthly">
                    <div class="pricing-item">
                      <div class="pricing-heading">
                        <h5>Starter Plan</h5>
                      </div>
                      <div class="pricing-rate">
                        <p class="pricing-name">Monthly Package</p>
                        <span class="pricing">$69.00</span>
                      </div>
                      <div class="pricing-payment">
                        <p>Lorem Ipsum dolor sit amet</p>
                        <ul>
                          <li>
                            <a href=";">
                              <i class="fa fa-cc-visa"></i>
                            </a>
                          </li>
                          <li>
                            <a href=";">
                              <i class="fa fa-cc-stripe"></i>
                            </a>
                          </li>
                          <li>
                            <a href=";">
                              <i class="fa fa-cc-paypal"></i>
                            </a>
                          </li>
                          <li>
                            <a href=";">
                              <i class="fa fa-cc-mastercard"></i>
                            </a>
                          </li>
                          <li>
                            <a href=";">
                              <i class="fa fa-cc-discover"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="pricing-list">
                        <ul>
                          <li>1300 Keywords</li>
                          <li>25 Analytics Compaign</li>
                          <li>1 Free Optimization</li>
                          <li>24/7 Support</li>
                        </ul>
                      </div>
                      <div class="pricing-btn">
                        <a
                          href="#"
                          class="av-btn av-btn-secondary av-btn-bubble"
                        >
                          Choose Plan <i class="fa fa-arrow-right"></i>{" "}
                          <span class="bubble_effect">
                            <span class="circle top-left"></span>{" "}
                            <span class="circle top-left"></span>{" "}
                            <span class="circle top-left"></span>{" "}
                            <span class="button effect-button"></span>{" "}
                            <span class="circle bottom-right"></span>{" "}
                            <span class="circle bottom-right"></span>{" "}
                            <span class="circle bottom-right"></span>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="av-column-4 av-sm-column-6 av-filter-item all yearly">
                    <div class="pricing-item recommended">
                      <div class="pricing-heading">
                        <h5>Business Plan</h5>
                      </div>
                      <div class="pricing-rate">
                        <p class="pricing-name">Monthly Package</p>
                        <span class="pricing">$89.75</span>
                      </div>
                      <div class="pricing-payment">
                        <p>Lorem Ipsum dolor sit amet</p>
                        <ul>
                          <li>
                            <a href=";">
                              <i class="fa fa-cc-visa"></i>
                            </a>
                          </li>
                          <li>
                            <a href=";">
                              <i class="fa fa-cc-stripe"></i>
                            </a>
                          </li>
                          <li>
                            <a href=";">
                              <i class="fa fa-cc-paypal"></i>
                            </a>
                          </li>
                          <li>
                            <a href=";">
                              <i class="fa fa-cc-mastercard"></i>
                            </a>
                          </li>
                          <li>
                            <a href=";">
                              <i class="fa fa-cc-discover"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="pricing-list">
                        <ul>
                          <li>1300 Keywords</li>
                          <li>25 Analytics Compaign</li>
                          <li>1 Free Optimization</li>
                          <li>24/7 Support</li>
                        </ul>
                      </div>
                      <div class="pricing-btn">
                        <a href="#" class="av-btn av-btn-primary av-btn-bubble">
                          Choose Plan <i class="fa fa-arrow-right"></i>{" "}
                          <span class="bubble_effect">
                            <span class="circle top-left"></span>{" "}
                            <span class="circle top-left"></span>{" "}
                            <span class="circle top-left"></span>{" "}
                            <span class="button effect-button"></span>{" "}
                            <span class="circle bottom-right"></span>{" "}
                            <span class="circle bottom-right"></span>{" "}
                            <span class="circle bottom-right"></span>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="av-column-4 av-sm-column-6 av-filter-item all lifetime">
                    <div class="pricing-item">
                      <div class="pricing-heading">
                        <h5>Premium Plan</h5>
                      </div>
                      <div class="pricing-rate">
                        <p class="pricing-name">Monthly Package</p>
                        <span class="pricing">$99.00</span>
                      </div>
                      <div class="pricing-payment">
                        <p>Lorem Ipsum dolor sit amet</p>
                        <ul>
                          <li>
                            <a href=";">
                              <i class="fa fa-cc-visa"></i>
                            </a>
                          </li>
                          <li>
                            <a href=";">
                              <i class="fa fa-cc-stripe"></i>
                            </a>
                          </li>
                          <li>
                            <a href=";">
                              <i class="fa fa-cc-paypal"></i>
                            </a>
                          </li>
                          <li>
                            <a href=";">
                              <i class="fa fa-cc-mastercard"></i>
                            </a>
                          </li>
                          <li>
                            <a href=";">
                              <i class="fa fa-cc-discover"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="pricing-list">
                        <ul>
                          <li>1300 Keywords</li>
                          <li>25 Analytics Compaign</li>
                          <li>1 Free Optimization</li>
                          <li>24/7 Support</li>
                        </ul>
                      </div>
                      <div class="pricing-btn">
                        <a
                          href="#"
                          class="av-btn av-btn-secondary av-btn-bubble"
                        >
                          Choose Plan <i class="fa fa-arrow-right"></i>{" "}
                          <span class="bubble_effect">
                            <span class="circle top-left"></span>{" "}
                            <span class="circle top-left"></span>{" "}
                            <span class="circle top-left"></span>{" "}
                            <span class="button effect-button"></span>{" "}
                            <span class="circle bottom-right"></span>{" "}
                            <span class="circle bottom-right"></span>{" "}
                            <span class="circle bottom-right"></span>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: Pricing
        =================================--> */}
    </div>
  );
};

export default About;
