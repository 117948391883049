import React from "react";

const ContactInfo = () => {
  return (
    <div>
      {/* <!--===// Start: user meta
        =================================--> */}
      {/* {Token ? (
        <section
          id="funfact-section"
          className="funfact-section av-py-default funfact-home shapes-section"
          data-roller="start:0.5"
        >
          <div className="av-container">
            <div className="av-columns-area">
              <div className="av-column-12">
                <div className="heading-default heading-white text-left wow fadeInUp">
                  <h3>
                    Your Journey <span className="primary-color">So Far</span>
                  </h3>
                </div>
                <div className="av-columns-area funfact-wrapper">
                  <div className="av-column-4 av-sm-column-6">
                    <div className="funfact-item">
                      <i className="fa fa-umbrella"></i>
                      <h3>
                        <span className="counter">{like}</span> +
                      </h3>
                      <h5>Profile Likes</h5>
                    </div>
                  </div>
                  <div className="av-column-4 av-sm-column-6">
                    <div className="funfact-item">
                      <i className="fa fa-hand-peace-o"></i>
                      <h3>
                        <span className="counter">{visit}</span> +
                      </h3>
                      <h5>Profile Visits</h5>
                    </div>
                  </div>
                  <div className="av-column-4 av-sm-column-6">
                    <div className="funfact-item">
                      <i className="fa fa-smile-o"></i>
                      <h3>
                        <span className="counter">{intrest}</span> +
                      </h3>
                      <h5>Interest Received</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="shape17 parallax-icon icon-one">
            <img src="assets/images/funfact/clipArt/shape17.png" alt="image" />
          </div>
          <div className="shape19 parallax-icon icon-two">
            <img src="assets/images/funfact/clipArt/shape19.png" alt="image" />
          </div>
        </section>
      ) : (
        false
      )} */}
      {/* <!-- End: user meta
        =================================--> */}

      {/* <ImageList sx={{ width: 500, height: 450 }}>
        <ImageListItem key="Subheader" cols={2}>
        </ImageListItem>
        {Array.isArray(ads) &&
          ads.map((item) => (
            <ImageListItem key={item.image}>
              <img
                src={process.env.REACT_APP_URL + item.image}
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={item.name}
                subtitle={item.contactPerson}
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                    aria-label={`info about ${item.title}`}
                  >
                    <MdPhone />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
      </ImageList> */}

      <section
        id="contactinfo-section"
        className="contactinfo-section av-py-default"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="av-container">
          <div className="av-columns-area">
            <div className="av-column-12">
              <div className="heading-default text-center wow fadeInUp">
                <h3>
                  Why Choose{" "}
                  <span className="primary-color">VVK Matrimony</span>
                </h3>
                <span className="separator">
                  <span>
                    <span></span>
                  </span>
                </span>
                {/* <p>
                  Most trusted online matrimonial exclusive for Arya Vysya
                  Community since 2008.
                </p> */}
              </div>
            </div>
          </div>
          <div className="av-columns-area contactinfo-row">
            <div className="av-column-3 av-sm-column-6 px-2">
              <div className="contactinfo-item">
                <i className="fa fa-camera"></i>
                <h5>
                  Enhanced <br /> Profile
                </h5>
                <p style={{ textAlign: "initial" }}>
                  Photo protection option. Personal notes option to keep track
                  of prospects. Personalized express interest. Show profile
                  option. Preferred partner settings. Pre populated matching
                  profiles.
                </p>
              </div>
            </div>
            <div className="av-column-3 av-sm-column-6 px-2">
              <div className="contactinfo-item">
                <i className="fa fa-user"></i>
                <h5>Wide Profile Coverage</h5>
                <p style={{ textAlign: "initial" }}>
                  Easy registration steps - less than 10 minutes. Largest
                  collection of profiles in Arya Vysya Community. Quick and
                  advanced search option to shortlist your matching profile.
                </p>
              </div>
            </div>
            <div className="av-column-3 av-sm-column-6 px-2">
              <div className="contactinfo-item">
                <i className="fa fa-headphones"></i>
                <h5>
                  Happy <br /> To Help{" "}
                </h5>
                <p style={{ textAlign: "initial" }}>
                  Matrimonial assistance thru customer support helpline +91
                 98942 34066 | email: vvkmatrimony12@gmail.com | WhatsApp at
                 98942 34066. We are here to help you to find your soulmate.
                </p>
              </div>
            </div>
            <div className="av-column-3 av-sm-column-6 px-2">
              <div className="contactinfo-item">
                <i className="fa fa-institution"></i>
                <h5>Matrimony Dashboard</h5>
                <p style={{ textAlign: "initial" }}>
                  Real time dashboard of number of visitors, viewed profiles,
                  shortlisted profiles, matching profile count, express interest
                  sent/received pre populated matching profiles.
                </p>
              </div>
            </div>
            {/* <div className="pricing-btn">
              <a href="/about" className="av-btn av-btn-secondary av-btn-bubble">Read More<i className="fa fa-arrow-right"></i> <span className="bubble_effect"><span className="circle top-left"></span> <span className="circle top-left"></span> <span className="circle top-left"></span> <span className="button effect-button"></span> <span className="circle bottom-right"></span> <span className="circle bottom-right"></span> <span className="circle bottom-right"></span></span></a>
            </div> */}
            <div className="cta-btn" style={{ display: "none" }}>
              <a href="/about" className="av-btn av-btn-primary av-btn-bubble">
                Read More<i className="fa fa-arrow-right"></i>{" "}
                <span className="bubble_effect">
                  <span className="circle top-left"></span>{" "}
                  <span className="circle top-left"></span>{" "}
                  <span className="circle top-left"></span>{" "}
                  <span className="button effect-button"></span>{" "}
                  <span className="circle bottom-right"></span>{" "}
                  <span className="circle bottom-right"></span>{" "}
                  <span className="circle bottom-right"></span>
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* <!--===// Start: CTA
        =================================--> */}
      <section
        id="cta-section"
        className="cta-section cta-home"
        style={{
          backgroundImage: "url('assets/images/cta_bg.jpg')",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="cta-overlay">
          <div className="av-container">
            <div className="av-columns-area">
              <div className="av-column-5 my-auto">
                <div className="call-wrapper">
                  <div className="call-icon-box">
                    <i className="fa fa-user"></i>
                  </div>
                  <div className="cta-info">
                    <div className="call-title">Call Us:</div>
                    <div className="call-phone">
                      <a href="tel:+919894234066">+(91) 98942 34066</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="av-column-7 my-auto">
                <div className="cta-content-wrap">
                  <div className="cta-content">
                    <span className="cta-icon-wrap">
                      <i className="fa fa-phone"></i>
                    </span>
                    <h4>Professional and Dedicated Consulting Services</h4>
                    <p>
                    Our mission is to help our members find their life partners with ease and convenience, using our advanced technology and personalized services.
                    </p>
                  </div>
                  <div className="cta-btn">
                    <a href="/contact" className="av-btn av-btn-primary av-btn-bubble">
                      Contact Us<i className="fa fa-arrow-right"></i>{" "}
                      <span className="bubble_effect">
                        <span className="circle top-left"></span>{" "}
                        <span className="circle top-left"></span>{" "}
                        <span className="circle top-left"></span>{" "}
                        <span className="button effect-button"></span>{" "}
                        <span className="circle bottom-right"></span>{" "}
                        <span className="circle bottom-right"></span>{" "}
                        <span className="circle bottom-right"></span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End: CTA
        =================================--> */}
    </div>
  );
};

export default ContactInfo;
